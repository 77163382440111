import {
  getItem as getStorageItem,
  setItem as setStorageItem,
  removeItem as removeStorageItem,
} from './storage';
import { STORAGE_KEYS } from '../constants';
import randomString from '../utils/randomString';

const tokenService = {
  getAuthToken(): string | null {
    return getStorageItem(STORAGE_KEYS.authTokenStorageKey);
  },

  setAuthToken(authToken: string) {
    setStorageItem(STORAGE_KEYS.authTokenStorageKey, authToken, {
      lifetime: 24 * 60 * 60 * 1000, // 24 hours
    });
  },

  removeAuthToken() {
    removeStorageItem(STORAGE_KEYS.authTokenStorageKey);
  },

  getRememberToken(): string | null {
    return getStorageItem(STORAGE_KEYS.rememberTokenStorageKey);
  },

  setRememberToken(rememberToken: string) {
    setStorageItem(STORAGE_KEYS.rememberTokenStorageKey, rememberToken, {
      lifetime: 30 * 24 * 60 * 60 * 1000, // 1 month
    });
  },

  removeRememberToken() {
    removeStorageItem(STORAGE_KEYS.rememberTokenStorageKey);
  },

  getDeviceToken(): string {
    let deviceToken = getStorageItem(STORAGE_KEYS.deviceTokenStorageKey);

    if (deviceToken == null) {
      deviceToken = randomString(32);
      setStorageItem(STORAGE_KEYS.deviceTokenStorageKey, deviceToken);
    }

    return deviceToken;
  },

  setDeviceToken(deviceToken: string) {
    setStorageItem(STORAGE_KEYS.deviceTokenStorageKey, deviceToken);
  },

  removeDeviceToken() {
    removeStorageItem(STORAGE_KEYS.deviceTokenStorageKey);
  },
};

export default tokenService;
