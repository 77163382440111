import { ReactNode } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { COLOR, GREYSCALE } from '../styles/colors';
import { TYPOGRAPHY } from '../styles/typography';
import { SPACING } from '../styles/spacing';

const badgeStyles = {
  variants: {
    cheapest: {
      backgroundColor: COLOR.darkGreen,
    },
    fastest: {
      backgroundColor: COLOR.blue,
    },
    best: {
      backgroundColor: GREYSCALE.black,
    },
  },
};

const Styled = {
  Badge: styled.span`
    font-size: ${TYPOGRAPHY.fontSize.xs};
    text-transform: uppercase;
    color: ${GREYSCALE.white};
    padding: ${SPACING.xs} ${SPACING.sm};
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    margin-right: 5px;
    &.cheapest {
      background-color: ${badgeStyles.variants.cheapest.backgroundColor};
    }
    &.fastest {
      background-color: ${badgeStyles.variants.fastest.backgroundColor};
    }
    &.best {
      background-color: ${badgeStyles.variants.best.backgroundColor};
    }
  `,
};

export const BadgeSelector = Styled.Badge;

export type BadgeProps = {
  variant: 'cheapest' | 'fastest' | 'best';
  children?: ReactNode;
};

function Badge({ variant, children, ...others }: BadgeProps) {
  return (
    <Styled.Badge className={clsx(variant)} {...others}>
      {children}
    </Styled.Badge>
  );
}

export default Badge;
