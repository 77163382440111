import english from './en';

export default function setLocale(locale: string) {
  if (!/^[a-z]{2}(-[A-Z]{2})?$/.test(locale)) {
    throw new Error(`Invalid locale: ${locale}`);
  }

  switch (locale) {
    case 'en-US':
    case 'en':
    default:
      english();
  }
}
