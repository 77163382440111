import styled from '@emotion/styled';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { GREYSCALE } from '../../styles/colors';
import { BORDER_RADIUS } from '../../styles/borders';
import Z_INDEX from '../../styles/zindex';

const Styled = {
  Tooltip: styled(ReactTooltip)`
    z-index: ${Z_INDEX.tooltip};
    background-color: ${GREYSCALE.black};
    color: ${GREYSCALE.white};
    border-radius: ${BORDER_RADIUS.sm};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-width: 276px;
  `,
};

export type TooltipProps = React.PropsWithChildren<{
  anchorSelector: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}>;

export default function Tooltip({ children, anchorSelector, placement = 'top' }: TooltipProps) {
  return (
    <Styled.Tooltip anchorSelect={anchorSelector} place={placement}>
      {children}
    </Styled.Tooltip>
  );
}
