import { css } from '@emotion/react';
import { COLOR } from '../styles/colors';
import { SPACING } from '../styles/spacing';

export default css`
  color: ${COLOR.blue};
  text-decoration: none;
  cursor: pointer;

  :hover,
  :focus {
    color: ${COLOR.darkBlue};
  }
  :focus-visible {
    outline-color: ${COLOR.blue};
    outline-offset: ${SPACING.sm};
  }
`;
