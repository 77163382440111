import { useEffect, useState, forwardRef, Ref, SyntheticEvent } from 'react';
import useImagePreloader from '../../hooks/useImagePreloader';
import useWindowSize from '../../hooks/useWindowSize';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import Icon from '../Icon';
import useForwardedRef from '../../hooks/useForwardedRef';
import DropdownSelect, {
  DropdownSelectOption,
  DropdownSelectProps,
  StyledDropdownImage,
  StyledDropdownIconWrapper,
  StyledLeftCaretIcon,
  StyledTitleWrapper,
  StyledDropdownSelectTitle,
  StyledDropdownDescription,
} from './DropdownSelect';

export type DropdownSetSelectOption<V extends string | number = string | number> =
  DropdownSelectOption<V> & {
    set?: string; // if provided, the option belongs to the named set of options, otherwise default (used in a multi-level dropdown)
    linkToSet?: string; // if provided, the option is a link to a different set of options (with the same "set" key) (used in a multi-level dropdown)
  };

export type DropdownSetSelectProps<V extends string | number = string | number> =
  DropdownSelectProps<V, DropdownSetSelectOption<V>>;

function getOptionsInSet<V extends string | number>(
  options: DropdownSetSelectOption<V>[],
  set: string,
) {
  return options.filter((option) => (set === 'default' ? !option.set : option.set === set));
}

function DropdownSetSelectComponent<V extends string | number = string | number>(
  props: DropdownSetSelectProps<V>,
  ref: Ref<HTMLSelectElement>,
) {
  const {
    options,
    value,
    name,
    defaultValue,
    disabled: selectDisabled,
    onChange,
    onRenderOption,
    imageSize,
    error,
    prefill,
    id,
    ...others
  } = props;

  const preloadImage = useImagePreloader();
  const [windowWidth] = useWindowSize();
  const openDropdownMenuRef = useForwardedRef(ref);
  const [selectedValue, setSelectedValue] = useState(value ?? defaultValue ?? options[0].value);

  const selectedOption = options.find((option) => option.value === selectedValue) ?? options[0];
  const [currentSet, setCurrentSet] = useState<string>('default');
  const [currentOptions, setCurrentOptions] = useState(getOptionsInSet(options, 'default'));

  // Preload all icons when options change (usually just after mount)
  useEffect(() => {
    options.flatMap((option) => (option.iconUrl ? [option.iconUrl] : [])).forEach(preloadImage);
  }, [options, preloadImage]);

  // React to value changes
  useEffect(() => {
    setSelectedValue(value ?? defaultValue ?? options[0].value);
  }, [value, defaultValue, options]);

  const onClickOption = (v: V, event: SyntheticEvent<any, any>) => {
    const newSelectedOption = options.find((option) => option.value === v) ?? options[0];
    const { disabled: optionDisabled, linkToSet, value: optionValue } = newSelectedOption;
    if (optionDisabled) {
      return;
    }
    if (linkToSet) {
      const newOptions = getOptionsInSet(options, linkToSet);
      setCurrentSet(linkToSet);
      setCurrentOptions([selectedOption, ...newOptions]); // don't change the selected value when clicking a link
      // if on mobile, scroll the page to the top of the select again
      if (windowWidth < parseInt(MEDIA_QUERY.xsMax)) {
        openDropdownMenuRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      event.stopPropagation(); // stops dropdown from closing
      return;
    }

    // uncontrolled DropdownSelect
    if (value === undefined) {
      setSelectedValue(optionValue);
    }
    const newOptions = getOptionsInSet(options, currentSet);
    setCurrentOptions(newOptions);
    onChange?.(optionValue, event);
  };

  const renderOption = (option: DropdownSetSelectOption<V>) => {
    const { iconUrl, title = '', description, linkToSet } = option;

    const isLinkToNonDefaultSet = !!linkToSet && linkToSet !== 'default';

    return (
      <>
        {iconUrl && linkToSet !== 'default' && (
          <StyledDropdownImage src={iconUrl} imageSize={imageSize} />
        )}
        {linkToSet === 'default' && (
          <StyledDropdownIconWrapper>
            <StyledLeftCaretIcon icon="caret-left" size="lg" />
          </StyledDropdownIconWrapper>
        )}
        <StyledTitleWrapper>
          <StyledDropdownSelectTitle>{title}</StyledDropdownSelectTitle>
          <StyledDropdownDescription>{description}</StyledDropdownDescription>
        </StyledTitleWrapper>

        {isLinkToNonDefaultSet && (
          <StyledDropdownIconWrapper>
            <Icon icon="caret-right" size="lg" />
          </StyledDropdownIconWrapper>
        )}
      </>
    );
  };

  return (
    <DropdownSelect
      onRenderOption={renderOption}
      onChange={(v, e) => onClickOption?.(v, e)}
      ref={openDropdownMenuRef}
      options={currentOptions}
      value={selectedValue}
      defaultValue={defaultValue}
      disabled={selectDisabled}
      name={name}
      imageSize={imageSize}
      id={id}
      {...others}
    />
  );
}

const DropdownSetSelect = forwardRef(DropdownSetSelectComponent);
export default DropdownSetSelect;
