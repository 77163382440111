import { css, Global } from '@emotion/react';

export default function ScrollBlocker() {
  return (
    <Global
      styles={css`
        body {
          overflow: hidden;
          position: relative;
        }
      `}
    />
  );
}
