import { StorageKey } from '../constants';

export type StorageSharedOptions = {
  session?: boolean;
};
export type StorageRetrieveOptions = StorageSharedOptions;
export type StoragePersistOptions = StorageSharedOptions & {
  lifetime?: number;
};
export type StorageRemoveOptions = StorageSharedOptions;
export type StorageClearOptions = StorageSharedOptions;

type StorageItem = {
  created: number;
  updated: number;
  lifetime?: number;
  value: any;
};

let inMemoryStorage: Record<string, StorageItem> = {};

function getStorageInterface({ session }: StorageSharedOptions) {
  return session ? sessionStorage : localStorage;
}

function retrieveItem(key: string, options: StorageRetrieveOptions): StorageItem | null {
  if (Object.prototype.hasOwnProperty.call(inMemoryStorage, key)) {
    return inMemoryStorage[key];
  }

  try {
    const storage = getStorageInterface(options);
    const serializedItem = storage.getItem(key);
    return serializedItem ? (JSON.parse(serializedItem) as StorageItem) : null;
  } catch (e) {
    return null;
  }
}

function persistItem(key: string, item: StorageItem, options: StoragePersistOptions): void {
  inMemoryStorage[key] = item;

  try {
    const storage = getStorageInterface(options);
    const serializedItem = JSON.stringify(item);
    storage.setItem(key, serializedItem);
  } catch (e) {
    // noop
  }
}

export function setItem<ItemType = any>(
  key: StorageKey,
  value: ItemType,
  options: StoragePersistOptions = {},
): void {
  const item: Partial<StorageItem> = { ...retrieveItem(key, options) };
  const now = Date.now();

  item.created = item.created || now;
  item.updated = now;
  item.lifetime = options.lifetime;
  item.value = value;

  persistItem(key, item as StorageItem, options);
}

export function getItem<ItemType = any>(
  key: StorageKey,
  options: StorageRetrieveOptions = {},
): ItemType | null {
  const item = retrieveItem(key, options);

  if (!item) {
    return null;
  }

  if (item.lifetime && item.updated + item.lifetime < Date.now()) {
    removeItem(key, options);
    return null;
  }

  return item.value;
}

export function removeItem(key: StorageKey, options: StorageRemoveOptions = {}): void {
  delete inMemoryStorage[key];

  const storage = getStorageInterface(options);
  storage.removeItem(key);
}

export function clear(options: StorageRemoveOptions = {}): void {
  inMemoryStorage = {};

  const storage = getStorageInterface(options);
  storage.clear();
}

const storageService = {
  setItem,
  getItem,
  removeItem,
  clear,
};

export default storageService;
