export type FormatCurrencyOptions = {
  showCents?: boolean;
  showDollarSign?: boolean;
};

export default function formatCurrency(
  value: number | null,
  options?: FormatCurrencyOptions,
): string {
  if (value == null) return 'INVALID_CURRENCY';
  const { showCents = true, showDollarSign = true } = options ?? {};
  const showDollarSignOptions = showDollarSign
    ? {
        style: 'currency',
        currency: 'USD',
      }
    : {};
  return new Intl.NumberFormat('en-US', {
    ...showDollarSignOptions,
    minimumFractionDigits: showCents ? 2 : 0,
    maximumFractionDigits: showCents ? 2 : 0,
  }).format(value);
}
