import { useContext, createContext, FunctionComponent, ReactNode, useMemo, Children } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Container as ReactGSContainer,
  Col as ReactGSCol,
  Row as ReactGSRow,
} from 'react-grid-system';
import { SPACING } from '../../styles/spacing';
import { BORDER_WIDTH } from '../../styles/borders';
import { COLOR } from '../../styles/colors';
import { GRID_COLUMNS } from '../../styles/grid';

export type ChildrenProps = {
  children: ReactNode;
};

export type ContainerProps = {
  debugMode?: boolean;
  noGutter?: boolean;
  role?: string;
};

type StyledColOrRowProps = {
  spaceBelow?: boolean;
  debugMode?: boolean;
};

// this options object prevents Emotion from forwarding all props to the dom as string tags,
// which causes warnings in the console
const options = {
  shouldForwardProp: (prop: string) => !['noGutter', 'spaceBelow', 'debugMode'].includes(prop),
};

const Styled = {
  Container: styled(ReactGSContainer, options)<ContainerProps>`
    ${({ debugMode }) =>
      debugMode &&
      css`
        outline: ${BORDER_WIDTH.xs} solid ${COLOR.lightBlue};
      `}

    ${({ noGutter }) =>
      noGutter &&
      css`
        padding-left: ${SPACING.none} !important;
        padding-right: ${SPACING.none} !important;
      `}
  `,
  Col: styled(ReactGSCol, options)<StyledColOrRowProps>`
    margin-bottom: ${({ spaceBelow }) => (spaceBelow ? SPACING.xl : SPACING.none)};
    border: ${({ debugMode }) => (debugMode ? `${BORDER_WIDTH.xs} solid ${COLOR.green}` : 'none')};
  `,
  Row: styled(ReactGSRow, options)<StyledColOrRowProps>`
    margin-bottom: ${({ spaceBelow }) => (spaceBelow ? SPACING.xl : SPACING.none)};
    ${({ debugMode }) =>
      debugMode &&
      css`
        border: ${BORDER_WIDTH.xs} solid ${COLOR.red};
      `}
  `,
};

// For containers, rows and cols, debug mode is default set to false. It is only true when the <GridDebugMode> tag wraps them
const GridDebugContext = createContext({ debugMode: false });

// Use <GridDebugMode> as a wrapper around your grid if you want to see the grid's outlines
export function GridDebugMode({ children }: ChildrenProps) {
  const value = useMemo(() => ({ debugMode: true }), []);
  return <GridDebugContext.Provider value={value}>{children}</GridDebugContext.Provider>;
}

export function PageContainer({ children, ...others }: ChildrenProps & ContainerProps) {
  return (
    <Styled.Container noGutter fluid style={{ paddingLeft: 0, paddingRight: 0 }} {...others}>
      {children}
    </Styled.Container>
  );
}

export function Container({ children, ...others }: ChildrenProps & ContainerProps) {
  return (
    <Styled.Container fluid {...others}>
      {children}
    </Styled.Container>
  );
}

const withDebugMode = <P extends {}>(Component: FunctionComponent<P>) =>
  function DebugModeHoc(props: P) {
    const { debugMode } = useContext(GridDebugContext);
    return <Component debugMode={debugMode} {...props} />;
  };

export const Row = withDebugMode(Styled.Row);

export const Col = withDebugMode(Styled.Col);

export const CenterCol = styled(ReactGSCol)`
  margin-bottom: ${SPACING.xl};
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

export const SubmitCol = styled(ReactGSCol)`
  margin-top: 40px;
`;

type AutoGridRowProps = Omit<StyledColOrRowProps, 'spaceBelow'> &
  ChildrenProps & {
    noSpaceBelow?: boolean;
  };
export function AutoGridRow({ children, noSpaceBelow = false, ...others }: AutoGridRowProps) {
  const childNodes = Children.toArray(children); // .filter((child) => isValidElement(child)) as ReactElement[];
  const colWidth = Math.floor(GRID_COLUMNS / childNodes.length);

  // we use another render function to make TypeScript happy - not using an array index as key
  const withCol = ({ children: child, key }: ChildrenProps & { key: string }) => (
    <Col key={key} xs={12} sm={12} md={colWidth} spaceBelow={!noSpaceBelow} {...others}>
      {child}
    </Col>
  );

  return (
    <Row>{childNodes.map((child, index) => withCol({ children: child, key: `${index}` }))}</Row>
  );
}
