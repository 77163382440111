import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ALERT_COLOR, GREYSCALE } from '../styles/colors';
import withOpacity from '../utils/withOpacity';
import { BORDER_RADIUS, BORDER_WIDTH } from '../styles/borders';
import { SPACING } from '../styles/spacing';

export type AlertVariant = 'success' | 'info' | 'warning' | 'danger';

export type AlertProps = PropsWithChildren<{
  variant: AlertVariant;
}>;

const Styled = {
  Alert: styled.div<AlertProps>`
    padding: ${SPACING.md};
    margin-bottom: 20px;
    border: ${BORDER_WIDTH.xs} solid transparent;
    border-radius: ${BORDER_RADIUS.xs};
    text-shadow: 0 1px 0 ${withOpacity(GREYSCALE.white, 0.2)};
    box-shadow: inset 0 1px 0 ${withOpacity(GREYSCALE.white, 0.25)},
      0 1px 2px ${withOpacity(GREYSCALE.black, 0.05)};

    hr {
      border: none;
      border-top: ${BORDER_WIDTH.xs} solid transparent;
    }

    /* color of background, text and border based on the variant */
    border-color: ${({ variant }) => ALERT_COLOR.border[variant]};
    color: ${({ variant }) => ALERT_COLOR.color[variant]};

    ${({ variant }) => css`
      background: ${ALERT_COLOR.background[variant]}
        linear-gradient(
          to bottom,
          ${ALERT_COLOR.background[variant]} 0%,
          ${ALERT_COLOR.gradient[variant]} 100%
        )
        repeat-x;
    `};

    hr {
      ${({ variant }) => ALERT_COLOR.borderTop[variant]};
    }
  `,
};

export default function Alert({ variant, ...others }: AlertProps) {
  return <Styled.Alert variant={variant} {...others} />;
}
