/**
 * Take an input and clamp it between two extreme values
 */
export default function clamp(input: number, min: number, max: number): number {
  if (input < min) {
    return min;
  }

  if (input > max) {
    return max;
  }

  return input;
}
