// Common
import Parcel from './Parcel.png';
import SoftEnvelope from './SoftEnvelope.png';
// USPS
import ExpressFlatRateEnvelope from './usps/ExpressFlatRateEnvelope.png';
import ExpressFlatRateLegalEnvelope from './usps/ExpressFlatRateLegalEnvelope.png';
import ExpressFlatRatePaddedEnvelope from './usps/ExpressFlatRatePaddedEnvelope.png';
import FlatRateEnvelope from './usps/FlatRateEnvelope.png';
import FlatRateLegalEnvelope from './usps/FlatRateLegalEnvelope.png';
import FlatRatePaddedEnvelope from './usps/FlatRatePaddedEnvelope.png';
import LargeFlatRateBox from './usps/LargeFlatRateBox.png';
import MediumFlatRateBox from './usps/MediumFlatRateBox.png';
import RegionalRateBoxA from './usps/RegionalRateBoxA.png';
import RegionalRateBoxB from './usps/RegionalRateBoxB.png';
import SmallFlatRateBox from './usps/SmallFlatRateBox.png';
// UPS
import ups01 from './ups/01.png';
import ups2a from './ups/2a.png';
import ups2b from './ups/2b.png';
import ups2c from './ups/2c.png';
import ups03 from './ups/03.png';
import ups04 from './ups/04.png';

const packageTypeIcons = {
  ExpressFlatRateEnvelope,
  ExpressFlatRateLegalEnvelope,
  ExpressFlatRatePaddedEnvelope,
  FlatRateEnvelope,
  FlatRateLegalEnvelope,
  FlatRatePaddedEnvelope,
  LargeFlatRateBox,
  MediumFlatRateBox,
  Parcel,
  RegionalRateBoxA,
  RegionalRateBoxB,
  SmallFlatRateBox,
  SoftEnvelope,
  '01': ups01,
  '2a': ups2a,
  '2b': ups2b,
  '2c': ups2c,
  '03': ups03,
  '04': ups04,
};

export default packageTypeIcons;
export type PackageTypeIconKeys = keyof typeof packageTypeIcons;
