import unescape from 'lodash.unescape';
import { ReactFragment, ReactNode } from 'react';
import IntercomArticleLink from '../components/IntercomArticleLink';
import { ExternalLink, MaybeExternalLink } from '../components/Link';

type Replacement = [RegExp, (matches: RegExpExecArray, key: number) => ReactNode];

const replacements: Replacement[] = [
  // [p]paragraphs[/p]
  [/\[p\](.*?)\[\/p\]/s, (matches, key) => <p key={key}>{convertBbcode(matches[1])}</p>],

  // [b]bold text[/b]
  [/\[b\](.*?)\[\/b\]/s, (matches, key) => <strong key={key}>{convertBbcode(matches[1])}</strong>],

  // [br] line break
  [/\[br\]/s, () => <br />],

  // [h1]headline[/h1]
  [/\[h1\](.*?)\[\/h1\]/s, (matches, key) => <h1 key={key}>{convertBbcode(matches[1])}</h1>],

  // [h2]heading[/h2]
  [/\[h2\](.*?)\[\/h2\]/s, (matches, key) => <h2 key={key}>{convertBbcode(matches[1])}</h2>],

  // [i]italic text[/i]
  [/\[i\](.*?)\[\/i\]/s, (matches, key) => <em key={key}>{convertBbcode(matches[1])}</em>],

  // [pre]preformatted text[/pre]
  [/\[pre\](.*?)\[\/pre\]/s, (matches, key) => <pre key={key}>{convertBbcode(matches[1])}</pre>],

  // [u]underlined text[/u]
  [
    /\[u\](.*?)\[\/u\]/s,
    (matches, key) => (
      <span key={key} style={{ textDecoration: 'underline' }}>
        {convertBbcode(matches[1])}
      </span>
    ),
  ],

  // [red]red text[/red]
  [
    /\[red\](.*?)\[\/red\]/s,
    (matches, key) => (
      <span key={key} style={{ color: 'red' }}>
        {convertBbcode(matches[1])}
      </span>
    ),
  ],

  // [link=https://... type]link text[/link]
  [
    /\[link=([^\] ]+)[ ]{1}([A-Za-z\-_0-9]+)\]([^\]]+)\[\/link\]/,
    (matches, key) => {
      const [, href, type, text] = matches;

      if (type === 'intercom-link') {
        return (
          <IntercomArticleLink key={key} href={href}>
            {text}
          </IntercomArticleLink>
        );
      }

      if (type === 'start-intercom-dialogue') {
        return (
          <ExternalLink
            key={key}
            href={href}
            onClick={(e) => {
              if ('Intercom' in window) {
                e.preventDefault();
                window.Intercom('showNewMessage');
              } else {
                // eslint-disable-next-line no-console
                console.error('Intercom not active');
              }
            }}
          >
            {text}
          </ExternalLink>
        );
      }

      return (
        <MaybeExternalLink key={key} href={href}>
          {text}
        </MaybeExternalLink>
      );
    },
  ],
  // [link=https://...]link text[/link]
  [
    /\[link=(.+?)\](.+?)\[\/link\]/,
    (matches, key) => (
      <MaybeExternalLink key={key} href={matches[1]}>
        {convertBbcode(matches[2])}
      </MaybeExternalLink>
    ),
  ],

  // [ul]...[/ul]
  [/\[ul\](.*?)\[\/ul\]/s, (matches, key) => <ul key={key}>{convertBbcode(matches[1])}</ul>],

  // [li]...[/li]
  [/\[li\](.*?)\[\/li\]/s, (matches, key) => <li key={key}>{convertBbcode(matches[1])}</li>],
];

export default function convertBbcode(text: string): ReactFragment {
  const result: ReactNode[] = [unescape(text)];
  let key = 0;

  replacements.forEach(([pattern, replacer]) => {
    // for each rule, keep parsing over the result array until no more instances of the pattern are found
    for (let i = 0; i < result.length; i++) {
      const part = result[i];
      if (part && typeof part === 'string') {
        const match = pattern.exec(part);
        if (match !== null) {
          const node = replacer(match, key);
          const beforeMatch = part.slice(0, match.index);
          const afterMatch = part.slice(match.index + match[0].length);
          key += 1;
          result.splice(i, 1, beforeMatch, node, afterMatch);
          i = -1; // go back to start
        }
      }
    }
  });

  return result.filter((str) => str !== '');
}
