import { useNavigate, useLocation } from 'react-router-dom';
import IntercomArticleModal from './modals/IntercomArticleModal';

export default function GlobalIntercomArticle() {
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();

  return (
    <IntercomArticleModal
      open={hash.startsWith('#help=')}
      url={hash.replace('#help=', '')}
      onClose={() => navigate(`${pathname}${search}`)}
      onLocationChange={(url) => navigate(`#help=${url}`)}
    />
  );
}
