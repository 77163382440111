import { useRef, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { QueryResult } from '@apollo/client';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { GREYSCALE } from '../../styles/colors';
import { Row, Col, PageContainer } from '../layout/Grid';
import FormControl from '../form/FormControl';
import { MultiFieldErrorMessage } from '../form/ErrorMessage';
import Label from '../form/Label';
import TextField from '../form/TextField';
import DropdownSetSelect from '../form/DropdownSetSelect';
import { useGetRatesLazyQuery } from '../../operations/queries/rates';
import { DimensionsConfig, usZip, WeightConfig } from '../../validation/validators';
import packageTypeIcons from '../../assets/packageTypeIcons';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { TYPOGRAPHY } from '../../styles/typography';
import { SPACING } from '../../styles/spacing';
import zip3ToState from '../../assets/data/zip3ToState.json';
import DimensionCol from './DimensionCol';
import ProgressButton from '../form/ProgressButton';
import { toOunces } from '../../utils/formatWeight';
import { GetRatesQuery, GetRatesQueryVariables } from '../../gql/graphql';
import { useShipmentBoundariesQuery } from '../../operations/queries/shipmentBoundaries';
import PageLoading from '../loading/PageLoading';

function getPackageTypes() {
  const uspsMailClassKeys = (isInternational: boolean) =>
    isInternational
      ? [
          'FirstClassPackageInternationalService',
          'PriorityMailInternational',
          'PriorityMailExpressInternational',
        ]
      : [
          'PriorityExpress',
          'Priority',
          'Priority_Cubic',
          'GroundAdvantage',
          'GroundAdvantage_Cubic',
        ];
  const upsMailClassKeys = (isInternational: boolean) =>
    isInternational
      ? ['07', '08', '11', '54', '65']
      : ['14', '01', '13', '59', '02', '12', '03', '03_cubic', '92', '93'];

  const mailClassKeys = (isInternational: boolean) => [
    ...uspsMailClassKeys(isInternational),
    ...upsMailClassKeys(isInternational),
  ];

  // filter out cubic services when no dimensions entered
  const pricingTypes = (dimensionX?: string, dimensionY?: string) =>
    dimensionX === '' && dimensionY === ''
      ? ['weight', 'flat_rate']
      : ['weight', 'cubic', 'flat_rate'];

  // a set of standard parameters we send with every ups request
  const upsStandardParameters = {
    dimensionX: 10,
    dimensionY: 10,
    dimensionZ: 10,
    weight: 16,
    dimensionsRequired: false,
    heightRequired: false,
    weightRequired: true,
  };

  const packageTypes = [
    {
      description: 'Any custom box or thick parcel',
      packageTypeKey: 'Parcel',
      title: 'Box or Rigid Packaging',
      dimensionsRequired: true,
      heightRequired: true,
      weightRequired: true,
      iconUrl: packageTypeIcons.Parcel,
      dimensionX: 10,
      dimensionY: 10,
      dimensionZ: 10,
      weight: undefined,
      mailClassKeys,
      packageTypeKeys: () => ['Parcel'],
      pricingTypes,
    },
    {
      title: 'Soft Pack, Padded or Flat Envelope, or Box in a Bag',
      description:
        'Measure & use the Length and Width of the Envelope before putting anything in it',
      packageTypeKey: 'SoftEnvelope',
      dimensionsRequired: true,
      heightRequired: false,
      weightRequired: true,
      iconUrl: packageTypeIcons.SoftEnvelope,
      dimensionX: 6,
      dimensionY: 3,
      dimensionZ: undefined,
      weight: 16,
      mailClassKeys,
      packageTypeKeys: () => ['SoftEnvelope'],
      pricingTypes,
    },
    {
      title: 'USPS Flat Rate Packaging',
      description: 'Flat Rate boxes and envelope options',
      packageTypeKey: 'UspsSmallFlatRateBox',
      dimensionsRequired: false,
      heightRequired: false,
      weightRequired: false,
      iconUrl: packageTypeIcons.FlatRatePaddedEnvelope,
      dimensionX: 10,
      dimensionY: 10,
      dimensionZ: 10,
      weight: 16,
      mailClassKeys: uspsMailClassKeys,
      packageTypeKeys: (isInternational: boolean) =>
        isInternational
          ? [
              // Do not request rates for regional rate boxes as they are not available for international shipments.
              // Do not request rates for Padded and Legal envelopes as they have the same price as normal envelopes
              // for international shipments.
              'SmallFlatRateBox',
              'MediumFlatRateBox',
              'LargeFlatRateBox',
              'FlatRateEnvelope',
              'ExpressFlatRateEnvelope',
            ]
          : [
              'SmallFlatRateBox',
              'MediumFlatRateBox',
              'LargeFlatRateBox',
              'FlatRateEnvelope',
              'FlatRatePaddedEnvelope',
              'FlatRateLegalEnvelope',
              'ExpressFlatRateEnvelope',
              'ExpressFlatRatePaddedEnvelope',
              'ExpressFlatRateLegalEnvelope',
              'RegionalRateBoxA',
              'RegionalRateBoxB',
            ],
      pricingTypes,
    },
    {
      title: 'UPS Packaging',
      description: 'UPS-branded boxes and envelopes',
      iconUrl: packageTypeIcons['2b'],
      linkToSet: 'UPS',
      packageTypeKey: 'LINK_TO_UPS',
      mailClassKeys,
      packageTypeKeys: () => [],
      pricingTypes,
    },
    {
      linkToSet: 'default',
      packageTypeKey: 'LINK_TO_DEFAULT',
      set: 'UPS',
      title: 'Back to other package types',
      mailClassKeys,
      packageTypeKeys: () => [],
      pricingTypes,
    },
    {
      title: 'UPS Express Box Small',
      description: 'Small Express Box',
      packageTypeKey: '2a',
      set: 'UPS',
      iconUrl: packageTypeIcons['2a'],
      ...upsStandardParameters,
      mailClassKeys: upsMailClassKeys,
      packageTypeKeys: () => ['2a'],
      pricingTypes,
    },
    {
      title: 'UPS Express Box Medium',
      description: 'Medium Express Box',
      packageTypeKey: '2b',
      set: 'UPS',
      iconUrl: packageTypeIcons['2b'],
      ...upsStandardParameters,
      mailClassKeys: upsMailClassKeys,
      packageTypeKeys: () => ['2b'],
      pricingTypes,
    },
    {
      title: 'UPS Express Box Large',
      description: 'Large Express Box',
      packageTypeKey: '2c',
      set: 'UPS',
      iconUrl: packageTypeIcons['2c'],
      ...upsStandardParameters,
      mailClassKeys: upsMailClassKeys,
      packageTypeKeys: () => ['2c'],
      pricingTypes,
    },
    {
      title: 'UPS Express Tube',
      description: 'Totally Tubular',
      packageTypeKey: '03',
      set: 'UPS',
      iconUrl: packageTypeIcons['03'],
      ...upsStandardParameters,
      mailClassKeys: upsMailClassKeys,
      packageTypeKeys: () => ['03'],
      pricingTypes,
    },
    {
      title: 'UPS Express Pak',
      description: 'Polymailer Express Pak',
      packageTypeKey: '04',
      set: 'UPS',
      iconUrl: packageTypeIcons['04'],
      ...upsStandardParameters,
      mailClassKeys: upsMailClassKeys,
      packageTypeKeys: () => ['04'],
      pricingTypes,
    },
    {
      title: 'UPS Express Envelope',
      description: 'Cardboard Envelope',
      packageTypeKey: '01',
      set: 'UPS',
      iconUrl: packageTypeIcons['01'],
      ...upsStandardParameters,
      mailClassKeys: upsMailClassKeys,
      packageTypeKeys: () => ['01'],
      pricingTypes,
    },
  ];

  return packageTypes;
}

const Styled = {
  CalcWrapper: styled.div`
    max-width: 500px;
    margin: 0 auto;

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      max-width: 350px;
    }
  `,
  Operator: styled.span`
    display: flex;
    justify-content: center;
    margin-top: ${SPACING.lg};
    font-weight: ${TYPOGRAPHY.fontSize.sm};
    color: ${GREYSCALE.grey50};
    @media (max-width: ${MEDIA_QUERY.smMax}) {
      margin-top: ${SPACING.none};
    }
  `,

  ResidentialFlagWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
};

export type PublicRatesCalculatorValues = {
  originZip: string;
  isResidential: boolean;
  destination: string;
  packageTypeKey: string;
  dimensionX?: string;
  dimensionY?: string;
  dimensionZ?: string;
  weightPounds: number | '';
  weightOunces: number | '';

  // virtual fields only used for validation
  combinedDimensions?: never;
  combinedWeight?: never;
};

const initialValues: PublicRatesCalculatorValues = {
  originZip: '',
  isResidential: true,
  destination: '',
  packageTypeKey: 'Parcel',
  dimensionX: '',
  dimensionY: '',
  dimensionZ: '',
  weightPounds: '',
  weightOunces: '',
};

const initValidationSchema = (
  boundaries: DimensionsConfig & WeightConfig,
  currentPackageType: any,
) => {
  const { dimensionsRequired, heightRequired, weightRequired } = currentPackageType;
  return yup.object<PublicRatesCalculatorValues>({
    originZip: yup.string().usZip().required(),
    isResidential: yup.boolean(),
    destination: yup.string().required('Enter a valid zipcode or country'),
    packageTypeKey: yup.string(),
    dimensionX: yup.string(),
    dimensionY: yup.string(),
    dimensionZ: yup.string(),
    combinedDimensions: (() => {
      if (dimensionsRequired) {
        return heightRequired
          ? yup.object().packageDimensions3dPublicRates(boundaries)
          : yup.object().packageDimensions2dPublicRates(boundaries);
      }
      return yup.object();
    })(),
    weightPounds: yup.number().min(0).default(0),
    weightOunces: yup.number().min(0).default(0),
    combinedWeight: (() =>
      weightRequired ? yup.object().packageWeight(boundaries) : yup.object())(),
  });
};

export type RateResults = QueryResult<GetRatesQuery, GetRatesQueryVariables>;

export type PublicRatesCalculatorFormProps = {
  onRateResults: (rateResults: RateResults, values: PublicRatesCalculatorValues) => void;
};

export default function PublicRatesCalculatorForm({
  onRateResults,
}: PublicRatesCalculatorFormProps) {
  const formRef = useRef<FormikProps<PublicRatesCalculatorValues>>(null);
  const [currentPackageTypeKey, setCurrentPackageTypeKey] = useState<string>('Parcel');
  const { data: boundariesData, loading: boundariesLoading } = useShipmentBoundariesQuery();

  const [fetchRates, { loading }] = useGetRatesLazyQuery({
    context: { errorHandled: true },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const packageTypes = getPackageTypes();
  const packageTypeOptions = useMemo(
    () =>
      packageTypes?.map((item: any) => ({
        value: item.packageTypeKey,
        title: item.title,
        description: item.description,
        iconUrl: item.iconUrl,
        set: item.set,
        linkToSet: item.linkToSet,
      })),
    [packageTypes],
  );

  const currentPackageType = useMemo(
    () =>
      packageTypes?.find((packageType) => packageType.packageTypeKey === currentPackageTypeKey)!,
    [packageTypes, currentPackageTypeKey],
  );

  const boundaries = boundariesData?.shipmentBoundaries as DimensionsConfig & WeightConfig;
  const validationSchema = useMemo(
    () => boundaries && currentPackageType && initValidationSchema(boundaries, currentPackageType),
    [boundaries, currentPackageType],
  );

  if (boundariesLoading)
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );

  return (
    <Styled.CalcWrapper>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values) => {
          const {
            originZip,
            destination,
            isResidential,
            dimensionX,
            dimensionY,
            dimensionZ,
            weightPounds,
            weightOunces,
          } = values;
          const isInternational = usZip(destination) !== undefined;

          // Resolve origin state
          const zip3 = originZip.slice(0, 3) as keyof typeof zip3ToState;
          const originRegionCode: string | undefined = zip3ToState[zip3];

          const results = await fetchRates({
            variables: {
              originZip,
              originRegionCode,
              isResidential,
              destinationZip: isInternational ? '' : destination,
              destinationCountryCode: isInternational ? destination : 'US',
              mailClassKeys: currentPackageType?.mailClassKeys(isInternational) || [],
              packageTypeKeys: currentPackageType?.packageTypeKeys(isInternational) || [],
              pricingTypes: currentPackageType?.pricingTypes(dimensionX, dimensionY) || [],
              dimensionX:
                currentPackageType?.dimensionsRequired && dimensionX
                  ? +dimensionX
                  : currentPackageType?.dimensionX,
              dimensionY:
                currentPackageType?.dimensionsRequired && dimensionY
                  ? +dimensionY
                  : currentPackageType?.dimensionY,
              dimensionZ:
                currentPackageType?.heightRequired && dimensionZ
                  ? +dimensionZ
                  : currentPackageType?.dimensionZ,
              weight: currentPackageType?.weightRequired
                ? toOunces({ pounds: Number(weightPounds), ounces: Number(weightOunces) })
                : currentPackageType?.weight,
            },
          });

          onRateResults(results, values);
        }}
      >
        {({ values, errors, touched }) => {
          const packageType = packageTypes.find(
            (item) => item.packageTypeKey === values.packageTypeKey,
          );

          // Show form-level error only if all fields were touched and don't have
          // field-level errors themselves
          const showDimensionsError =
            errors.combinedDimensions !== undefined &&
            touched.dimensionX &&
            touched.dimensionY &&
            (!packageType?.heightRequired || touched.dimensionZ) &&
            !errors.dimensionX &&
            !errors.dimensionY &&
            !errors.dimensionZ;
          const showWeightError =
            errors.combinedWeight !== undefined &&
            touched.weightPounds &&
            touched.weightOunces &&
            !errors.weightPounds &&
            !errors.weightOunces;

          return (
            <Form noValidate>
              <Row spaceBelow>
                <Col spaceBelow xs={12} md={5}>
                  <Label secondary="(USA only)">From</Label>
                  <FormControl name="originZip" as={TextField} label="Zipcode" loading={false} />
                </Col>
                <Col spaceBelow xs={12} md={7}>
                  <Label secondary="(USA zipcode or Country Name only)">To</Label>
                  <FormControl
                    name="destination"
                    as={TextField}
                    label="Zipcode or Country"
                    loading={false}
                  />
                </Col>
                <Col md={12} spaceBelow>
                  <Label>Type of Packaging</Label>
                  <FormControl
                    name="packageTypeKey"
                    as={DropdownSetSelect}
                    options={packageTypeOptions}
                    imageSize={70}
                    onChange={(e: any) => {
                      setCurrentPackageTypeKey(e as string);
                    }}
                  />
                </Col>
                {packageType?.dimensionsRequired && (
                  <Col xs={12} md={7}>
                    <Label secondary="(Inches)">Dimensions</Label>
                    <Row nogutter>
                      <DimensionCol
                        dimension="x"
                        spaceBelow
                        xs={packageType.heightRequired ? 4 : 6}
                      >
                        <FormControl
                          name="dimensionX"
                          as={TextField}
                          label="Length"
                          type="number"
                          // We override error here to incorporate the form-level error
                          error={
                            (touched.dimensionX && errors.dimensionX !== undefined) ||
                            showDimensionsError
                          }
                          center
                        />
                      </DimensionCol>
                      <DimensionCol
                        dimension="y"
                        spaceBelow
                        xs={packageType.heightRequired ? 4 : 6}
                        zRequired={packageType.heightRequired}
                      >
                        <FormControl
                          name="dimensionY"
                          as={TextField}
                          label="Width"
                          type="number"
                          // We override error here to incorporate the form-level error
                          error={
                            (touched.dimensionY && errors.dimensionY !== undefined) ||
                            showDimensionsError
                          }
                          center
                        />
                      </DimensionCol>
                      {packageType.heightRequired && (
                        <DimensionCol dimension="z" xs={4}>
                          <FormControl
                            name="dimensionZ"
                            as={TextField}
                            label="Height"
                            type="number"
                            center
                            error={
                              (touched.dimensionZ && errors.dimensionZ !== undefined) ||
                              showDimensionsError
                            }
                          />
                        </DimensionCol>
                      )}
                      {showDimensionsError && (
                        <Col xs={12}>
                          <MultiFieldErrorMessage className="DimensionErrors">
                            {errors.combinedDimensions}
                          </MultiFieldErrorMessage>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
                {packageType?.weightRequired && (
                  <Col xs={12} md={packageType.dimensionsRequired ? 5 : 12}>
                    <Label>Weight</Label>
                    <Row nogutter>
                      <Col md={5.5}>
                        <FormControl
                          name="weightPounds"
                          as={TextField}
                          label="Pounds"
                          type="number"
                          // We override error here to incorporate the form-level error
                          error={
                            (touched.weightPounds && errors.weightPounds !== undefined) ||
                            showWeightError
                          }
                          center
                        />
                      </Col>
                      <Col md={1}>
                        <Styled.Operator>+</Styled.Operator>
                      </Col>
                      <Col spaceBelow md={5.5}>
                        <FormControl
                          name="weightOunces"
                          as={TextField}
                          type="number"
                          label="Ounces"
                          // We override error here to incorporate the form-level error
                          error={
                            (touched.weightOunces && errors.weightOunces !== undefined) ||
                            showWeightError
                          }
                          center
                        />
                      </Col>
                      {showWeightError && (
                        <Col md={12}>
                          <MultiFieldErrorMessage className="WeightErrors">
                            {errors.combinedWeight}
                          </MultiFieldErrorMessage>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={12}>
                  <ProgressButton
                    size="xLarge"
                    variant="info"
                    title="See shipping rates"
                    type="submit"
                    fullWidth
                    progress={loading}
                    disabled={loading}
                  >
                    See shipping rates
                  </ProgressButton>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Styled.CalcWrapper>
  );
}
