/**********************************************************/
/**                                                      **/
/**                THIS FILE IS GENERATED                **/
/**                DO NOT MODIFY DIRECTLY                **/
/**                                                      **/
/**  Call yarn graphql:codegen, optionally with --watch  **/
/**                                                      **/
/**********************************************************/

/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `AuthToken` scalar type represents a UTF-8 character string that is
   * used to authenticate against the backend.
   */
  AuthToken: string;
  /** The `Date` scalar type represents a ISO-8601 date string. */
  Date: string;
  /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
  DateTime: string;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: object;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
};

export type AccountingTransaction = {
  readonly __typename: 'AccountingTransaction';
  readonly amount: Scalars['String'];
  readonly balance: Maybe<Scalars['String']>;
  readonly batchId: Scalars['String'];
  readonly carrierId: Scalars['String'];
  readonly companyId: Maybe<Scalars['String']>;
  readonly createdAt: Maybe<Scalars['String']>;
  readonly identifier: Scalars['ID'];
  readonly isMoneyTransferred: Scalars['String'];
  readonly isProfit: Scalars['String'];
  readonly isTopLevel: Scalars['String'];
  readonly moreLink: Maybe<Scalars['String']>;
  readonly note: Maybe<Scalars['String']>;
  readonly paymentId: Scalars['String'];
  readonly pickupRequestId: Scalars['String'];
  readonly reconciliationAdjustmentId: Scalars['String'];
  readonly reconciliationRunId: Scalars['String'];
  readonly referenceId: Scalars['String'];
  readonly shipmentId: Scalars['String'];
  readonly timestamp: Scalars['String'];
  readonly title: Maybe<Scalars['String']>;
  readonly transactionCount: Maybe<Scalars['String']>;
  readonly transactionType: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['String']>;
  readonly userId: Maybe<Scalars['String']>;
};

export type AdditionalMappingInput = {
  readonly additionalField: Scalars['String'];
  readonly customOptionSourceKeyToOverride?: InputMaybe<Scalars['String']>;
};

export type Address = AddressInterface &
  BasicAddressInterface & {
    readonly __typename: 'Address';
    readonly address1: Scalars['String'];
    readonly address2: Scalars['String'];
    readonly addressType: Scalars['String'];
    readonly city: Scalars['String'];
    readonly company: Scalars['String'];
    readonly countryCode: Scalars['String'];
    readonly fullName: Scalars['String'];
    readonly id: Scalars['ID'];
    readonly location: Location;
    readonly origin: Scalars['Boolean'];
    readonly originalId: Maybe<Scalars['ID']>;
    readonly phone: Scalars['String'];
    readonly postcode: Scalars['String'];
    readonly regionCode: Scalars['String'];
    readonly residential: Scalars['Boolean'];
    readonly timeZone: Scalars['String'];
    readonly valid: Scalars['Boolean'];
    readonly validationError: Scalars['String'];
    readonly warehouse: Maybe<Warehouse>;
    readonly zip4: Scalars['String'];
  };

export type AddressAutocompleteSettings = {
  readonly __typename: 'AddressAutocompleteSettings';
  readonly addressProvider: Scalars['String'];
  readonly companyEnabled: Scalars['Boolean'];
  readonly emailEnabled: Scalars['Boolean'];
  readonly fullNameEnabled: Scalars['Boolean'];
  readonly googleApiKey: Scalars['String'];
  readonly googlePlacesSessionRequestsEnabled: Scalars['Boolean'];
  readonly streetEnabled: Scalars['Boolean'];
  readonly zipcodeEnabled: Scalars['Boolean'];
};

export type AddressInput = {
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly company: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly postcode: Scalars['String'];
  readonly regionCode: Scalars['String'];
  readonly zip4?: InputMaybe<Scalars['String']>;
};

export type AddressInterface = {
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly addressType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly company: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly location: Location;
  readonly origin: Scalars['Boolean'];
  readonly originalId: Maybe<Scalars['ID']>;
  readonly phone: Scalars['String'];
  readonly postcode: Scalars['String'];
  readonly regionCode: Scalars['String'];
  readonly residential: Scalars['Boolean'];
  readonly timeZone: Scalars['String'];
  readonly valid: Scalars['Boolean'];
  readonly validationError: Scalars['String'];
  readonly warehouse: Maybe<Warehouse>;
  readonly zip4: Scalars['String'];
};

export type AssociatedCompany = {
  readonly __typename: 'AssociatedCompany';
  readonly address: Address;
  readonly title: Scalars['String'];
};

export type BasicAddress = BasicAddressInterface & {
  readonly __typename: 'BasicAddress';
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly addressType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly company: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly phone: Scalars['String'];
  readonly postcode: Scalars['String'];
  readonly regionCode: Scalars['String'];
  readonly residential: Scalars['Boolean'];
  readonly timeZone: Scalars['String'];
  readonly valid: Scalars['Boolean'];
  readonly zip4: Scalars['String'];
};

export type BasicAddressInterface = {
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly addressType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly company: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly phone: Scalars['String'];
  readonly postcode: Scalars['String'];
  readonly regionCode: Scalars['String'];
  readonly residential: Scalars['Boolean'];
  readonly timeZone: Scalars['String'];
  readonly valid: Scalars['Boolean'];
  readonly zip4: Scalars['String'];
};

export type Batch = {
  readonly __typename: 'Batch';
  readonly canCreateScanform: Scalars['Boolean'];
  readonly canInstantRefundBatch: Scalars['Boolean'];
  readonly canPrint: Scalars['Boolean'];
  readonly canRefund: Scalars['Boolean'];
  readonly carrierLogoClasses: ReadonlyArray<Scalars['String']>;
  readonly cleanupComplete: Scalars['Boolean'];
  readonly cleanupOriginal: Scalars['Boolean'];
  readonly createdAt: Scalars['String'];
  readonly customsFormRequired: Scalars['Boolean'];
  readonly customsFormSummary: BatchCustomsFormSummary;
  readonly dataSource: BatchSource;
  readonly defaultShipDate: Scalars['String'];
  /** Batch was requested to be deleted by the user */
  readonly deleted: Scalars['Boolean'];
  readonly emailNotificationPossible: Scalars['Boolean'];
  readonly emailNotificationSent: Scalars['Boolean'];
  readonly emailNotificationStatus: NotifyRecipientStatus;
  readonly emailNotificationTemplate: Maybe<MailTemplate>;
  /**
   * Starts the creation of an export file if no exportId is specified. If exportId is specified, the request will
   * be treated as a status request for the existing export.
   */
  readonly failedShipmentsExportStatus: DataGridExportStatus;
  readonly hsCodeRequired: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly labelFileFormat: FileFormat;
  readonly labelSize: LabelSize;
  readonly notifyRecipientsDate: Maybe<Scalars['String']>;
  readonly numShipments: Scalars['Int'];
  readonly originAddress: Address;
  readonly packageSummary: BatchPackageSummary;
  readonly percentageDelivered: Scalars['Float'];
  /** A summary of the purchased services in this batch with their costs(mail-classes + surcharges + extra services - refunds) */
  readonly priceSummary: ReadonlyArray<BatchPriceSummary>;
  readonly printed: Scalars['Boolean'];
  /**
   * All possible Rates for the shipments in this batch, sorted by groups of similar shipments
   * like 'international bigger' and 'domestic smaller'
   */
  readonly rateGroups: ReadonlyArray<RateGroup>;
  readonly refundSuccessMessages: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly returnAddress: Address;
  readonly rubberStamps: ReadonlyArray<ShipmentMapping>;
  readonly runningProcess: Maybe<BatchProcess>;
  readonly scanformCreated: Scalars['Boolean'];
  readonly scanformId: Maybe<Scalars['String']>;
  readonly scanformUrl: Maybe<Scalars['String']>;
  readonly shipDate: Scalars['String'];
  readonly shipDatePossibleNow: Scalars['Boolean'];
  /** A summary of the shipment statuses in this batch */
  readonly shipmentStatusSummary: ShipmentStatusSummary;
  readonly shipments: ReadonlyArray<Shipment>;
  readonly spreadsheetMapping: ReadonlyArray<ShipmentMapping>;
  /**
   * This is named 'BatchStatusTag' in the legacy implementation, and is not in the database (i.e. it is computed at
   * runtime). Includes more info that is partially the status of the batch as it relates to the Pirate Ship buying
   * process, but also:
   * - Status of running or pending Batch Processes like Buy and Rate
   * - Refunds that are pending
   * - Tracking Status like In Transit or Delivered
   * As it acts like the actual status of the batch, we name it as such here (note: the column named 'status' we map
   * to "step", see above)
   */
  readonly status: BatchStatusTag;
  /**
   * User-friendly version of Batch Status
   * This is the 'Status Tag' that includes more info than just pp_batch status, it includes:
   * - Status of Batch Process like Buy and Rate
   * - Refunds that are pending
   * - Tracking Status like In Transit or Delivered
   */
  readonly statusText: Scalars['String'];
  /**
   * This is named 'status' in the database, however it represents more of a step in the Pirate Ship purchase
   * workflow. It is used more for logic than display. To avoid confusion with the BatchStatusTag (which is a
   * user-friendly combination status that involves tracking and our native statuses), we have named the property
   * "step".
   */
  readonly step: BatchStatus;
  readonly stepText: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingDataGrid: DataGridResult;
  readonly upload: Maybe<ShipmentUpload>;
  readonly warehouse: Warehouse;
};

export type BatchFailedShipmentsExportStatusArgs = {
  exportId?: InputMaybe<Scalars['Int']>;
};

export type BatchShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type BatchSpreadsheetMappingArgs = {
  shipmentFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type BatchCustomsFormSummary = {
  readonly __typename: 'BatchCustomsFormSummary';
  readonly contentType: Scalars['String'];
  readonly exporterTaxIdSummary: Scalars['String'];
  readonly itemSummary: ReadonlyArray<Scalars['String']>;
  readonly numCustomsForms: Scalars['Int'];
  readonly signer: Scalars['String'];
  readonly title: Scalars['String'];
};

export type BatchPackageSummary = {
  readonly __typename: 'BatchPackageSummary';
  readonly deliveryConfirmationSummary: Scalars['String'];
  readonly dimensionsSummary: Scalars['String'];
  readonly maxInsured: Scalars['Float'];
  readonly maxWeight: Scalars['Float'];
  readonly minInsured: Scalars['Float'];
  readonly minWeight: Scalars['Float'];
  /**
   * The default values the user has entered in our shipment form, they can be overridden by
   * data imported from Spreadsheet or from Integrations for each shipment in the batch
   */
  readonly packagePreset: ShipmentPreset;
  readonly packageTypeSummary: Scalars['String'];
};

export type BatchPriceSummary = {
  readonly __typename: 'BatchPriceSummary';
  readonly averagePrice: Scalars['Float'];
  readonly carrierKeys: ReadonlyArray<Scalars['String']>;
  readonly isRefunded: Scalars['Boolean'];
  readonly isReturnLabel: Scalars['Boolean'];
  readonly pirateShipService: Scalars['Boolean'];
  readonly returnsUsed: Scalars['Int'];
  readonly samePriceForAll: Scalars['Boolean'];
  readonly shipmentCount: Scalars['Int'];
  readonly surcharge: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly totalPrice: Scalars['Float'];
  readonly usedReturnPrice: Scalars['Float'];
};

export type BatchProcess = {
  readonly __typename: 'BatchProcess';
  /** Amount of run items in the process that are finished or currently running */
  readonly itemsInProgressCount: Scalars['Int'];
  /** Total amount of run items in the process (Normally, in a batch process each shipment has one run item) */
  readonly itemsTotalCount: Scalars['Int'];
  readonly key: BatchProcessKey;
  readonly processKey: Scalars['String'];
  readonly progressPercentage: Scalars['Int'];
  readonly progressTitle: Scalars['String'];
  /** Estimated seconds it will take for the process to finish */
  readonly secondsLeft: Scalars['Int'];
  readonly status: Scalars['String'];
};

/** All possible processes on batches of shipments labels */
export type BatchProcessKey = 'BUY_POSTAGE' | 'REFUND' | 'UNKNOWN' | 'VALIDATE_AND_RATE';

/** Enumeration of possible data sources for batches */
export type BatchSource =
  /** Data was manually entered in our single shipment form */
  | 'FORM'
  /** Data was fetched from integration (Orders) */
  | 'INTEGRATION'
  /** Data was uploaded from spreadsheet */
  | 'SPREADSHEET';

export type BatchStatus =
  | 'BILLED'
  | 'ERROR'
  | 'IMPORTED'
  | 'NEW'
  | 'PURCHASING'
  | 'RATED'
  | 'RATING'
  | 'REFUNDED'
  | 'REFUNDING'
  | 'VALIDATED';

export type BatchStatusTag =
  | 'COMPLETE'
  | 'DELIVERED'
  | 'ERROR'
  | 'IDLE'
  | 'IN_TRANSIT'
  | 'PARTIALLY_DELIVERED'
  | 'PARTLY_REFUNDED'
  | 'READY_TO_PRINT'
  | 'READY_TO_SHIP'
  | 'REFUNDED'
  | 'REFUND_FAILED'
  | 'REFUND_PENDING'
  | 'RUNNING';

export type BigcartelChannel = ChannelInterface & {
  readonly __typename: 'BigcartelChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly plan: Scalars['String'];
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type BigcommerceChannel = ChannelInterface & {
  readonly __typename: 'BigcommerceChannel';
  readonly active: Scalars['Boolean'];
  readonly apiUrl: Scalars['String'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type BlockedCountry = {
  readonly __typename: 'BlockedCountry';
  readonly blockReason: Scalars['String'];
  readonly twoCharIsoCode: Scalars['String'];
};

export type BlockedCountryInput = {
  readonly blockReason: Scalars['String'];
  readonly twoCharIsoCode: Scalars['String'];
};

export type Carrier = {
  readonly __typename: 'Carrier';
  readonly allowedPickupDateCutOff: Maybe<Scalars['DateTime']>;
  readonly allowedPickupDates: ReadonlyArray<Scalars['Date']>;
  readonly carrierKey: CarrierKey;
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly mailClasses: ReadonlyArray<CarrierMailClass>;
  readonly packageTypes: ReadonlyArray<CarrierPackageType>;
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
};

export type CarrierAllowedPickupDatesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type CarrierMailClassesArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  topLevelOnly?: InputMaybe<Scalars['Boolean']>;
};

export type CarrierPackageTypesArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type CarrierKey = 'fedex' | 'ups' | 'usps';

export type CarrierMailClass = {
  readonly __typename: 'CarrierMailClass';
  readonly accuracy: Maybe<Scalars['Int']>;
  readonly averageDeliveryDays: Scalars['Float'];
  readonly carrierTitle: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly groupKey: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly international: Scalars['Boolean'];
  readonly mailClassKey: Scalars['String'];
  readonly serviceLevel: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
};

export type CarrierPackageType = {
  readonly __typename: 'CarrierPackageType';
  readonly carrierId: Scalars['ID'];
  readonly description: Scalars['String'];
  readonly dimensionsRequired: Scalars['Boolean'];
  readonly heightRequired: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly packageTypeKey: Scalars['String'];
  readonly title: Scalars['String'];
  readonly weightRequired: Scalars['Boolean'];
};

export type Challenge = {
  readonly __typename: 'Challenge';
  readonly action: ChallengeAction;
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly key: ChallengeKey;
};

/** Enumeration of challenge actions */
export type ChallengeAction = 'ACTION_ABORT' | 'ACTION_SUCCESS';

/** Enumeration of all challenge keys */
export type ChallengeKey =
  | 'CHALLENGE_ADD_PAYMENT_METHOD'
  | 'CHALLENGE_CONNECT_STORE'
  | 'CHALLENGE_EDIT_TRACKING'
  | 'CHALLENGE_PLAY_SOUNDS'
  | 'CHALLENGE_SHIP_PACKAGE'
  | 'CHALLENGE_VISIT_GENERAL_SETTINGS_PAGE'
  | 'CHALLENGE_VISIT_HELP_GENERAL'
  | 'CHALLENGE_VISIT_HELP_PRINT'
  | 'CHALLENGE_VISIT_RATES_PAGE'
  | 'CHALLENGE_WATCH_VIDEO_GENERAL'
  | 'CHALLENGE_WATCH_VIDEO_V2';

export type Channel = ChannelInterface & {
  readonly __typename: 'Channel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type ChannelInterface = {
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type ChannelRubberstampConfig = {
  readonly __typename: 'ChannelRubberstampConfig';
  readonly configuredField: Scalars['String'];
  readonly rubberstamp: Scalars['String'];
};

export type ChannelSync = {
  readonly __typename: 'ChannelSync';
  readonly date: Scalars['DateTime'];
  readonly info: Scalars['String'];
  readonly message: Scalars['String'];
  readonly status: LastSyncStatus;
};

export type ChannelUnion =
  | BigcartelChannel
  | BigcommerceChannel
  | ChargifyChannel
  | CratejoyChannel
  | DiscogsChannel
  | EbayChannel
  | EcwidChannel
  | EtsyChannel
  | FakeshopChannel
  | MoonclerkChannel
  | PaypalChannel
  | ShopifyChannel
  | SquarespaceChannel
  | SubblyChannel
  | WoocommerceChannel;

export type ChargifyChannel = ChannelInterface & {
  readonly __typename: 'ChargifyChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly billingDateSyncEnabled: Scalars['Boolean'];
  readonly billingDateSyncOption: Scalars['Int'];
  readonly clientId: Scalars['String'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly preventDuplicateShippingEnabled: Scalars['Boolean'];
  readonly preventDuplicateShippingOption: Scalars['Int'];
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly skipFutureSubscriptions: Scalars['Boolean'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type Chart = {
  readonly __typename: 'Chart';
  readonly charts: ReadonlyArray<Scalars['Float']>;
  readonly colors: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly labels: ReadonlyArray<Scalars['String']>;
};

export type Company = {
  readonly __typename: 'Company';
  readonly accountBalance: Scalars['Float'];
  readonly activeCarriers: ReadonlyArray<CarrierKey>;
  readonly activePaymentMethods: ReadonlyArray<PaymentMethodType>;
  readonly address: Address;
  readonly channels: ReadonlyArray<ChannelUnion>;
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly createdMerchantAccounts: ReadonlyArray<CarrierKey>;
  readonly debuggingEnabled: Scalars['Boolean'];
  readonly email: Scalars['String'];
  readonly features: ReadonlyArray<Feature>;
  readonly fraudScore: Scalars['Int'];
  readonly fraudStatus: Scalars['String'];
  readonly hasUspsMerchantAccountError: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly mailTemplates: ReadonlyArray<MailTemplate>;
  readonly paymentSources: ReadonlyArray<PaymentSource>;
  readonly phone: Scalars['String'];
  /**
   * Summarize the shipments created with the specified carrier for the specified ship date (postmark date)
   * of today (in the company's time zone) or greater.
   */
  readonly pickupSummary: ShipmentSummary;
  readonly pickups: ReadonlyArray<PickupRequest>;
  readonly settings: CompanySettings;
  readonly shipmentPresets: ReadonlyArray<ShipmentPreset>;
  /**
   * Summarize the shipments created with the specified carrier in the specified time range for the current company
   * The dates can also include a time.
   * If they do not include a time, the range will be from the start of the $startDate to the end of the $endDate
   * by default
   */
  readonly shipmentSummary: ShipmentSummary;
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly upsMerchantAccountStatus: UpsMerchantAccountStatus;
  readonly uspsMerchantAccountStatus: Scalars['String'];
  readonly warehouses: ReadonlyArray<Warehouse>;
  readonly zoneReportType: Scalars['String'];
};

export type CompanyChannelsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  onlyActiveAndValid?: InputMaybe<Scalars['Boolean']>;
  platformKey?: InputMaybe<Scalars['String']>;
};

export type CompanyMailTemplatesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyPickupSummaryArgs = {
  carrierKey: CarrierKey;
};

export type CompanyPickupsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyShipmentPresetsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CompanyShipmentSummaryArgs = {
  carrierKey: CarrierKey;
  endDate: Scalars['DateTime'];
  includeFullEndDate?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
};

export type CompanyWarehousesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  withScanFormsAvailable?: InputMaybe<Scalars['Boolean']>;
};

export type CompanySettings = {
  readonly __typename: 'CompanySettings';
  readonly addressCorrectionEnabled: Scalars['Boolean'];
  readonly defaultChargeAmount: Scalars['Int'];
  readonly defaultDestinationCountryCode: Scalars['String'];
  readonly defaultEmailTemplateId: Scalars['ID'];
  readonly defaultPaymentSourceId: Scalars['ID'];
  readonly defaultShipmentPresetId: Scalars['ID'];
  readonly defaultTrackingEmailsDelay: Scalars['String'];
  readonly defaultTrackingEmailsEnabled: Scalars['Boolean'];
  readonly defaultWarehouseId: Scalars['ID'];
  readonly packingListTemplate: Scalars['String'];
  readonly shipmentLabelFileFormat: Scalars['String'];
  readonly shipmentLabelFormat: Scalars['String'];
  readonly shipmentLabelSize: Scalars['String'];
  readonly shipmentSpreadsheetFormat: Scalars['String'];
};

export type Country = {
  readonly __typename: 'Country';
  readonly countryCode: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isoAlpha2Code: Scalars['String'];
  readonly isoAlpha3Code: Scalars['String'];
  readonly isoNumericCode: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly parentCountryId: Scalars['ID'];
  readonly postcodeRequired: Scalars['Boolean'];
};

export type CountryDistributionChart = {
  readonly __typename: 'CountryDistributionChart';
  readonly countryDistributions: ReadonlyArray<DistributionChartItem>;
  readonly totalCount: Scalars['Int'];
};

export type CratejoyChannel = ChannelInterface & {
  readonly __typename: 'CratejoyChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientId: Scalars['String'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly skipFutureSubscriptions: Scalars['Boolean'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type CreateBigcommerceChannelInput = {
  readonly apiUrl: Scalars['String'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type CreateChargifyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly billingDateSyncEnabled: Scalars['Boolean'];
  readonly billingDateSyncOption?: InputMaybe<Scalars['Int']>;
  readonly clientId: Scalars['String'];
  readonly clientSecret: Scalars['String'];
  readonly preventDuplicateShippingEnabled: Scalars['Boolean'];
  readonly preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type CreateCratejoyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientId: Scalars['String'];
  readonly clientSecret: Scalars['String'];
  readonly skipFutureSubscriptions: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type CreateDiscogsChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type CreateFakeshopChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly orderGroups?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly title: Scalars['String'];
};

export type CreateMoonclerkChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly preventDuplicateShippingEnabled: Scalars['Boolean'];
  readonly preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
};

export type CreatePaymentSourceResponse = {
  readonly __typename: 'CreatePaymentSourceResponse';
  readonly isDefault: Scalars['Boolean'];
  readonly paymentSource: PaymentSource;
};

export type CreatePaymentSourceResult = {
  readonly __typename: 'CreatePaymentSourceResult';
  readonly default: Scalars['Boolean'];
  readonly paymentSource: PaymentSource;
};

export type CreateShipmentPresetResult = {
  readonly __typename: 'CreateShipmentPresetResult';
  readonly default: Scalars['Boolean'];
  readonly shipmentPreset: ShipmentPreset;
};

export type CreateSquarespaceChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type CreateSubblyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type CreateUpsMerchantAccountResult = {
  readonly __typename: 'CreateUpsMerchantAccountResult';
  readonly error: Scalars['String'];
  readonly errorCategory: Scalars['String'];
  readonly status: Scalars['String'];
};

export type CreateUspsMerchantAccountResult = {
  readonly __typename: 'CreateUspsMerchantAccountResult';
  readonly error: Scalars['String'];
  readonly errorCategory: Scalars['String'];
  readonly status: Scalars['String'];
};

export type CustomOption = {
  readonly __typename: 'CustomOption';
  readonly channelId: Scalars['Int'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly override: Scalars['String'];
  readonly sourceKey: Scalars['String'];
  readonly status: CustomOptionStatus;
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valueType: CustomOptionValueType;
};

/** Enumeration of all status */
export type CustomOptionStatus = 'STATUS_DEFAULT' | 'STATUS_IGNORE' | 'STATUS_OVERRIDE';

/** Enumeration of all value types */
export type CustomOptionValueType =
  | 'VALUE_TYPE_DATE'
  | 'VALUE_TYPE_DATETIME'
  | 'VALUE_TYPE_NUMBER'
  | 'VALUE_TYPE_OPTION'
  | 'VALUE_TYPE_PARAGRAPH'
  | 'VALUE_TYPE_TEXT';

export type CustomsItem = {
  readonly __typename: 'CustomsItem';
  readonly countryCodeOfOrigin: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly hsTariffNumber: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly itemValue: Scalars['Float'];
  readonly quantity: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly weight: Scalars['Float'];
};

export type CustomsItemInput = {
  readonly countryCodeOfOrigin: Scalars['String'];
  readonly hsTariffNumber: Scalars['String'];
  readonly itemValue: Scalars['Float'];
  readonly quantity: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly weight: Scalars['Float'];
};

export type DataGridColumn = {
  readonly __typename: 'DataGridColumn';
  readonly alternativeSortingField: Maybe<Scalars['String']>;
  readonly exactMatch: Scalars['Boolean'];
  readonly field: Scalars['String'];
  readonly hidden: Scalars['Boolean'];
  readonly label: Scalars['String'];
  readonly multiCheck: Scalars['Boolean'];
  readonly sortable: Scalars['Boolean'];
  readonly type: Scalars['String'];
  readonly width: Maybe<Scalars['Int']>;
};

export type DataGridColumnOverride = {
  readonly __typename: 'DataGridColumnOverride';
  readonly field: Scalars['String'];
  readonly hidden: Scalars['Boolean'];
  readonly width: Scalars['Int'];
};

export type DataGridColumnOverrideInput = {
  readonly field: Scalars['String'];
  readonly hidden: Scalars['Boolean'];
  readonly width: Scalars['Int'];
};

export type DataGridExportStatus = {
  readonly __typename: 'DataGridExportStatus';
  readonly downloadable: Scalars['Boolean'];
  /** How much time is left until the token for the export expires in milliseconds */
  readonly expire: Scalars['Int'];
  readonly exportId: Scalars['Int'];
  readonly filename: Scalars['String'];
  readonly url: Maybe<Scalars['String']>;
};

export type DataGridFilter = {
  readonly __typename: 'DataGridFilter';
  readonly field: Scalars['String'];
  readonly operator: Scalars['String'];
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type DataGridFilterInput = {
  readonly field: Scalars['String'];
  readonly operator: Scalars['String'];
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type DataGridFilterOption = {
  readonly __typename: 'DataGridFilterOption';
  readonly count: Scalars['Int'];
  readonly disabled: Scalars['Boolean'];
  readonly label: Scalars['String'];
  readonly value: Scalars['String'];
};

export type DataGridMetaData = {
  readonly __typename: 'DataGridMetaData';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type DataGridResult = {
  readonly __typename: 'DataGridResult';
  /** Grid column definition. */
  readonly columns: ReadonlyArray<DataGridColumn>;
  /** Whether to show an export button. */
  readonly exportAllowed: Scalars['Boolean'];
  /**
   * Starts the creation of an export file. If there is no exportId specified,
   * returns the status of the export file, the URL where it can be downloaded and the exportId
   * (if it is a large amount of data, the file might not be immediately downloadable).
   * Specify an exportId to check the status of a previously requested export file.
   */
  readonly exportUrl: DataGridExportStatus;
  /** Whether to show a refund button. */
  readonly gridActions: ReadonlyArray<DataGridRowAction>;
  /** Grid ID to use when saving state. */
  readonly id: Scalars['ID'];
  /** Whether to use filter settings stored locally in the browser instead of the server-defined static filters. */
  readonly localFiltersEnabled: Scalars['Boolean'];
  /** Maximum number of rows per page allowed. */
  readonly maxPageSize: Scalars['Int'];
  readonly metaData: ReadonlyArray<DataGridMetaData>;
  /**
   * Returns a distinct set of values for a given field using a defined set of filters and search term. Use to provide
   * multi-check capability resulting in a includes-filter.
   */
  readonly multiCheckOptions: ReadonlyArray<DataGridFilterOption>;
  /** Row actions define a set of per-row buttons that need their click event mapped in the client. */
  readonly rowActions: Maybe<ReadonlyArray<DataGridRowAction>>;
  /** Grid rows and related information. */
  readonly rows: DataGridResultRows;
  /** Default filters the grid must be initialized with. */
  readonly staticFilters: ReadonlyArray<DataGridFilter>;
  /** Persisted per-user grid settings. */
  readonly userSettings: Maybe<DataGridSettings>;
};

export type DataGridResultExportUrlArgs = {
  columns?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  exportId?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ReadonlyArray<DataGridFilterInput>>;
  ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<ReadonlyArray<DataGridSortingInput>>;
};

export type DataGridResultMultiCheckOptionsArgs = {
  filters?: InputMaybe<ReadonlyArray<DataGridFilterInput>>;
  groupByField: Scalars['String'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type DataGridResultRowsArgs = {
  filters?: InputMaybe<ReadonlyArray<DataGridFilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<ReadonlyArray<DataGridSortingInput>>;
};

export type DataGridResultUserSettingsArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type DataGridResultRows = {
  readonly __typename: 'DataGridResultRows';
  /** Checkable IDs of all pages */
  readonly checkableIds: ReadonlyArray<Scalars['ID']>;
  /** Untyped key-value data with keys in snake_case format matching the column definition. */
  readonly nodes: ReadonlyArray<Scalars['JSONObject']>;
  /** Total count of matching rows. */
  readonly totalCount: Scalars['Int'];
};

export type DataGridRowAction = {
  readonly __typename: 'DataGridRowAction';
  readonly fullWidth: Scalars['Boolean'];
  /** ID used for mapping. */
  readonly id: Maybe<Scalars['ID']>;
  /** Button label. */
  readonly label: Scalars['String'];
  /** Button variant. */
  readonly variant: Scalars['String'];
};

export type DataGridSettings = {
  readonly __typename: 'DataGridSettings';
  readonly columnOrder: ReadonlyArray<Scalars['String']>;
  readonly columns: ReadonlyArray<DataGridColumnOverride>;
  readonly pageSize: Scalars['Int'];
  readonly sorting: ReadonlyArray<DataGridSorting>;
};

export type DataGridSettingsInput = {
  readonly columnOrder: ReadonlyArray<Scalars['String']>;
  readonly columns: ReadonlyArray<DataGridColumnOverrideInput>;
  readonly pageSize: Scalars['Int'];
  readonly sorting: ReadonlyArray<DataGridSortingInput>;
};

export type DataGridSorting = {
  readonly __typename: 'DataGridSorting';
  readonly direction: Scalars['String'];
  readonly field: Scalars['String'];
};

export type DataGridSortingInput = {
  readonly direction: Scalars['String'];
  readonly field: Scalars['String'];
};

export type DateTimeRange = {
  readonly __typename: 'DateTimeRange';
  readonly endDatetime: Scalars['DateTime'];
  readonly startDatetime: Scalars['DateTime'];
};

export type DatetimeRangeInput = {
  readonly endDatetime: Scalars['String'];
  readonly startDatetime: Scalars['String'];
};

export type DeliveryConfirmation = 'adult_signature' | 'direct_signature' | 'none' | 'signature';

export type DeliveryEstimation = {
  readonly __typename: 'DeliveryEstimation';
  readonly arrivalLocalDateTime: Maybe<Scalars['DateTime']>;
  readonly pickupLocalDateTime: Maybe<Scalars['DateTime']>;
};

export type DiscogsChannel = ChannelInterface & {
  readonly __typename: 'DiscogsChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type DisputeStatus =
  | 'DISPUTE_APPROVED'
  | 'DISPUTE_DENIED'
  | 'DISPUTE_PENDING'
  | 'UNDISPUTED';

export type DistributionChartItem = {
  readonly __typename: 'DistributionChartItem';
  readonly count: Scalars['String'];
  readonly key: Scalars['String'];
  readonly name: Scalars['String'];
  readonly percent: Scalars['Float'];
  readonly price: Scalars['Float'];
};

export type DownloadLabelsByBatchResult = {
  readonly __typename: 'DownloadLabelsByBatchResult';
  readonly batch: Batch;
  readonly fileFormat: Maybe<FileFormat>;
  readonly id: Scalars['ID'];
  readonly labelSize: Maybe<Scalars['String']>;
  readonly pageLayout: Maybe<PageLayout>;
  readonly status: DownloadStatus;
  readonly url: Maybe<Scalars['String']>;
};

export type DownloadLabelsByShareTokenResult = {
  readonly __typename: 'DownloadLabelsByShareTokenResult';
  readonly fileFormat: Maybe<FileFormat>;
  readonly labelSize: Maybe<Scalars['String']>;
  readonly pageLayout: Maybe<PageLayout>;
  readonly scanform: Maybe<ScanformByShareTokenResult>;
  readonly status: DownloadStatus;
  readonly url: Maybe<Scalars['String']>;
};

export type DownloadLabelsByShipmentsResult = {
  readonly __typename: 'DownloadLabelsByShipmentsResult';
  readonly fileFormat: Maybe<FileFormat>;
  readonly id: Scalars['ID'];
  readonly labelSize: Maybe<Scalars['String']>;
  readonly pageLayout: Maybe<PageLayout>;
  readonly shipments: ReadonlyArray<Maybe<Shipment>>;
  readonly status: DownloadStatus;
  readonly url: Maybe<Scalars['String']>;
};

export type DownloadLabelsResult = {
  readonly __typename: 'DownloadLabelsResult';
  readonly batch: Maybe<Batch>;
  readonly fileFormat: FileFormat;
  readonly id: Scalars['ID'];
  readonly labelSize: Scalars['String'];
  readonly message: Scalars['String'];
  readonly pageLayout: PageLayout;
  readonly scanformUrl: Maybe<Scalars['String']>;
  readonly shipments: ReadonlyArray<Maybe<Shipment>>;
  readonly status: DownloadStatus;
  readonly url: Scalars['String'];
};

export type DownloadStatus = 'ERROR' | 'FINISHED' | 'IDLE' | 'RUNNING';

export type EbayChannel = ChannelInterface & {
  readonly __typename: 'EbayChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type EcwidChannel = ChannelInterface & {
  readonly __typename: 'EcwidChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customFulfillmentStatuses: ReadonlyArray<EcwidOrderStatus>;
  readonly customFulfillmentStatusesToImport: ReadonlyArray<Scalars['String']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly customPaymentStatuses: ReadonlyArray<EcwidOrderStatus>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly username: Scalars['String'];
  readonly valid: Scalars['Boolean'];
};

export type EcwidOrderStatus = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EcwidOrderStatusImpl = EcwidOrderStatus & {
  readonly __typename: 'EcwidOrderStatusImpl';
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EmailTemplate = {
  readonly __typename: 'EmailTemplate';
  readonly html: Scalars['String'];
  readonly plainText: Scalars['String'];
  readonly subject: Scalars['String'];
};

/** Enumeration of email verification statuses */
export type EmailVerificationStatus = 'CONFIRMED' | 'NOT_SENT' | 'NOT_SUPPORTED' | 'PENDING';

export type EtsyChannel = ChannelInterface & {
  readonly __typename: 'EtsyChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type FakeshopChannel = ChannelInterface & {
  readonly __typename: 'FakeshopChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly orderGroups: ReadonlyArray<Scalars['String']>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type Feature = {
  readonly __typename: 'Feature';
  readonly key: Scalars['String'];
  readonly value: Scalars['Boolean'];
};

export type FileFormat = 'PDF' | 'ZPL';

/** Value type that describes a payment source's funding. */
export type Funding =
  /** Charged card */
  | 'CHARGE'
  /** Credit card */
  | 'CREDIT'
  /** Debit card */
  | 'DEBIT'
  /** Deffered debit card */
  | 'DEFERRED_DEBIT'
  /** Prepaid credit/debit card. This type is important for our fraud prevention mechanism. */
  | 'PREPAID'
  /** Credit/debit card, where we cannot determine the funding */
  | 'UNKNOWN';

export type GlobalSettings = {
  readonly __typename: 'GlobalSettings';
  readonly defaultEmailResponseTimeOptions: ReadonlyArray<Option>;
  readonly emailTemplate: EmailTemplate;
};

export type GridMode = 'DEFAULT' | 'IGNORED';

export type IgnoreOrderResult = {
  readonly __typename: 'IgnoreOrderResult';
  readonly count: Scalars['Int'];
  readonly ids: ReadonlyArray<Scalars['ID']>;
  readonly success: Scalars['Boolean'];
};

export type LabelSize = 'SIZE_2x7' | 'SIZE_4x6';

export type LastSyncStatus = 'ERROR' | 'SUCCESS';

export type Link = {
  readonly __typename: 'Link';
  readonly text: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Location = {
  readonly __typename: 'Location';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type LoginResult = {
  readonly __typename: 'LoginResult';
  readonly authToken: Scalars['AuthToken'];
  readonly user: User;
};

export type MailClassCount = {
  readonly __typename: 'MailClassCount';
  readonly mailClass: CarrierMailClass;
  readonly shipmentCount: Scalars['Int'];
};

export type MailClassRateInput = {
  readonly mailClassGroup: Scalars['String'];
  readonly price: Scalars['Float'];
};

export type MailTemplate = {
  readonly __typename: 'MailTemplate';
  readonly asDefault: Scalars['Boolean'];
  readonly bccEmail: Scalars['String'];
  readonly companyId: Scalars['ID'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly replyToEmail: Scalars['String'];
  readonly senderEmail: Scalars['String'];
  readonly senderName: Scalars['String'];
  readonly subject: Scalars['String'];
  readonly templateHtml: Scalars['String'];
  readonly templateText: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly userId: Scalars['ID'];
};

export type MappableShipmentField = {
  readonly __typename: 'MappableShipmentField';
  readonly key: ShipmentKey;
  /** If null, there is no limit */
  readonly maxMappings: Maybe<Scalars['Int']>;
  readonly required: Maybe<Scalars['Int']>;
  readonly title: Scalars['String'];
};

/** Enum constants listing all possible payment strategy names. */
export type Mel_Payment_Enum_StrategyName =
  /**
   * Authorization on payment creation and charge (aka capture) at payment completion (which might happen sometime
   * later than creation)
   */
  | 'AUTH_AND_CAPTURE'
  /**
   * Payments stay in pending state, until the amount and/or time threshold is reached, at which point a group
   * payment is created that is used to charge the sum of all pending payment's amounts in one go.
   */
  | 'CONSOLIDATION'
  /** Immediate charge, on payment creation */
  | 'IMMEDIATE';

export type Mel_Pp_Model_Shipment_TrackingStatus =
  | 'AVAILABLE_FOR_PICKUP'
  | 'CUSTOMER_RECALL'
  | 'DEAD_LETTER'
  | 'DELIVERED'
  | 'DELIVERY_STATUS_NOT_UPDATED'
  | 'FORWARD'
  | 'NEW_ITEM'
  | 'NOTICE'
  | 'NOT_FOUND'
  | 'OTHER'
  | 'OUT_FOR_DELIVERY'
  | 'REFUNDED'
  | 'RETURNED_TO_SENDER'
  | 'SCANNED'
  | 'UNDELIVERABLE'
  | 'UNKNOWN';

export type MoonclerkChannel = ChannelInterface & {
  readonly __typename: 'MoonclerkChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly preventDuplicateShippingEnabled: Scalars['Boolean'];
  readonly preventDuplicateShippingOption: Scalars['Int'];
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly acceptTerms: User;
  readonly addCreditToPaymentSource: Company;
  readonly buyBatch: Batch;
  /** Cancel email notifications for all recipients of the batch */
  readonly cancelEmailNotification: Batch;
  readonly cancelPickup: PickupRequest;
  readonly channelAutoSync: Scalars['Boolean'];
  readonly completeBatchFromUpload: Batch;
  readonly connectWoocommerceChannel: Scalars['String'];
  readonly createAchPaymentSource: CreatePaymentSourceResponse;
  /**
   * Create a new batch of shipments from a previously uploaded spreadsheet.
   * If a batch for this upload already exists, the existing batch will be returned with updated mapping
   */
  readonly createBatchFromUpload: Batch;
  readonly createBigcommerceChannel: BigcommerceChannel;
  readonly createCcPaymentSource: CreatePaymentSourceResponse;
  readonly createChallenge: Challenge;
  readonly createChargifyChannel: ChargifyChannel;
  readonly createCratejoyChannel: CratejoyChannel;
  readonly createDiscogsChannel: DiscogsChannel;
  readonly createFakeshopChannel: FakeshopChannel;
  readonly createMailTemplate: MailTemplate;
  readonly createMappings: ChannelUnion;
  readonly createMoonclerkChannel: MoonclerkChannel;
  readonly createOfflinePaymentSource: CreatePaymentSourceResponse;
  /** Update the UPS Merchant Account with the current billing address of the company */
  readonly createOrUpdateUpsMerchantAccount: CreateUpsMerchantAccountResult;
  readonly createPayPalPaymentSource: CreatePaymentSourceResponse;
  readonly createPlaidAccessToken: PlaidActivation;
  readonly createPlaidActivation: PlaidActivation;
  readonly createPlaidConsent: CreatePaymentSourceResponse;
  readonly createPlaidValidationActivation: PlaidActivation;
  readonly createScanformByBatch: ScanformByBatchResult;
  readonly createScanformByShareToken: ScanformByShareTokenResult;
  readonly createScopedShipmentPreset: ShipmentPreset;
  /** This mutation also triggers sending of a confirmation mail via Postmark. */
  readonly createSenderEmailMetadata: Maybe<SenderEmailMetadata>;
  readonly createShipmentPreset: CreateShipmentPresetResult;
  readonly createSquarespaceChannel: SquarespaceChannel;
  readonly createSubblyChannel: SubblyChannel;
  /** Add a batch to the database. By default, it will include two shipments and one of them will be international */
  readonly createTestBatch: Batch;
  /**
   * Add a purchased shipment to the database, all unspecified parameters will get default values, so you will
   * always receive a shipment with all related data, that can be used to go directly to Rate or Buy Page
   * The used rate data is fake, so please don't rely on the prices on the Buy Page
   */
  readonly createTestShipment: Shipment;
  /**
   * Add a shipment to the database with fixture data (can be exported from live on the shipment page)
   * The ship date will be moved to today.
   */
  readonly createTestShipmentFromFixture: Shipment;
  /** Update the USPS Merchant Account with the current billing address of the company */
  readonly createUspsMerchantAccount: CreateUspsMerchantAccountResult;
  readonly createVenmoPaymentSource: CreatePaymentSourceResponse;
  readonly createWarehouse: Warehouse;
  readonly deleteBatch: Batch;
  readonly deleteChannel: ChannelUnion;
  readonly deleteMailTemplate: MailTemplate;
  readonly deletePaymentSource: PaymentSource;
  readonly deleteShipmentPreset: ShipmentPreset;
  readonly deleteTestBatch: Scalars['Boolean'];
  readonly deleteWarehouse: Warehouse;
  readonly disableChannel: ChannelUnion;
  readonly disputeAdjustments: ReadonlyArray<ReconciliationAdjustment>;
  readonly enableChannel: ChannelUnion;
  readonly fetchSenderEmailMetadata: SenderEmailMetadata;
  readonly forgetLogin: Maybe<Scalars['AuthToken']>;
  readonly ignoreOrders: IgnoreOrderResult;
  readonly invalidateOtherSessions: Scalars['AuthToken'];
  readonly loginWithPassword: LoginResult;
  readonly loginWithRememberToken: LoginResult;
  readonly logout: Maybe<Scalars['AuthToken']>;
  readonly ratePickup: PickupRequestRate;
  /**
   * Will start a background process that will refund all shipments in the batch
   * Poll the status on the Shipments to see the result
   */
  readonly refundBatch: Batch;
  readonly refundCreditFromPaymentSource: Company;
  readonly refundShipment: Batch;
  readonly rememberLogin: Scalars['AuthToken'];
  readonly requestPickup: PickupRequest;
  /** Trigger a new Rating of the Batch in the Background (will return before the process is finished) */
  readonly rerateBatch: Batch;
  readonly resetPassword: User;
  readonly sendConfirmationMail: SenderEmailMetadata;
  readonly sendEmail: Scalars['String'];
  readonly sendResetPasswordLink: Scalars['String'];
  readonly sendUserEmailVerificationLink: Scalars['Boolean'];
  readonly setBatchToNew: Batch;
  readonly setDefaultPaymentSource: PaymentSource;
  readonly setDefaultWarehouse: Warehouse;
  readonly setShipmentUploadMapping: ShipmentUpload;
  readonly signup: LoginResult;
  readonly triggerChannelSync: TriggerSyncResult;
  readonly unignoreOrders: IgnoreOrderResult;
  readonly updateAccountDetails: User;
  readonly updateBatchTitle: Batch;
  readonly updateBigcartelChannel: BigcartelChannel;
  readonly updateBigcommerceChannel: BigcommerceChannel;
  readonly updateBillingAddress: Address;
  readonly updateBlockedCountries: ReadonlyArray<BlockedCountry>;
  readonly updateChallengeAction: Challenge;
  readonly updateChargeAmount: Scalars['Int'];
  readonly updateChargifyChannel: ChargifyChannel;
  readonly updateCratejoyChannel: CratejoyChannel;
  readonly updateDataGridUserSettings: DataGridSettings;
  readonly updateDefaultTrackingEmailsDelay: Scalars['String'];
  readonly updateDefaultTrackingEmailsEnabled: Scalars['Boolean'];
  readonly updateDiscogsChannel: DiscogsChannel;
  readonly updateEbayChannel: EbayChannel;
  readonly updateEcwidChannel: EcwidChannel;
  /** Change email notification date time and template for all recipients of the batch */
  readonly updateEmailNotification: Batch;
  readonly updateEtsyChannel: EtsyChannel;
  readonly updateFakeshopChannel: FakeshopChannel;
  readonly updateGeneralSettings: Scalars['Boolean'];
  readonly updateMailTemplate: MailTemplate;
  readonly updateMoonclerkChannel: MoonclerkChannel;
  readonly updateNotificationsSettings: NotificationsSettings;
  readonly updatePaypalChannel: PaypalChannel;
  readonly updateRateGroupSortOrder: User;
  readonly updateSenderEmailMetadata: SenderEmailMetadata;
  readonly updateShipmentPreset: UpdateShipmentPresetResult;
  readonly updateShopifyChannel: ShopifyChannel;
  readonly updateSquarespaceChannel: SquarespaceChannel;
  readonly updateSubblyChannel: SubblyChannel;
  readonly updateWarehouse: Warehouse;
  readonly updateWoocommerceChannel: WoocommerceChannel;
  readonly uploadFile: Scalars['String'];
  readonly uploadShipmentFile: ShipmentUpload;
  readonly verifyAchPaymentSource: PaymentSource;
};

export type MutationAcceptTermsArgs = {
  accepted: Scalars['Boolean'];
};

export type MutationAddCreditToPaymentSourceArgs = {
  addCreditAmount: Scalars['Float'];
  paymentSourceId: Scalars['ID'];
};

export type MutationBuyBatchArgs = {
  id: Scalars['ID'];
  mailTemplateId?: InputMaybe<Scalars['ID']>;
  notifyRecipientsDate?: InputMaybe<Scalars['String']>;
  paymentSourceId: Scalars['ID'];
  rateSelection: ReadonlyArray<RateSelectionInput>;
  shipDate: Scalars['DateTime'];
  totalCharge: Scalars['Float'];
};

export type MutationCancelEmailNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationCancelPickupArgs = {
  id: Scalars['ID'];
};

export type MutationCompleteBatchFromUploadArgs = {
  batchId: Scalars['ID'];
  batchTitle: Scalars['String'];
  shipmentPresetId: Scalars['ID'];
  warehouseId: Scalars['ID'];
};

export type MutationConnectWoocommerceChannelArgs = {
  shopUrl: Scalars['String'];
};

export type MutationCreateAchPaymentSourceArgs = {
  accountHolderName: Scalars['String'];
  accountHolderType: Scalars['String'];
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type MutationCreateBatchFromUploadArgs = {
  mapping: ReadonlyArray<ShipmentMappingInput>;
  uploadId: Scalars['ID'];
};

export type MutationCreateBigcommerceChannelArgs = {
  input: CreateBigcommerceChannelInput;
};

export type MutationCreateCcPaymentSourceArgs = {
  ccReference: Scalars['String'];
  cp: Scalars['Boolean'];
};

export type MutationCreateChallengeArgs = {
  action: ChallengeAction;
  key: ChallengeKey;
};

export type MutationCreateChargifyChannelArgs = {
  input: CreateChargifyChannelInput;
};

export type MutationCreateCratejoyChannelArgs = {
  input: CreateCratejoyChannelInput;
};

export type MutationCreateDiscogsChannelArgs = {
  input: CreateDiscogsChannelInput;
};

export type MutationCreateFakeshopChannelArgs = {
  input: CreateFakeshopChannelInput;
};

export type MutationCreateMailTemplateArgs = {
  asDefault: Scalars['Boolean'];
  bccEmail?: InputMaybe<Scalars['String']>;
  senderEmail: Scalars['String'];
  senderName: Scalars['String'];
  subject: Scalars['String'];
  templateHtml: Scalars['String'];
  title: Scalars['String'];
};

export type MutationCreateMappingsArgs = {
  additionalMapping?: ReadonlyArray<AdditionalMappingInput>;
  channelId: Scalars['ID'];
  rubberStamp1?: InputMaybe<Scalars['String']>;
  rubberStamp2?: InputMaybe<Scalars['String']>;
  rubberStamp3?: InputMaybe<Scalars['String']>;
};

export type MutationCreateMoonclerkChannelArgs = {
  input: CreateMoonclerkChannelInput;
};

export type MutationCreatePayPalPaymentSourceArgs = {
  nonce: Scalars['String'];
};

export type MutationCreatePlaidAccessTokenArgs = {
  handle: Scalars['String'];
  publicToken: Scalars['String'];
};

export type MutationCreatePlaidActivationArgs = {
  origin: Scalars['String'];
};

export type MutationCreatePlaidConsentArgs = {
  paymentSourceId: Scalars['ID'];
};

export type MutationCreatePlaidValidationActivationArgs = {
  origin: Scalars['String'];
  paymentSourceId: Scalars['ID'];
};

export type MutationCreateScanformByBatchArgs = {
  batchId: Scalars['ID'];
};

export type MutationCreateScanformByShareTokenArgs = {
  shareToken: Scalars['String'];
};

export type MutationCreateScopedShipmentPresetArgs = {
  originalPresetId?: InputMaybe<Scalars['ID']>;
  values: ShipmentPresetInput;
};

export type MutationCreateSenderEmailMetadataArgs = {
  emailAddress: Scalars['ID'];
  senderName: Scalars['String'];
};

export type MutationCreateShipmentPresetArgs = {
  default: Scalars['Boolean'];
  shipmentPreset: ShipmentPresetInput;
};

export type MutationCreateSquarespaceChannelArgs = {
  input: CreateSquarespaceChannelInput;
};

export type MutationCreateSubblyChannelArgs = {
  input: CreateSubblyChannelInput;
};

export type MutationCreateTestBatchArgs = {
  customsItems?: ReadonlyArray<CustomsItemInput>;
  destinationAddresses?: ReadonlyArray<AddressInput>;
  existingWarehouseTitle?: InputMaybe<Scalars['String']>;
  includeSingleCarrierRateGroup?: InputMaybe<Scalars['Int']>;
  mailClasses?: ReadonlyArray<Scalars['Int']>;
  numInternationalShipments?: InputMaybe<Scalars['Int']>;
  orderItems?: ReadonlyArray<OrderItemInput>;
  originAddress?: InputMaybe<AddressInput>;
  returnAddress?: InputMaybe<AddressInput>;
  shipDate?: InputMaybe<Scalars['DateTime']>;
  shipmentPreset?: InputMaybe<ShipmentPresetInput>;
  shipmentStatuses?: ReadonlyArray<ShipmentStatus>;
  surcharges?: ReadonlyArray<SurchargePriceInput>;
  totalPrice?: InputMaybe<Scalars['Float']>;
  trackingStatus?: InputMaybe<Mel_Pp_Model_Shipment_TrackingStatus>;
  uploadMapping?: ReadonlyArray<ShipmentMappingInput>;
  userId: Scalars['ID'];
  warehouseId?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateTestShipmentArgs = {
  customsItems?: ReadonlyArray<CustomsItemInput>;
  destinationAddress?: InputMaybe<AddressInput>;
  existingWarehouseTitle?: InputMaybe<Scalars['String']>;
  isReturnLabel?: InputMaybe<Scalars['Boolean']>;
  labelFileType?: InputMaybe<Scalars['String']>;
  labelSize?: InputMaybe<Scalars['String']>;
  mailClassKey?: InputMaybe<Scalars['String']>;
  orderItems?: ReadonlyArray<OrderItemInput>;
  originAddress?: InputMaybe<AddressInput>;
  rates?: ReadonlyArray<MailClassRateInput>;
  returnAddress?: InputMaybe<AddressInput>;
  rubberStamps?: ReadonlyArray<Scalars['String']>;
  shipDate?: InputMaybe<Scalars['DateTime']>;
  shipmentPreset?: InputMaybe<ShipmentPresetInput>;
  singleCarrier?: InputMaybe<Scalars['Int']>;
  status: ShipmentStatus;
  surcharges?: ReadonlyArray<SurchargePriceInput>;
  totalPrice?: InputMaybe<Scalars['Float']>;
  trackingStatus?: InputMaybe<Mel_Pp_Model_Shipment_TrackingStatus>;
  uploadMapping?: ReadonlyArray<ShipmentMappingInput>;
  userId: Scalars['ID'];
  warehouseId?: InputMaybe<Scalars['Int']>;
  warehouseTitle?: InputMaybe<Scalars['String']>;
};

export type MutationCreateTestShipmentFromFixtureArgs = {
  fixturePath: Scalars['String'];
  userId: Scalars['Int'];
};

export type MutationCreateVenmoPaymentSourceArgs = {
  nonce: Scalars['String'];
};

export type MutationCreateWarehouseArgs = {
  originAddress: AddressInput;
  returnAddress?: InputMaybe<AddressInput>;
  saveAddressForReuse?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  useOriginAsReturnAddress: Scalars['Boolean'];
};

export type MutationDeleteBatchArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteChannelArgs = {
  channelId: Scalars['ID'];
};

export type MutationDeleteMailTemplateArgs = {
  id: Scalars['String'];
};

export type MutationDeletePaymentSourceArgs = {
  paymentSourceId: Scalars['ID'];
};

export type MutationDeleteShipmentPresetArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTestBatchArgs = {
  batchId: Scalars['ID'];
};

export type MutationDeleteWarehouseArgs = {
  id: Scalars['ID'];
};

export type MutationDisableChannelArgs = {
  channelId: Scalars['ID'];
};

export type MutationDisputeAdjustmentsArgs = {
  disputeReason: Scalars['String'];
  shipmentId: Scalars['ID'];
};

export type MutationEnableChannelArgs = {
  channelId: Scalars['ID'];
};

export type MutationFetchSenderEmailMetadataArgs = {
  emailAddress: Scalars['ID'];
};

export type MutationForgetLoginArgs = {
  rememberToken: Scalars['String'];
};

export type MutationIgnoreOrdersArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};

export type MutationLoginWithPasswordArgs = {
  captchaResponse?: InputMaybe<Scalars['String']>;
  cp: Scalars['Boolean'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginWithRememberTokenArgs = {
  rememberToken: Scalars['AuthToken'];
};

export type MutationRatePickupArgs = {
  pickupRequest: PickupRequestInput;
};

export type MutationRefundBatchArgs = {
  id: Scalars['ID'];
};

export type MutationRefundCreditFromPaymentSourceArgs = {
  paymentSourceId: Scalars['ID'];
  refundCreditAmount: Scalars['Float'];
};

export type MutationRefundShipmentArgs = {
  shipmentId: Scalars['ID'];
};

export type MutationRequestPickupArgs = {
  paymentSourceId: Scalars['ID'];
  pickupRequest: PickupRequestInput;
  totalCharge: Scalars['Float'];
};

export type MutationRerateBatchArgs = {
  id: Scalars['ID'];
  shipDate?: InputMaybe<Scalars['DateTime']>;
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
};

export type MutationSendConfirmationMailArgs = {
  emailAddress: Scalars['ID'];
};

export type MutationSendEmailArgs = {
  id: Scalars['String'];
};

export type MutationSendResetPasswordLinkArgs = {
  email: Scalars['String'];
};

export type MutationSetBatchToNewArgs = {
  id: Scalars['ID'];
};

export type MutationSetDefaultPaymentSourceArgs = {
  paymentSourceId: Scalars['ID'];
};

export type MutationSetDefaultWarehouseArgs = {
  warehouseId: Scalars['ID'];
};

export type MutationSetShipmentUploadMappingArgs = {
  batchId: Scalars['ID'];
  mapping: ReadonlyArray<ShipmentMappingInput>;
};

export type MutationSignupArgs = {
  activelyAcceptedTerms: Scalars['Boolean'];
  captchaResponse?: InputMaybe<Scalars['String']>;
  cp: Scalars['Boolean'];
  email: Scalars['String'];
  originAddress: OriginAddressInput;
  password: Scalars['String'];
  returnAddress?: InputMaybe<AddressInput>;
};

export type MutationUnignoreOrdersArgs = {
  ids: ReadonlyArray<Scalars['Int']>;
};

export type MutationUpdateAccountDetailsArgs = {
  currentPassword: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  newPassword?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type MutationUpdateBatchTitleArgs = {
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type MutationUpdateBigcartelChannelArgs = {
  input: UpdateBigcartelChannelInput;
};

export type MutationUpdateBigcommerceChannelArgs = {
  input: UpdateBigcommerceChannelInput;
};

export type MutationUpdateBillingAddressArgs = {
  billingAddress: AddressInput;
};

export type MutationUpdateBlockedCountriesArgs = {
  blockedCountries: ReadonlyArray<BlockedCountryInput>;
  carrierKey: CarrierKey;
};

export type MutationUpdateChallengeActionArgs = {
  action: ChallengeAction;
  key: ChallengeKey;
  newAction: ChallengeAction;
};

export type MutationUpdateChargeAmountArgs = {
  amount: Scalars['Int'];
};

export type MutationUpdateChargifyChannelArgs = {
  input: UpdateChargifyChannelInput;
};

export type MutationUpdateCratejoyChannelArgs = {
  input: UpdateCratejoyChannelInput;
};

export type MutationUpdateDataGridUserSettingsArgs = {
  gridId: Scalars['String'];
  settings: DataGridSettingsInput;
};

export type MutationUpdateDefaultTrackingEmailsDelayArgs = {
  inAdvance: Scalars['String'];
};

export type MutationUpdateDefaultTrackingEmailsEnabledArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationUpdateDiscogsChannelArgs = {
  input: UpdateDiscogsChannelInput;
};

export type MutationUpdateEbayChannelArgs = {
  input: UpdateEbayChannelInput;
};

export type MutationUpdateEcwidChannelArgs = {
  input: UpdateEcwidChannelInput;
};

export type MutationUpdateEmailNotificationArgs = {
  batchId: Scalars['ID'];
  mailTemplateId: Scalars['ID'];
  scheduledDateTime: Scalars['String'];
};

export type MutationUpdateEtsyChannelArgs = {
  input: UpdateEtsyChannelInput;
};

export type MutationUpdateFakeshopChannelArgs = {
  input: UpdateFakeshopChannelInput;
};

export type MutationUpdateGeneralSettingsArgs = {
  addressCorrectionEnabled: Scalars['Boolean'];
  packingListTemplate: Scalars['String'];
  shipmentLabelFileFormat: Scalars['String'];
  shipmentLabelSize: Scalars['String'];
  shipmentSpreadsheetFormat: Scalars['String'];
};

export type MutationUpdateMailTemplateArgs = {
  asDefault: Scalars['Boolean'];
  bccEmail?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  sendTemplatePreview: Scalars['Boolean'];
  senderEmail: Scalars['String'];
  senderName: Scalars['String'];
  subject: Scalars['String'];
  templateHtml: Scalars['String'];
  title: Scalars['String'];
};

export type MutationUpdateMoonclerkChannelArgs = {
  input: UpdateMoonclerkChannelInput;
};

export type MutationUpdateNotificationsSettingsArgs = {
  carrierAdjustmentDisputesEnabled: Scalars['Boolean'];
  carrierAdjustmentsNotificationEnabled: Scalars['Boolean'];
  newFeaturesAnnouncementsNotificationEnabled: Scalars['Boolean'];
  newLoginNotificationEnabled: Scalars['Boolean'];
  paymentFailuresNotificationEnabled: Scalars['Boolean'];
  paymentReceiptsNotificationEnabled: Scalars['Boolean'];
};

export type MutationUpdatePaypalChannelArgs = {
  input: UpdatePaypalChannelInput;
};

export type MutationUpdateRateGroupSortOrderArgs = {
  sortOrder: RateGroupSortOrder;
};

export type MutationUpdateSenderEmailMetadataArgs = {
  emailAddress: Scalars['ID'];
  senderName: Scalars['String'];
};

export type MutationUpdateShipmentPresetArgs = {
  default: Scalars['Boolean'];
  shipmentPreset: ShipmentPresetInput;
};

export type MutationUpdateShopifyChannelArgs = {
  input: UpdateShopifyChannelInput;
};

export type MutationUpdateSquarespaceChannelArgs = {
  input: UpdateSquarespaceChannelInput;
};

export type MutationUpdateSubblyChannelArgs = {
  input: UpdateSubblyChannelInput;
};

export type MutationUpdateWarehouseArgs = {
  id: Scalars['ID'];
  originAddress: AddressInput;
  returnAddress?: InputMaybe<AddressInput>;
  title: Scalars['String'];
  useOriginAsReturnAddress: Scalars['Boolean'];
};

export type MutationUpdateWoocommerceChannelArgs = {
  input: UpdateWoocommerceChannelInput;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type MutationUploadShipmentFileArgs = {
  file: Scalars['Upload'];
};

export type MutationVerifyAchPaymentSourceArgs = {
  amountInCents1: Scalars['Int'];
  amountInCents2: Scalars['Int'];
  paymentSourceId: Scalars['ID'];
};

export type NotificationsSettings = {
  readonly __typename: 'NotificationsSettings';
  readonly carrierAdjustmentDisputesEnabled: Scalars['Boolean'];
  readonly carrierAdjustmentsNotificationEnabled: Scalars['Boolean'];
  readonly newFeaturesAnnouncementsNotificationEnabled: Scalars['Boolean'];
  readonly newLoginNotificationEnabled: Scalars['Boolean'];
  readonly paymentFailuresNotificationEnabled: Scalars['Boolean'];
  readonly paymentReceiptsNotificationEnabled: Scalars['Boolean'];
};

export type NotifyRecipientStatus = 'ERROR' | 'NOT_SCHEDULED' | 'RUNNING' | 'SCHEDULED' | 'SENT';

export type Numbers = {
  readonly __typename: 'Numbers';
  readonly carrierAdjustmentsAmount: Scalars['Float'];
  readonly carrierAdjustmentsCount: Scalars['Int'];
  readonly netLabelAverage: Scalars['Float'];
  readonly netLabelAverageDom: Scalars['Float'];
  readonly netLabelAverageInt: Scalars['Float'];
  readonly netLabelCount: Scalars['Float'];
  readonly netLabelTotal: Scalars['Float'];
  readonly payments: Scalars['Float'];
  readonly pendingNumbers: PendingNumbers;
};

export type Option = {
  readonly __typename: 'Option';
  readonly title: Scalars['String'];
  readonly value: Scalars['String'];
};

export type Order = {
  readonly __typename: 'Order';
  /** ID that this order has on the platform we imported it from */
  readonly foreignOrderId: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly orderItems: ReadonlyArray<OrderItem>;
  /** Link to the order on the platform we imported it from */
  readonly orderLink: Maybe<Link>;
  /** The platform we imported this order from (Shopify, WooCommerce, etc) */
  readonly platform: Platform;
  /** Name of the platform we imported this order from */
  readonly platformKey: Scalars['String'];
  /** Link to the subscription on the platform we imported it from */
  readonly shipmentLink: Maybe<Link>;
  /** Link to the subscription on the platform we imported it from */
  readonly subscriptionLink: Maybe<Link>;
};

export type OrderItem = {
  readonly __typename: 'OrderItem';
  readonly id: Scalars['ID'];
  readonly quantity: Scalars['Float'];
  readonly sku: Scalars['String'];
  readonly title: Scalars['String'];
};

export type OrderItemInput = {
  readonly quantity: Scalars['Int'];
  readonly sku: Scalars['String'];
  readonly title: Scalars['String'];
};

export type OriginAddressInput = {
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly city: Scalars['String'];
  readonly company: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly postcode: Scalars['String'];
  readonly regionCode: Scalars['String'];
  readonly zip4?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  readonly __typename: 'PageInfo';
  readonly endCursor: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
  readonly startCursor: Maybe<Scalars['String']>;
};

export type PageLayout =
  | 'LAYOUT_2x7'
  | 'LAYOUT_4x6'
  | 'LAYOUT_85x11_1UP_LEFT'
  | 'LAYOUT_85x11_1UP_RIGHT'
  | 'LAYOUT_85x11_2UP';

export type Payment = {
  readonly __typename: 'Payment';
  readonly apiMethodId: Scalars['String'];
  readonly authorizedAmount: Scalars['Float'];
  readonly batchId: Scalars['Int'];
  readonly batchRatedAmount: Scalars['Float'];
  readonly chargeError: Scalars['String'];
  readonly chargebackFeeClient: Scalars['Float'];
  readonly chargebackFeePirate: Scalars['Float'];
  readonly chargedAmount: Scalars['Float'];
  readonly companyId: Scalars['Int'];
  readonly createdAt: Scalars['String'];
  readonly debtDue: Scalars['Float'];
  readonly fee: Scalars['Float'];
  readonly feeCompany: Scalars['Float'];
  readonly feeDate: Scalars['String'];
  readonly gatewayId: Scalars['String'];
  readonly groupPaymentId: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly isCompleted: Scalars['Boolean'];
  readonly moreLink: Maybe<Scalars['String']>;
  readonly note: Scalars['String'];
  readonly paymentSourceId: Scalars['String'];
  readonly receipt: Receipt;
  readonly reconciliationRunId: Scalars['Int'];
  readonly refundTotal: Scalars['Float'];
  readonly status: Scalars['String'];
  readonly strategy: Maybe<Mel_Payment_Enum_StrategyName>;
  readonly title: Scalars['String'];
  readonly total: Scalars['String'];
  readonly updatedAt: Scalars['String'];
  readonly userId: Scalars['Int'];
};

export type PaymentAction = {
  readonly __typename: 'PaymentAction';
  readonly actionType: Scalars['String'];
  readonly applicationErrorCode: Scalars['String'];
  readonly createdAt: Scalars['String'];
  readonly errorMessage: Scalars['String'];
  readonly gatewayToken: Scalars['String'];
  readonly httpErrorCode: Scalars['String'];
  readonly identifier: Scalars['ID'];
  readonly newPaymentStatus: Scalars['String'];
  readonly paymentId: Scalars['Int'];
  readonly remoteReferenceId: Scalars['String'];
  readonly remoteTransactionId: Scalars['String'];
  readonly success: Scalars['Int'];
  readonly userId: Scalars['Int'];
};

export type PaymentApiMethod = {
  readonly __typename: 'PaymentApiMethod';
  /** Public key (PayPal: tokenization key) for payment API */
  readonly apiKey: Maybe<Scalars['String']>;
  /** API to be used for new payment sources with this payment method */
  readonly apiTitle: PaymentApiMethodTitle;
  readonly code: PaymentMethodType;
  readonly id: Scalars['ID'];
};

/** Enumeration of all value types */
export type PaymentApiMethodTitle =
  | 'Offline'
  | 'PayPal'
  | 'Plaid'
  | 'Spreedly'
  | 'Stripe'
  | 'VENMO';

/** Enumeration of all value types */
export type PaymentMethodType = 'ach' | 'cc' | 'manual' | 'paypal' | 'plaidach' | 'venmo';

export type PaymentSource = {
  readonly __typename: 'PaymentSource';
  readonly bankAccountType: Scalars['String'];
  readonly billingZipcode: Scalars['String'];
  readonly billingZipcodeVerification: Scalars['String'];
  readonly brand: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly email: Maybe<Scalars['String']>;
  readonly expMonth: Maybe<Scalars['Int']>;
  readonly expYear: Maybe<Scalars['Int']>;
  readonly first6: Maybe<Scalars['String']>;
  readonly funding: Maybe<Funding>;
  readonly hasConsented: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly last4: Maybe<Scalars['String']>;
  readonly paymentMethodType: PaymentMethodType;
  readonly refundableAmount: Scalars['Float'];
  readonly resultMessage: Maybe<Scalars['String']>;
  readonly shortTitle: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly validationStatus: Scalars['String'];
};

export type PaypalChannel = ChannelInterface & {
  readonly __typename: 'PaypalChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly genericLineItemEnabled: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type PendingNumbers = {
  readonly __typename: 'PendingNumbers';
  readonly paymentsTotalAmount: Scalars['Float'];
  readonly refunds: Summary;
  readonly returns: Summary;
};

export type PickupRequest = {
  readonly __typename: 'PickupRequest';
  readonly canCancel: Scalars['Boolean'];
  readonly carrier: Carrier;
  readonly confirmationNumber: Maybe<Scalars['String']>;
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly errorMessage: Scalars['String'];
  readonly foreignId: Maybe<Scalars['String']>;
  readonly frequency: Maybe<PickupRequestFrequency>;
  readonly hasUpsOverweightPackages: Maybe<Scalars['Boolean']>;
  readonly highestServiceLevel: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  /**
   * The date on which the pickup will happen in the location where the pickup happens
   * (Since this is a whole day without a specific time, it has no time and no timezone)
   * Format: 2023-06-22
   */
  readonly oneTimePickupDate: Maybe<Scalars['String']>;
  readonly packageLocation: PickupRequestPackageLocation;
  readonly previouslyRatedPrice: Maybe<Scalars['Float']>;
  readonly rate: PickupRequestRate;
  readonly recurringPickupDays: Maybe<ReadonlyArray<PickupRequestDay>>;
  readonly specialInstructions: Maybe<Scalars['String']>;
  readonly status: PickupRequestStatus;
  readonly totalPackageCount: Scalars['Int'];
  readonly totalPackageWeight: Scalars['Float'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly warehouse: Warehouse;
  readonly warehouseCloseTime: Scalars['String'];
  readonly warehouseReadyTime: Scalars['String'];
};

/** Enumeration of pickup days */
export type PickupRequestDay =
  /** Pickup on Friday */
  | 'FRIDAY'
  /** Pickup on Monday */
  | 'MONDAY'
  /** Pickup on Saturday */
  | 'SATURDAY'
  /** Pickup on Sunday */
  | 'SUNDAY'
  /** Pickup on Thursday */
  | 'THURSDAY'
  /** Pickup on Tuesday */
  | 'TUESDAY'
  /** Pickup on Wednesday */
  | 'WEDNESDAY';

/** Enumeration of pickup request frequencies */
export type PickupRequestFrequency =
  /** Request a one-time pickup */
  | 'ONE_TIME'
  /** Request a recurring pickup on set days */
  | 'RECURRING';

export type PickupRequestInput = {
  readonly carrierKey: CarrierKey;
  readonly frequency: PickupRequestFrequency;
  readonly hasUpsOverweightPackages?: InputMaybe<Scalars['Boolean']>;
  readonly highestServiceLevel?: InputMaybe<Scalars['String']>;
  readonly oneTimePickupDate?: InputMaybe<Scalars['Date']>;
  readonly packageLocation: PickupRequestPackageLocation;
  readonly previouslyRatedPrice?: InputMaybe<Scalars['Float']>;
  readonly recurringPickupDays?: InputMaybe<ReadonlyArray<PickupRequestDay>>;
  readonly specialInstructions?: InputMaybe<Scalars['String']>;
  readonly totalPackageCount: Scalars['Int'];
  readonly totalPackageWeight: Scalars['Float'];
  readonly warehouseCloseTime: Scalars['String'];
  readonly warehouseId: Scalars['ID'];
  readonly warehouseReadyTime: Scalars['String'];
};

/** Enumeration of pickup package locations */
export type PickupRequestPackageLocation =
  /** Pick up packages at the back door */
  | 'BACK_DOOR'
  /** Pick up packages at the front door */
  | 'FRONT_DOOR'
  /** Knock on door to pick up packages */
  | 'KNOCK_OR_RING'
  /** Pick up packages from or at the mailbox */
  | 'MAILBOX'
  /** Pick up packages at the mail room */
  | 'MAIL_ROOM'
  /** Pick up packages at the office */
  | 'OFFICE'
  /** Pick up packages from another location (see further instructions) */
  | 'OTHER'
  /** Pick up packages at the reception */
  | 'RECEPTION'
  /** Pick up packages at the side door */
  | 'SIDE_DOOR';

export type PickupRequestRate = {
  readonly __typename: 'PickupRequestRate';
  readonly basePrice: Scalars['Float'];
  readonly surcharges: ReadonlyArray<PickupSurcharge>;
  readonly totalPrice: Scalars['Float'];
};

/** Enumeration of pickup request statuses */
export type PickupRequestStatus =
  /** Pickup was canceled */
  | 'CANCELLED'
  /** Pickup was requested to be canceled */
  | 'CANCEL_REQUESTED'
  /** Pickup was confirmed by carrier */
  | 'CONFIRMED'
  /** Pickup request is created */
  | 'CREATED'
  /** Pickup request was denied */
  | 'DENIED'
  /** Pickup was requested from carrier */
  | 'REQUESTED';

export type PickupSurcharge = {
  readonly __typename: 'PickupSurcharge';
  readonly helpLink: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly price: Scalars['Float'];
};

export type PlaidActivation = {
  readonly __typename: 'PlaidActivation';
  readonly handle: Scalars['String'];
  readonly linkToken: Scalars['String'];
  readonly mask: Scalars['String'];
  readonly name: Scalars['String'];
  readonly paymentSource: Maybe<PaymentSource>;
  readonly publicToken: Maybe<Scalars['String']>;
};

export type Platform = {
  readonly __typename: 'Platform';
  readonly additionalTrackingEmailCopy: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly installUrl: Maybe<Scalars['String']>;
  readonly module: Scalars['String'];
  readonly platformKey: Scalars['String'];
  readonly reconnectSupported: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly trackingEmailSupported: Scalars['Boolean'];
};

export type PlatformMappableField = {
  readonly __typename: 'PlatformMappableField';
  readonly field: Scalars['String'];
  readonly label: Scalars['String'];
};

export type PlatformOverrideField = {
  readonly __typename: 'PlatformOverrideField';
  readonly field: Scalars['String'];
  readonly label: Scalars['String'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly addressAutocompleteSettings: AddressAutocompleteSettings;
  readonly adminShipmentsReport: DataGridResult;
  readonly applicationEnvironment: Scalars['String'];
  readonly applicationHostname: Scalars['String'];
  readonly autoRefundedShipmentsSummary: Summary;
  readonly averageCostChart: Chart;
  readonly batch: Batch;
  readonly batchDebug: Scalars['JSONObject'];
  readonly blockedCountries: ReadonlyArray<BlockedCountry>;
  readonly boughtBatchShipmentsGrid: DataGridResult;
  readonly braintreeClientToken: Scalars['String'];
  readonly carrier: Carrier;
  readonly carrierAdjustmentReport: DataGridResult;
  readonly carrierAdjustments: Scalars['JSONObject'];
  readonly carriers: ReadonlyArray<Carrier>;
  readonly challenges: ReadonlyArray<Challenge>;
  readonly channel: Maybe<Channel>;
  readonly channelSyncStatus: SyncStatusResult;
  readonly chargeAmountOptions: ReadonlyArray<Scalars['Int']>;
  /**
   * Returns a grid listing all clients(companies) of Pirate Ship
   * This is only available for supporters
   * The available columns and features depend on the supporters access rights
   */
  readonly clientsGrid: DataGridResult;
  readonly company: Company;
  readonly companySavings: Scalars['Float'];
  readonly countries: ReadonlyArray<Country>;
  readonly countryDistributionChart: CountryDistributionChart;
  readonly downloadLabelByShipment: DownloadLabelsByShipmentsResult;
  readonly downloadLabelsByBatch: DownloadLabelsByBatchResult;
  readonly downloadLabelsByShareToken: DownloadLabelsByShareTokenResult;
  readonly downloadLabelsByShipments: DownloadLabelsByShipmentsResult;
  readonly globalSettings: GlobalSettings;
  readonly importedOrders: DataGridResult;
  readonly intercomArticle: Scalars['String'];
  readonly isEu: Scalars['Boolean'];
  readonly latestBatches: ReadonlyArray<Batch>;
  readonly latestTerms: UserTerms;
  /** All shipment fields that can be mapped to a column in an order upload spreadsheet */
  readonly mappableShipmentFields: ReadonlyArray<MappableShipmentField>;
  readonly notificationsSettings: NotificationsSettings;
  readonly numbers: Numbers;
  /**
   * Defines which payment service should be used for which payment method.
   * This is a global setting for all clients and users that can only be changed by admins.
   */
  readonly paymentApiMethods: ReadonlyArray<PaymentApiMethod>;
  readonly paymentChart: Chart;
  readonly paymentReceipts: Maybe<Scalars['JSONObject']>;
  readonly platforms: ReadonlyArray<Platform>;
  readonly rates: ReadonlyArray<RateResult>;
  readonly receipt: Receipt;
  readonly receiptsReport: DataGridResult;
  readonly refundReport: DataGridResult;
  readonly refunds: Maybe<Scalars['JSONObject']>;
  readonly regions: ReadonlyArray<Region>;
  readonly returnLabelsReport: DataGridResult;
  readonly returns: Maybe<Scalars['JSONObject']>;
  readonly senderEmailMetadata: Maybe<SenderEmailMetadata>;
  readonly shareDownloadUrlByBatch: Scalars['String'];
  readonly shareDownloadUrlByShipment: Scalars['String'];
  readonly shipment: Shipment;
  readonly shipmentBoundaries: ShipmentBoundaries;
  readonly shipmentChart: Chart;
  readonly shipmentDebug: Scalars['JSONObject'];
  readonly shipmentPieChart: Chart;
  readonly shipmentReport: DataGridResult;
  readonly shipmentTrackingNumbers: ShipmentTrackingNumbers;
  /**
   * Info about an Upload of orders from a spreadsheet with it's mapping to shipment fields.
   * (initial mapping is a suggested mapping)
   */
  readonly shipmentUpload: ShipmentUpload;
  readonly stateDistributionChart: StateDistributionChart;
  readonly supportSearch: ReadonlyArray<SupportSearchResult>;
  readonly timezones: ReadonlyArray<Timezone>;
  readonly transactionHistory: DataGridResult;
  readonly transactions: Maybe<Scalars['JSONObject']>;
  readonly user: User;
  readonly zoneChart: Chart;
  /** Returns zone for the provided origin-destination zip code pair. */
  readonly zoneForRoute: Scalars['Int'];
  /**
   * Returns zones from the provided origin zip to all destinations. Returned as a hash table keyed by 3-digit
   * destination zip codes for quick lookup. Zip codes are prefixed to avoid key access issues in JavaScript objects.
   * The returned JSON object looks like this:
   *
   * {
   *   ...
   *   "zip832": 4,
   *   "zip833": 5,
   *   ...
   * }
   */
  readonly zoneMapForOrigin: Scalars['JSONObject'];
  /**
   * Returns zones from all origins to all destinations, keyed by 3-digit zip codes in a nested hashtable for
   * quick lookup. Zip codes are prefixed to avoid key access issues in JavaScript objects.
   * The returned JSON object looks like this:
   *
   * {
   *   ...
   *   "zip902": { ..., "zip832": 4, "zip833": 5, ...},
   *   "zip903": { ..., "zip832": 4, "zip833": 5, ...},
   *   ...
   * }
   */
  readonly zoneTable: Scalars['JSONObject'];
};

export type QueryAverageCostChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryBatchArgs = {
  id: Scalars['ID'];
};

export type QueryBatchDebugArgs = {
  batchId: Scalars['ID'];
};

export type QueryBlockedCountriesArgs = {
  carrierKey: CarrierKey;
};

export type QueryBoughtBatchShipmentsGridArgs = {
  batchId: Scalars['ID'];
};

export type QueryCarrierArgs = {
  carrierKey: CarrierKey;
};

export type QueryChannelArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type QueryCompanySavingsArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryCountryDistributionChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryDownloadLabelByShipmentArgs = {
  pageLayout?: InputMaybe<PageLayout>;
  shipmentId: Scalars['ID'];
};

export type QueryDownloadLabelsByBatchArgs = {
  batchId: Scalars['ID'];
  pageLayout?: InputMaybe<PageLayout>;
};

export type QueryDownloadLabelsByShareTokenArgs = {
  pageLayout?: InputMaybe<PageLayout>;
  shareToken: Scalars['String'];
};

export type QueryDownloadLabelsByShipmentsArgs = {
  pageLayout?: InputMaybe<PageLayout>;
  shipmentIds: ReadonlyArray<Scalars['ID']>;
};

export type QueryImportedOrdersArgs = {
  gridMode?: InputMaybe<GridMode>;
};

export type QueryIntercomArticleArgs = {
  url: Scalars['String'];
};

export type QueryNumbersArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryPaymentChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryPlatformsArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRatesArgs = {
  addressType?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  destinationCountryCode?: InputMaybe<Scalars['String']>;
  destinationRegionCode?: InputMaybe<Scalars['String']>;
  destinationZip?: InputMaybe<Scalars['String']>;
  dimensionX?: InputMaybe<Scalars['Float']>;
  dimensionY?: InputMaybe<Scalars['Float']>;
  dimensionZ?: InputMaybe<Scalars['Float']>;
  extraServices?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  hazardousMaterials?: InputMaybe<Scalars['Boolean']>;
  irregularPackage?: InputMaybe<Scalars['Boolean']>;
  isResidential?: InputMaybe<Scalars['Boolean']>;
  mailClassKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  originCity?: InputMaybe<Scalars['String']>;
  originRegionCode?: InputMaybe<Scalars['String']>;
  originZip: Scalars['String'];
  packageTypeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  priceBaseTypeKeys?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  pricingTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  returnCheapest?: InputMaybe<Scalars['Boolean']>;
  shipDate?: InputMaybe<Scalars['String']>;
  showUpsRatesWhen2x7Selected?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type QueryReceiptArgs = {
  paymentId: Scalars['ID'];
};

export type QueryRegionsArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
};

export type QuerySenderEmailMetadataArgs = {
  emailAddress: Scalars['ID'];
};

export type QueryShareDownloadUrlByBatchArgs = {
  batchId: Scalars['ID'];
};

export type QueryShareDownloadUrlByShipmentArgs = {
  shipmentId: Scalars['ID'];
};

export type QueryShipmentArgs = {
  id: Scalars['ID'];
};

export type QueryShipmentChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryShipmentDebugArgs = {
  shipmentId: Scalars['ID'];
};

export type QueryShipmentPieChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryShipmentTrackingNumbersArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryShipmentUploadArgs = {
  id: Scalars['ID'];
};

export type QueryStateDistributionChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QuerySupportSearchArgs = {
  term: Scalars['String'];
};

export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryZoneChartArgs = {
  datetimeRange: DatetimeRangeInput;
};

export type QueryZoneForRouteArgs = {
  carrierId: Scalars['ID'];
  destinationZip: Scalars['String'];
  originZip: Scalars['String'];
};

export type QueryZoneMapForOriginArgs = {
  carrierId: Scalars['ID'];
  originZip: Scalars['String'];
};

export type QueryZoneTableArgs = {
  carrierId: Scalars['ID'];
};

export type RateGroup = {
  readonly __typename: 'RateGroup';
  readonly affectedByUpsRateLimit: Scalars['Boolean'];
  readonly defaultShipDate: Scalars['String'];
  readonly groupId: Scalars['String'];
  readonly groupKey: RateGroupKey;
  readonly intercomLink: Maybe<Scalars['String']>;
  readonly label: Scalars['String'];
  readonly maximumShipments: Scalars['Int'];
  readonly numberOfCheapestOrBestServices: Scalars['Int'];
  readonly rateSummaries: ReadonlyArray<RateSummary>;
  readonly title: Scalars['String'];
};

export type RateGroupKey = {
  readonly __typename: 'RateGroupKey';
  readonly string: Scalars['String'];
  readonly traits: ReadonlyArray<RateGroupTrait>;
};

export type RateGroupKeyInput = {
  readonly traits: ReadonlyArray<RateGroupTraitInput>;
};

export type RateGroupLayerKey = 'Address' | 'Carrier' | 'Direction' | 'Measurements';

export type RateGroupSortOrder = 'BEST' | 'CHEAPEST' | 'UPS' | 'USPS';

export type RateGroupTrait = {
  readonly __typename: 'RateGroupTrait';
  readonly layer: RateGroupLayerKey;
  readonly value: RateGroupTraitKey;
};

export type RateGroupTraitInput = {
  readonly layer: RateGroupLayerKey;
  readonly value: RateGroupTraitKey;
};

export type RateGroupTraitKey =
  | 'ASSOCIATED'
  | 'BIG_OR_HEAVY'
  | 'DOMESTIC'
  | 'INTERNATIONAL'
  | 'LAYER_IRRELEVANT'
  | 'LIGHT_AND_SMALL'
  | 'MILITARY_PO_BOX'
  | 'RETURN'
  | 'UPS'
  | 'USPS';

export type RateResult = {
  readonly __typename: 'RateResult';
  readonly arrivalLocalTime: Maybe<Scalars['DateTime']>;
  readonly basePrice: Scalars['Float'];
  readonly carrier: Carrier;
  readonly cheapest: Scalars['Boolean'];
  readonly crossedTotalPrice: Scalars['Float'];
  readonly cubicTier: Maybe<Scalars['String']>;
  readonly deliveryDescription: Scalars['String'];
  readonly fastest: Scalars['Boolean'];
  readonly isGuaranteedDelivery: Scalars['Boolean'];
  readonly isSaturdayDelivery: Scalars['Boolean'];
  readonly learnMoreUrl: Scalars['String'];
  readonly mailClass: CarrierMailClass;
  readonly mailClassKey: Scalars['String'];
  readonly packageTypeKey: Scalars['String'];
  readonly pickupLocalTime: Maybe<Scalars['DateTime']>;
  readonly priceBaseTypeKey: Scalars['String'];
  readonly pricingDescription: Scalars['String'];
  readonly pricingSubType: Scalars['String'];
  readonly pricingType: Scalars['String'];
  readonly ratePeriodId: Scalars['Int'];
  readonly serviceDescription: Scalars['String'];
  readonly surcharges: ReadonlyArray<RateSurcharge>;
  readonly title: Scalars['String'];
  readonly totalPrice: Scalars['Float'];
  readonly trackingDescription: Scalars['String'];
  readonly zone: Scalars['String'];
};

export type RateSelection = {
  readonly __typename: 'RateSelection';
  readonly groupKey: RateGroupKey;
  readonly mailClassId: Scalars['String'];
  readonly packageTypeId: Scalars['String'];
  readonly saturdayDelivery: Scalars['Boolean'];
};

export type RateSelectionInput = {
  readonly groupKeyInput: RateGroupKeyInput;
  readonly isSaturdayDelivery: Scalars['Boolean'];
  readonly mailClassId: Scalars['String'];
  readonly packageTypeId: Scalars['String'];
};

export type RateSummary = {
  readonly __typename: 'RateSummary';
  readonly availableShipDates: ReadonlyArray<Scalars['String']>;
  readonly averageBasePrice: Scalars['Float'];
  readonly averageTotalPrice: Scalars['Float'];
  readonly basePrice: Scalars['Float'];
  readonly best: Scalars['Boolean'];
  readonly carrier: Carrier;
  readonly cheapest: Scalars['Boolean'];
  readonly crossedTotalPrice: Scalars['Float'];
  readonly deliveryDays: Scalars['Float'];
  readonly errorMessage: Maybe<Scalars['String']>;
  readonly fastest: Scalars['Boolean'];
  readonly firstMailClass: CarrierMailClass;
  readonly firstZone: Scalars['String'];
  readonly flatPrice: Scalars['Boolean'];
  readonly mailClassTitle: Scalars['String'];
  readonly maxWeightOz: Scalars['Float'];
  readonly packageType: CarrierPackageType;
  readonly ratePeriodEndDate: Maybe<Scalars['String']>;
  readonly ratePeriodStartDate: Maybe<Scalars['String']>;
  readonly savings: Scalars['String'];
  readonly serviceTitle: Scalars['String'];
  readonly shipmentCount: Scalars['Int'];
  readonly surcharges: ReadonlyArray<RateSurchargeSummary>;
  readonly surchargesPrice: Scalars['Float'];
  readonly totalPrice: Scalars['Float'];
  readonly uniqueId: Scalars['String'];
  readonly upsellRateSummary: Maybe<RateSummary>;
};

export type RateSurcharge = {
  readonly __typename: 'RateSurcharge';
  readonly parentSurchargeId: Maybe<Scalars['Int']>;
  readonly price: Scalars['Float'];
  readonly surchargeKey: Scalars['String'];
  readonly title: Scalars['String'];
};

export type RateSurchargeSummary = {
  readonly __typename: 'RateSurchargeSummary';
  readonly averagePrice: Scalars['Float'];
  readonly crossedPrice: Maybe<Scalars['Float']>;
  readonly helpLink: Maybe<Scalars['String']>;
  readonly price: Scalars['Float'];
  readonly shipmentCount: Scalars['Int'];
  readonly surchargeId: Scalars['String'];
  readonly surchargeKey: Scalars['String'];
  readonly title: Scalars['String'];
};

export type Receipt = {
  readonly __typename: 'Receipt';
  readonly billingAddress: AddressInterface;
  readonly chargeSuccessful: Scalars['Boolean'];
  readonly company: Company;
  readonly endBalance: Scalars['Float'];
  readonly isCharged: Scalars['Boolean'];
  readonly payment: Payment;
  readonly paymentSuccessful: Scalars['Boolean'];
  readonly reversals: ReadonlyArray<ReversalType>;
  readonly startBalance: Scalars['Float'];
  readonly transactions: ReadonlyArray<AccountingTransaction>;
};

export type RecipientAddress = BasicAddressInterface & {
  readonly __typename: 'RecipientAddress';
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly address3: Scalars['String'];
  readonly addressType: Scalars['String'];
  readonly city: Scalars['String'];
  readonly company: Scalars['String'];
  readonly countryCode: Scalars['String'];
  readonly email: Maybe<Scalars['String']>;
  readonly fullName: Scalars['String'];
  readonly location: Location;
  readonly phone: Scalars['String'];
  readonly postcode: Scalars['String'];
  readonly regionCode: Scalars['String'];
  readonly residential: Scalars['Boolean'];
  readonly timeZone: Scalars['String'];
  readonly valid: Scalars['Boolean'];
  readonly zip4: Scalars['String'];
};

export type ReconciliationAdjustment = {
  readonly __typename: 'ReconciliationAdjustment';
  readonly createdAt: Scalars['DateTime'];
  readonly dimensionX: Scalars['Float'];
  readonly dimensionXOriginal: Maybe<Scalars['Float']>;
  readonly dimensionY: Scalars['Float'];
  readonly dimensionYOriginal: Maybe<Scalars['Float']>;
  readonly dimensionZ: Scalars['Float'];
  readonly dimensionZOriginal: Maybe<Scalars['Float']>;
  readonly disputeReason: Scalars['String'];
  readonly disputeStatus: DisputeStatus;
  readonly disputedAt: Maybe<Scalars['DateTime']>;
  readonly eligibilityIssueMessage: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly notes: ReadonlyArray<Scalars['String']>;
  readonly processedAt: Scalars['DateTime'];
  readonly weight: Scalars['Float'];
  readonly weightOriginal: Maybe<Scalars['Float']>;
};

export type Region = {
  readonly __typename: 'Region';
  readonly countryCode: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly regionCode: Scalars['String'];
};

/** Enumeration of all value types */
export type ReturnLabel = 'return' | 'standard' | 'standard_and_return';

export type ReversalType = {
  readonly __typename: 'ReversalType';
  readonly action: PaymentAction;
  readonly amount: Scalars['Float'];
  readonly coveringAccountingTransaction: Maybe<AccountingTransaction>;
};

export type ScanformByBatchResult = {
  readonly __typename: 'ScanformByBatchResult';
  readonly batchId: Scalars['ID'];
  readonly canCreateScanform: Scalars['Boolean'];
  readonly scanformUrl: Scalars['String'];
};

export type ScanformByShareTokenResult = {
  readonly __typename: 'ScanformByShareTokenResult';
  readonly canCreateScanform: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isScanformCreated: Scalars['Boolean'];
  readonly scanformUrl: Maybe<Scalars['String']>;
};

export type ScanformFile = {
  readonly __typename: 'ScanformFile';
  readonly downloadUrl: Scalars['String'];
  readonly fileType: Scalars['String'];
  readonly id: Scalars['Int'];
};

export type SelectOption = {
  readonly __typename: 'SelectOption';
  readonly label: Scalars['String'];
  readonly value: Scalars['String'];
};

export type SenderEmailMetadata = {
  readonly __typename: 'SenderEmailMetadata';
  readonly dkimHost: Scalars['String'];
  readonly dkimTextValue: Scalars['String'];
  readonly dkimVerified: Scalars['Boolean'];
  readonly domain: Scalars['String'];
  readonly errorMessage: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly returnPathDomain: Scalars['String'];
  readonly returnPathDomainCnameValue: Scalars['String'];
  readonly returnPathDomainVerified: Scalars['Boolean'];
  readonly senderName: Scalars['String'];
  readonly verificationStatus: Maybe<EmailVerificationStatus>;
};

export type Shipment = AddressInterface &
  BasicAddressInterface & {
    readonly __typename: 'Shipment';
    readonly additionalRefundNotice: Scalars['String'];
    readonly address1: Scalars['String'];
    readonly address2: Scalars['String'];
    readonly addressType: Scalars['String'];
    readonly adjustments: ReadonlyArray<ReconciliationAdjustment>;
    readonly baseClientPrice: Maybe<Scalars['Float']>;
    readonly baseClientPriceOriginal: Maybe<Scalars['Float']>;
    readonly batch: Batch;
    readonly canDisplayPrices: Scalars['Boolean'];
    readonly canInstantRefundShipment: Scalars['Boolean'];
    readonly canPrint: Scalars['Boolean'];
    readonly carrierKey: Scalars['String'];
    readonly carrierTitle: Scalars['String'];
    readonly city: Scalars['String'];
    readonly company: Scalars['String'];
    readonly companyId: Scalars['String'];
    readonly countryCode: Scalars['String'];
    readonly countryId: Scalars['String'];
    readonly countryIdOriginal: Scalars['String'];
    readonly deliveryEstimation: Maybe<DeliveryEstimation>;
    readonly dimensionX: Scalars['Float'];
    readonly dimensionXOriginal: Scalars['Float'];
    readonly dimensionY: Scalars['Float'];
    readonly dimensionYOriginal: Scalars['Float'];
    readonly dimensionZ: Maybe<Scalars['Float']>;
    readonly dimensionZOriginal: Maybe<Scalars['Float']>;
    readonly disputableAdjustmentsCount: Scalars['Int'];
    readonly duplicateShipmentLink: Scalars['String'];
    readonly errorMessage: Scalars['String'];
    readonly foreignOrderId: Scalars['String'];
    readonly fullName: Scalars['String'];
    readonly id: Scalars['ID'];
    readonly insuranceClaimUrl: Maybe<Scalars['String']>;
    readonly insuredValueRequested: Scalars['Float'];
    readonly isRedacted: Scalars['Boolean'];
    readonly isReturnLabel: Scalars['Boolean'];
    readonly isShipmentRefundable: Scalars['Boolean'];
    readonly location: Location;
    readonly mailClass: Maybe<CarrierMailClass>;
    readonly mailClassKey: Maybe<Scalars['String']>;
    readonly mailClassOriginal: Maybe<CarrierMailClass>;
    readonly map: Scalars['String'];
    readonly notificationEmailSent: Scalars['Boolean'];
    readonly order: Maybe<Order>;
    readonly origin: Scalars['Boolean'];
    readonly originalId: Maybe<Scalars['ID']>;
    readonly packageType: CarrierPackageType;
    readonly packageTypeOriginal: CarrierPackageType;
    readonly passthroughFields: ReadonlyArray<ShipmentPassthroughFieldType>;
    readonly phone: Scalars['String'];
    readonly postcode: Scalars['String'];
    readonly printed: Scalars['Boolean'];
    readonly recipientAddress: RecipientAddress;
    readonly refundTimeNoticeShipmentDescription: Scalars['String'];
    readonly refundTimeNoticeShipmentTitle: Scalars['String'];
    readonly regionCode: Scalars['String'];
    readonly residential: Scalars['Boolean'];
    readonly stageDots: ReadonlyArray<StageDot>;
    readonly stageDotsStatus: ReadonlyArray<Scalars['String']>;
    readonly status: ShipmentStatus;
    readonly surcharges: Maybe<ReadonlyArray<ShipmentPrice>>;
    readonly timeZone: Scalars['String'];
    readonly totalClientPrice: Maybe<Scalars['Float']>;
    readonly totalClientPriceOriginal: Maybe<Scalars['Float']>;
    /**
     * Starts the creation of an export file if no exportId is specified. If exportId is specified, the request will
     * be treated as a status request for the existing export.
     */
    readonly trackingDataExportUrl: DataGridExportStatus;
    readonly trackingLink: Scalars['String'];
    readonly trackingNr: Maybe<Scalars['String']>;
    readonly valid: Scalars['Boolean'];
    readonly validationError: Scalars['String'];
    readonly warehouse: Maybe<Warehouse>;
    readonly weight: Scalars['Float'];
    readonly weightOriginal: Scalars['Float'];
    readonly zip4: Scalars['String'];
    readonly zone: Maybe<Scalars['String']>;
    readonly zoneOriginal: Maybe<Scalars['String']>;
  };

export type ShipmentMapArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ShipmentTrackingDataExportUrlArgs = {
  exportId?: InputMaybe<Scalars['Int']>;
};

export type ShipmentAutocompleteConfig = {
  readonly __typename: 'ShipmentAutocompleteConfig';
  readonly addressBaseUrl: Scalars['String'];
  readonly addressProvider: Scalars['String'];
  readonly googleApiKey: Scalars['String'];
  readonly googlePlacesSessionRequestsEnabled: Scalars['Boolean'];
};

export type ShipmentBoundaries = {
  readonly __typename: 'ShipmentBoundaries';
  readonly maxCombinedLength: Scalars['Float'];
  readonly maxLengthPlusGirth: Scalars['Float'];
  readonly maxLongSide: Scalars['Float'];
  readonly maxMiddleSide: Scalars['Float'];
  readonly maxShortSide: Scalars['Float'];
  readonly maxWeight: Scalars['Float'];
  readonly minLongSide: Scalars['Float'];
  readonly minMiddleSide: Scalars['Float'];
  readonly minShortSide: Scalars['Float'];
};

/** Enumeration of shipment keys */
export type ShipmentKey =
  | 'ADDRESS1'
  | 'ADDRESS2'
  | 'ADDRESS3'
  | 'CITY'
  | 'COMPANY'
  | 'COUNTRY_ID'
  | 'DIMENSION_X'
  | 'DIMENSION_Y'
  | 'DIMENSION_Z'
  | 'EMAIL'
  | 'FIRST_NAME'
  | 'FOREIGN_ORDER_ID'
  | 'FULL_NAME'
  | 'LAST_NAME'
  | 'MIDDLE_NAME'
  | 'OMIT'
  | 'PASSTHROUGH'
  | 'PASSTHROUGH1'
  | 'PASSTHROUGH2'
  | 'PASSTHROUGH3'
  | 'PASSTHROUGH4'
  | 'PASSTHROUGH5'
  | 'PASSTHROUGH6'
  | 'PASSTHROUGH7'
  | 'PASSTHROUGH8'
  | 'PASSTHROUGH9'
  | 'PASSTHROUGH10'
  | 'PHONE'
  | 'POSTCODE'
  | 'REGION_ID'
  | 'RUBBER_STAMP1'
  | 'RUBBER_STAMP2'
  | 'RUBBER_STAMP3'
  | 'WEIGHT'
  | 'WEIGHT_POUNDS';

export type ShipmentMapping = {
  readonly __typename: 'ShipmentMapping';
  readonly firstValue: Scalars['String'];
  readonly id: Maybe<Scalars['ID']>;
  readonly ordering: Scalars['Int'];
  readonly shipmentKey: ShipmentKey;
  readonly userFriendlyShipmentKey: Scalars['String'];
  /**
   * For Spreadsheets, this will be the name of the column, for integrations the field in the imported order,
   * for values that come directly from a single shipment or from what the user entered in the form, it is the value
   */
  readonly valueOrMappingKey: Scalars['String'];
};

export type ShipmentMappingInput = {
  readonly ordering?: InputMaybe<Scalars['Int']>;
  readonly shipmentKey: ShipmentKey;
  readonly valueOrMappingKey: Scalars['String'];
};

export type ShipmentPassthroughFieldType = {
  readonly __typename: 'ShipmentPassthroughFieldType';
  readonly fieldName: Scalars['String'];
  readonly value: Scalars['String'];
};

export type ShipmentPreset = {
  readonly __typename: 'ShipmentPreset';
  readonly availableReturnLabelOptions: ReadonlyArray<SelectOption>;
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customsContentType: Scalars['String'];
  readonly customsFormEnabled: Scalars['Boolean'];
  readonly customsItems: ReadonlyArray<CustomsItem>;
  readonly customsSigner: Scalars['String'];
  readonly deliveryConfirmation: DeliveryConfirmation;
  readonly deliveryConfirmationFlag: Scalars['Boolean'];
  readonly deliveryConfirmationTitle: Scalars['String'];
  readonly description: Scalars['String'];
  readonly dimensionX: Scalars['Float'];
  readonly dimensionY: Scalars['Float'];
  readonly dimensionZ: Scalars['Float'];
  readonly hazardousMaterialsEnabled: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly insuredValue: Scalars['Float'];
  readonly insuredValueFlag: Scalars['Boolean'];
  readonly internationalTaxId: Scalars['String'];
  readonly irregularPackage: Scalars['Boolean'];
  readonly originalId: Maybe<Scalars['ID']>;
  readonly packageTypeId: Scalars['String'];
  readonly packageTypeKey: Scalars['String'];
  readonly qualifiesAsMediaMail: Scalars['Boolean'];
  readonly returnLabel: ReturnLabel;
  readonly returnLabelFlag: Scalars['Boolean'];
  /** If true, it is a single use preset created in the shipment form or has been deleted by the user */
  readonly shy: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly weight: Scalars['Float'];
};

export type ShipmentPresetInput = {
  readonly customsContentType: Scalars['String'];
  readonly customsFormEnabled: Scalars['Boolean'];
  readonly customsItems?: InputMaybe<ReadonlyArray<CustomsItemInput>>;
  readonly customsSigner: Scalars['String'];
  readonly deliveryConfirmation: DeliveryConfirmation;
  readonly deliveryConfirmationFlag: Scalars['Boolean'];
  readonly dimensionX: Scalars['Float'];
  readonly dimensionY: Scalars['Float'];
  readonly dimensionZ: Scalars['Float'];
  readonly hazardousMaterialsEnabled: Scalars['Boolean'];
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly insuredValue: Scalars['Float'];
  readonly insuredValueFlag: Scalars['Boolean'];
  readonly internationalTaxId: Scalars['String'];
  readonly irregularPackage: Scalars['Boolean'];
  readonly packageTypeKey: Scalars['String'];
  readonly qualifiesAsMediaMail: Scalars['Boolean'];
  readonly returnLabel: ReturnLabel;
  readonly returnLabelFlag: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly weight: Scalars['Float'];
};

export type ShipmentPrice = {
  readonly __typename: 'ShipmentPrice';
  readonly apiKey: Scalars['String'];
  readonly clientPrice: Scalars['Float'];
  readonly clientPriceOriginal: Scalars['Float'];
  readonly helpLink: Scalars['String'];
  readonly isIgnored: Scalars['Boolean'];
  readonly isOriginalSurcharge: Scalars['Boolean'];
  readonly title: Scalars['String'];
};

export type ShipmentStatus =
  | 'CREATED'
  | 'IMPORTED'
  | 'INVALID'
  | 'PURCHASED'
  | 'PURCHASE_FAILED'
  | 'RATED'
  | 'RATE_FAILED'
  | 'REFUNDED'
  | 'REFUND_DECLINED'
  | 'REFUND_FAILED'
  | 'REFUND_REQUESTED'
  | 'REFUND_REVERSED'
  | 'RETURN_EXCEEDED'
  | 'RETURN_PURCHASED'
  | 'RETURN_UNUSED'
  | 'VALIDATED'
  | 'VALIDATION_FAILED';

export type ShipmentStatusSummary = {
  readonly __typename: 'ShipmentStatusSummary';
  readonly deliveredCount: Scalars['Int'];
  readonly errorCount: Scalars['Int'];
  readonly inTransitCount: Scalars['Int'];
  readonly readyToPrintCount: Scalars['Int'];
  readonly readyToShipCount: Scalars['Int'];
  readonly refundPendingCount: Scalars['Int'];
  readonly refundableCount: Scalars['Int'];
  readonly refundedCount: Scalars['Int'];
};

export type ShipmentSummary = {
  readonly __typename: 'ShipmentSummary';
  /** The count of shipments per mail class */
  readonly mailClassCounts: ReadonlyArray<MailClassCount>;
  /** Whether a shipment of over 70 pounds is included */
  readonly overweightShipmentIncluded: Scalars['Boolean'];
  readonly totalCount: Scalars['Int'];
  readonly totalWeightLbs: Scalars['Float'];
  readonly totalWeightOz: Scalars['Float'];
};

export type ShipmentTrackingNumbers = {
  readonly __typename: 'ShipmentTrackingNumbers';
  readonly shipmentsDelivered: Scalars['Int'];
  readonly shipmentsDeliveryIssue: Scalars['Int'];
  readonly shipmentsNew: Scalars['Int'];
  readonly shipmentsTransit: Scalars['Int'];
};

export type ShipmentUpload = {
  readonly __typename: 'ShipmentUpload';
  readonly batch: Maybe<Batch>;
  readonly errorMessage: Scalars['String'];
  readonly fileFormat: Scalars['String'];
  readonly filename: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly mapping: ReadonlyArray<Maybe<ShipmentMapping>>;
  readonly suggestedMapping: ReadonlyArray<ShipmentMapping>;
  readonly uploadStatus: Scalars['String'];
};

export type ShopifyChannel = ChannelInterface & {
  readonly __typename: 'ShopifyChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly fulfillmentLocations: ReadonlyArray<ShopifyLocation>;
  readonly id: Scalars['ID'];
  readonly importLineItemsNotRequireShippingEnabled: Scalars['Boolean'];
  readonly importOrdersNewerThan: Maybe<Scalars['Int']>;
  readonly lastSync: Maybe<ChannelSync>;
  readonly locationsToImport: ReadonlyArray<Scalars['ID']>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type ShopifyLocation = {
  readonly __typename: 'ShopifyLocation';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type SquarespaceChannel = ChannelInterface & {
  readonly __typename: 'SquarespaceChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type StageDot = {
  readonly __typename: 'StageDot';
  readonly message: Scalars['String'];
  readonly status: Scalars['String'];
  readonly title: Scalars['String'];
};

export type StateDistributionChart = {
  readonly __typename: 'StateDistributionChart';
  readonly stateDistributions: ReadonlyArray<DistributionChartItem>;
  readonly totalCount: Scalars['Int'];
};

export type SubblyChannel = ChannelInterface & {
  readonly __typename: 'SubblyChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly clientSecret: Scalars['String'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type Summary = {
  readonly __typename: 'Summary';
  readonly amount: Scalars['Float'];
  readonly count: Scalars['Float'];
};

export type SupportSearchResult = {
  readonly __typename: 'SupportSearchResult';
  readonly id: Scalars['String'];
  readonly label: Scalars['String'];
  readonly type: Scalars['String'];
  readonly value: Scalars['String'];
};

export type SurchargePriceInput = {
  readonly price: Scalars['Float'];
  readonly surchargeKey: Scalars['String'];
};

export type SyncStatusResult = {
  readonly __typename: 'SyncStatusResult';
  readonly autosyncRequired: Scalars['Boolean'];
  readonly hasError: Scalars['Boolean'];
  readonly info: Scalars['String'];
  readonly isRunning: Scalars['Boolean'];
  readonly messages: ReadonlyArray<Scalars['String']>;
  readonly progress: Scalars['Int'];
  readonly timestamp: Scalars['Int'];
};

export type Timezone = {
  readonly __typename: 'Timezone';
  readonly description: Scalars['String'];
  readonly ianaName: Scalars['String'];
};

export type TriggerSyncResult = {
  readonly __typename: 'TriggerSyncResult';
  readonly counter: Maybe<Scalars['Int']>;
  readonly status: Maybe<Scalars['String']>;
};

export type UpdateBigcartelChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateBigcommerceChannelInput = {
  readonly apiUrl: Scalars['String'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateChargifyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly billingDateSyncEnabled: Scalars['Boolean'];
  readonly billingDateSyncOption?: InputMaybe<Scalars['Int']>;
  readonly channelId: Scalars['ID'];
  readonly clientId: Scalars['String'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly preventDuplicateShippingEnabled: Scalars['Boolean'];
  readonly preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateCratejoyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly clientId: Scalars['String'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly skipFutureSubscriptions: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateDiscogsChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateEbayChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
};

export type UpdateEcwidChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly customFulfillmentStatusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateEtsyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateFakeshopChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly orderGroups?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly title: Scalars['String'];
};

export type UpdateMoonclerkChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly preventDuplicateShippingEnabled: Scalars['Boolean'];
  readonly preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
};

export type UpdatePaypalChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly genericLineItemEnabled?: InputMaybe<Scalars['Boolean']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateShipmentPresetResult = {
  readonly __typename: 'UpdateShipmentPresetResult';
  readonly default: Scalars['Boolean'];
  readonly shipmentPreset: ShipmentPreset;
};

export type UpdateShopifyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly importLineItemsNotRequireShippingEnabled: Scalars['Boolean'];
  readonly importOrdersNewerThan?: InputMaybe<Scalars['Int']>;
  readonly locationsToImport: ReadonlyArray<Scalars['ID']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateSquarespaceChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateSubblyChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly clientSecret?: InputMaybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpdateWoocommerceChannelInput = {
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly channelId: Scalars['ID'];
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
};

export type UpsMerchantAccountStatus = 'OK' | 'OUTDATED' | 'UNKNOWN';

export type User = {
  readonly __typename: 'User';
  readonly activePlatforms: ReadonlyArray<Platform>;
  readonly admin: Scalars['Boolean'];
  readonly challenges: ReadonlyArray<Challenge>;
  readonly company: Company;
  /** @deprecated use company.id */
  readonly companyId: Scalars['Int'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly email: Scalars['String'];
  readonly emailVerified: Scalars['Boolean'];
  readonly emulated: Scalars['Boolean'];
  readonly emulatedInDangerMode: Scalars['Boolean'];
  readonly firstName: Scalars['String'];
  readonly groupId: Scalars['Int'];
  readonly id: Scalars['ID'];
  readonly lastName: Scalars['String'];
  readonly rateGroupSortOrder: RateGroupSortOrder;
  readonly roles: ReadonlyArray<Scalars['String']>;
  readonly timezone: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly userHash: Scalars['String'];
};

export type UserTerms = {
  readonly __typename: 'UserTerms';
  readonly id: Scalars['ID'];
  readonly terms: Scalars['String'];
};

export type Warehouse = {
  readonly __typename: 'Warehouse';
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly originAddress: Address;
  readonly originalId: Maybe<Scalars['ID']>;
  readonly returnAddress: Address;
  /** If true, it is a single use warehouse created in the shipment form or has been deleted by the user */
  readonly shy: Scalars['Boolean'];
  readonly title: Scalars['String'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly useOriginAsReturnAddress: Scalars['Boolean'];
};

export type WoocommerceChannel = ChannelInterface & {
  readonly __typename: 'WoocommerceChannel';
  readonly active: Scalars['Boolean'];
  readonly autoSyncEnabled: Scalars['Boolean'];
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly customOptions: ReadonlyArray<CustomOption>;
  readonly id: Scalars['ID'];
  readonly lastSync: Maybe<ChannelSync>;
  readonly mappableFields: ReadonlyArray<PlatformMappableField>;
  readonly platform: Maybe<Platform>;
  readonly reconnectUrl: Maybe<Scalars['String']>;
  readonly rubberstampConfig: ReadonlyArray<ChannelRubberstampConfig>;
  readonly shopUrl: Scalars['String'];
  readonly statusesToImport: ReadonlyArray<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly trackingEmailsEnabled: Scalars['Boolean'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
  readonly valid: Scalars['Boolean'];
};

export type ZoneChart = {
  readonly __typename: 'ZoneChart';
  readonly charts: ReadonlyArray<Scalars['Float']>;
  readonly colors: ReadonlyArray<Scalars['String']>;
  readonly labels: ReadonlyArray<Scalars['String']>;
};

export type DummyDataGridInputTypeUsageQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ReadonlyArray<DataGridFilterInput> | DataGridFilterInput>;
  sorting?: InputMaybe<ReadonlyArray<DataGridSortingInput> | DataGridSortingInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type DummyDataGridInputTypeUsageQuery = {
  readonly shipmentReport: {
    readonly __typename: 'DataGridResult';
    readonly id: string;
    readonly rows: {
      readonly __typename: 'DataGridResultRows';
      readonly totalCount: number;
      readonly nodes: ReadonlyArray<object>;
    };
  };
  readonly receiptsReport: {
    readonly __typename: 'DataGridResult';
    readonly id: string;
    readonly rows: {
      readonly __typename: 'DataGridResultRows';
      readonly totalCount: number;
      readonly nodes: ReadonlyArray<object>;
    };
  };
};

type AddressFields_Address_Fragment = {
  readonly __typename: 'Address';
  readonly fullName: string;
  readonly company: string;
  readonly address1: string;
  readonly address2: string;
  readonly city: string;
  readonly regionCode: string;
  readonly postcode: string;
  readonly zip4: string;
  readonly countryCode: string;
};

type AddressFields_BasicAddress_Fragment = {
  readonly __typename: 'BasicAddress';
  readonly fullName: string;
  readonly company: string;
  readonly address1: string;
  readonly address2: string;
  readonly city: string;
  readonly regionCode: string;
  readonly postcode: string;
  readonly zip4: string;
  readonly countryCode: string;
};

type AddressFields_RecipientAddress_Fragment = {
  readonly __typename: 'RecipientAddress';
  readonly fullName: string;
  readonly company: string;
  readonly address1: string;
  readonly address2: string;
  readonly city: string;
  readonly regionCode: string;
  readonly postcode: string;
  readonly zip4: string;
  readonly countryCode: string;
};

type AddressFields_Shipment_Fragment = {
  readonly __typename: 'Shipment';
  readonly fullName: string;
  readonly company: string;
  readonly address1: string;
  readonly address2: string;
  readonly city: string;
  readonly regionCode: string;
  readonly postcode: string;
  readonly zip4: string;
  readonly countryCode: string;
};

export type AddressFieldsFragment =
  | AddressFields_Address_Fragment
  | AddressFields_BasicAddress_Fragment
  | AddressFields_RecipientAddress_Fragment
  | AddressFields_Shipment_Fragment;

export type BasicPlatformFieldsFragment = {
  readonly __typename: 'Platform';
  readonly id: string;
  readonly platformKey: string;
  readonly title: string;
};

export type BasicUserFragment = {
  readonly __typename: 'User';
  readonly id: string;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly timezone: string;
  readonly roles: ReadonlyArray<string>;
  readonly admin: boolean;
  readonly userHash: string;
  readonly createdAt: string | null;
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly id: string;
    readonly key: ChallengeKey;
    readonly action: ChallengeAction;
    readonly createdAt: string;
  }>;
};

export type CurrentUserFragment = {
  readonly __typename: 'User';
  readonly id: string;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly timezone: string;
  readonly roles: ReadonlyArray<string>;
  readonly admin: boolean;
  readonly userHash: string;
  readonly createdAt: string | null;
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly debuggingEnabled: boolean;
    readonly features: ReadonlyArray<{
      readonly __typename: 'Feature';
      readonly key: string;
      readonly value: boolean;
    }>;
  };
  readonly activePlatforms: ReadonlyArray<{
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  }>;
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly id: string;
    readonly key: ChallengeKey;
    readonly action: ChallengeAction;
    readonly createdAt: string;
  }>;
};

export type DataGridUserSettingsFragment = {
  readonly __typename: 'DataGridResult';
  readonly userSettings: {
    readonly __typename: 'DataGridSettings';
    readonly columnOrder: ReadonlyArray<string>;
    readonly pageSize: number;
    readonly columns: ReadonlyArray<{
      readonly __typename: 'DataGridColumnOverride';
      readonly field: string;
      readonly width: number;
      readonly hidden: boolean;
    }>;
    readonly sorting: ReadonlyArray<{
      readonly __typename: 'DataGridSorting';
      readonly field: string;
      readonly direction: string;
    }>;
  } | null;
};

export type DataGridUserSettingsFieldsFragment = {
  readonly __typename: 'DataGridSettings';
  readonly columnOrder: ReadonlyArray<string>;
  readonly pageSize: number;
  readonly columns: ReadonlyArray<{
    readonly __typename: 'DataGridColumnOverride';
    readonly field: string;
    readonly width: number;
    readonly hidden: boolean;
  }>;
  readonly sorting: ReadonlyArray<{
    readonly __typename: 'DataGridSorting';
    readonly field: string;
    readonly direction: string;
  }>;
};

export type MailTemplateFieldsFragment = {
  readonly __typename: 'MailTemplate';
  readonly id: string;
  readonly name: string;
  readonly senderEmail: string;
  readonly senderName: string;
  readonly asDefault: boolean;
  readonly bccEmail: string;
  readonly subject: string;
  readonly templateHtml: string;
};

export type PackageFieldsFragment = {
  readonly __typename: 'ShipmentPreset';
  readonly title: string;
  readonly packageTypeKey: string;
  readonly dimensionX: number;
  readonly dimensionY: number;
  readonly dimensionZ: number;
  readonly weight: number;
  readonly deliveryConfirmation: DeliveryConfirmation;
  readonly insuredValue: number;
  readonly returnLabelFlag: boolean;
  readonly hazardousMaterialsEnabled: boolean;
  readonly irregularPackage: boolean;
};

export type PaymentApiMethodFieldsFragment = {
  readonly __typename: 'PaymentApiMethod';
  readonly id: string;
  readonly code: PaymentMethodType;
  readonly apiTitle: PaymentApiMethodTitle;
  readonly apiKey: string | null;
};

export type PaymentSourceFieldsFragment = {
  readonly __typename: 'PaymentSource';
  readonly id: string;
  readonly paymentMethodType: PaymentMethodType;
  readonly validationStatus: string;
  readonly brand: string;
  readonly last4: string | null;
  readonly expMonth: number | null;
  readonly expYear: number | null;
  readonly resultMessage: string | null;
  readonly email: string | null;
  readonly title: string;
  readonly refundableAmount: number;
  readonly hasConsented: boolean;
};

export type PickupFieldsFragment = {
  readonly __typename: 'PickupRequest';
  readonly id: string;
  readonly oneTimePickupDate: string | null;
  readonly warehouseReadyTime: string;
  readonly warehouseCloseTime: string;
  readonly totalPackageWeight: number;
  readonly confirmationNumber: string | null;
  readonly totalPackageCount: number;
  readonly status: PickupRequestStatus;
  readonly canCancel: boolean;
  readonly packageLocation: PickupRequestPackageLocation;
  readonly specialInstructions: string | null;
  readonly rate: { readonly __typename: 'PickupRequestRate'; readonly totalPrice: number };
  readonly warehouse: { readonly __typename: 'Warehouse'; readonly title: string };
  readonly carrier: {
    readonly __typename: 'Carrier';
    readonly carrierKey: CarrierKey;
    readonly title: string;
  };
};

export type BigcartelFieldsFragment = {
  readonly __typename: 'BigcartelChannel';
  readonly plan: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly trackingEmailsEnabled: boolean;
  readonly autoSyncEnabled: boolean;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type BigcommerceFieldsFragment = {
  readonly __typename: 'BigcommerceChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly shopUrl: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly clientSecret: string;
  readonly apiUrl: string;
  readonly trackingEmailsEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type ChargifyFieldsFragment = {
  readonly __typename: 'ChargifyChannel';
  readonly clientId: string;
  readonly clientSecret: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly trackingEmailsEnabled: boolean;
  readonly preventDuplicateShippingEnabled: boolean;
  readonly preventDuplicateShippingOption: number;
  readonly billingDateSyncEnabled: boolean;
  readonly billingDateSyncOption: number;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type ChannelFields_BigcartelChannel_Fragment = {
  readonly __typename: 'BigcartelChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_BigcommerceChannel_Fragment = {
  readonly __typename: 'BigcommerceChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_Channel_Fragment = {
  readonly __typename: 'Channel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_ChargifyChannel_Fragment = {
  readonly __typename: 'ChargifyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_CratejoyChannel_Fragment = {
  readonly __typename: 'CratejoyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_DiscogsChannel_Fragment = {
  readonly __typename: 'DiscogsChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_EbayChannel_Fragment = {
  readonly __typename: 'EbayChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_EcwidChannel_Fragment = {
  readonly __typename: 'EcwidChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_EtsyChannel_Fragment = {
  readonly __typename: 'EtsyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_FakeshopChannel_Fragment = {
  readonly __typename: 'FakeshopChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_MoonclerkChannel_Fragment = {
  readonly __typename: 'MoonclerkChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_PaypalChannel_Fragment = {
  readonly __typename: 'PaypalChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_ShopifyChannel_Fragment = {
  readonly __typename: 'ShopifyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_SquarespaceChannel_Fragment = {
  readonly __typename: 'SquarespaceChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_SubblyChannel_Fragment = {
  readonly __typename: 'SubblyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

type ChannelFields_WoocommerceChannel_Fragment = {
  readonly __typename: 'WoocommerceChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

export type ChannelFieldsFragment =
  | ChannelFields_BigcartelChannel_Fragment
  | ChannelFields_BigcommerceChannel_Fragment
  | ChannelFields_Channel_Fragment
  | ChannelFields_ChargifyChannel_Fragment
  | ChannelFields_CratejoyChannel_Fragment
  | ChannelFields_DiscogsChannel_Fragment
  | ChannelFields_EbayChannel_Fragment
  | ChannelFields_EcwidChannel_Fragment
  | ChannelFields_EtsyChannel_Fragment
  | ChannelFields_FakeshopChannel_Fragment
  | ChannelFields_MoonclerkChannel_Fragment
  | ChannelFields_PaypalChannel_Fragment
  | ChannelFields_ShopifyChannel_Fragment
  | ChannelFields_SquarespaceChannel_Fragment
  | ChannelFields_SubblyChannel_Fragment
  | ChannelFields_WoocommerceChannel_Fragment;

type MappingFields_BigcartelChannel_Fragment = {
  readonly __typename: 'BigcartelChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_BigcommerceChannel_Fragment = {
  readonly __typename: 'BigcommerceChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_Channel_Fragment = {
  readonly __typename: 'Channel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_ChargifyChannel_Fragment = {
  readonly __typename: 'ChargifyChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_CratejoyChannel_Fragment = {
  readonly __typename: 'CratejoyChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_DiscogsChannel_Fragment = {
  readonly __typename: 'DiscogsChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_EbayChannel_Fragment = {
  readonly __typename: 'EbayChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_EcwidChannel_Fragment = {
  readonly __typename: 'EcwidChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_EtsyChannel_Fragment = {
  readonly __typename: 'EtsyChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_FakeshopChannel_Fragment = {
  readonly __typename: 'FakeshopChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_MoonclerkChannel_Fragment = {
  readonly __typename: 'MoonclerkChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_PaypalChannel_Fragment = {
  readonly __typename: 'PaypalChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_ShopifyChannel_Fragment = {
  readonly __typename: 'ShopifyChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_SquarespaceChannel_Fragment = {
  readonly __typename: 'SquarespaceChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_SubblyChannel_Fragment = {
  readonly __typename: 'SubblyChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

type MappingFields_WoocommerceChannel_Fragment = {
  readonly __typename: 'WoocommerceChannel';
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type MappingFieldsFragment =
  | MappingFields_BigcartelChannel_Fragment
  | MappingFields_BigcommerceChannel_Fragment
  | MappingFields_Channel_Fragment
  | MappingFields_ChargifyChannel_Fragment
  | MappingFields_CratejoyChannel_Fragment
  | MappingFields_DiscogsChannel_Fragment
  | MappingFields_EbayChannel_Fragment
  | MappingFields_EcwidChannel_Fragment
  | MappingFields_EtsyChannel_Fragment
  | MappingFields_FakeshopChannel_Fragment
  | MappingFields_MoonclerkChannel_Fragment
  | MappingFields_PaypalChannel_Fragment
  | MappingFields_ShopifyChannel_Fragment
  | MappingFields_SquarespaceChannel_Fragment
  | MappingFields_SubblyChannel_Fragment
  | MappingFields_WoocommerceChannel_Fragment;

export type CratejoyFieldsFragment = {
  readonly __typename: 'CratejoyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly shopUrl: string;
  readonly clientId: string;
  readonly clientSecret: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly skipFutureSubscriptions: boolean;
  readonly trackingEmailsEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type DiscogsFieldsFragment = {
  readonly __typename: 'DiscogsChannel';
  readonly clientSecret: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly trackingEmailsEnabled: boolean;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type EbayFieldsFragment = {
  readonly __typename: 'EbayChannel';
  readonly statusesToImport: ReadonlyArray<string>;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type EcwidFieldsFragment = {
  readonly __typename: 'EcwidChannel';
  readonly autoSyncEnabled: boolean;
  readonly shopUrl: string;
  readonly username: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly customFulfillmentStatusesToImport: ReadonlyArray<string>;
  readonly trackingEmailsEnabled: boolean;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly customPaymentStatuses: ReadonlyArray<{
    readonly __typename: 'EcwidOrderStatusImpl';
    readonly id: string;
    readonly name: string;
  }>;
  readonly customFulfillmentStatuses: ReadonlyArray<{
    readonly __typename: 'EcwidOrderStatusImpl';
    readonly id: string;
    readonly name: string;
  }>;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type EtsyFieldsFragment = {
  readonly __typename: 'EtsyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly shopUrl: string;
  readonly trackingEmailsEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type FakeshopFieldsFragment = {
  readonly __typename: 'FakeshopChannel';
  readonly orderGroups: ReadonlyArray<string>;
  readonly autoSyncEnabled: boolean;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
};

export type MoonclerkFieldsFragment = {
  readonly __typename: 'MoonclerkChannel';
  readonly clientSecret: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly preventDuplicateShippingEnabled: boolean;
  readonly preventDuplicateShippingOption: number;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type PaypalFieldsFragment = {
  readonly __typename: 'PaypalChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly shopUrl: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly genericLineItemEnabled: boolean | null;
  readonly trackingEmailsEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type ShopifyFieldsFragment = {
  readonly __typename: 'ShopifyChannel';
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly shopUrl: string;
  readonly importLineItemsNotRequireShippingEnabled: boolean;
  readonly trackingEmailsEnabled: boolean;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly importOrdersNewerThan: number | null;
  readonly locationsToImport: ReadonlyArray<string>;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
  readonly customOptions: ReadonlyArray<{
    readonly __typename: 'CustomOption';
    readonly sourceKey: string;
    readonly name: string;
    readonly override: string;
  }>;
  readonly fulfillmentLocations: ReadonlyArray<{
    readonly __typename: 'ShopifyLocation';
    readonly id: string;
    readonly name: string;
  }>;
};

export type SquarespaceFieldsFragment = {
  readonly __typename: 'SquarespaceChannel';
  readonly clientSecret: string;
  readonly trackingEmailsEnabled: boolean;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type SubblyFieldsFragment = {
  readonly __typename: 'SubblyChannel';
  readonly clientSecret: string;
  readonly trackingEmailsEnabled: boolean;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type WoocommerceFieldsFragment = {
  readonly __typename: 'WoocommerceChannel';
  readonly shopUrl: string;
  readonly statusesToImport: ReadonlyArray<string>;
  readonly trackingEmailsEnabled: boolean;
  readonly id: string;
  readonly title: string;
  readonly active: boolean;
  readonly valid: boolean;
  readonly autoSyncEnabled: boolean;
  readonly platform: {
    readonly __typename: 'Platform';
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  } | null;
  readonly mappableFields: ReadonlyArray<{
    readonly __typename: 'PlatformMappableField';
    readonly field: string;
    readonly label: string;
  }>;
  readonly rubberstampConfig: ReadonlyArray<{
    readonly __typename: 'ChannelRubberstampConfig';
    readonly rubberstamp: string;
    readonly configuredField: string;
  }>;
};

export type RateSummaryFieldsFragment = {
  readonly __typename: 'RateSummary';
  readonly serviceTitle: string;
  readonly mailClassTitle: string;
  readonly averageBasePrice: number;
  readonly averageTotalPrice: number;
  readonly basePrice: number;
  readonly best: boolean;
  readonly cheapest: boolean;
  readonly crossedTotalPrice: number;
  readonly deliveryDays: number;
  readonly fastest: boolean;
  readonly flatPrice: boolean;
  readonly ratePeriodEndDate: string | null;
  readonly ratePeriodStartDate: string | null;
  readonly errorMessage: string | null;
  readonly savings: string;
  readonly shipmentCount: number;
  readonly totalPrice: number;
  readonly uniqueId: string;
  readonly firstZone: string;
  readonly maxWeightOz: number;
  readonly carrier: {
    readonly __typename: 'Carrier';
    readonly carrierKey: CarrierKey;
    readonly title: string;
  };
  readonly firstMailClass: {
    readonly __typename: 'CarrierMailClass';
    readonly mailClassKey: string;
  };
  readonly packageType: {
    readonly __typename: 'CarrierPackageType';
    readonly packageTypeKey: string;
  };
  readonly surcharges: ReadonlyArray<{
    readonly __typename: 'RateSurchargeSummary';
    readonly surchargeKey: string;
    readonly title: string;
    readonly price: number;
    readonly helpLink: string | null;
    readonly crossedPrice: number | null;
  }>;
};

export type RunningProcessFieldsFragment = {
  readonly __typename: 'Batch';
  readonly runningProcess: {
    readonly __typename: 'BatchProcess';
    readonly status: string;
    readonly itemsInProgressCount: number;
    readonly itemsTotalCount: number;
    readonly processKey: string;
    readonly progressPercentage: number;
    readonly progressTitle: string;
    readonly secondsLeft: number;
  } | null;
};

export type SenderEmailMetadataFieldsFragment = {
  readonly __typename: 'SenderEmailMetadata';
  readonly verificationStatus: EmailVerificationStatus | null;
  readonly errorMessage: string | null;
  readonly dkimHost: string;
  readonly dkimTextValue: string;
  readonly dkimVerified: boolean;
  readonly returnPathDomain: string;
  readonly returnPathDomainCnameValue: string;
  readonly returnPathDomainVerified: boolean;
};

export type ShipmentPresetFieldsFragment = {
  readonly __typename: 'ShipmentPreset';
  readonly id: string;
  readonly originalId: string | null;
  readonly title: string;
  readonly packageTypeId: string;
  readonly packageTypeKey: string;
  readonly dimensionX: number;
  readonly dimensionY: number;
  readonly dimensionZ: number;
  readonly weight: number;
  readonly deliveryConfirmation: DeliveryConfirmation;
  readonly deliveryConfirmationFlag: boolean;
  readonly irregularPackage: boolean;
  readonly insuredValue: number;
  readonly insuredValueFlag: boolean;
  readonly returnLabel: ReturnLabel;
  readonly returnLabelFlag: boolean;
  readonly customsFormEnabled: boolean;
  readonly customsSigner: string;
  readonly customsContentType: string;
  readonly internationalTaxId: string;
  readonly qualifiesAsMediaMail: boolean;
  readonly hazardousMaterialsEnabled: boolean;
  readonly customsItems: ReadonlyArray<{
    readonly __typename: 'CustomsItem';
    readonly id: string;
    readonly title: string;
    readonly hsTariffNumber: string;
    readonly quantity: number;
    readonly itemValue: number;
    readonly weight: number;
    readonly countryCodeOfOrigin: string;
  }>;
};

export type ShipmentStatusSummaryFieldsFragment = {
  readonly __typename: 'ShipmentStatusSummary';
  readonly errorCount: number;
  readonly refundedCount: number;
  readonly refundableCount: number;
  readonly refundPendingCount: number;
  readonly inTransitCount: number;
  readonly deliveredCount: number;
  readonly readyToShipCount: number;
  readonly readyToPrintCount: number;
};

export type WarehouseFieldsFragment = {
  readonly __typename: 'Warehouse';
  readonly id: string;
  readonly title: string;
  readonly useOriginAsReturnAddress: boolean;
  readonly originAddress: {
    readonly __typename: 'Address';
    readonly id: string;
    readonly phone: string;
    readonly fullName: string;
    readonly company: string;
    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly regionCode: string;
    readonly postcode: string;
    readonly zip4: string;
    readonly countryCode: string;
  };
  readonly returnAddress: {
    readonly __typename: 'Address';
    readonly id: string;
    readonly fullName: string;
    readonly company: string;
    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly regionCode: string;
    readonly postcode: string;
    readonly zip4: string;
    readonly countryCode: string;
  };
};

export type AcceptTermsMutationVariables = Exact<{
  accepted: Scalars['Boolean'];
}>;

export type AcceptTermsMutation = {
  readonly acceptTerms: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly firstName: string;
    readonly lastName: string;
    readonly timezone: string;
    readonly roles: ReadonlyArray<string>;
    readonly admin: boolean;
    readonly userHash: string;
    readonly createdAt: string | null;
    readonly challenges: ReadonlyArray<{
      readonly __typename: 'Challenge';
      readonly id: string;
      readonly key: ChallengeKey;
      readonly action: ChallengeAction;
      readonly createdAt: string;
    }>;
  };
};

export type UpdateUserAfterAcceptTermsQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateUserAfterAcceptTermsQuery = {
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly firstName: string;
    readonly lastName: string;
    readonly timezone: string;
    readonly roles: ReadonlyArray<string>;
    readonly admin: boolean;
    readonly userHash: string;
    readonly createdAt: string | null;
    readonly challenges: ReadonlyArray<{
      readonly __typename: 'Challenge';
      readonly id: string;
      readonly key: ChallengeKey;
      readonly action: ChallengeAction;
      readonly createdAt: string;
    }>;
  };
};

export type AddCreditToPaymentSourceMutationVariables = Exact<{
  addCreditAmount: Scalars['Float'];
  paymentSourceId: Scalars['ID'];
}>;

export type AddCreditToPaymentSourceMutation = {
  readonly addCreditToPaymentSource: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly accountBalance: number;
  };
};

export type BuyBatchMutationVariables = Exact<{
  id: Scalars['ID'];
  rateSelection: ReadonlyArray<RateSelectionInput> | RateSelectionInput;
  paymentSourceId: Scalars['ID'];
  shipDate: Scalars['DateTime'];
  totalCharge: Scalars['Float'];
  mailTemplateId?: InputMaybe<Scalars['ID']>;
  notifyRecipientsDate?: InputMaybe<Scalars['String']>;
}>;

export type BuyBatchMutation = {
  readonly buyBatch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly stepText: string;
    readonly shipDate: string;
  };
};

export type CancelEmailNotificationMutationVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type CancelEmailNotificationMutation = {
  readonly cancelEmailNotification: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly notifyRecipientsDate: string | null;
    readonly emailNotificationTemplate: {
      readonly __typename: 'MailTemplate';
      readonly id: string;
    } | null;
  };
};

export type CancelPickupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelPickupMutation = {
  readonly cancelPickup: {
    readonly __typename: 'PickupRequest';
    readonly id: string;
    readonly canCancel: boolean;
  };
};

export type UpdatePickupsAfterCancelQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePickupsAfterCancelQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly pickups: ReadonlyArray<{
      readonly __typename: 'PickupRequest';
      readonly id: string;
      readonly oneTimePickupDate: string | null;
      readonly warehouseReadyTime: string;
      readonly warehouseCloseTime: string;
      readonly totalPackageWeight: number;
      readonly confirmationNumber: string | null;
      readonly totalPackageCount: number;
      readonly status: PickupRequestStatus;
      readonly canCancel: boolean;
      readonly packageLocation: PickupRequestPackageLocation;
      readonly specialInstructions: string | null;
      readonly rate: { readonly __typename: 'PickupRequestRate'; readonly totalPrice: number };
      readonly warehouse: { readonly __typename: 'Warehouse'; readonly title: string };
      readonly carrier: {
        readonly __typename: 'Carrier';
        readonly carrierKey: CarrierKey;
        readonly title: string;
      };
    }>;
  };
};

export type ChannelAutoSyncMutationVariables = Exact<{ [key: string]: never }>;

export type ChannelAutoSyncMutation = { readonly channelAutoSync: boolean };

export type CompleteBatchFromUploadMutationVariables = Exact<{
  batchId: Scalars['ID'];
  batchTitle: Scalars['String'];
  warehouseId: Scalars['ID'];
  shipmentPresetId: Scalars['ID'];
}>;

export type CompleteBatchFromUploadMutation = {
  readonly completeBatchFromUpload: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly title: string;
    readonly runningProcess: {
      readonly __typename: 'BatchProcess';
      readonly status: string;
      readonly progressTitle: string;
    } | null;
  };
};

export type CreateAchPaymentSourceMutationVariables = Exact<{
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountHolderType: Scalars['String'];
}>;

export type CreateAchPaymentSourceMutation = {
  readonly createAchPaymentSource: {
    readonly __typename: 'CreatePaymentSourceResponse';
    readonly isDefault: boolean;
    readonly paymentSource: {
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    };
  };
};

export type UpdateAchPaymentSourceAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateAchPaymentSourceAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type CreateBatchFromUploadMutationVariables = Exact<{
  mapping: ReadonlyArray<ShipmentMappingInput> | ShipmentMappingInput;
  uploadId: Scalars['ID'];
}>;

export type CreateBatchFromUploadMutation = {
  readonly createBatchFromUpload: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly upload: {
      readonly __typename: 'ShipmentUpload';
      readonly id: string;
      readonly mapping: ReadonlyArray<{
        readonly __typename: 'ShipmentMapping';
        readonly valueOrMappingKey: string;
        readonly shipmentKey: ShipmentKey;
        readonly userFriendlyShipmentKey: string;
      } | null>;
    } | null;
  };
};

export type CreateCcPaymentSourceMutationVariables = Exact<{
  ccReference: Scalars['String'];
  cp: Scalars['Boolean'];
}>;

export type CreateCcPaymentSourceMutation = {
  readonly createCcPaymentSource: {
    readonly __typename: 'CreatePaymentSourceResponse';
    readonly isDefault: boolean;
    readonly paymentSource: {
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    };
  };
};

export type UpdateCcPaymentSourceAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateCcPaymentSourceAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type CreateChallengeMutationVariables = Exact<{
  key: ChallengeKey;
  action: ChallengeAction;
}>;

export type CreateChallengeMutation = {
  readonly createChallenge: {
    readonly __typename: 'Challenge';
    readonly id: string;
    readonly key: ChallengeKey;
    readonly action: ChallengeAction;
    readonly createdAt: string;
  };
};

export type CreateMailTemplateMutationVariables = Exact<{
  title: Scalars['String'];
  asDefault: Scalars['Boolean'];
  senderEmail: Scalars['String'];
  senderName: Scalars['String'];
  subject: Scalars['String'];
  templateHtml: Scalars['String'];
  bccEmail?: InputMaybe<Scalars['String']>;
}>;

export type CreateMailTemplateMutation = {
  readonly createMailTemplate: {
    readonly __typename: 'MailTemplate';
    readonly id: string;
    readonly name: string;
    readonly senderEmail: string;
    readonly senderName: string;
    readonly asDefault: boolean;
    readonly bccEmail: string;
    readonly subject: string;
    readonly templateHtml: string;
  };
};

export type UpdateMailTemplatesAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateMailTemplatesAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultEmailTemplateId: string;
    };
    readonly mailTemplates: ReadonlyArray<{
      readonly __typename: 'MailTemplate';
      readonly id: string;
      readonly name: string;
      readonly senderEmail: string;
      readonly senderName: string;
      readonly asDefault: boolean;
      readonly bccEmail: string;
      readonly subject: string;
      readonly templateHtml: string;
    }>;
  };
};

export type CreateMappingsMutationVariables = Exact<{
  channelId: Scalars['ID'];
  rubberStamp1?: InputMaybe<Scalars['String']>;
  rubberStamp2?: InputMaybe<Scalars['String']>;
  rubberStamp3?: InputMaybe<Scalars['String']>;
  additionalMapping?: InputMaybe<ReadonlyArray<AdditionalMappingInput> | AdditionalMappingInput>;
}>;

export type CreateMappingsMutation = {
  readonly createMappings:
    | { readonly __typename: 'BigcartelChannel'; readonly id: string }
    | { readonly __typename: 'BigcommerceChannel'; readonly id: string }
    | { readonly __typename: 'ChargifyChannel'; readonly id: string }
    | { readonly __typename: 'CratejoyChannel'; readonly id: string }
    | { readonly __typename: 'DiscogsChannel'; readonly id: string }
    | { readonly __typename: 'EbayChannel'; readonly id: string }
    | { readonly __typename: 'EcwidChannel'; readonly id: string }
    | { readonly __typename: 'EtsyChannel'; readonly id: string }
    | { readonly __typename: 'FakeshopChannel'; readonly id: string }
    | { readonly __typename: 'MoonclerkChannel'; readonly id: string }
    | { readonly __typename: 'PaypalChannel'; readonly id: string }
    | { readonly __typename: 'ShopifyChannel'; readonly id: string }
    | { readonly __typename: 'SquarespaceChannel'; readonly id: string }
    | { readonly __typename: 'SubblyChannel'; readonly id: string }
    | { readonly __typename: 'WoocommerceChannel'; readonly id: string };
};

export type CreateOrUpdateUpsMerchantAccountMutationVariables = Exact<{ [key: string]: never }>;

export type CreateOrUpdateUpsMerchantAccountMutation = {
  readonly createOrUpdateUpsMerchantAccount: {
    readonly __typename: 'CreateUpsMerchantAccountResult';
    readonly status: string;
    readonly error: string;
    readonly errorCategory: string;
  };
};

export type AddUpsToCreatedMerchantAccountsAfterCreateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AddUpsToCreatedMerchantAccountsAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly createdMerchantAccounts: ReadonlyArray<CarrierKey>;
  };
};

export type CreatePayPalPaymentSourceMutationVariables = Exact<{
  nonce: Scalars['String'];
}>;

export type CreatePayPalPaymentSourceMutation = {
  readonly createPayPalPaymentSource: {
    readonly __typename: 'CreatePaymentSourceResponse';
    readonly isDefault: boolean;
    readonly paymentSource: {
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    };
  };
};

export type UpdatePayPalPaymentSourceAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePayPalPaymentSourceAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type CreatePlaidAccessTokenMutationVariables = Exact<{
  publicToken: Scalars['String'];
  handle: Scalars['String'];
}>;

export type CreatePlaidAccessTokenMutation = {
  readonly createPlaidAccessToken: {
    readonly __typename: 'PlaidActivation';
    readonly handle: string;
    readonly mask: string;
    readonly name: string;
    readonly paymentSource: {
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    } | null;
  };
};

export type UpdatePlaidPaymentSourceAfterValidationQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePlaidPaymentSourceAfterValidationQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type CreatePlaidActivationMutationVariables = Exact<{
  origin: Scalars['String'];
}>;

export type CreatePlaidActivationMutation = {
  readonly createPlaidActivation: {
    readonly __typename: 'PlaidActivation';
    readonly handle: string;
    readonly linkToken: string;
  };
};

export type CreatePlaidConsentMutationVariables = Exact<{
  paymentSourceId: Scalars['ID'];
}>;

export type CreatePlaidConsentMutation = {
  readonly createPlaidConsent: {
    readonly __typename: 'CreatePaymentSourceResponse';
    readonly isDefault: boolean;
    readonly paymentSource: {
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    };
  };
};

export type UpdatePlaidPaymentSourceAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePlaidPaymentSourceAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type CreatePlaidValidationActivationMutationVariables = Exact<{
  paymentSourceId: Scalars['ID'];
  origin: Scalars['String'];
}>;

export type CreatePlaidValidationActivationMutation = {
  readonly createPlaidValidationActivation: {
    readonly __typename: 'PlaidActivation';
    readonly handle: string;
    readonly linkToken: string;
  };
};

export type CreateScanformByBatchMutationVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type CreateScanformByBatchMutation = {
  readonly createScanformByBatch: {
    readonly __typename: 'ScanformByBatchResult';
    readonly batchId: string;
    readonly canCreateScanform: boolean;
    readonly scanformUrl: string;
  };
};

export type CreateScanformByShareTokenMutationVariables = Exact<{
  shareToken: Scalars['String'];
}>;

export type CreateScanformByShareTokenMutation = {
  readonly createScanformByShareToken: {
    readonly __typename: 'ScanformByShareTokenResult';
    readonly id: string;
    readonly canCreateScanform: boolean;
    readonly isScanformCreated: boolean;
    readonly scanformUrl: string | null;
  };
};

export type CreateScopedShipmentPresetMutationVariables = Exact<{
  originalPresetId: Scalars['ID'];
  shipmentPresetInput: ShipmentPresetInput;
}>;

export type CreateScopedShipmentPresetMutation = {
  readonly createScopedShipmentPreset: {
    readonly __typename: 'ShipmentPreset';
    readonly id: string;
  };
};

export type CreateSenderEmailMetadataMutationVariables = Exact<{
  emailAddress: Scalars['ID'];
  senderName: Scalars['String'];
}>;

export type CreateSenderEmailMetadataMutation = {
  readonly createSenderEmailMetadata: {
    readonly __typename: 'SenderEmailMetadata';
    readonly verificationStatus: EmailVerificationStatus | null;
    readonly errorMessage: string | null;
    readonly dkimHost: string;
    readonly dkimTextValue: string;
    readonly dkimVerified: boolean;
    readonly returnPathDomain: string;
    readonly returnPathDomainCnameValue: string;
    readonly returnPathDomainVerified: boolean;
  } | null;
};

export type CreateShipmentPresetMutationVariables = Exact<{
  default: Scalars['Boolean'];
  shipmentPreset: ShipmentPresetInput;
}>;

export type CreateShipmentPresetMutation = {
  readonly createShipmentPreset: {
    readonly __typename: 'CreateShipmentPresetResult';
    readonly default: boolean;
    readonly shipmentPreset: {
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    };
  };
};

export type UpdateShipmentPresetsAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateShipmentPresetsAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly shipmentPresets: ReadonlyArray<{
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    }>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultShipmentPresetId: string;
    };
  };
};

export type CreateUspsMerchantAccountMutationVariables = Exact<{ [key: string]: never }>;

export type CreateUspsMerchantAccountMutation = {
  readonly createUspsMerchantAccount: {
    readonly __typename: 'CreateUspsMerchantAccountResult';
    readonly status: string;
    readonly error: string;
    readonly errorCategory: string;
  };
};

export type AddUspsToCreatedMerchantAccountsAfterCreateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AddUspsToCreatedMerchantAccountsAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly createdMerchantAccounts: ReadonlyArray<CarrierKey>;
  };
};

export type CreateVenmoPaymentSourceMutationVariables = Exact<{
  nonce: Scalars['String'];
}>;

export type CreateVenmoPaymentSourceMutation = {
  readonly createVenmoPaymentSource: {
    readonly __typename: 'CreatePaymentSourceResponse';
    readonly isDefault: boolean;
    readonly paymentSource: {
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    };
  };
};

export type UpdateVenmoSourceAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateVenmoSourceAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type CreateWarehouseMutationVariables = Exact<{
  title: Scalars['String'];
  saveAddressForReuse: Scalars['Boolean'];
  useOriginAsReturnAddress: Scalars['Boolean'];
  originAddress: AddressInput;
  returnAddress?: InputMaybe<AddressInput>;
}>;

export type CreateWarehouseMutation = {
  readonly createWarehouse: {
    readonly __typename: 'Warehouse';
    readonly id: string;
    readonly title: string;
    readonly useOriginAsReturnAddress: boolean;
    readonly originAddress: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly phone: string;
      readonly fullName: string;
      readonly company: string;
      readonly address1: string;
      readonly address2: string;
      readonly city: string;
      readonly regionCode: string;
      readonly postcode: string;
      readonly zip4: string;
      readonly countryCode: string;
    };
    readonly returnAddress: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly fullName: string;
      readonly company: string;
      readonly address1: string;
      readonly address2: string;
      readonly city: string;
      readonly regionCode: string;
      readonly postcode: string;
      readonly zip4: string;
      readonly countryCode: string;
    };
  };
};

export type UpdateWarehousesAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateWarehousesAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
};

export type DeleteBatchMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBatchMutation = {
  readonly deleteBatch: { readonly __typename: 'Batch'; readonly id: string };
};

export type DeleteChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;

export type DeleteChannelMutation = {
  readonly deleteChannel:
    | { readonly __typename: 'BigcartelChannel'; readonly id: string }
    | { readonly __typename: 'BigcommerceChannel'; readonly id: string }
    | { readonly __typename: 'ChargifyChannel'; readonly id: string }
    | { readonly __typename: 'CratejoyChannel'; readonly id: string }
    | { readonly __typename: 'DiscogsChannel'; readonly id: string }
    | { readonly __typename: 'EbayChannel'; readonly id: string }
    | { readonly __typename: 'EcwidChannel'; readonly id: string }
    | { readonly __typename: 'EtsyChannel'; readonly id: string }
    | { readonly __typename: 'FakeshopChannel'; readonly id: string }
    | { readonly __typename: 'MoonclerkChannel'; readonly id: string }
    | { readonly __typename: 'PaypalChannel'; readonly id: string }
    | { readonly __typename: 'ShopifyChannel'; readonly id: string }
    | { readonly __typename: 'SquarespaceChannel'; readonly id: string }
    | { readonly __typename: 'SubblyChannel'; readonly id: string }
    | { readonly __typename: 'WoocommerceChannel'; readonly id: string };
};

export type UpdateChannelsAfterDeleteQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateChannelsAfterDeleteQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel'; readonly id: string }
      | { readonly __typename: 'BigcommerceChannel'; readonly id: string }
      | { readonly __typename: 'ChargifyChannel'; readonly id: string }
      | { readonly __typename: 'CratejoyChannel'; readonly id: string }
      | { readonly __typename: 'DiscogsChannel'; readonly id: string }
      | { readonly __typename: 'EbayChannel'; readonly id: string }
      | { readonly __typename: 'EcwidChannel'; readonly id: string }
      | { readonly __typename: 'EtsyChannel'; readonly id: string }
      | { readonly __typename: 'FakeshopChannel'; readonly id: string }
      | { readonly __typename: 'MoonclerkChannel'; readonly id: string }
      | { readonly __typename: 'PaypalChannel'; readonly id: string }
      | { readonly __typename: 'ShopifyChannel'; readonly id: string }
      | { readonly __typename: 'SquarespaceChannel'; readonly id: string }
      | { readonly __typename: 'SubblyChannel'; readonly id: string }
      | { readonly __typename: 'WoocommerceChannel'; readonly id: string }
    >;
  };
};

export type DeleteMailTemplateMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteMailTemplateMutation = {
  readonly deleteMailTemplate: { readonly __typename: 'MailTemplate'; readonly id: string };
};

export type UpdateMailTemplatesAfterDeleteQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateMailTemplatesAfterDeleteQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly mailTemplates: ReadonlyArray<{
      readonly __typename: 'MailTemplate';
      readonly id: string;
      readonly name: string;
      readonly senderEmail: string;
      readonly senderName: string;
      readonly asDefault: boolean;
      readonly bccEmail: string;
      readonly subject: string;
      readonly templateHtml: string;
    }>;
  };
};

export type DeletePaymentSourceMutationVariables = Exact<{
  paymentSourceId: Scalars['ID'];
}>;

export type DeletePaymentSourceMutation = {
  readonly deletePaymentSource: { readonly __typename: 'PaymentSource'; readonly id: string };
};

export type UpdatePaymentSourcesAfterDeleteQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePaymentSourcesAfterDeleteQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
  };
};

export type DeleteShipmentPresetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteShipmentPresetMutation = {
  readonly deleteShipmentPreset: { readonly __typename: 'ShipmentPreset'; readonly id: string };
};

export type UpdateShipmentPresetsAfterDeleteQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateShipmentPresetsAfterDeleteQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly shipmentPresets: ReadonlyArray<{
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    }>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultShipmentPresetId: string;
    };
  };
};

export type DeleteWarehouseMutationVariables = Exact<{
  warehouseId: Scalars['ID'];
}>;

export type DeleteWarehouseMutation = {
  readonly deleteWarehouse: { readonly __typename: 'Warehouse'; readonly id: string };
};

export type UpdateWarehousesAfterDeleteQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateWarehousesAfterDeleteQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
};

export type DisableChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;

export type DisableChannelMutation = {
  readonly disableChannel:
    | {
        readonly __typename: 'BigcartelChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'BigcommerceChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'ChargifyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'CratejoyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'DiscogsChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'EbayChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'EcwidChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'EtsyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'FakeshopChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'MoonclerkChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'PaypalChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'ShopifyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'SquarespaceChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'SubblyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'WoocommerceChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      };
};

export type DisputeAdjustmentsMutationVariables = Exact<{
  shipmentId: Scalars['ID'];
  disputeReason: Scalars['String'];
}>;

export type DisputeAdjustmentsMutation = {
  readonly disputeAdjustments: ReadonlyArray<{
    readonly __typename: 'ReconciliationAdjustment';
    readonly id: string;
    readonly disputedAt: string | null;
    readonly disputeStatus: DisputeStatus;
  }>;
};

export type EnableChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;

export type EnableChannelMutation = {
  readonly enableChannel:
    | {
        readonly __typename: 'BigcartelChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'BigcommerceChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'ChargifyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'CratejoyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'DiscogsChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'EbayChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'EcwidChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'EtsyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'FakeshopChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'MoonclerkChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'PaypalChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'ShopifyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'SquarespaceChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'SubblyChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      }
    | {
        readonly __typename: 'WoocommerceChannel';
        readonly id: string;
        readonly title: string;
        readonly active: boolean;
        readonly valid: boolean;
        readonly autoSyncEnabled: boolean;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly id: string;
          readonly platformKey: string;
          readonly title: string;
        } | null;
      };
};

export type FetchSenderEmailMetadataMutationVariables = Exact<{
  emailAddress: Scalars['ID'];
}>;

export type FetchSenderEmailMetadataMutation = {
  readonly fetchSenderEmailMetadata: {
    readonly __typename: 'SenderEmailMetadata';
    readonly verificationStatus: EmailVerificationStatus | null;
    readonly errorMessage: string | null;
    readonly dkimHost: string;
    readonly dkimTextValue: string;
    readonly dkimVerified: boolean;
    readonly returnPathDomain: string;
    readonly returnPathDomainCnameValue: string;
    readonly returnPathDomainVerified: boolean;
  };
};

export type ForgetLoginMutationVariables = Exact<{
  rememberToken: Scalars['String'];
}>;

export type ForgetLoginMutation = { readonly forgetLogin: string | null };

export type IgnoreOrdersMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['Int']> | Scalars['Int'];
}>;

export type IgnoreOrdersMutation = {
  readonly ignoreOrders: {
    readonly __typename: 'IgnoreOrderResult';
    readonly success: boolean;
    readonly count: number;
    readonly ids: ReadonlyArray<string>;
  };
};

export type LoginWithPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  cp: Scalars['Boolean'];
  captchaResponse?: InputMaybe<Scalars['String']>;
}>;

export type LoginWithPasswordMutation = {
  readonly loginWithPassword: {
    readonly __typename: 'LoginResult';
    readonly authToken: string;
    readonly user: {
      readonly __typename: 'User';
      readonly id: string;
      readonly email: string;
      readonly emailVerified: boolean;
      readonly firstName: string;
      readonly lastName: string;
      readonly timezone: string;
      readonly roles: ReadonlyArray<string>;
      readonly admin: boolean;
      readonly userHash: string;
      readonly createdAt: string | null;
      readonly company: {
        readonly __typename: 'Company';
        readonly id: string;
        readonly debuggingEnabled: boolean;
        readonly features: ReadonlyArray<{
          readonly __typename: 'Feature';
          readonly key: string;
          readonly value: boolean;
        }>;
      };
      readonly activePlatforms: ReadonlyArray<{
        readonly __typename: 'Platform';
        readonly id: string;
        readonly platformKey: string;
        readonly title: string;
      }>;
      readonly challenges: ReadonlyArray<{
        readonly __typename: 'Challenge';
        readonly id: string;
        readonly key: ChallengeKey;
        readonly action: ChallengeAction;
        readonly createdAt: string;
      }>;
    };
  };
};

export type LoginWithRememberTokenMutationVariables = Exact<{
  rememberToken: Scalars['AuthToken'];
}>;

export type LoginWithRememberTokenMutation = {
  readonly loginWithRememberToken: {
    readonly __typename: 'LoginResult';
    readonly authToken: string;
    readonly user: {
      readonly __typename: 'User';
      readonly id: string;
      readonly email: string;
      readonly emailVerified: boolean;
      readonly firstName: string;
      readonly lastName: string;
      readonly timezone: string;
      readonly roles: ReadonlyArray<string>;
      readonly admin: boolean;
      readonly userHash: string;
      readonly createdAt: string | null;
      readonly company: {
        readonly __typename: 'Company';
        readonly id: string;
        readonly debuggingEnabled: boolean;
        readonly features: ReadonlyArray<{
          readonly __typename: 'Feature';
          readonly key: string;
          readonly value: boolean;
        }>;
      };
      readonly activePlatforms: ReadonlyArray<{
        readonly __typename: 'Platform';
        readonly id: string;
        readonly platformKey: string;
        readonly title: string;
      }>;
      readonly challenges: ReadonlyArray<{
        readonly __typename: 'Challenge';
        readonly id: string;
        readonly key: ChallengeKey;
        readonly action: ChallengeAction;
        readonly createdAt: string;
      }>;
    };
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { readonly logout: string | null };

export type ConnectWoocommerceMutationVariables = Exact<{
  shopUrl: Scalars['String'];
}>;

export type ConnectWoocommerceMutation = { readonly connectWoocommerceChannel: string };

export type CreateBigcommerceMutationVariables = Exact<{
  title: Scalars['String'];
  clientSecret: Scalars['String'];
  apiUrl: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateBigcommerceMutation = {
  readonly createBigcommerceChannel: {
    readonly __typename: 'BigcommerceChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly clientSecret: string;
    readonly apiUrl: string;
    readonly trackingEmailsEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateBigcommerceAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateBigcommerceAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | {
          readonly __typename: 'BigcommerceChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly clientSecret: string;
          readonly apiUrl: string;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateChargifyMutationVariables = Exact<{
  title: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  preventDuplicateShippingEnabled: Scalars['Boolean'];
  preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  billingDateSyncEnabled: Scalars['Boolean'];
  billingDateSyncOption?: InputMaybe<Scalars['Int']>;
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateChargifyMutation = {
  readonly createChargifyChannel: {
    readonly __typename: 'ChargifyChannel';
    readonly clientId: string;
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly preventDuplicateShippingEnabled: boolean;
    readonly preventDuplicateShippingOption: number;
    readonly billingDateSyncEnabled: boolean;
    readonly billingDateSyncOption: number;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateChargifyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateChargifyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | {
          readonly __typename: 'ChargifyChannel';
          readonly clientId: string;
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly preventDuplicateShippingEnabled: boolean;
          readonly preventDuplicateShippingOption: number;
          readonly billingDateSyncEnabled: boolean;
          readonly billingDateSyncOption: number;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateCratejoyMutationVariables = Exact<{
  title: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  skipFutureSubscriptions: Scalars['Boolean'];
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateCratejoyMutation = {
  readonly createCratejoyChannel: {
    readonly __typename: 'CratejoyChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly clientId: string;
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly skipFutureSubscriptions: boolean;
    readonly trackingEmailsEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateCratejoyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateCratejoyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | {
          readonly __typename: 'CratejoyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly clientId: string;
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly skipFutureSubscriptions: boolean;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateDiscogsMutationVariables = Exact<{
  title: Scalars['String'];
  clientSecret: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateDiscogsMutation = {
  readonly createDiscogsChannel: {
    readonly __typename: 'DiscogsChannel';
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateDiscogsAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateDiscogsAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | {
          readonly __typename: 'DiscogsChannel';
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateFakeshopMutationVariables = Exact<{
  title: Scalars['String'];
  orderGroups: ReadonlyArray<Scalars['String']> | Scalars['String'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateFakeshopMutation = {
  readonly createFakeshopChannel: {
    readonly __typename: 'FakeshopChannel';
    readonly orderGroups: ReadonlyArray<string>;
    readonly autoSyncEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
  };
};

export type CreateFakeshopAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateFakeshopAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | {
          readonly __typename: 'FakeshopChannel';
          readonly orderGroups: ReadonlyArray<string>;
          readonly autoSyncEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateMoonclerkMutationVariables = Exact<{
  title: Scalars['String'];
  clientSecret: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  preventDuplicateShippingEnabled: Scalars['Boolean'];
  preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateMoonclerkMutation = {
  readonly createMoonclerkChannel: {
    readonly __typename: 'MoonclerkChannel';
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly preventDuplicateShippingEnabled: boolean;
    readonly preventDuplicateShippingOption: number;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateMoonclerkAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateMoonclerkAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | {
          readonly __typename: 'MoonclerkChannel';
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly preventDuplicateShippingEnabled: boolean;
          readonly preventDuplicateShippingOption: number;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateSquarespaceMutationVariables = Exact<{
  title: Scalars['String'];
  clientSecret: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateSquarespaceMutation = {
  readonly createSquarespaceChannel: {
    readonly __typename: 'SquarespaceChannel';
    readonly clientSecret: string;
    readonly trackingEmailsEnabled: boolean;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateSquarespaceAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateSquarespaceAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | {
          readonly __typename: 'SquarespaceChannel';
          readonly clientSecret: string;
          readonly trackingEmailsEnabled: boolean;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CreateSubblyMutationVariables = Exact<{
  title: Scalars['String'];
  clientSecret: Scalars['String'];
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type CreateSubblyMutation = {
  readonly createSubblyChannel: {
    readonly __typename: 'SubblyChannel';
    readonly clientSecret: string;
    readonly trackingEmailsEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type CreateSubblyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type CreateSubblyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | {
          readonly __typename: 'SubblyChannel';
          readonly clientSecret: string;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateBigcartelMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateBigcartelMutation = {
  readonly updateBigcartelChannel: {
    readonly __typename: 'BigcartelChannel';
    readonly plan: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly autoSyncEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateBigcartelAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateBigcartelAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | {
          readonly __typename: 'BigcartelChannel';
          readonly plan: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly autoSyncEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateBigcommerceMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  apiUrl: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateBigcommerceMutation = {
  readonly updateBigcommerceChannel: {
    readonly __typename: 'BigcommerceChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly clientSecret: string;
    readonly apiUrl: string;
    readonly trackingEmailsEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateBigcommerceAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateBigcommerceAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | {
          readonly __typename: 'BigcommerceChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly clientSecret: string;
          readonly apiUrl: string;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateChargifyMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  preventDuplicateShippingEnabled: Scalars['Boolean'];
  preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  billingDateSyncEnabled: Scalars['Boolean'];
  billingDateSyncOption?: InputMaybe<Scalars['Int']>;
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateChargifyMutation = {
  readonly updateChargifyChannel: {
    readonly __typename: 'ChargifyChannel';
    readonly clientId: string;
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly preventDuplicateShippingEnabled: boolean;
    readonly preventDuplicateShippingOption: number;
    readonly billingDateSyncEnabled: boolean;
    readonly billingDateSyncOption: number;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateChargifyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateChargifyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | {
          readonly __typename: 'ChargifyChannel';
          readonly clientId: string;
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly preventDuplicateShippingEnabled: boolean;
          readonly preventDuplicateShippingOption: number;
          readonly billingDateSyncEnabled: boolean;
          readonly billingDateSyncOption: number;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateCratejoyMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  skipFutureSubscriptions: Scalars['Boolean'];
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateCratejoyMutation = {
  readonly updateCratejoyChannel: {
    readonly __typename: 'CratejoyChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly clientId: string;
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly skipFutureSubscriptions: boolean;
    readonly trackingEmailsEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateCratejoyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateCratejoyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | {
          readonly __typename: 'CratejoyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly clientId: string;
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly skipFutureSubscriptions: boolean;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateDiscogsMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateDiscogsMutation = {
  readonly updateDiscogsChannel: {
    readonly __typename: 'DiscogsChannel';
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateDiscogsAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateDiscogsAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | {
          readonly __typename: 'DiscogsChannel';
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateEbayMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateEbayMutation = {
  readonly updateEbayChannel: {
    readonly __typename: 'EbayChannel';
    readonly statusesToImport: ReadonlyArray<string>;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateEbayAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateEbayAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | {
          readonly __typename: 'EbayChannel';
          readonly statusesToImport: ReadonlyArray<string>;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateEcwidMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  customFulfillmentStatusesToImport?: InputMaybe<
    ReadonlyArray<Scalars['String']> | Scalars['String']
  >;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateEcwidMutation = {
  readonly updateEcwidChannel: {
    readonly __typename: 'EcwidChannel';
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly username: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly customFulfillmentStatusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly customPaymentStatuses: ReadonlyArray<{
      readonly __typename: 'EcwidOrderStatusImpl';
      readonly id: string;
      readonly name: string;
    }>;
    readonly customFulfillmentStatuses: ReadonlyArray<{
      readonly __typename: 'EcwidOrderStatusImpl';
      readonly id: string;
      readonly name: string;
    }>;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateEcwidAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateEcwidAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | {
          readonly __typename: 'EcwidChannel';
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly username: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly customFulfillmentStatusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly customPaymentStatuses: ReadonlyArray<{
            readonly __typename: 'EcwidOrderStatusImpl';
            readonly id: string;
            readonly name: string;
          }>;
          readonly customFulfillmentStatuses: ReadonlyArray<{
            readonly __typename: 'EcwidOrderStatusImpl';
            readonly id: string;
            readonly name: string;
          }>;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateEtsyMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateEtsyMutation = {
  readonly updateEtsyChannel: {
    readonly __typename: 'EtsyChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly trackingEmailsEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateEtsyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateEtsyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | {
          readonly __typename: 'EtsyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateFakeshopMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  orderGroups: ReadonlyArray<Scalars['String']> | Scalars['String'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateFakeshopMutation = {
  readonly updateFakeshopChannel: {
    readonly __typename: 'FakeshopChannel';
    readonly orderGroups: ReadonlyArray<string>;
    readonly autoSyncEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
  };
};

export type UpdateFakeshopAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateFakeshopAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | {
          readonly __typename: 'FakeshopChannel';
          readonly orderGroups: ReadonlyArray<string>;
          readonly autoSyncEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateMoonclerkMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  preventDuplicateShippingEnabled: Scalars['Boolean'];
  preventDuplicateShippingOption?: InputMaybe<Scalars['Int']>;
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateMoonclerkMutation = {
  readonly updateMoonclerkChannel: {
    readonly __typename: 'MoonclerkChannel';
    readonly clientSecret: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly preventDuplicateShippingEnabled: boolean;
    readonly preventDuplicateShippingOption: number;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateMoonclerkAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateMoonclerkAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | {
          readonly __typename: 'MoonclerkChannel';
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly preventDuplicateShippingEnabled: boolean;
          readonly preventDuplicateShippingOption: number;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdatePaypalMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  genericLineItemEnabled?: InputMaybe<Scalars['Boolean']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdatePaypalMutation = {
  readonly updatePaypalChannel: {
    readonly __typename: 'PaypalChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly genericLineItemEnabled: boolean | null;
    readonly trackingEmailsEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdatePayPalAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePayPalAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | {
          readonly __typename: 'PaypalChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly genericLineItemEnabled: boolean | null;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateShopifyMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  locationsToImport: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  importLineItemsNotRequireShippingEnabled: Scalars['Boolean'];
  importOrdersNewerThan?: InputMaybe<Scalars['Int']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateShopifyMutation = {
  readonly updateShopifyChannel: {
    readonly __typename: 'ShopifyChannel';
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly shopUrl: string;
    readonly importLineItemsNotRequireShippingEnabled: boolean;
    readonly trackingEmailsEnabled: boolean;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly importOrdersNewerThan: number | null;
    readonly locationsToImport: ReadonlyArray<string>;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
    readonly customOptions: ReadonlyArray<{
      readonly __typename: 'CustomOption';
      readonly sourceKey: string;
      readonly name: string;
      readonly override: string;
    }>;
    readonly fulfillmentLocations: ReadonlyArray<{
      readonly __typename: 'ShopifyLocation';
      readonly id: string;
      readonly name: string;
    }>;
  };
};

export type UpdateShopifyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateShopifyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | {
          readonly __typename: 'ShopifyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly importLineItemsNotRequireShippingEnabled: boolean;
          readonly trackingEmailsEnabled: boolean;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly importOrdersNewerThan: number | null;
          readonly locationsToImport: ReadonlyArray<string>;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
          readonly customOptions: ReadonlyArray<{
            readonly __typename: 'CustomOption';
            readonly sourceKey: string;
            readonly name: string;
            readonly override: string;
          }>;
          readonly fulfillmentLocations: ReadonlyArray<{
            readonly __typename: 'ShopifyLocation';
            readonly id: string;
            readonly name: string;
          }>;
        }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateSquarespaceMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateSquarespaceMutation = {
  readonly updateSquarespaceChannel: {
    readonly __typename: 'SquarespaceChannel';
    readonly clientSecret: string;
    readonly trackingEmailsEnabled: boolean;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateSquarespaceAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateSquarespaceAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | {
          readonly __typename: 'SquarespaceChannel';
          readonly clientSecret: string;
          readonly trackingEmailsEnabled: boolean;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateSubblyMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateSubblyMutation = {
  readonly updateSubblyChannel: {
    readonly __typename: 'SubblyChannel';
    readonly clientSecret: string;
    readonly trackingEmailsEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateSubblyAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateSubblyAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | {
          readonly __typename: 'SubblyChannel';
          readonly clientSecret: string;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type UpdateWoocommerceMutationVariables = Exact<{
  channelId: Scalars['ID'];
  title: Scalars['String'];
  shopUrl: Scalars['String'];
  statusesToImport?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  trackingEmailsEnabled: Scalars['Boolean'];
  autoSyncEnabled: Scalars['Boolean'];
}>;

export type UpdateWoocommerceMutation = {
  readonly updateWoocommerceChannel: {
    readonly __typename: 'WoocommerceChannel';
    readonly shopUrl: string;
    readonly statusesToImport: ReadonlyArray<string>;
    readonly trackingEmailsEnabled: boolean;
    readonly id: string;
    readonly title: string;
    readonly active: boolean;
    readonly valid: boolean;
    readonly autoSyncEnabled: boolean;
    readonly platform: {
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    } | null;
    readonly mappableFields: ReadonlyArray<{
      readonly __typename: 'PlatformMappableField';
      readonly field: string;
      readonly label: string;
    }>;
    readonly rubberstampConfig: ReadonlyArray<{
      readonly __typename: 'ChannelRubberstampConfig';
      readonly rubberstamp: string;
      readonly configuredField: string;
    }>;
  };
};

export type UpdateWoocommerceAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateWoocommerceAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | {
          readonly __typename: 'WoocommerceChannel';
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
    >;
  };
};

export type RatePickupMutationVariables = Exact<{
  pickupRequest: PickupRequestInput;
}>;

export type RatePickupMutation = {
  readonly ratePickup: {
    readonly __typename: 'PickupRequestRate';
    readonly basePrice: number;
    readonly totalPrice: number;
    readonly surcharges: ReadonlyArray<{
      readonly __typename: 'PickupSurcharge';
      readonly price: number;
      readonly name: string;
    }>;
  };
};

export type RefundBatchtMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RefundBatchtMutation = {
  readonly refundBatch: { readonly __typename: 'Batch'; readonly id: string };
};

export type RefundCreditFromPaymentSourceMutationVariables = Exact<{
  refundCreditAmount: Scalars['Float'];
  paymentSourceId: Scalars['ID'];
}>;

export type RefundCreditFromPaymentSourceMutation = {
  readonly refundCreditFromPaymentSource: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly accountBalance: number;
  };
};

export type RefundShipmentMutationVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type RefundShipmentMutation = {
  readonly refundShipment: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly canPrint: boolean;
    readonly canRefund: boolean;
    readonly canInstantRefundBatch: boolean;
    readonly shipmentStatusSummary: {
      readonly __typename: 'ShipmentStatusSummary';
      readonly refundableCount: number;
    };
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly status: ShipmentStatus;
      readonly printed: boolean;
      readonly canPrint: boolean;
      readonly canInstantRefundShipment: boolean;
      readonly stageDots: ReadonlyArray<{
        readonly __typename: 'StageDot';
        readonly title: string;
        readonly status: string;
        readonly message: string;
      }>;
    }>;
  };
};

export type RememberLoginMutationVariables = Exact<{ [key: string]: never }>;

export type RememberLoginMutation = { readonly rememberLogin: string };

export type RequestPickupMutationVariables = Exact<{
  pickupRequest: PickupRequestInput;
  paymentSourceId: Scalars['ID'];
  totalCharge: Scalars['Float'];
}>;

export type RequestPickupMutation = {
  readonly requestPickup: {
    readonly __typename: 'PickupRequest';
    readonly id: string;
    readonly oneTimePickupDate: string | null;
    readonly warehouseReadyTime: string;
    readonly warehouseCloseTime: string;
    readonly totalPackageWeight: number;
    readonly confirmationNumber: string | null;
    readonly totalPackageCount: number;
    readonly status: PickupRequestStatus;
    readonly canCancel: boolean;
    readonly packageLocation: PickupRequestPackageLocation;
    readonly specialInstructions: string | null;
    readonly rate: { readonly __typename: 'PickupRequestRate'; readonly totalPrice: number };
    readonly warehouse: { readonly __typename: 'Warehouse'; readonly title: string };
    readonly carrier: {
      readonly __typename: 'Carrier';
      readonly carrierKey: CarrierKey;
      readonly title: string;
    };
  };
};

export type UpdatePickupsAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdatePickupsAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly pickups: ReadonlyArray<{
      readonly __typename: 'PickupRequest';
      readonly id: string;
      readonly oneTimePickupDate: string | null;
      readonly warehouseReadyTime: string;
      readonly warehouseCloseTime: string;
      readonly totalPackageWeight: number;
      readonly confirmationNumber: string | null;
      readonly totalPackageCount: number;
      readonly status: PickupRequestStatus;
      readonly canCancel: boolean;
      readonly packageLocation: PickupRequestPackageLocation;
      readonly specialInstructions: string | null;
      readonly rate: { readonly __typename: 'PickupRequestRate'; readonly totalPrice: number };
      readonly warehouse: { readonly __typename: 'Warehouse'; readonly title: string };
      readonly carrier: {
        readonly __typename: 'Carrier';
        readonly carrierKey: CarrierKey;
        readonly title: string;
      };
    }>;
  };
};

export type RerateBatchMutationVariables = Exact<{
  id: Scalars['ID'];
  shipDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type RerateBatchMutation = {
  readonly rerateBatch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly stepText: string;
    readonly shipDate: string;
    readonly shipDatePossibleNow: boolean;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  resetToken: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  readonly resetPassword: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly firstName: string;
    readonly lastName: string;
    readonly timezone: string;
    readonly roles: ReadonlyArray<string>;
    readonly admin: boolean;
    readonly userHash: string;
    readonly createdAt: string | null;
    readonly challenges: ReadonlyArray<{
      readonly __typename: 'Challenge';
      readonly id: string;
      readonly key: ChallengeKey;
      readonly action: ChallengeAction;
      readonly createdAt: string;
    }>;
  };
};

export type SendConfirmationMailMutationVariables = Exact<{
  emailAddress: Scalars['ID'];
}>;

export type SendConfirmationMailMutation = {
  readonly sendConfirmationMail: {
    readonly __typename: 'SenderEmailMetadata';
    readonly verificationStatus: EmailVerificationStatus | null;
    readonly dkimHost: string;
    readonly dkimTextValue: string;
    readonly dkimVerified: boolean;
    readonly returnPathDomain: string;
    readonly returnPathDomainCnameValue: string;
    readonly returnPathDomainVerified: boolean;
  };
};

export type SendPreViewEmailMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SendPreViewEmailMutation = { readonly sendEmail: string };

export type SendResetPasswordLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type SendResetPasswordLinkMutation = { readonly sendResetPasswordLink: string };

export type SendUserEmailVerificationLinkMutationVariables = Exact<{ [key: string]: never }>;

export type SendUserEmailVerificationLinkMutation = {
  readonly sendUserEmailVerificationLink: boolean;
};

export type SetBatchToNewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SetBatchToNewMutation = {
  readonly setBatchToNew: { readonly __typename: 'Batch'; readonly id: string };
};

export type SetDefaultPaymentSourceMutationVariables = Exact<{
  paymentSourceId: Scalars['ID'];
}>;

export type SetDefaultPaymentSourceMutation = {
  readonly setDefaultPaymentSource: { readonly __typename: 'PaymentSource'; readonly id: string };
};

export type UpdateSettingsAfterDefaultPaymentSourceQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateSettingsAfterDefaultPaymentSourceQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultPaymentSourceId: string;
    };
  };
};

export type SetDefaultWarehouseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SetDefaultWarehouseMutation = {
  readonly setDefaultWarehouse: { readonly __typename: 'Warehouse'; readonly id: string };
};

export type UpdateSettingsAfterDefaultWarehouseQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateSettingsAfterDefaultWarehouseQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultWarehouseId: string;
    };
  };
};

export type SignupMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  activelyAcceptedTerms: Scalars['Boolean'];
  cp: Scalars['Boolean'];
  originAddress: OriginAddressInput;
  returnAddress?: InputMaybe<AddressInput>;
  captchaResponse?: InputMaybe<Scalars['String']>;
}>;

export type SignupMutation = {
  readonly signup: {
    readonly __typename: 'LoginResult';
    readonly authToken: string;
    readonly user: {
      readonly __typename: 'User';
      readonly id: string;
      readonly email: string;
      readonly emailVerified: boolean;
      readonly firstName: string;
      readonly lastName: string;
      readonly timezone: string;
      readonly roles: ReadonlyArray<string>;
      readonly admin: boolean;
      readonly userHash: string;
      readonly createdAt: string | null;
      readonly company: {
        readonly __typename: 'Company';
        readonly id: string;
        readonly debuggingEnabled: boolean;
        readonly features: ReadonlyArray<{
          readonly __typename: 'Feature';
          readonly key: string;
          readonly value: boolean;
        }>;
      };
      readonly activePlatforms: ReadonlyArray<{
        readonly __typename: 'Platform';
        readonly id: string;
        readonly platformKey: string;
        readonly title: string;
      }>;
      readonly challenges: ReadonlyArray<{
        readonly __typename: 'Challenge';
        readonly id: string;
        readonly key: ChallengeKey;
        readonly action: ChallengeAction;
        readonly createdAt: string;
      }>;
    };
  };
};

export type TriggerChannelSyncMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerChannelSyncMutation = {
  readonly triggerChannelSync: {
    readonly __typename: 'TriggerSyncResult';
    readonly status: string | null;
    readonly counter: number | null;
  };
};

export type UnignoreOrdersMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['Int']> | Scalars['Int'];
}>;

export type UnignoreOrdersMutation = {
  readonly unignoreOrders: {
    readonly __typename: 'IgnoreOrderResult';
    readonly success: boolean;
    readonly count: number;
    readonly ids: ReadonlyArray<string>;
  };
};

export type UpdateAccountDetailsMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  timezone: Scalars['String'];
  currentPassword: Scalars['String'];
}>;

export type UpdateAccountDetailsMutation = {
  readonly updateAccountDetails: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly firstName: string;
    readonly lastName: string;
    readonly timezone: string;
  };
};

export type UpdateUserAfterAccountDetailsUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateUserAfterAccountDetailsUpdateQuery = {
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly firstName: string;
    readonly lastName: string;
    readonly timezone: string;
  };
};

export type UpdateBatchTitleMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
}>;

export type UpdateBatchTitleMutation = {
  readonly updateBatchTitle: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly title: string;
  };
};

export type UpdateBillingAddressMutationVariables = Exact<{
  billingAddress: AddressInput;
}>;

export type UpdateBillingAddressMutation = {
  readonly updateBillingAddress: {
    readonly __typename: 'Address';
    readonly id: string;
    readonly company: string;
    readonly fullName: string;
    readonly address1: string;
    readonly address2: string;
    readonly city: string;
    readonly postcode: string;
    readonly countryCode: string;
    readonly regionCode: string;
    readonly valid: boolean;
    readonly phone: string;
  };
};

export type UpdateBlockedCountriesMutationVariables = Exact<{
  carrierKey: CarrierKey;
  blockedCountries: ReadonlyArray<BlockedCountryInput> | BlockedCountryInput;
}>;

export type UpdateBlockedCountriesMutation = {
  readonly updateBlockedCountries: ReadonlyArray<{
    readonly __typename: 'BlockedCountry';
    readonly twoCharIsoCode: string;
    readonly blockReason: string;
  }>;
};

export type UpdateChallengeActionMutationVariables = Exact<{
  key: ChallengeKey;
  action: ChallengeAction;
  newAction: ChallengeAction;
}>;

export type UpdateChallengeActionMutation = {
  readonly updateChallengeAction: {
    readonly __typename: 'Challenge';
    readonly id: string;
    readonly key: ChallengeKey;
    readonly action: ChallengeAction;
    readonly createdAt: string;
  };
};

export type UpdateChargeAmountMutationVariables = Exact<{
  amount: Scalars['Int'];
}>;

export type UpdateChargeAmountMutation = { readonly updateChargeAmount: number };

export type UpdateChargeAmountAfterCreateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateChargeAmountAfterCreateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultChargeAmount: number;
    };
  };
};

export type UpdateDataGridUserSettingsMutationVariables = Exact<{
  gridId: Scalars['String'];
  settings: DataGridSettingsInput;
}>;

export type UpdateDataGridUserSettingsMutation = {
  readonly updateDataGridUserSettings: {
    readonly __typename: 'DataGridSettings';
    readonly columnOrder: ReadonlyArray<string>;
    readonly pageSize: number;
    readonly columns: ReadonlyArray<{
      readonly __typename: 'DataGridColumnOverride';
      readonly field: string;
      readonly width: number;
      readonly hidden: boolean;
    }>;
    readonly sorting: ReadonlyArray<{
      readonly __typename: 'DataGridSorting';
      readonly field: string;
      readonly direction: string;
    }>;
  };
};

export type UpdateDefaultTrackingEmailsDelayMutationVariables = Exact<{
  inAdvance: Scalars['String'];
}>;

export type UpdateDefaultTrackingEmailsDelayMutation = {
  readonly updateDefaultTrackingEmailsDelay: string;
};

export type UpdateDefaultTrackingEmailsEnabledMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;

export type UpdateDefaultTrackingEmailsEnabledMutation = {
  readonly updateDefaultTrackingEmailsEnabled: boolean;
};

export type UpdateDefaultTrackingEmailsEnabledUpdateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateDefaultTrackingEmailsEnabledUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultTrackingEmailsEnabled: boolean;
    };
  };
};

export type UpdateEmailNotificationMutationVariables = Exact<{
  batchId: Scalars['ID'];
  mailTemplateId: Scalars['ID'];
  scheduledDateTime: Scalars['String'];
}>;

export type UpdateEmailNotificationMutation = {
  readonly updateEmailNotification: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly notifyRecipientsDate: string | null;
    readonly emailNotificationTemplate: {
      readonly __typename: 'MailTemplate';
      readonly id: string;
    } | null;
  };
};

export type UpdateGeneralSettingsMutationVariables = Exact<{
  shipmentLabelSize: Scalars['String'];
  shipmentLabelFileFormat: Scalars['String'];
  packingListTemplate: Scalars['String'];
  shipmentSpreadsheetFormat: Scalars['String'];
  addressCorrectionEnabled: Scalars['Boolean'];
}>;

export type UpdateGeneralSettingsMutation = { readonly updateGeneralSettings: boolean };

export type UpdateCompanySettingsAfterGeneralSettingsUpdateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateCompanySettingsAfterGeneralSettingsUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly shipmentLabelSize: string;
      readonly packingListTemplate: string;
      readonly shipmentSpreadsheetFormat: string;
      readonly shipmentLabelFileFormat: string;
      readonly addressCorrectionEnabled: boolean;
    };
  };
};

export type UpdateMailTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  asDefault: Scalars['Boolean'];
  senderEmail: Scalars['String'];
  senderName: Scalars['String'];
  subject: Scalars['String'];
  templateHtml: Scalars['String'];
  bccEmail?: InputMaybe<Scalars['String']>;
  sendTemplatePreview: Scalars['Boolean'];
}>;

export type UpdateMailTemplateMutation = {
  readonly updateMailTemplate: {
    readonly __typename: 'MailTemplate';
    readonly id: string;
    readonly name: string;
    readonly senderEmail: string;
    readonly senderName: string;
    readonly asDefault: boolean;
    readonly bccEmail: string;
    readonly subject: string;
    readonly templateHtml: string;
  };
};

export type UpdateSettingsAfterUpdateMailTemplateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateSettingsAfterUpdateMailTemplateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultEmailTemplateId: string;
    };
    readonly mailTemplates: ReadonlyArray<{
      readonly __typename: 'MailTemplate';
      readonly id: string;
      readonly name: string;
      readonly senderEmail: string;
      readonly senderName: string;
      readonly asDefault: boolean;
      readonly bccEmail: string;
      readonly subject: string;
      readonly templateHtml: string;
    }>;
  };
};

export type UpdateNotificationsSettingsMutationVariables = Exact<{
  carrierAdjustmentsNotificationEnabled: Scalars['Boolean'];
  paymentReceiptsNotificationEnabled: Scalars['Boolean'];
  paymentFailuresNotificationEnabled: Scalars['Boolean'];
  newLoginNotificationEnabled: Scalars['Boolean'];
  carrierAdjustmentDisputesEnabled: Scalars['Boolean'];
  newFeaturesAnnouncementsNotificationEnabled: Scalars['Boolean'];
}>;

export type UpdateNotificationsSettingsMutation = {
  readonly updateNotificationsSettings: {
    readonly __typename: 'NotificationsSettings';
    readonly carrierAdjustmentsNotificationEnabled: boolean;
    readonly paymentReceiptsNotificationEnabled: boolean;
    readonly paymentFailuresNotificationEnabled: boolean;
    readonly newLoginNotificationEnabled: boolean;
    readonly carrierAdjustmentDisputesEnabled: boolean;
    readonly newFeaturesAnnouncementsNotificationEnabled: boolean;
  };
};

export type UpdateRateGroupSortOrderMutationVariables = Exact<{
  sortOrder: RateGroupSortOrder;
}>;

export type UpdateRateGroupSortOrderMutation = {
  readonly updateRateGroupSortOrder: {
    readonly __typename: 'User';
    readonly id: string;
    readonly rateGroupSortOrder: RateGroupSortOrder;
  };
};

export type UpdateSenderEmailMetadataMutationVariables = Exact<{
  emailAddress: Scalars['ID'];
  senderName: Scalars['String'];
}>;

export type UpdateSenderEmailMetadataMutation = {
  readonly updateSenderEmailMetadata: {
    readonly __typename: 'SenderEmailMetadata';
    readonly verificationStatus: EmailVerificationStatus | null;
    readonly errorMessage: string | null;
    readonly dkimHost: string;
    readonly dkimTextValue: string;
    readonly dkimVerified: boolean;
    readonly returnPathDomain: string;
    readonly returnPathDomainCnameValue: string;
    readonly returnPathDomainVerified: boolean;
  };
};

export type UpdateShipmentPresetMutationVariables = Exact<{
  default: Scalars['Boolean'];
  shipmentPreset: ShipmentPresetInput;
}>;

export type UpdateShipmentPresetMutation = {
  readonly updateShipmentPreset: {
    readonly __typename: 'UpdateShipmentPresetResult';
    readonly default: boolean;
    readonly shipmentPreset: {
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    };
  };
};

export type UpdateShipmentPresetsAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateShipmentPresetsAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly shipmentPresets: ReadonlyArray<{
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    }>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultShipmentPresetId: string;
    };
  };
};

export type UpdateWarehouseMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  useOriginAsReturnAddress: Scalars['Boolean'];
  originAddress: AddressInput;
  returnAddress?: InputMaybe<AddressInput>;
}>;

export type UpdateWarehouseMutation = {
  readonly updateWarehouse: {
    readonly __typename: 'Warehouse';
    readonly originalId: string | null;
    readonly id: string;
    readonly title: string;
    readonly useOriginAsReturnAddress: boolean;
    readonly originAddress: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly phone: string;
      readonly fullName: string;
      readonly company: string;
      readonly address1: string;
      readonly address2: string;
      readonly city: string;
      readonly regionCode: string;
      readonly postcode: string;
      readonly zip4: string;
      readonly countryCode: string;
    };
    readonly returnAddress: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly fullName: string;
      readonly company: string;
      readonly address1: string;
      readonly address2: string;
      readonly city: string;
      readonly regionCode: string;
      readonly postcode: string;
      readonly zip4: string;
      readonly countryCode: string;
    };
  };
};

export type UpdateWarehousesAfterUpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateWarehousesAfterUpdateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
};

export type UploadShipmentFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadShipmentFileMutation = {
  readonly uploadShipmentFile: {
    readonly __typename: 'ShipmentUpload';
    readonly id: string;
    readonly filename: string;
    readonly fileFormat: string;
    readonly uploadStatus: string;
    readonly errorMessage: string;
    readonly suggestedMapping: ReadonlyArray<{
      readonly __typename: 'ShipmentMapping';
      readonly valueOrMappingKey: string;
      readonly shipmentKey: ShipmentKey;
      readonly userFriendlyShipmentKey: string;
    }>;
  };
};

export type VerifyAchPaymentSourceMutationVariables = Exact<{
  paymentSourceId: Scalars['ID'];
  amountInCents1: Scalars['Int'];
  amountInCents2: Scalars['Int'];
}>;

export type VerifyAchPaymentSourceMutation = {
  readonly verifyAchPaymentSource: {
    readonly __typename: 'PaymentSource';
    readonly id: string;
    readonly paymentMethodType: PaymentMethodType;
    readonly validationStatus: string;
    readonly brand: string;
    readonly last4: string | null;
    readonly expMonth: number | null;
    readonly expYear: number | null;
    readonly resultMessage: string | null;
    readonly email: string | null;
    readonly title: string;
    readonly refundableAmount: number;
    readonly hasConsented: boolean;
  };
};

export type AccountSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountSettingsQuery = {
  readonly timezones: ReadonlyArray<{
    readonly __typename: 'Timezone';
    readonly ianaName: string;
    readonly description: string;
  }>;
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly timezone: string;
  };
  readonly notificationsSettings: {
    readonly __typename: 'NotificationsSettings';
    readonly carrierAdjustmentsNotificationEnabled: boolean;
    readonly paymentReceiptsNotificationEnabled: boolean;
    readonly paymentFailuresNotificationEnabled: boolean;
    readonly newLoginNotificationEnabled: boolean;
    readonly carrierAdjustmentDisputesEnabled: boolean;
    readonly newFeaturesAnnouncementsNotificationEnabled: boolean;
  };
};

export type ApplicationInformationQueryVariables = Exact<{ [key: string]: never }>;

export type ApplicationInformationQuery = {
  readonly applicationHostname: string;
  readonly applicationEnvironment: string;
};

export type AutoRefundedShipmentsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type AutoRefundedShipmentsSummaryQuery = {
  readonly autoRefundedShipmentsSummary: {
    readonly __typename: 'Summary';
    readonly count: number;
    readonly amount: number;
  };
};

export type AverageCostChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type AverageCostChartQuery = {
  readonly averageCostChart: {
    readonly __typename: 'Chart';
    readonly charts: ReadonlyArray<number>;
    readonly labels: ReadonlyArray<string>;
  };
};

export type BatchQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BatchQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly title: string;
    readonly status: BatchStatusTag;
    readonly stepText: string;
    readonly shipDatePossibleNow: boolean;
    readonly numShipments: number;
    readonly shipDate: string;
    readonly dataSource: BatchSource;
    readonly labelFileFormat: FileFormat;
    readonly labelSize: LabelSize;
    readonly emailNotificationPossible: boolean;
    readonly warehouse: {
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly shy: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    };
    readonly spreadsheetMapping: ReadonlyArray<{
      readonly __typename: 'ShipmentMapping';
      readonly id: string | null;
      readonly shipmentKey: ShipmentKey;
      readonly valueOrMappingKey: string;
    }>;
    readonly packageSummary: {
      readonly __typename: 'BatchPackageSummary';
      readonly maxWeight: number;
      readonly minWeight: number;
      readonly maxInsured: number;
      readonly minInsured: number;
      readonly dimensionsSummary: string;
      readonly packageTypeSummary: string;
      readonly deliveryConfirmationSummary: string;
      readonly packagePreset: {
        readonly __typename: 'ShipmentPreset';
        readonly title: string;
        readonly packageTypeKey: string;
        readonly dimensionX: number;
        readonly dimensionY: number;
        readonly dimensionZ: number;
        readonly weight: number;
        readonly deliveryConfirmation: DeliveryConfirmation;
        readonly insuredValue: number;
        readonly returnLabelFlag: boolean;
        readonly hazardousMaterialsEnabled: boolean;
        readonly irregularPackage: boolean;
      };
    };
    readonly customsFormSummary: {
      readonly __typename: 'BatchCustomsFormSummary';
      readonly numCustomsForms: number;
      readonly signer: string;
      readonly title: string;
      readonly contentType: string;
      readonly itemSummary: ReadonlyArray<string>;
      readonly exporterTaxIdSummary: string;
    };
    readonly shipmentStatusSummary: {
      readonly __typename: 'ShipmentStatusSummary';
      readonly errorCount: number;
      readonly refundedCount: number;
      readonly refundableCount: number;
      readonly refundPendingCount: number;
      readonly inTransitCount: number;
      readonly deliveredCount: number;
      readonly readyToShipCount: number;
      readonly readyToPrintCount: number;
    };
    readonly rubberStamps: ReadonlyArray<{
      readonly __typename: 'ShipmentMapping';
      readonly shipmentKey: ShipmentKey;
      readonly valueOrMappingKey: string;
      readonly userFriendlyShipmentKey: string;
    }>;
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly isReturnLabel: boolean;
      readonly order: {
        readonly __typename: 'Order';
        readonly id: string;
        readonly foreignOrderId: string;
        readonly platformKey: string;
        readonly orderLink: {
          readonly __typename: 'Link';
          readonly text: string;
          readonly url: string;
        } | null;
        readonly shipmentLink: {
          readonly __typename: 'Link';
          readonly url: string;
          readonly text: string;
        } | null;
        readonly subscriptionLink: {
          readonly __typename: 'Link';
          readonly url: string;
          readonly text: string;
        } | null;
        readonly platform: { readonly __typename: 'Platform'; readonly title: string };
        readonly orderItems: ReadonlyArray<{
          readonly __typename: 'OrderItem';
          readonly quantity: number;
          readonly title: string;
          readonly sku: string;
        }>;
      } | null;
      readonly recipientAddress: {
        readonly __typename: 'RecipientAddress';
        readonly address3: string;
        readonly email: string | null;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
    readonly rateGroups: ReadonlyArray<{
      readonly __typename: 'RateGroup';
      readonly title: string;
      readonly maximumShipments: number;
      readonly numberOfCheapestOrBestServices: number;
      readonly defaultShipDate: string;
      readonly intercomLink: string | null;
      readonly affectedByUpsRateLimit: boolean;
      readonly groupKey: {
        readonly __typename: 'RateGroupKey';
        readonly string: string;
        readonly traits: ReadonlyArray<{
          readonly __typename: 'RateGroupTrait';
          readonly layer: RateGroupLayerKey;
          readonly value: RateGroupTraitKey;
        }>;
      };
      readonly rateSummaries: ReadonlyArray<{
        readonly __typename: 'RateSummary';
        readonly availableShipDates: ReadonlyArray<string>;
        readonly serviceTitle: string;
        readonly mailClassTitle: string;
        readonly averageBasePrice: number;
        readonly averageTotalPrice: number;
        readonly basePrice: number;
        readonly best: boolean;
        readonly cheapest: boolean;
        readonly crossedTotalPrice: number;
        readonly deliveryDays: number;
        readonly fastest: boolean;
        readonly flatPrice: boolean;
        readonly ratePeriodEndDate: string | null;
        readonly ratePeriodStartDate: string | null;
        readonly errorMessage: string | null;
        readonly savings: string;
        readonly shipmentCount: number;
        readonly totalPrice: number;
        readonly uniqueId: string;
        readonly firstZone: string;
        readonly maxWeightOz: number;
        readonly upsellRateSummary: {
          readonly __typename: 'RateSummary';
          readonly mailClassTitle: string;
          readonly totalPrice: number;
          readonly uniqueId: string;
          readonly carrier: {
            readonly __typename: 'Carrier';
            readonly carrierKey: CarrierKey;
            readonly title: string;
          };
          readonly firstMailClass: {
            readonly __typename: 'CarrierMailClass';
            readonly mailClassKey: string;
          };
        } | null;
        readonly carrier: {
          readonly __typename: 'Carrier';
          readonly carrierKey: CarrierKey;
          readonly title: string;
        };
        readonly firstMailClass: {
          readonly __typename: 'CarrierMailClass';
          readonly mailClassKey: string;
        };
        readonly packageType: {
          readonly __typename: 'CarrierPackageType';
          readonly packageTypeKey: string;
        };
        readonly surcharges: ReadonlyArray<{
          readonly __typename: 'RateSurchargeSummary';
          readonly surchargeKey: string;
          readonly title: string;
          readonly price: number;
          readonly helpLink: string | null;
          readonly crossedPrice: number | null;
        }>;
      }>;
    }>;
    readonly runningProcess: {
      readonly __typename: 'BatchProcess';
      readonly status: string;
      readonly itemsInProgressCount: number;
      readonly itemsTotalCount: number;
      readonly processKey: string;
      readonly progressPercentage: number;
      readonly progressTitle: string;
      readonly secondsLeft: number;
    } | null;
  };
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly rateGroupSortOrder: RateGroupSortOrder;
    readonly email: string;
    readonly timezone: string;
  };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
    readonly accountBalance: number;
    readonly createdMerchantAccounts: ReadonlyArray<CarrierKey>;
    readonly hasUspsMerchantAccountError: boolean;
    readonly uspsMerchantAccountStatus: string;
    readonly upsMerchantAccountStatus: UpsMerchantAccountStatus;
    readonly activePaymentMethods: ReadonlyArray<PaymentMethodType>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly shipmentLabelSize: string;
      readonly shipmentLabelFileFormat: string;
      readonly packingListTemplate: string;
      readonly shipmentSpreadsheetFormat: string;
      readonly addressCorrectionEnabled: boolean;
      readonly defaultTrackingEmailsDelay: string;
      readonly defaultTrackingEmailsEnabled: boolean;
      readonly defaultEmailTemplateId: string;
      readonly defaultChargeAmount: number;
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
    readonly mailTemplates: ReadonlyArray<{
      readonly __typename: 'MailTemplate';
      readonly id: string;
      readonly subject: string;
      readonly name: string;
      readonly senderEmail: string;
      readonly senderName: string;
      readonly asDefault: boolean;
    }>;
  };
  readonly paymentApiMethods: ReadonlyArray<{
    readonly __typename: 'PaymentApiMethod';
    readonly id: string;
    readonly code: PaymentMethodType;
    readonly apiTitle: PaymentApiMethodTitle;
    readonly apiKey: string | null;
  }>;
};

export type LatestBatchesQueryVariables = Exact<{ [key: string]: never }>;

export type LatestBatchesQuery = {
  readonly latestBatches: ReadonlyArray<{
    readonly __typename: 'Batch';
    readonly id: string;
    readonly createdAt: string;
    readonly title: string;
    readonly shipDate: string;
    readonly numShipments: number;
    readonly status: BatchStatusTag;
    readonly statusText: string;
    readonly step: BatchStatus;
    readonly printed: boolean;
    readonly canPrint: boolean;
    readonly canCreateScanform: boolean;
    readonly scanformUrl: string | null;
    readonly carrierLogoClasses: ReadonlyArray<string>;
    readonly runningProcess: {
      readonly __typename: 'BatchProcess';
      readonly progressPercentage: number;
    } | null;
    readonly shipmentStatusSummary: {
      readonly __typename: 'ShipmentStatusSummary';
      readonly errorCount: number;
      readonly refundedCount: number;
      readonly refundableCount: number;
      readonly refundPendingCount: number;
      readonly inTransitCount: number;
      readonly deliveredCount: number;
      readonly readyToShipCount: number;
      readonly readyToPrintCount: number;
    };
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly trackingNr: string | null;
    }>;
  }>;
};

export type BatchDebugQueryVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type BatchDebugQuery = { readonly batchDebug: object };

export type BatchIdFromShipmentQueryVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type BatchIdFromShipmentQuery = {
  readonly shipment: {
    readonly __typename: 'Shipment';
    readonly id: string;
    readonly batch: { readonly __typename: 'Batch'; readonly id: string };
  };
};

export type BatchProcessStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BatchProcessStatusQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly status: BatchStatusTag;
    readonly canInstantRefundBatch: boolean;
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly carrierKey: string;
    }>;
    readonly runningProcess: {
      readonly __typename: 'BatchProcess';
      readonly status: string;
      readonly itemsInProgressCount: number;
      readonly itemsTotalCount: number;
      readonly processKey: string;
      readonly progressPercentage: number;
      readonly progressTitle: string;
      readonly secondsLeft: number;
    } | null;
  };
};

export type BatchShipmentIdsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BatchShipmentIdsQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly shipments: ReadonlyArray<{ readonly __typename: 'Shipment'; readonly id: string }>;
  };
};

export type BatchStepQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BatchStepQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly step: BatchStatus;
    readonly shipments: ReadonlyArray<{ readonly __typename: 'Shipment'; readonly id: string }>;
  };
};

export type BatchUploadFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BatchUploadFormQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly title: string;
    readonly step: BatchStatus;
    readonly customsFormRequired: boolean;
    readonly hsCodeRequired: boolean;
    readonly upload: { readonly __typename: 'ShipmentUpload'; readonly id: string } | null;
  };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultWarehouseId: string;
    };
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
    readonly shipmentPresets: ReadonlyArray<{
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    }>;
  };
  readonly countries: ReadonlyArray<{
    readonly __typename: 'Country';
    readonly id: string;
    readonly countryCode: string;
    readonly name: string;
  }>;
};

export type BillingAddressQueryVariables = Exact<{ [key: string]: never }>;

export type BillingAddressQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly address: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly company: string;
      readonly fullName: string;
      readonly address1: string;
      readonly address2: string;
      readonly city: string;
      readonly postcode: string;
      readonly countryCode: string;
      readonly regionCode: string;
      readonly valid: boolean;
      readonly phone: string;
    };
  };
};

export type BillingPageQueryVariables = Exact<{ [key: string]: never }>;

export type BillingPageQuery = {
  readonly chargeAmountOptions: ReadonlyArray<number>;
  readonly user: { readonly __typename: 'User'; readonly id: string; readonly email: string };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly accountBalance: number;
    readonly activePaymentMethods: ReadonlyArray<PaymentMethodType>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultDestinationCountryCode: string;
      readonly defaultPaymentSourceId: string;
      readonly defaultChargeAmount: number;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
    readonly features: ReadonlyArray<{
      readonly __typename: 'Feature';
      readonly key: string;
      readonly value: boolean;
    }>;
  };
  readonly paymentApiMethods: ReadonlyArray<{
    readonly __typename: 'PaymentApiMethod';
    readonly id: string;
    readonly code: PaymentMethodType;
    readonly apiTitle: PaymentApiMethodTitle;
    readonly apiKey: string | null;
  }>;
};

export type BoughtBatchQueryVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type BoughtBatchQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly title: string;
    readonly stepText: string;
    readonly status: BatchStatusTag;
    readonly statusText: string;
    readonly labelFileFormat: FileFormat;
    readonly labelSize: LabelSize;
    readonly canPrint: boolean;
    readonly canRefund: boolean;
    readonly canInstantRefundBatch: boolean;
    readonly canCreateScanform: boolean;
    readonly scanformCreated: boolean;
    readonly scanformUrl: string | null;
    readonly numShipments: number;
    readonly shipDate: string;
    readonly dataSource: BatchSource;
    readonly createdAt: string;
    readonly cleanupComplete: boolean;
    readonly cleanupOriginal: boolean;
    readonly notifyRecipientsDate: string | null;
    readonly emailNotificationPossible: boolean;
    readonly emailNotificationSent: boolean;
    readonly warehouse: {
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly shy: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    };
    readonly spreadsheetMapping: ReadonlyArray<{
      readonly __typename: 'ShipmentMapping';
      readonly id: string | null;
      readonly shipmentKey: ShipmentKey;
      readonly valueOrMappingKey: string;
    }>;
    readonly packageSummary: {
      readonly __typename: 'BatchPackageSummary';
      readonly maxWeight: number;
      readonly minWeight: number;
      readonly maxInsured: number;
      readonly minInsured: number;
      readonly dimensionsSummary: string;
      readonly packageTypeSummary: string;
      readonly deliveryConfirmationSummary: string;
      readonly packagePreset: {
        readonly __typename: 'ShipmentPreset';
        readonly title: string;
        readonly packageTypeKey: string;
        readonly dimensionX: number;
        readonly dimensionY: number;
        readonly dimensionZ: number;
        readonly weight: number;
        readonly deliveryConfirmation: DeliveryConfirmation;
        readonly insuredValue: number;
        readonly returnLabelFlag: boolean;
        readonly hazardousMaterialsEnabled: boolean;
        readonly irregularPackage: boolean;
      };
    };
    readonly customsFormSummary: {
      readonly __typename: 'BatchCustomsFormSummary';
      readonly numCustomsForms: number;
      readonly signer: string;
      readonly title: string;
      readonly contentType: string;
      readonly itemSummary: ReadonlyArray<string>;
      readonly exporterTaxIdSummary: string;
    };
    readonly shipmentStatusSummary: {
      readonly __typename: 'ShipmentStatusSummary';
      readonly errorCount: number;
      readonly refundedCount: number;
      readonly refundableCount: number;
      readonly refundPendingCount: number;
      readonly inTransitCount: number;
      readonly deliveredCount: number;
      readonly readyToShipCount: number;
      readonly readyToPrintCount: number;
    };
    readonly rubberStamps: ReadonlyArray<{
      readonly __typename: 'ShipmentMapping';
      readonly shipmentKey: ShipmentKey;
      readonly valueOrMappingKey: string;
      readonly userFriendlyShipmentKey: string;
    }>;
    readonly emailNotificationTemplate: {
      readonly __typename: 'MailTemplate';
      readonly id: string;
    } | null;
    readonly priceSummary: ReadonlyArray<{
      readonly __typename: 'BatchPriceSummary';
      readonly title: string;
      readonly shipmentCount: number;
      readonly averagePrice: number;
      readonly totalPrice: number;
      readonly carrierKeys: ReadonlyArray<string>;
      readonly surcharge: boolean;
      readonly pirateShipService: boolean;
      readonly samePriceForAll: boolean;
      readonly usedReturnPrice: number;
      readonly isReturnLabel: boolean;
      readonly returnsUsed: number;
      readonly isRefunded: boolean;
    }>;
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly companyId: string;
      readonly trackingNr: string | null;
      readonly trackingLink: string;
      readonly foreignOrderId: string;
      readonly duplicateShipmentLink: string;
      readonly carrierTitle: string;
      readonly carrierKey: string;
      readonly errorMessage: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number | null;
      readonly dimensionXOriginal: number;
      readonly dimensionYOriginal: number;
      readonly dimensionZOriginal: number | null;
      readonly weight: number;
      readonly weightOriginal: number;
      readonly disputableAdjustmentsCount: number;
      readonly status: ShipmentStatus;
      readonly printed: boolean;
      readonly canPrint: boolean;
      readonly stageDotsStatus: ReadonlyArray<string>;
      readonly baseClientPrice: number | null;
      readonly baseClientPriceOriginal: number | null;
      readonly totalClientPrice: number | null;
      readonly totalClientPriceOriginal: number | null;
      readonly canDisplayPrices: boolean;
      readonly mailClassKey: string | null;
      readonly zone: string | null;
      readonly zoneOriginal: string | null;
      readonly countryId: string;
      readonly countryIdOriginal: string;
      readonly additionalRefundNotice: string;
      readonly insuredValueRequested: number;
      readonly insuranceClaimUrl: string | null;
      readonly notificationEmailSent: boolean;
      readonly isShipmentRefundable: boolean;
      readonly canInstantRefundShipment: boolean;
      readonly isRedacted: boolean;
      readonly order: {
        readonly __typename: 'Order';
        readonly id: string;
        readonly foreignOrderId: string;
        readonly platformKey: string;
        readonly orderLink: {
          readonly __typename: 'Link';
          readonly text: string;
          readonly url: string;
        } | null;
        readonly shipmentLink: {
          readonly __typename: 'Link';
          readonly url: string;
          readonly text: string;
        } | null;
        readonly subscriptionLink: {
          readonly __typename: 'Link';
          readonly url: string;
          readonly text: string;
        } | null;
        readonly platform: {
          readonly __typename: 'Platform';
          readonly title: string;
          readonly additionalTrackingEmailCopy: string;
        };
        readonly orderItems: ReadonlyArray<{
          readonly __typename: 'OrderItem';
          readonly quantity: number;
          readonly title: string;
          readonly sku: string;
        }>;
      } | null;
      readonly passthroughFields: ReadonlyArray<{
        readonly __typename: 'ShipmentPassthroughFieldType';
        readonly fieldName: string;
        readonly value: string;
      }>;
      readonly adjustments: ReadonlyArray<{
        readonly __typename: 'ReconciliationAdjustment';
        readonly id: string;
        readonly createdAt: string;
        readonly dimensionXOriginal: number | null;
        readonly dimensionX: number;
        readonly dimensionYOriginal: number | null;
        readonly dimensionY: number;
        readonly dimensionZOriginal: number | null;
        readonly dimensionZ: number;
        readonly disputedAt: string | null;
        readonly disputeStatus: DisputeStatus;
        readonly disputeReason: string;
        readonly eligibilityIssueMessage: string;
        readonly processedAt: string;
        readonly notes: ReadonlyArray<string>;
        readonly weightOriginal: number | null;
        readonly weight: number;
      }>;
      readonly stageDots: ReadonlyArray<{
        readonly __typename: 'StageDot';
        readonly title: string;
        readonly status: string;
        readonly message: string;
      }>;
      readonly recipientAddress: {
        readonly __typename: 'RecipientAddress';
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
        readonly address3: string;
        readonly email: string | null;
        readonly phone: string;
      };
      readonly deliveryEstimation: {
        readonly __typename: 'DeliveryEstimation';
        readonly arrivalLocalDateTime: string | null;
        readonly pickupLocalDateTime: string | null;
      } | null;
      readonly packageTypeOriginal: {
        readonly __typename: 'CarrierPackageType';
        readonly title: string;
      };
      readonly packageType: { readonly __typename: 'CarrierPackageType'; readonly title: string };
      readonly mailClassOriginal: {
        readonly __typename: 'CarrierMailClass';
        readonly title: string;
      } | null;
      readonly mailClass: {
        readonly __typename: 'CarrierMailClass';
        readonly title: string;
      } | null;
      readonly surcharges: ReadonlyArray<{
        readonly __typename: 'ShipmentPrice';
        readonly isIgnored: boolean;
        readonly title: string;
        readonly apiKey: string;
        readonly clientPrice: number;
        readonly clientPriceOriginal: number;
        readonly isOriginalSurcharge: boolean;
        readonly helpLink: string;
      }> | null;
    }>;
    readonly trackingDataGrid: {
      readonly __typename: 'DataGridResult';
      readonly id: string;
      readonly rowActions: ReadonlyArray<{
        readonly __typename: 'DataGridRowAction';
        readonly id: string | null;
      }> | null;
    };
  };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly accountBalance: number;
  };
};

export type BraintreeClientTokenQueryVariables = Exact<{ [key: string]: never }>;

export type BraintreeClientTokenQuery = { readonly braintreeClientToken: string };

export type CarrierAdjustmentsQueryVariables = Exact<{ [key: string]: never }>;

export type CarrierAdjustmentsQuery = { readonly carrierAdjustments: object };

export type ChannelsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type ChannelsQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly channels: ReadonlyArray<
      | {
          readonly __typename: 'BigcartelChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'BigcommerceChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'ChargifyChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'CratejoyChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'DiscogsChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'EbayChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'EcwidChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'EtsyChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'FakeshopChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'MoonclerkChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'PaypalChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'ShopifyChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'SquarespaceChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'SubblyChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | {
          readonly __typename: 'WoocommerceChannel';
          readonly reconnectUrl: string | null;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
    >;
  };
};

export type ChannelSyncStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ChannelSyncStatusQuery = {
  readonly channelSyncStatus: {
    readonly __typename: 'SyncStatusResult';
    readonly timestamp: number;
    readonly messages: ReadonlyArray<string>;
    readonly info: string;
    readonly autosyncRequired: boolean;
    readonly hasError: boolean;
    readonly progress: number;
    readonly isRunning: boolean;
  };
};

export type ChargeAmountOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ChargeAmountOptionsQuery = {
  readonly chargeAmountOptions: ReadonlyArray<number>;
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultChargeAmount: number;
    };
  };
};

export type CompanySavingsQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type CompanySavingsQuery = { readonly companySavings: number };

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  readonly countries: ReadonlyArray<{
    readonly __typename: 'Country';
    readonly id: string;
    readonly countryCode: string;
    readonly name: string;
    readonly parentCountryId: string;
  }>;
};

export type BlockedCountriesQueryVariables = Exact<{
  carrierKey: CarrierKey;
}>;

export type BlockedCountriesQuery = {
  readonly countries: ReadonlyArray<{
    readonly __typename: 'Country';
    readonly countryCode: string;
    readonly name: string;
  }>;
  readonly blockedCountries: ReadonlyArray<{
    readonly __typename: 'BlockedCountry';
    readonly twoCharIsoCode: string;
    readonly blockReason: string;
  }>;
};

export type CountryDistributionChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type CountryDistributionChartQuery = {
  readonly countryDistributionChart: {
    readonly __typename: 'CountryDistributionChart';
    readonly totalCount: number;
    readonly countryDistributions: ReadonlyArray<{
      readonly __typename: 'DistributionChartItem';
      readonly key: string;
      readonly count: string;
      readonly name: string;
      readonly percent: number;
      readonly price: number;
    }>;
  };
};

export type DataGridExportStatusQueryVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type DataGridExportStatusQuery = {
  readonly shipment: {
    readonly __typename: 'Shipment';
    readonly trackingDataExportUrl: {
      readonly __typename: 'DataGridExportStatus';
      readonly exportId: number;
      readonly url: string | null;
      readonly downloadable: boolean;
      readonly filename: string;
      readonly expire: number;
    };
  };
};

export type DownloadLabelByShipmentQueryVariables = Exact<{
  shipmentId: Scalars['ID'];
  pageLayout?: InputMaybe<PageLayout>;
}>;

export type DownloadLabelByShipmentQuery = {
  readonly downloadLabelByShipment: {
    readonly __typename: 'DownloadLabelsByShipmentsResult';
    readonly id: string;
    readonly status: DownloadStatus;
    readonly fileFormat: FileFormat | null;
    readonly labelSize: string | null;
    readonly pageLayout: PageLayout | null;
    readonly url: string | null;
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly canInstantRefundShipment: boolean;
      readonly printed: boolean;
      readonly canPrint: boolean;
      readonly isShipmentRefundable: boolean;
    } | null>;
  };
};

export type DownloadLabelsByBatchQueryVariables = Exact<{
  batchId: Scalars['ID'];
  pageLayout?: InputMaybe<PageLayout>;
}>;

export type DownloadLabelsByBatchQuery = {
  readonly downloadLabelsByBatch: {
    readonly __typename: 'DownloadLabelsByBatchResult';
    readonly id: string;
    readonly status: DownloadStatus;
    readonly fileFormat: FileFormat | null;
    readonly labelSize: string | null;
    readonly pageLayout: PageLayout | null;
    readonly url: string | null;
    readonly batch: {
      readonly __typename: 'Batch';
      readonly id: string;
      readonly canInstantRefundBatch: boolean;
      readonly canPrint: boolean;
      readonly canRefund: boolean;
      readonly statusText: string;
      readonly shipments: ReadonlyArray<{
        readonly __typename: 'Shipment';
        readonly id: string;
        readonly printed: boolean;
        readonly canPrint: boolean;
        readonly isShipmentRefundable: boolean;
        readonly canInstantRefundShipment: boolean;
      }>;
    };
  };
};

export type DownloadLabelsByShareTokenQueryVariables = Exact<{
  shareToken: Scalars['String'];
  pageLayout?: InputMaybe<PageLayout>;
}>;

export type DownloadLabelsByShareTokenQuery = {
  readonly downloadLabelsByShareToken: {
    readonly __typename: 'DownloadLabelsByShareTokenResult';
    readonly status: DownloadStatus;
    readonly fileFormat: FileFormat | null;
    readonly pageLayout: PageLayout | null;
    readonly url: string | null;
    readonly labelSize: string | null;
    readonly scanform: {
      readonly __typename: 'ScanformByShareTokenResult';
      readonly id: string;
      readonly isScanformCreated: boolean;
      readonly canCreateScanform: boolean;
      readonly scanformUrl: string | null;
    } | null;
  };
};

export type DownloadLabelsByShipmentsQueryVariables = Exact<{
  shipmentIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  pageLayout?: InputMaybe<PageLayout>;
}>;

export type DownloadLabelsByShipmentsQuery = {
  readonly downloadLabelsByShipments: {
    readonly __typename: 'DownloadLabelsByShipmentsResult';
    readonly id: string;
    readonly status: DownloadStatus;
    readonly fileFormat: FileFormat | null;
    readonly labelSize: string | null;
    readonly pageLayout: PageLayout | null;
    readonly url: string | null;
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly canInstantRefundShipment: boolean;
      readonly printed: boolean;
      readonly canPrint: boolean;
      readonly isShipmentRefundable: boolean;
    } | null>;
  };
};

export type FailedShipmentsExportQueryVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type FailedShipmentsExportQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly failedShipmentsExportStatus: {
      readonly __typename: 'DataGridExportStatus';
      readonly exportId: number;
      readonly url: string | null;
    };
  };
};

export type GeneralSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GeneralSettingsQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly shipmentLabelSize: string;
      readonly shipmentLabelFileFormat: string;
      readonly packingListTemplate: string;
      readonly shipmentSpreadsheetFormat: string;
      readonly addressCorrectionEnabled: boolean;
    };
  };
};

export type LatestTermsQueryVariables = Exact<{ [key: string]: never }>;

export type LatestTermsQuery = {
  readonly latestTerms: {
    readonly __typename: 'UserTerms';
    readonly id: string;
    readonly terms: string;
  };
};

export type GlobalSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalSettingsQuery = {
  readonly globalSettings: {
    readonly __typename: 'GlobalSettings';
    readonly emailTemplate: {
      readonly __typename: 'EmailTemplate';
      readonly subject: string;
      readonly plainText: string;
      readonly html: string;
    };
  };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly title: string;
    readonly email: string;
  };
};

export type DefaultEmailResponseTimeOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultEmailResponseTimeOptionsQuery = {
  readonly globalSettings: {
    readonly __typename: 'GlobalSettings';
    readonly defaultEmailResponseTimeOptions: ReadonlyArray<{
      readonly __typename: 'Option';
      readonly title: string;
      readonly value: string;
    }>;
  };
};

export type ImportedOrdersBaseQueryVariables = Exact<{
  gridMode: GridMode;
}>;

export type ImportedOrdersBaseQuery = {
  readonly importedOrders: {
    readonly __typename: 'DataGridResult';
    readonly id: string;
    readonly metaData: ReadonlyArray<{
      readonly __typename: 'DataGridMetaData';
      readonly key: string;
      readonly value: string;
    }>;
  };
};

export type IntercomArticleQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type IntercomArticleQuery = { readonly intercomArticle: string };

export type IsEuQueryVariables = Exact<{ [key: string]: never }>;

export type IsEuQuery = { readonly isEu: boolean };

export type MailTemplateQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type MailTemplateQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly mailTemplates: ReadonlyArray<{
      readonly __typename: 'MailTemplate';
      readonly id: string;
      readonly name: string;
      readonly senderEmail: string;
      readonly senderName: string;
      readonly asDefault: boolean;
      readonly bccEmail: string;
      readonly subject: string;
      readonly templateHtml: string;
    }>;
  };
};

export type MailTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type MailTemplatesQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultTrackingEmailsDelay: string;
      readonly defaultTrackingEmailsEnabled: boolean;
      readonly defaultEmailTemplateId: string;
    };
    readonly mailTemplates: ReadonlyArray<{
      readonly __typename: 'MailTemplate';
      readonly id: string;
      readonly name: string;
      readonly senderEmail: string;
      readonly senderName: string;
      readonly asDefault: boolean;
      readonly bccEmail: string;
      readonly subject: string;
      readonly templateHtml: string;
    }>;
  };
};

export type DefaultTrackingEmailDelayQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultTrackingEmailDelayQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultTrackingEmailsDelay: string;
    };
  };
};

export type MapQueryVariables = Exact<{
  id: Scalars['ID'];
  width: Scalars['Int'];
  height: Scalars['Int'];
}>;

export type MapQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly shipments: ReadonlyArray<{
      readonly __typename: 'Shipment';
      readonly id: string;
      readonly map: string;
    }>;
  };
};

export type NumbersQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type NumbersQuery = {
  readonly numbers: {
    readonly __typename: 'Numbers';
    readonly carrierAdjustmentsAmount: number;
    readonly carrierAdjustmentsCount: number;
    readonly payments: number;
    readonly netLabelCount: number;
    readonly netLabelTotal: number;
    readonly netLabelAverage: number;
    readonly netLabelAverageDom: number;
    readonly netLabelAverageInt: number;
    readonly pendingNumbers: {
      readonly __typename: 'PendingNumbers';
      readonly paymentsTotalAmount: number;
      readonly returns: {
        readonly __typename: 'Summary';
        readonly amount: number;
        readonly count: number;
      };
      readonly refunds: {
        readonly __typename: 'Summary';
        readonly amount: number;
        readonly count: number;
      };
    };
  };
};

export type PackageTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PackageTypesQuery = {
  readonly carriers: ReadonlyArray<{
    readonly __typename: 'Carrier';
    readonly id: string;
    readonly carrierKey: CarrierKey;
    readonly packageTypes: ReadonlyArray<{
      readonly __typename: 'CarrierPackageType';
      readonly id: string;
      readonly packageTypeKey: string;
      readonly title: string;
      readonly description: string;
      readonly heightRequired: boolean;
      readonly weightRequired: boolean;
      readonly dimensionsRequired: boolean;
    }>;
  }>;
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
  };
};

export type PaymentChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type PaymentChartQuery = {
  readonly paymentChart: {
    readonly __typename: 'Chart';
    readonly labels: ReadonlyArray<string>;
    readonly charts: ReadonlyArray<number>;
  };
};

export type PaymentModalQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentModalQuery = {
  readonly chargeAmountOptions: ReadonlyArray<number>;
  readonly user: { readonly __typename: 'User'; readonly id: string; readonly email: string };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly accountBalance: number;
    readonly activePaymentMethods: ReadonlyArray<PaymentMethodType>;
    readonly features: ReadonlyArray<{
      readonly __typename: 'Feature';
      readonly key: string;
      readonly value: boolean;
    }>;
  };
  readonly paymentApiMethods: ReadonlyArray<{
    readonly __typename: 'PaymentApiMethod';
    readonly id: string;
    readonly code: PaymentMethodType;
    readonly apiTitle: PaymentApiMethodTitle;
    readonly apiKey: string | null;
  }>;
};

export type PaymentReceiptsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentReceiptsQuery = { readonly paymentReceipts: object | null };

export type PickupQueryVariables = Exact<{ [key: string]: never }>;

export type PickupQuery = {
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly timezone: string;
  };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly createdMerchantAccounts: ReadonlyArray<CarrierKey>;
    readonly accountBalance: number;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
    readonly activePaymentMethods: ReadonlyArray<PaymentMethodType>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultWarehouseId: string;
      readonly defaultChargeAmount: number;
      readonly defaultPaymentSourceId: string;
    };
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
    readonly pickups: ReadonlyArray<{
      readonly __typename: 'PickupRequest';
      readonly id: string;
      readonly oneTimePickupDate: string | null;
      readonly warehouseReadyTime: string;
      readonly warehouseCloseTime: string;
      readonly totalPackageWeight: number;
      readonly confirmationNumber: string | null;
      readonly totalPackageCount: number;
      readonly status: PickupRequestStatus;
      readonly canCancel: boolean;
      readonly packageLocation: PickupRequestPackageLocation;
      readonly specialInstructions: string | null;
      readonly rate: { readonly __typename: 'PickupRequestRate'; readonly totalPrice: number };
      readonly warehouse: { readonly __typename: 'Warehouse'; readonly title: string };
      readonly carrier: {
        readonly __typename: 'Carrier';
        readonly carrierKey: CarrierKey;
        readonly title: string;
      };
    }>;
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
  readonly paymentApiMethods: ReadonlyArray<{
    readonly __typename: 'PaymentApiMethod';
    readonly id: string;
    readonly code: PaymentMethodType;
    readonly apiTitle: PaymentApiMethodTitle;
    readonly apiKey: string | null;
  }>;
};

export type PickupCreationQueryVariables = Exact<{ [key: string]: never }>;

export type PickupCreationQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly accountBalance: number;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
    readonly activePaymentMethods: ReadonlyArray<PaymentMethodType>;
    readonly createdMerchantAccounts: ReadonlyArray<CarrierKey>;
    readonly hasUspsMerchantAccountError: boolean;
    readonly uspsMerchantAccountStatus: string;
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
      readonly validationStatus: string;
      readonly brand: string;
      readonly last4: string | null;
      readonly expMonth: number | null;
      readonly expYear: number | null;
      readonly resultMessage: string | null;
      readonly email: string | null;
      readonly title: string;
      readonly refundableAmount: number;
      readonly hasConsented: boolean;
    }>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultChargeAmount: number;
      readonly defaultPaymentSourceId: string;
      readonly defaultWarehouseId: string;
    };
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
  readonly countries: ReadonlyArray<{
    readonly __typename: 'Country';
    readonly id: string;
    readonly countryCode: string;
    readonly name: string;
  }>;
  readonly carriers: ReadonlyArray<{
    readonly __typename: 'Carrier';
    readonly id: string;
    readonly carrierKey: CarrierKey;
    readonly allowedPickupDates: ReadonlyArray<string>;
    readonly mailClasses: ReadonlyArray<{
      readonly __typename: 'CarrierMailClass';
      readonly id: string;
      readonly title: string;
      readonly mailClassKey: string;
      readonly serviceLevel: number;
    }>;
  }>;
  readonly paymentApiMethods: ReadonlyArray<{
    readonly __typename: 'PaymentApiMethod';
    readonly id: string;
    readonly code: PaymentMethodType;
    readonly apiTitle: PaymentApiMethodTitle;
    readonly apiKey: string | null;
  }>;
  readonly user: { readonly __typename: 'User'; readonly id: string; readonly email: string };
};

export type PickupsQueryVariables = Exact<{ [key: string]: never }>;

export type PickupsQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
    readonly pickups: ReadonlyArray<{
      readonly __typename: 'PickupRequest';
      readonly id: string;
      readonly oneTimePickupDate: string | null;
      readonly warehouseReadyTime: string;
      readonly warehouseCloseTime: string;
      readonly totalPackageWeight: number;
      readonly confirmationNumber: string | null;
      readonly totalPackageCount: number;
      readonly status: PickupRequestStatus;
      readonly canCancel: boolean;
      readonly packageLocation: PickupRequestPackageLocation;
      readonly specialInstructions: string | null;
      readonly rate: { readonly __typename: 'PickupRequestRate'; readonly totalPrice: number };
      readonly warehouse: { readonly __typename: 'Warehouse'; readonly title: string };
      readonly carrier: {
        readonly __typename: 'Carrier';
        readonly carrierKey: CarrierKey;
        readonly title: string;
      };
    }>;
  };
};

export type BigcartelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BigcartelQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | {
          readonly __typename: 'BigcartelChannel';
          readonly plan: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly autoSyncEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type BigcommerceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BigcommerceQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | {
          readonly __typename: 'BigcommerceChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly clientSecret: string;
          readonly apiUrl: string;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type ChargifyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ChargifyQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | {
          readonly __typename: 'ChargifyChannel';
          readonly clientId: string;
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly preventDuplicateShippingEnabled: boolean;
          readonly preventDuplicateShippingOption: number;
          readonly billingDateSyncEnabled: boolean;
          readonly billingDateSyncOption: number;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type CratejoyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CratejoyQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | {
          readonly __typename: 'CratejoyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly clientId: string;
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly skipFutureSubscriptions: boolean;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type DiscogsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DiscogsQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | {
          readonly __typename: 'DiscogsChannel';
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type EbayQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EbayQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | {
          readonly __typename: 'EbayChannel';
          readonly statusesToImport: ReadonlyArray<string>;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type EcwidQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EcwidQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | {
          readonly __typename: 'EcwidChannel';
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly username: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly customFulfillmentStatusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly customPaymentStatuses: ReadonlyArray<{
            readonly __typename: 'EcwidOrderStatusImpl';
            readonly id: string;
            readonly name: string;
          }>;
          readonly customFulfillmentStatuses: ReadonlyArray<{
            readonly __typename: 'EcwidOrderStatusImpl';
            readonly id: string;
            readonly name: string;
          }>;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type EtsyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EtsyQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | {
          readonly __typename: 'EtsyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type FakeshopQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FakeshopQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | {
          readonly __typename: 'FakeshopChannel';
          readonly orderGroups: ReadonlyArray<string>;
          readonly autoSyncEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
        }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type MoonclerkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MoonclerkQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | {
          readonly __typename: 'MoonclerkChannel';
          readonly clientSecret: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly preventDuplicateShippingEnabled: boolean;
          readonly preventDuplicateShippingOption: number;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type PaypalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PaypalQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | {
          readonly __typename: 'PaypalChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly genericLineItemEnabled: boolean | null;
          readonly trackingEmailsEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type ShopifyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShopifyQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | {
          readonly __typename: 'ShopifyChannel';
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly shopUrl: string;
          readonly importLineItemsNotRequireShippingEnabled: boolean;
          readonly trackingEmailsEnabled: boolean;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly importOrdersNewerThan: number | null;
          readonly locationsToImport: ReadonlyArray<string>;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
          readonly customOptions: ReadonlyArray<{
            readonly __typename: 'CustomOption';
            readonly sourceKey: string;
            readonly name: string;
            readonly override: string;
          }>;
          readonly fulfillmentLocations: ReadonlyArray<{
            readonly __typename: 'ShopifyLocation';
            readonly id: string;
            readonly name: string;
          }>;
        }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type SquarespaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SquarespaceQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | {
          readonly __typename: 'SquarespaceChannel';
          readonly clientSecret: string;
          readonly trackingEmailsEnabled: boolean;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'SubblyChannel' }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type SubblyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubblyQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | {
          readonly __typename: 'SubblyChannel';
          readonly clientSecret: string;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
      | { readonly __typename: 'WoocommerceChannel' }
    >;
  };
};

export type WoocommerceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type WoocommerceQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly channels: ReadonlyArray<
      | { readonly __typename: 'BigcartelChannel' }
      | { readonly __typename: 'BigcommerceChannel' }
      | { readonly __typename: 'ChargifyChannel' }
      | { readonly __typename: 'CratejoyChannel' }
      | { readonly __typename: 'DiscogsChannel' }
      | { readonly __typename: 'EbayChannel' }
      | { readonly __typename: 'EcwidChannel' }
      | { readonly __typename: 'EtsyChannel' }
      | { readonly __typename: 'FakeshopChannel' }
      | { readonly __typename: 'MoonclerkChannel' }
      | { readonly __typename: 'PaypalChannel' }
      | { readonly __typename: 'ShopifyChannel' }
      | { readonly __typename: 'SquarespaceChannel' }
      | { readonly __typename: 'SubblyChannel' }
      | {
          readonly __typename: 'WoocommerceChannel';
          readonly shopUrl: string;
          readonly statusesToImport: ReadonlyArray<string>;
          readonly trackingEmailsEnabled: boolean;
          readonly id: string;
          readonly title: string;
          readonly active: boolean;
          readonly valid: boolean;
          readonly autoSyncEnabled: boolean;
          readonly platform: {
            readonly __typename: 'Platform';
            readonly id: string;
            readonly platformKey: string;
            readonly title: string;
          } | null;
          readonly mappableFields: ReadonlyArray<{
            readonly __typename: 'PlatformMappableField';
            readonly field: string;
            readonly label: string;
          }>;
          readonly rubberstampConfig: ReadonlyArray<{
            readonly __typename: 'ChannelRubberstampConfig';
            readonly rubberstamp: string;
            readonly configuredField: string;
          }>;
        }
    >;
  };
};

export type PlatformsQueryVariables = Exact<{ [key: string]: never }>;

export type PlatformsQuery = {
  readonly platforms: ReadonlyArray<{
    readonly __typename: 'Platform';
    readonly installUrl: string | null;
    readonly reconnectSupported: boolean;
    readonly trackingEmailSupported: boolean;
    readonly id: string;
    readonly platformKey: string;
    readonly title: string;
  }>;
};

export type PrintPreviewQueryVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type PrintPreviewQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly canCreateScanform: boolean;
    readonly scanformCreated: boolean;
    readonly scanformUrl: string | null;
    readonly numShipments: number;
    readonly shipmentStatusSummary: {
      readonly __typename: 'ShipmentStatusSummary';
      readonly errorCount: number;
    };
  };
};

export type PrintSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type PrintSettingsQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly shipmentLabelSize: string;
      readonly shipmentLabelFileFormat: string;
      readonly packingListTemplate: string;
    };
  };
};

export type GetRateGroupsQueryVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type GetRateGroupsQuery = {
  readonly batch: {
    readonly __typename: 'Batch';
    readonly id: string;
    readonly rateGroups: ReadonlyArray<{
      readonly __typename: 'RateGroup';
      readonly title: string;
      readonly intercomLink: string | null;
      readonly maximumShipments: number;
      readonly numberOfCheapestOrBestServices: number;
      readonly groupKey: {
        readonly __typename: 'RateGroupKey';
        readonly string: string;
        readonly traits: ReadonlyArray<{
          readonly __typename: 'RateGroupTrait';
          readonly layer: RateGroupLayerKey;
          readonly value: RateGroupTraitKey;
        }>;
      };
      readonly rateSummaries: ReadonlyArray<{
        readonly __typename: 'RateSummary';
        readonly serviceTitle: string;
        readonly mailClassTitle: string;
        readonly averageBasePrice: number;
        readonly averageTotalPrice: number;
        readonly basePrice: number;
        readonly best: boolean;
        readonly cheapest: boolean;
        readonly crossedTotalPrice: number;
        readonly deliveryDays: number;
        readonly fastest: boolean;
        readonly flatPrice: boolean;
        readonly ratePeriodEndDate: string | null;
        readonly ratePeriodStartDate: string | null;
        readonly errorMessage: string | null;
        readonly savings: string;
        readonly shipmentCount: number;
        readonly totalPrice: number;
        readonly uniqueId: string;
        readonly firstZone: string;
        readonly maxWeightOz: number;
        readonly upsellRateSummary: {
          readonly __typename: 'RateSummary';
          readonly serviceTitle: string;
          readonly totalPrice: number;
          readonly uniqueId: string;
          readonly carrier: {
            readonly __typename: 'Carrier';
            readonly carrierKey: CarrierKey;
            readonly title: string;
          };
          readonly firstMailClass: {
            readonly __typename: 'CarrierMailClass';
            readonly mailClassKey: string;
          };
        } | null;
        readonly carrier: {
          readonly __typename: 'Carrier';
          readonly carrierKey: CarrierKey;
          readonly title: string;
        };
        readonly firstMailClass: {
          readonly __typename: 'CarrierMailClass';
          readonly mailClassKey: string;
        };
        readonly packageType: {
          readonly __typename: 'CarrierPackageType';
          readonly packageTypeKey: string;
        };
        readonly surcharges: ReadonlyArray<{
          readonly __typename: 'RateSurchargeSummary';
          readonly surchargeKey: string;
          readonly title: string;
          readonly price: number;
          readonly helpLink: string | null;
          readonly crossedPrice: number | null;
        }>;
      }>;
    }>;
  };
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly rateGroupSortOrder: RateGroupSortOrder;
  };
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
  };
};

export type GetRatesConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatesConfigQuery = {
  readonly carriers: ReadonlyArray<{
    readonly __typename: 'Carrier';
    readonly id: string;
    readonly carrierKey: CarrierKey;
    readonly mailClasses: ReadonlyArray<{
      readonly __typename: 'CarrierMailClass';
      readonly mailClassKey: string;
    }>;
    readonly packageTypes: ReadonlyArray<{
      readonly __typename: 'CarrierPackageType';
      readonly id: string;
      readonly packageTypeKey: string;
      readonly title: string;
      readonly description: string;
      readonly heightRequired: boolean;
      readonly weightRequired: boolean;
      readonly dimensionsRequired: boolean;
    }>;
  }>;
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly activeCarriers: ReadonlyArray<CarrierKey>;
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultDestinationCountryCode: string;
      readonly defaultWarehouseId: string;
    };
  };
  readonly shipmentBoundaries: {
    readonly __typename: 'ShipmentBoundaries';
    readonly maxWeight: number;
    readonly maxCombinedLength: number;
    readonly maxLengthPlusGirth: number;
    readonly minLongSide: number;
    readonly maxLongSide: number;
    readonly minMiddleSide: number;
    readonly maxMiddleSide: number;
    readonly minShortSide: number;
    readonly maxShortSide: number;
  };
  readonly countries: ReadonlyArray<{
    readonly __typename: 'Country';
    readonly id: string;
    readonly name: string;
    readonly countryCode: string;
    readonly parentCountryId: string;
  }>;
};

export type GetRatesQueryVariables = Exact<{
  originZip: Scalars['String'];
  originCity?: InputMaybe<Scalars['String']>;
  originRegionCode?: InputMaybe<Scalars['String']>;
  destinationZip?: InputMaybe<Scalars['String']>;
  isResidential?: InputMaybe<Scalars['Boolean']>;
  destinationCountryCode?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
  dimensionX?: InputMaybe<Scalars['Float']>;
  dimensionY?: InputMaybe<Scalars['Float']>;
  dimensionZ?: InputMaybe<Scalars['Float']>;
  mailClassKeys: ReadonlyArray<Scalars['String']> | Scalars['String'];
  packageTypeKeys: ReadonlyArray<Scalars['String']> | Scalars['String'];
  pricingTypes?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  showUpsRatesWhen2x7Selected?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRatesQuery = {
  readonly rates: ReadonlyArray<{
    readonly __typename: 'RateResult';
    readonly title: string;
    readonly deliveryDescription: string;
    readonly trackingDescription: string;
    readonly serviceDescription: string;
    readonly pricingDescription: string;
    readonly cubicTier: string | null;
    readonly mailClassKey: string;
    readonly packageTypeKey: string;
    readonly zone: string;
    readonly totalPrice: number;
    readonly priceBaseTypeKey: string;
    readonly basePrice: number;
    readonly crossedTotalPrice: number;
    readonly pricingType: string;
    readonly pricingSubType: string;
    readonly ratePeriodId: number;
    readonly learnMoreUrl: string;
    readonly cheapest: boolean;
    readonly fastest: boolean;
    readonly mailClass: {
      readonly __typename: 'CarrierMailClass';
      readonly accuracy: number | null;
      readonly international: boolean;
    };
    readonly surcharges: ReadonlyArray<{
      readonly __typename: 'RateSurcharge';
      readonly title: string;
      readonly price: number;
    }>;
    readonly carrier: {
      readonly __typename: 'Carrier';
      readonly carrierKey: CarrierKey;
      readonly title: string;
    };
  }>;
};

export type ReceiptQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ReceiptQuery = {
  readonly receipt: {
    readonly __typename: 'Receipt';
    readonly startBalance: number;
    readonly endBalance: number;
    readonly paymentSuccessful: boolean;
    readonly isCharged: boolean;
    readonly billingAddress:
      | {
          readonly __typename: 'Address';
          readonly fullName: string;
          readonly company: string;
          readonly address1: string;
          readonly address2: string;
          readonly city: string;
          readonly postcode: string;
          readonly regionCode: string;
          readonly timeZone: string;
          readonly zip4: string;
        }
      | {
          readonly __typename: 'Shipment';
          readonly fullName: string;
          readonly company: string;
          readonly address1: string;
          readonly address2: string;
          readonly city: string;
          readonly postcode: string;
          readonly regionCode: string;
          readonly timeZone: string;
          readonly zip4: string;
        };
    readonly payment: {
      readonly __typename: 'Payment';
      readonly title: string;
      readonly total: string;
      readonly createdAt: string;
      readonly status: string;
      readonly chargeError: string;
      readonly refundTotal: number;
    };
    readonly transactions: ReadonlyArray<{
      readonly __typename: 'AccountingTransaction';
      readonly paymentId: string;
      readonly batchId: string;
      readonly shipmentId: string;
      readonly reconciliationRunId: string;
      readonly createdAt: string | null;
      readonly transactionType: string;
      readonly title: string | null;
      readonly amount: string;
      readonly balance: string | null;
    }>;
    readonly reversals: ReadonlyArray<{
      readonly __typename: 'ReversalType';
      readonly amount: number;
      readonly action: {
        readonly __typename: 'PaymentAction';
        readonly actionType: string;
        readonly newPaymentStatus: string;
        readonly createdAt: string;
      };
      readonly coveringAccountingTransaction: {
        readonly __typename: 'AccountingTransaction';
        readonly paymentId: string;
      } | null;
    }>;
  };
};

export type RefundsQueryVariables = Exact<{ [key: string]: never }>;

export type RefundsQuery = { readonly refunds: object | null };

export type RegionsQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;

export type RegionsQuery = {
  readonly regions: ReadonlyArray<{
    readonly __typename: 'Region';
    readonly id: string;
    readonly regionCode: string;
    readonly countryCode: string;
    readonly name: string;
  }>;
};

export type ReportsPageQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsPageQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly createdAt: string | null;
    readonly accountBalance: number;
    readonly paymentSources: ReadonlyArray<{
      readonly __typename: 'PaymentSource';
      readonly id: string;
      readonly paymentMethodType: PaymentMethodType;
    }>;
  };
};

export type ReturnsQueryVariables = Exact<{ [key: string]: never }>;

export type ReturnsQuery = { readonly returns: object | null };

export type ScanformsQueryVariables = Exact<{ [key: string]: never }>;

export type ScanformsQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultWarehouseId: string;
    };
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
};

export type SenderEmailMetadataQueryVariables = Exact<{
  emailAddress: Scalars['ID'];
}>;

export type SenderEmailMetadataQuery = {
  readonly senderEmailMetadata: {
    readonly __typename: 'SenderEmailMetadata';
    readonly verificationStatus: EmailVerificationStatus | null;
    readonly errorMessage: string | null;
    readonly domain: string;
    readonly dkimHost: string;
    readonly dkimTextValue: string;
    readonly dkimVerified: boolean;
    readonly returnPathDomain: string;
    readonly returnPathDomainCnameValue: string;
    readonly returnPathDomainVerified: boolean;
  } | null;
};

export type ShareDownloadUrlByBatchQueryVariables = Exact<{
  batchId: Scalars['ID'];
}>;

export type ShareDownloadUrlByBatchQuery = { readonly shareDownloadUrlByBatch: string };

export type ShareDownloadUrlByShipmentQueryVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type ShareDownloadUrlByShipmentQuery = { readonly shareDownloadUrlByShipment: string };

export type ShipmentBoundariesQueryVariables = Exact<{ [key: string]: never }>;

export type ShipmentBoundariesQuery = {
  readonly shipmentBoundaries: {
    readonly __typename: 'ShipmentBoundaries';
    readonly maxWeight: number;
    readonly maxCombinedLength: number;
    readonly maxLengthPlusGirth: number;
    readonly minLongSide: number;
    readonly maxLongSide: number;
    readonly minMiddleSide: number;
    readonly maxMiddleSide: number;
    readonly minShortSide: number;
    readonly maxShortSide: number;
  };
};

export type ShipmentChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type ShipmentChartQuery = {
  readonly shipmentChart: {
    readonly __typename: 'Chart';
    readonly charts: ReadonlyArray<number>;
    readonly labels: ReadonlyArray<string>;
  };
};

export type ShipmentDebugQueryVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type ShipmentDebugQuery = { readonly shipmentDebug: object };

export type ShipmentPieChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type ShipmentPieChartQuery = {
  readonly shipmentPieChart: {
    readonly __typename: 'Chart';
    readonly charts: ReadonlyArray<number>;
    readonly colors: ReadonlyArray<string> | null;
    readonly labels: ReadonlyArray<string>;
  };
};

export type ShipmentPresetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShipmentPresetQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly shipmentPresets: ReadonlyArray<{
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    }>;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultShipmentPresetId: string;
    };
  };
};

export type ShipmentPresetsQueryVariables = Exact<{ [key: string]: never }>;

export type ShipmentPresetsQuery = {
  readonly carriers: ReadonlyArray<{
    readonly __typename: 'Carrier';
    readonly id: string;
    readonly packageTypes: ReadonlyArray<{
      readonly __typename: 'CarrierPackageType';
      readonly id: string;
      readonly packageTypeKey: string;
      readonly title: string;
      readonly heightRequired: boolean;
      readonly weightRequired: boolean;
      readonly dimensionsRequired: boolean;
    }>;
  }>;
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultShipmentPresetId: string;
    };
    readonly shipmentPresets: ReadonlyArray<{
      readonly __typename: 'ShipmentPreset';
      readonly id: string;
      readonly originalId: string | null;
      readonly title: string;
      readonly packageTypeId: string;
      readonly packageTypeKey: string;
      readonly dimensionX: number;
      readonly dimensionY: number;
      readonly dimensionZ: number;
      readonly weight: number;
      readonly deliveryConfirmation: DeliveryConfirmation;
      readonly deliveryConfirmationFlag: boolean;
      readonly irregularPackage: boolean;
      readonly insuredValue: number;
      readonly insuredValueFlag: boolean;
      readonly returnLabel: ReturnLabel;
      readonly returnLabelFlag: boolean;
      readonly customsFormEnabled: boolean;
      readonly customsSigner: string;
      readonly customsContentType: string;
      readonly internationalTaxId: string;
      readonly qualifiesAsMediaMail: boolean;
      readonly hazardousMaterialsEnabled: boolean;
      readonly customsItems: ReadonlyArray<{
        readonly __typename: 'CustomsItem';
        readonly id: string;
        readonly title: string;
        readonly hsTariffNumber: string;
        readonly quantity: number;
        readonly itemValue: number;
        readonly weight: number;
        readonly countryCodeOfOrigin: string;
      }>;
    }>;
  };
};

export type ShipmentTrackingNumbersQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type ShipmentTrackingNumbersQuery = {
  readonly shipmentTrackingNumbers: {
    readonly __typename: 'ShipmentTrackingNumbers';
    readonly shipmentsTransit: number;
    readonly shipmentsDelivered: number;
    readonly shipmentsNew: number;
    readonly shipmentsDeliveryIssue: number;
  };
};

export type StateDistributionChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type StateDistributionChartQuery = {
  readonly stateDistributionChart: {
    readonly __typename: 'StateDistributionChart';
    readonly totalCount: number;
    readonly stateDistributions: ReadonlyArray<{
      readonly __typename: 'DistributionChartItem';
      readonly key: string;
      readonly count: string;
      readonly name: string;
      readonly percent: number;
      readonly price: number;
    }>;
  };
};

export type SuggestedMappingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SuggestedMappingQuery = {
  readonly shipmentUpload: {
    readonly __typename: 'ShipmentUpload';
    readonly suggestedMapping: ReadonlyArray<{
      readonly __typename: 'ShipmentMapping';
      readonly shipmentKey: ShipmentKey;
      readonly firstValue: string;
      readonly userFriendlyShipmentKey: string;
      readonly valueOrMappingKey: string;
    }>;
  };
};

export type SupportSearchQueryVariables = Exact<{
  term: Scalars['String'];
}>;

export type SupportSearchQuery = {
  readonly supportSearch: ReadonlyArray<{
    readonly __typename: 'SupportSearchResult';
    readonly id: string;
    readonly type: string;
    readonly label: string;
    readonly value: string;
  }>;
};

export type TransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type TransactionsQuery = { readonly transactions: object | null };

export type UpsPickupPrefillQueryVariables = Exact<{ [key: string]: never }>;

export type UpsPickupPrefillQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly pickupSummary: {
      readonly __typename: 'ShipmentSummary';
      readonly overweightShipmentIncluded: boolean;
      readonly totalCount: number;
      readonly mailClassCounts: ReadonlyArray<{
        readonly __typename: 'MailClassCount';
        readonly shipmentCount: number;
        readonly mailClass: {
          readonly __typename: 'CarrierMailClass';
          readonly mailClassKey: string;
        };
      }>;
    };
  };
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  readonly user: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly firstName: string;
    readonly lastName: string;
    readonly timezone: string;
    readonly roles: ReadonlyArray<string>;
    readonly admin: boolean;
    readonly userHash: string;
    readonly createdAt: string | null;
    readonly company: {
      readonly __typename: 'Company';
      readonly id: string;
      readonly debuggingEnabled: boolean;
      readonly features: ReadonlyArray<{
        readonly __typename: 'Feature';
        readonly key: string;
        readonly value: boolean;
      }>;
    };
    readonly activePlatforms: ReadonlyArray<{
      readonly __typename: 'Platform';
      readonly id: string;
      readonly platformKey: string;
      readonly title: string;
    }>;
    readonly challenges: ReadonlyArray<{
      readonly __typename: 'Challenge';
      readonly id: string;
      readonly key: ChallengeKey;
      readonly action: ChallengeAction;
      readonly createdAt: string;
    }>;
  };
};

export type UspsPickupPrefillQueryVariables = Exact<{ [key: string]: never }>;

export type UspsPickupPrefillQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly pickupSummary: {
      readonly __typename: 'ShipmentSummary';
      readonly overweightShipmentIncluded: boolean;
      readonly totalWeightOz: number;
      readonly totalWeightLbs: number;
      readonly totalCount: number;
    };
  };
};

export type WarehouseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type WarehouseQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultWarehouseId: string;
    };
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
};

export type WarehousesQueryVariables = Exact<{ [key: string]: never }>;

export type WarehousesQuery = {
  readonly company: {
    readonly __typename: 'Company';
    readonly id: string;
    readonly settings: {
      readonly __typename: 'CompanySettings';
      readonly defaultWarehouseId: string;
    };
    readonly warehouses: ReadonlyArray<{
      readonly __typename: 'Warehouse';
      readonly id: string;
      readonly title: string;
      readonly useOriginAsReturnAddress: boolean;
      readonly originAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly phone: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
      readonly returnAddress: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly fullName: string;
        readonly company: string;
        readonly address1: string;
        readonly address2: string;
        readonly city: string;
        readonly regionCode: string;
        readonly postcode: string;
        readonly zip4: string;
        readonly countryCode: string;
      };
    }>;
  };
};

export type ZoneChartQueryVariables = Exact<{
  datetimeRange: DatetimeRangeInput;
}>;

export type ZoneChartQuery = {
  readonly zoneChart: {
    readonly __typename: 'Chart';
    readonly labels: ReadonlyArray<string>;
    readonly charts: ReadonlyArray<number>;
    readonly colors: ReadonlyArray<string> | null;
  };
};

export const BasicUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicUserFragment, unknown>;
export const BasicPlatformFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicPlatformFieldsFragment, unknown>;
export const CurrentUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'debuggingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlatforms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserFragment, unknown>;
export const DataGridUserSettingsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataGridUserSettingsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridSettings' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'columns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'columnOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sorting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataGridUserSettingsFieldsFragment, unknown>;
export const DataGridUserSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataGridUserSettings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataGridUserSettingsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataGridUserSettingsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridSettings' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'columns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'columnOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sorting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataGridUserSettingsFragment, unknown>;
export const MailTemplateFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MailTemplateFieldsFragment, unknown>;
export const PackageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PackageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PackageFieldsFragment, unknown>;
export const PaymentApiMethodFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentApiMethodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentApiMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentApiMethodFieldsFragment, unknown>;
export const PaymentSourceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentSourceFieldsFragment, unknown>;
export const PickupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PickupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseReadyTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseCloseTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialInstructions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PickupFieldsFragment, unknown>;
export const ChannelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelFieldsFragment, unknown>;
export const MappingFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MappingFieldsFragment, unknown>;
export const BigcartelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcartelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcartelChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcartelChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BigcartelFieldsFragment, unknown>;
export const BigcommerceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BigcommerceFieldsFragment, unknown>;
export const ChargifyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChargifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncOption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChargifyFieldsFragment, unknown>;
export const CratejoyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CratejoyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CratejoyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CratejoyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CratejoyFieldsFragment, unknown>;
export const DiscogsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscogsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscogsChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DiscogsChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscogsFieldsFragment, unknown>;
export const EbayFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EbayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EbayChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EbayChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EbayFieldsFragment, unknown>;
export const EcwidFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EcwidFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EcwidChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EcwidChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customPaymentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EcwidFieldsFragment, unknown>;
export const EtsyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EtsyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EtsyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EtsyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EtsyFieldsFragment, unknown>;
export const FakeshopFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FakeshopFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FakeshopChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FakeshopChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FakeshopFieldsFragment, unknown>;
export const MoonclerkFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoonclerkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MoonclerkChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MoonclerkChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoonclerkFieldsFragment, unknown>;
export const PaypalFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaypalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaypalChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PaypalChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'genericLineItemEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaypalFieldsFragment, unknown>;
export const ShopifyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'override' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ShopifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'importOrdersNewerThan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locationsToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentLocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopifyFieldsFragment, unknown>;
export const SquarespaceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SquarespaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SquarespaceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SquarespaceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SquarespaceFieldsFragment, unknown>;
export const SubblyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubblyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubblyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SubblyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubblyFieldsFragment, unknown>;
export const WoocommerceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoocommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WoocommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'WoocommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WoocommerceFieldsFragment, unknown>;
export const RateSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RateSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RateSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstMailClass' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packageType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailClassTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageBasePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageTotalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'best' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cheapest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossedTotalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fastest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodEndDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'surcharges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'surchargeKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'helpLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crossedPrice' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWeightOz' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RateSummaryFieldsFragment, unknown>;
export const RunningProcessFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RunningProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Batch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runningProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsInProgressCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsTotalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progressPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progressTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondsLeft' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RunningProcessFieldsFragment, unknown>;
export const SenderEmailMetadataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SenderEmailMetadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimHost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimTextValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainCnameValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainVerified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SenderEmailMetadataFieldsFragment, unknown>;
export const ShipmentPresetFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentPresetFieldsFragment, unknown>;
export const ShipmentStatusSummaryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentStatusSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundPendingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inTransitCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToShipCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToPrintCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentStatusSummaryFieldsFragment, unknown>;
export const AddressFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressFieldsFragment, unknown>;
export const WarehouseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarehouseFieldsFragment, unknown>;
export const DummyDataGridInputTypeUsageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DummyDataGridInputTypeUsageQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridSortingInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rows' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchTerm' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sorting' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nodes' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiptsReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rows' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchTerm' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sorting' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sorting' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nodes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DummyDataGridInputTypeUsageQuery,
  DummyDataGridInputTypeUsageQueryVariables
>;
export const AcceptTermsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptTermsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accepted' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptTerms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accepted' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accepted' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptTermsMutation, AcceptTermsMutationVariables>;
export const UpdateUserAfterAcceptTermsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateUserAfterAcceptTermsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserAfterAcceptTermsQuery,
  UpdateUserAfterAcceptTermsQueryVariables
>;
export const AddCreditToPaymentSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCreditToPaymentSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addCreditAmount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCreditToPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addCreditAmount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'addCreditAmount' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddCreditToPaymentSourceMutation,
  AddCreditToPaymentSourceMutationVariables
>;
export const BuyBatchMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BuyBatchMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rateSelection' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'RateSelectionInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'totalCharge' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailTemplateId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notifyRecipientsDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateSelection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rateSelection' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'totalCharge' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'totalCharge' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notifyRecipientsDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notifyRecipientsDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyBatchMutation, BuyBatchMutationVariables>;
export const CancelEmailNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelEmailNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelEmailNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyRecipientsDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailNotificationTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelEmailNotificationMutation,
  CancelEmailNotificationMutationVariables
>;
export const CancelPickupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelPickupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelPickup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelPickupMutation, CancelPickupMutationVariables>;
export const UpdatePickupsAfterCancelQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePickupsAfterCancelQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pickups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PickupFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PickupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseReadyTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseCloseTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialInstructions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePickupsAfterCancelQuery, UpdatePickupsAfterCancelQueryVariables>;
export const ChannelAutoSyncMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChannelAutoSyncMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'channelAutoSync' } }],
      },
    },
  ],
} as unknown as DocumentNode<ChannelAutoSyncMutation, ChannelAutoSyncMutationVariables>;
export const CompleteBatchFromUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteBatchFromUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchTitle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'warehouseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPresetId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeBatchFromUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchTitle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchTitle' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'warehouseId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentPresetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPresetId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'runningProcess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'progressTitle' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteBatchFromUploadMutation,
  CompleteBatchFromUploadMutationVariables
>;
export const CreateAchPaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAchPaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'routingNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountHolderName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountHolderType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAchPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'routingNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'routingNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountHolderName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountHolderName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountHolderType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountHolderType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAchPaymentSourceMutation,
  CreateAchPaymentSourceMutationVariables
>;
export const UpdateAchPaymentSourceAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateAchPaymentSourceAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAchPaymentSourceAfterCreateQuery,
  UpdateAchPaymentSourceAfterCreateQueryVariables
>;
export const CreateBatchFromUploadMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBatchFromUploadMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mapping' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentMappingInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBatchFromUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mapping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mapping' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapping' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userFriendlyShipmentKey' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBatchFromUploadMutation, CreateBatchFromUploadMutationVariables>;
export const CreateCcPaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCcPaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ccReference' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cp' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCcPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ccReference' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ccReference' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cp' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cp' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCcPaymentSourceMutation, CreateCcPaymentSourceMutationVariables>;
export const UpdateCcPaymentSourceAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateCcPaymentSourceAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCcPaymentSourceAfterCreateQuery,
  UpdateCcPaymentSourceAfterCreateQueryVariables
>;
export const CreateChallengeMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateChallengeMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeKey' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeAction' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createChallenge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateChallengeMutation, CreateChallengeMutationVariables>;
export const CreateMailTemplateMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMailTemplateMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'asDefault' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'senderEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateHtml' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bccEmail' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMailTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'asDefault' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'asDefault' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderEmail' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'senderEmail' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateHtml' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateHtml' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bccEmail' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bccEmail' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailTemplateFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>;
export const UpdateMailTemplatesAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateMailTemplatesAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultEmailTemplateId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MailTemplateFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMailTemplatesAfterCreateQuery,
  UpdateMailTemplatesAfterCreateQueryVariables
>;
export const CreateMappingsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMappingsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rubberStamp1' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rubberStamp2' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rubberStamp3' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'additionalMapping' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdditionalMappingInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMappings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rubberStamp1' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rubberStamp1' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rubberStamp2' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rubberStamp2' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rubberStamp3' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rubberStamp3' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalMapping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'additionalMapping' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChannelInterface' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMappingsMutation, CreateMappingsMutationVariables>;
export const CreateOrUpdateUpsMerchantAccountMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrUpdateUpsMerchantAccountMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrUpdateUpsMerchantAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCategory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrUpdateUpsMerchantAccountMutation,
  CreateOrUpdateUpsMerchantAccountMutationVariables
>;
export const AddUpsToCreatedMerchantAccountsAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AddUpsToCreatedMerchantAccountsAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdMerchantAccounts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUpsToCreatedMerchantAccountsAfterCreateQuery,
  AddUpsToCreatedMerchantAccountsAfterCreateQueryVariables
>;
export const CreatePayPalPaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePayPalPaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nonce' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPayPalPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nonce' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nonce' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePayPalPaymentSourceMutation,
  CreatePayPalPaymentSourceMutationVariables
>;
export const UpdatePayPalPaymentSourceAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePayPalPaymentSourceAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePayPalPaymentSourceAfterCreateQuery,
  UpdatePayPalPaymentSourceAfterCreateQueryVariables
>;
export const CreatePlaidAccessTokenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePlaidAccessTokenMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaidAccessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'handle' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'handle' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mask' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePlaidAccessTokenMutation,
  CreatePlaidAccessTokenMutationVariables
>;
export const UpdatePlaidPaymentSourceAfterValidationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePlaidPaymentSourceAfterValidationQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePlaidPaymentSourceAfterValidationQuery,
  UpdatePlaidPaymentSourceAfterValidationQueryVariables
>;
export const CreatePlaidActivationMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePlaidActivationMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'origin' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaidActivation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'origin' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'origin' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePlaidActivationMutation, CreatePlaidActivationMutationVariables>;
export const CreatePlaidConsentMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePlaidConsentMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaidConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePlaidConsentMutation, CreatePlaidConsentMutationVariables>;
export const UpdatePlaidPaymentSourceAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePlaidPaymentSourceAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePlaidPaymentSourceAfterCreateQuery,
  UpdatePlaidPaymentSourceAfterCreateQueryVariables
>;
export const CreatePlaidValidationActivationMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePlaidValidationActivationMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'origin' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaidValidationActivation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'origin' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'origin' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePlaidValidationActivationMutation,
  CreatePlaidValidationActivationMutationVariables
>;
export const CreateScanformByBatchMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScanformByBatchMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScanformByBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'batchId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateScanform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateScanformByBatchMutation, CreateScanformByBatchMutationVariables>;
export const CreateScanformByShareTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScanformByShareToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScanformByShareToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shareToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shareToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateScanform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isScanformCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateScanformByShareTokenMutation,
  CreateScanformByShareTokenMutationVariables
>;
export const CreateScopedShipmentPresetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateScopedShipmentPreset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originalPresetId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPresetInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPresetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScopedShipmentPreset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originalPresetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originalPresetId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'values' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPresetInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateScopedShipmentPresetMutation,
  CreateScopedShipmentPresetMutationVariables
>;
export const CreateSenderEmailMetadataMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSenderEmailMetadataMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSenderEmailMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SenderEmailMetadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimHost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimTextValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainCnameValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainVerified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSenderEmailMetadataMutation,
  CreateSenderEmailMetadataMutationVariables
>;
export const CreateShipmentPresetMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateShipmentPresetMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'default' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPreset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPresetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShipmentPreset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'default' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'default' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentPreset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPreset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPreset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateShipmentPresetMutation, CreateShipmentPresetMutationVariables>;
export const UpdateShipmentPresetsAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateShipmentPresetsAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPresets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultShipmentPresetId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateShipmentPresetsAfterCreateQuery,
  UpdateShipmentPresetsAfterCreateQueryVariables
>;
export const CreateUspsMerchantAccountMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUspsMerchantAccountMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUspsMerchantAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCategory' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUspsMerchantAccountMutation,
  CreateUspsMerchantAccountMutationVariables
>;
export const AddUspsToCreatedMerchantAccountsAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AddUspsToCreatedMerchantAccountsAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdMerchantAccounts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUspsToCreatedMerchantAccountsAfterCreateQuery,
  AddUspsToCreatedMerchantAccountsAfterCreateQueryVariables
>;
export const CreateVenmoPaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateVenmoPaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nonce' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVenmoPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nonce' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nonce' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateVenmoPaymentSourceMutation,
  CreateVenmoPaymentSourceMutationVariables
>;
export const UpdateVenmoSourceAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateVenmoSourceAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVenmoSourceAfterCreateQuery,
  UpdateVenmoSourceAfterCreateQueryVariables
>;
export const CreateWarehouseMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateWarehouseMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'saveAddressForReuse' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'returnAddress' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWarehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useOriginAsReturnAddress' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'useOriginAsReturnAddress' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'returnAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'returnAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'saveAddressForReuse' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'saveAddressForReuse' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateWarehouseMutation, CreateWarehouseMutationVariables>;
export const UpdateWarehousesAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateWarehousesAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehousesAfterCreateQuery,
  UpdateWarehousesAfterCreateQueryVariables
>;
export const DeleteBatchMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBatchMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBatchMutation, DeleteBatchMutationVariables>;
export const DeleteChannelMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteChannelMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChannelInterface' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteChannelMutation, DeleteChannelMutationVariables>;
export const UpdateChannelsAfterDeleteQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateChannelsAfterDeleteQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ChannelInterface' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChannelsAfterDeleteQuery,
  UpdateChannelsAfterDeleteQueryVariables
>;
export const DeleteMailTemplateMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMailTemplateMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMailTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>;
export const UpdateMailTemplatesAfterDeleteQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateMailTemplatesAfterDeleteQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MailTemplateFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMailTemplatesAfterDeleteQuery,
  UpdateMailTemplatesAfterDeleteQueryVariables
>;
export const DeletePaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePaymentSourceMutation, DeletePaymentSourceMutationVariables>;
export const UpdatePaymentSourcesAfterDeleteQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePaymentSourcesAfterDeleteQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePaymentSourcesAfterDeleteQuery,
  UpdatePaymentSourcesAfterDeleteQueryVariables
>;
export const DeleteShipmentPresetMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteShipmentPresetMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteShipmentPreset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteShipmentPresetMutation, DeleteShipmentPresetMutationVariables>;
export const UpdateShipmentPresetsAfterDeleteQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateShipmentPresetsAfterDeleteQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPresets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultShipmentPresetId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateShipmentPresetsAfterDeleteQuery,
  UpdateShipmentPresetsAfterDeleteQueryVariables
>;
export const DeleteWarehouseMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteWarehouseMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'warehouseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWarehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'warehouseId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>;
export const UpdateWarehousesAfterDeleteQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateWarehousesAfterDeleteQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehousesAfterDeleteQuery,
  UpdateWarehousesAfterDeleteQueryVariables
>;
export const DisableChannelMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisableChannelMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disableChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChannelInterface' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DisableChannelMutation, DisableChannelMutationVariables>;
export const DisputeAdjustmentsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisputeAdjustmentsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'disputeReason' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disputeAdjustments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'disputeReason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'disputeReason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disputedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disputeStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DisputeAdjustmentsMutation, DisputeAdjustmentsMutationVariables>;
export const EnableChannelMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableChannelMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChannelInterface' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableChannelMutation, EnableChannelMutationVariables>;
export const FetchSenderEmailMetadataMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FetchSenderEmailMetadataMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fetchSenderEmailMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SenderEmailMetadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimHost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimTextValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainCnameValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainVerified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchSenderEmailMetadataMutation,
  FetchSenderEmailMetadataMutationVariables
>;
export const ForgetLoginMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgetLoginMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rememberToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgetLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rememberToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rememberToken' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForgetLoginMutation, ForgetLoginMutationVariables>;
export const IgnoreOrdersMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IgnoreOrdersMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ignoreOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ids' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IgnoreOrdersMutation, IgnoreOrdersMutationVariables>;
export const LoginWithPasswordMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginWithPasswordMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cp' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaResponse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginWithPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cp' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cp' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaResponse' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaResponse' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrentUser' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'debuggingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlatforms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>;
export const LoginWithRememberTokenMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LoginWithRememberTokenMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rememberToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthToken' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginWithRememberToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rememberToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rememberToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrentUser' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'debuggingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlatforms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginWithRememberTokenMutation,
  LoginWithRememberTokenMutationVariables
>;
export const LogoutMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogoutMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'logout' } }],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const ConnectWoocommerceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectWoocommerceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectWoocommerceChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shopUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopUrl' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectWoocommerceMutation, ConnectWoocommerceMutationVariables>;
export const CreateBigcommerceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBigcommerceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBigcommerceChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'apiUrl' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'apiUrl' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BigcommerceFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBigcommerceMutation, CreateBigcommerceMutationVariables>;
export const CreateBigcommerceAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateBigcommerceAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BigcommerceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBigcommerceAfterUpdateQuery,
  CreateBigcommerceAfterUpdateQueryVariables
>;
export const CreateChargifyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateChargifyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingDateSyncOption' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createChargifyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'billingDateSyncEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'billingDateSyncEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'billingDateSyncOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'billingDateSyncOption' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargifyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChargifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncOption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateChargifyMutation, CreateChargifyMutationVariables>;
export const CreateChargifyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateChargifyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargifyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChargifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncOption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateChargifyAfterUpdateQuery,
  CreateChargifyAfterUpdateQueryVariables
>;
export const CreateCratejoyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCratejoyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCratejoyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skipFutureSubscriptions' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skipFutureSubscriptions' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CratejoyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CratejoyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CratejoyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CratejoyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCratejoyMutation, CreateCratejoyMutationVariables>;
export const CreateCratejoyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateCratejoyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CratejoyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CratejoyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CratejoyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CratejoyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCratejoyAfterUpdateQuery,
  CreateCratejoyAfterUpdateQueryVariables
>;
export const CreateDiscogsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDiscogsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDiscogsChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscogsFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscogsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscogsChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DiscogsChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDiscogsMutation, CreateDiscogsMutationVariables>;
export const CreateDiscogsAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateDiscogsAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscogsFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscogsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscogsChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DiscogsChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDiscogsAfterUpdateQuery, CreateDiscogsAfterUpdateQueryVariables>;
export const CreateFakeshopMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFakeshopMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderGroups' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFakeshopChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderGroups' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FakeshopFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FakeshopFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FakeshopChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FakeshopChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFakeshopMutation, CreateFakeshopMutationVariables>;
export const CreateFakeshopAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateFakeshopAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FakeshopFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FakeshopFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FakeshopChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FakeshopChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFakeshopAfterUpdateQuery,
  CreateFakeshopAfterUpdateQueryVariables
>;
export const CreateMoonclerkMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMoonclerkMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMoonclerkChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MoonclerkFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoonclerkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MoonclerkChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MoonclerkChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMoonclerkMutation, CreateMoonclerkMutationVariables>;
export const CreateMoonclerkAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateMoonclerkAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MoonclerkFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoonclerkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MoonclerkChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MoonclerkChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMoonclerkAfterUpdateQuery,
  CreateMoonclerkAfterUpdateQueryVariables
>;
export const CreateSquarespaceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSquarespaceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSquarespaceChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SquarespaceFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SquarespaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SquarespaceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SquarespaceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSquarespaceMutation, CreateSquarespaceMutationVariables>;
export const CreateSquarespaceAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateSquarespaceAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespaceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SquarespaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SquarespaceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SquarespaceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSquarespaceAfterUpdateQuery,
  CreateSquarespaceAfterUpdateQueryVariables
>;
export const CreateSubblyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSubblyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSubblyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubblyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubblyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubblyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SubblyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSubblyMutation, CreateSubblyMutationVariables>;
export const CreateSubblyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateSubblyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubblyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubblyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubblyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SubblyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSubblyAfterUpdateQuery, CreateSubblyAfterUpdateQueryVariables>;
export const UpdateBigcartelMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBigcartelMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBigcartelChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BigcartelFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcartelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcartelChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcartelChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBigcartelMutation, UpdateBigcartelMutationVariables>;
export const UpdateBigcartelAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateBigcartelAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BigcartelFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcartelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcartelChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcartelChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBigcartelAfterUpdateQuery,
  UpdateBigcartelAfterUpdateQueryVariables
>;
export const UpdateBigcommerceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBigcommerceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBigcommerceChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'apiUrl' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'apiUrl' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BigcommerceFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBigcommerceMutation, UpdateBigcommerceMutationVariables>;
export const UpdateBigcommerceAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateBigcommerceAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BigcommerceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBigcommerceAfterUpdateQuery,
  UpdateBigcommerceAfterUpdateQueryVariables
>;
export const UpdateChargifyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChargifyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingDateSyncOption' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateChargifyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'billingDateSyncEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'billingDateSyncEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'billingDateSyncOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'billingDateSyncOption' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargifyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChargifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncOption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateChargifyMutation, UpdateChargifyMutationVariables>;
export const UpdateChargifyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateChargifyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargifyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChargifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncOption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChargifyAfterUpdateQuery,
  UpdateChargifyAfterUpdateQueryVariables
>;
export const UpdateCratejoyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCratejoyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCratejoyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'skipFutureSubscriptions' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skipFutureSubscriptions' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CratejoyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CratejoyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CratejoyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CratejoyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCratejoyMutation, UpdateCratejoyMutationVariables>;
export const UpdateCratejoyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateCratejoyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CratejoyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CratejoyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CratejoyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CratejoyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCratejoyAfterUpdateQuery,
  UpdateCratejoyAfterUpdateQueryVariables
>;
export const UpdateDiscogsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDiscogsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDiscogsChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscogsFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscogsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscogsChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DiscogsChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDiscogsMutation, UpdateDiscogsMutationVariables>;
export const UpdateDiscogsAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateDiscogsAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscogsFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscogsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscogsChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DiscogsChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDiscogsAfterUpdateQuery, UpdateDiscogsAfterUpdateQueryVariables>;
export const UpdateEbayMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEbayMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEbayChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EbayFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EbayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EbayChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EbayChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEbayMutation, UpdateEbayMutationVariables>;
export const UpdateEbayAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateEbayAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EbayFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EbayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EbayChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EbayChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEbayAfterUpdateQuery, UpdateEbayAfterUpdateQueryVariables>;
export const UpdateEcwidMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEcwidMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEcwidChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EcwidFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EcwidFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EcwidChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EcwidChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customPaymentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEcwidMutation, UpdateEcwidMutationVariables>;
export const UpdateEcwidAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateEcwidAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EcwidFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EcwidFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EcwidChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EcwidChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customPaymentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEcwidAfterUpdateQuery, UpdateEcwidAfterUpdateQueryVariables>;
export const UpdateEtsyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEtsyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEtsyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EtsyFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EtsyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EtsyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EtsyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEtsyMutation, UpdateEtsyMutationVariables>;
export const UpdateEtsyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateEtsyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EtsyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EtsyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EtsyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EtsyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEtsyAfterUpdateQuery, UpdateEtsyAfterUpdateQueryVariables>;
export const UpdateFakeshopMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFakeshopMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderGroups' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFakeshopChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderGroups' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FakeshopFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FakeshopFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FakeshopChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FakeshopChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFakeshopMutation, UpdateFakeshopMutationVariables>;
export const UpdateFakeshopAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateFakeshopAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FakeshopFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FakeshopFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FakeshopChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FakeshopChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFakeshopAfterUpdateQuery,
  UpdateFakeshopAfterUpdateQueryVariables
>;
export const UpdateMoonclerkMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMoonclerkMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMoonclerkChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MoonclerkFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoonclerkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MoonclerkChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MoonclerkChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMoonclerkMutation, UpdateMoonclerkMutationVariables>;
export const UpdateMoonclerkAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateMoonclerkAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MoonclerkFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoonclerkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MoonclerkChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MoonclerkChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMoonclerkAfterUpdateQuery,
  UpdateMoonclerkAfterUpdateQueryVariables
>;
export const UpdatePaypalMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaypalMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'genericLineItemEnabled' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaypalChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'genericLineItemEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'genericLineItemEnabled' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaypalFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaypalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaypalChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PaypalChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'genericLineItemEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaypalMutation, UpdatePaypalMutationVariables>;
export const UpdatePayPalAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePayPalAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaypalFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaypalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaypalChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PaypalChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'genericLineItemEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePayPalAfterUpdateQuery, UpdatePayPalAfterUpdateQueryVariables>;
export const UpdateShopifyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateShopifyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationsToImport' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'importOrdersNewerThan' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShopifyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'locationsToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locationsToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'importOrdersNewerThan' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'importOrdersNewerThan' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopifyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'override' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ShopifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'importOrdersNewerThan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locationsToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentLocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateShopifyMutation, UpdateShopifyMutationVariables>;
export const UpdateShopifyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateShopifyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopifyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'override' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ShopifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'importOrdersNewerThan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locationsToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentLocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateShopifyAfterUpdateQuery, UpdateShopifyAfterUpdateQueryVariables>;
export const UpdateSquarespaceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSquarespaceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSquarespaceChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SquarespaceFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SquarespaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SquarespaceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SquarespaceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSquarespaceMutation, UpdateSquarespaceMutationVariables>;
export const UpdateSquarespaceAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateSquarespaceAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespaceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SquarespaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SquarespaceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SquarespaceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSquarespaceAfterUpdateQuery,
  UpdateSquarespaceAfterUpdateQueryVariables
>;
export const UpdateSubblyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubblyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubblyChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubblyFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubblyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubblyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SubblyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSubblyMutation, UpdateSubblyMutationVariables>;
export const UpdateSubblyAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateSubblyAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubblyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubblyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubblyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SubblyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSubblyAfterUpdateQuery, UpdateSubblyAfterUpdateQueryVariables>;
export const UpdateWoocommerceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWoocommerceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopUrl' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusesToImport' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWoocommerceChannel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channelId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'channelId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopUrl' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shopUrl' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'statusesToImport' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statusesToImport' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingEmailsEnabled' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autoSyncEnabled' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WoocommerceFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoocommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WoocommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'WoocommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateWoocommerceMutation, UpdateWoocommerceMutationVariables>;
export const UpdateWoocommerceAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateWoocommerceAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WoocommerceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoocommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WoocommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'WoocommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWoocommerceAfterUpdateQuery,
  UpdateWoocommerceAfterUpdateQueryVariables
>;
export const RatePickupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RatePickupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pickupRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratePickup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pickupRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pickupRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'basePrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'surcharges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RatePickupMutation, RatePickupMutationVariables>;
export const RefundBatchtMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefundBatchtMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefundBatchtMutation, RefundBatchtMutationVariables>;
export const RefundCreditFromPaymentSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefundCreditFromPaymentSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refundCreditAmount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundCreditFromPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refundCreditAmount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refundCreditAmount' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefundCreditFromPaymentSourceMutation,
  RefundCreditFromPaymentSourceMutationVariables
>;
export const RefundShipmentMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefundShipmentMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canRefund' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundBatch' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentStatusSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'refundableCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundShipment' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stageDots' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefundShipmentMutation, RefundShipmentMutationVariables>;
export const RememberLoginMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RememberLoginMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rememberLogin' } }],
      },
    },
  ],
} as unknown as DocumentNode<RememberLoginMutation, RememberLoginMutationVariables>;
export const RequestPickupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestPickupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pickupRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequestInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'totalCharge' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPickup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pickupRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pickupRequest' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'totalCharge' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'totalCharge' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PickupFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PickupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseReadyTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseCloseTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialInstructions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestPickupMutation, RequestPickupMutationVariables>;
export const UpdatePickupsAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdatePickupsAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pickups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PickupFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PickupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseReadyTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseCloseTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialInstructions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePickupsAfterCreateQuery, UpdatePickupsAfterCreateQueryVariables>;
export const RerateBatchMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RerateBatchMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rerateBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDatePossibleNow' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RerateBatchMutation, RerateBatchMutationVariables>;
export const ResetPasswordMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPasswordMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resetToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resetToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resetToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendConfirmationMailMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendConfirmationMailMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendConfirmationMail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dkimHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dkimTextValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dkimVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainCnameValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainVerified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendConfirmationMailMutation, SendConfirmationMailMutationVariables>;
export const SendPreViewEmailMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendPreViewEmailMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendPreViewEmailMutation, SendPreViewEmailMutationVariables>;
export const SendResetPasswordLinkMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendResetPasswordLinkMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPasswordLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>;
export const SendUserEmailVerificationLinkMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendUserEmailVerificationLinkMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sendUserEmailVerificationLink' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendUserEmailVerificationLinkMutation,
  SendUserEmailVerificationLinkMutationVariables
>;
export const SetBatchToNewMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetBatchToNewMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setBatchToNew' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetBatchToNewMutation, SetBatchToNewMutationVariables>;
export const SetDefaultPaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDefaultPaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetDefaultPaymentSourceMutation,
  SetDefaultPaymentSourceMutationVariables
>;
export const UpdateSettingsAfterDefaultPaymentSourceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateSettingsAfterDefaultPaymentSourceQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSettingsAfterDefaultPaymentSourceQuery,
  UpdateSettingsAfterDefaultPaymentSourceQueryVariables
>;
export const SetDefaultWarehouseMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDefaultWarehouseMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultWarehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetDefaultWarehouseMutation, SetDefaultWarehouseMutationVariables>;
export const UpdateSettingsAfterDefaultWarehouseQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateSettingsAfterDefaultWarehouseQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSettingsAfterDefaultWarehouseQuery,
  UpdateSettingsAfterDefaultWarehouseQueryVariables
>;
export const SignupMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignupMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activelyAcceptedTerms' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cp' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OriginAddressInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'returnAddress' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaResponse' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activelyAcceptedTerms' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activelyAcceptedTerms' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cp' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cp' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'returnAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'returnAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaResponse' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaResponse' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrentUser' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'debuggingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlatforms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignupMutation, SignupMutationVariables>;
export const TriggerChannelSyncMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TriggerChannelSyncMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerChannelSync' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'counter' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerChannelSyncMutation, TriggerChannelSyncMutationVariables>;
export const UnignoreOrdersMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnignoreOrdersMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unignoreOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ids' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnignoreOrdersMutation, UnignoreOrdersMutationVariables>;
export const UpdateAccountDetailsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountDetailsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timezone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables>;
export const UpdateUserAfterAccountDetailsUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateUserAfterAccountDetailsUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserAfterAccountDetailsUpdateQuery,
  UpdateUserAfterAccountDetailsUpdateQueryVariables
>;
export const UpdateBatchTitleMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBatchTitleMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBatchTitle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBatchTitleMutation, UpdateBatchTitleMutationVariables>;
export const UpdateBillingAddressMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBillingAddressMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillingAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAddress' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBillingAddressMutation, UpdateBillingAddressMutationVariables>;
export const UpdateBlockedCountriesMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBlockedCountriesMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'carrierKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CarrierKey' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'blockedCountries' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlockedCountryInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBlockedCountries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'carrierKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'carrierKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'blockedCountries' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'blockedCountries' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'twoCharIsoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blockReason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBlockedCountriesMutation,
  UpdateBlockedCountriesMutationVariables
>;
export const UpdateChallengeActionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChallengeActionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeKey' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeAction' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newAction' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeAction' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateChallengeAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newAction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newAction' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateChallengeActionMutation, UpdateChallengeActionMutationVariables>;
export const UpdateChargeAmountMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChargeAmountMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateChargeAmount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateChargeAmountMutation, UpdateChargeAmountMutationVariables>;
export const UpdateChargeAmountAfterCreateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateChargeAmountAfterCreateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultChargeAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChargeAmountAfterCreateQuery,
  UpdateChargeAmountAfterCreateQueryVariables
>;
export const UpdateDataGridUserSettingsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDataGridUserSettingsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gridId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridSettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDataGridUserSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gridId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'gridId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataGridUserSettingsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataGridUserSettingsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataGridSettings' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'columns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'columnOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sorting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDataGridUserSettingsMutation,
  UpdateDataGridUserSettingsMutationVariables
>;
export const UpdateDefaultTrackingEmailsDelayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDefaultTrackingEmailsDelay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inAdvance' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDefaultTrackingEmailsDelay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inAdvance' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inAdvance' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDefaultTrackingEmailsDelayMutation,
  UpdateDefaultTrackingEmailsDelayMutationVariables
>;
export const UpdateDefaultTrackingEmailsEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDefaultTrackingEmailsEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDefaultTrackingEmailsEnabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enabled' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDefaultTrackingEmailsEnabledMutation,
  UpdateDefaultTrackingEmailsEnabledMutationVariables
>;
export const UpdateDefaultTrackingEmailsEnabledUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateDefaultTrackingEmailsEnabledUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultTrackingEmailsEnabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDefaultTrackingEmailsEnabledUpdateQuery,
  UpdateDefaultTrackingEmailsEnabledUpdateQueryVariables
>;
export const UpdateEmailNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmailNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledDateTime' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmailNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scheduledDateTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledDateTime' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyRecipientsDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailNotificationTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmailNotificationMutation,
  UpdateEmailNotificationMutationVariables
>;
export const UpdateGeneralSettingsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGeneralSettingsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentLabelSize' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentLabelFileFormat' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'packingListTemplate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentSpreadsheetFormat' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressCorrectionEnabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGeneralSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentLabelSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentLabelSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'packingListTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'packingListTemplate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentSpreadsheetFormat' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentSpreadsheetFormat' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentLabelFileFormat' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentLabelFileFormat' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addressCorrectionEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addressCorrectionEnabled' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGeneralSettingsMutation, UpdateGeneralSettingsMutationVariables>;
export const UpdateCompanySettingsAfterGeneralSettingsUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateCompanySettingsAfterGeneralSettingsUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packingListTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentSpreadsheetFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelFileFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressCorrectionEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanySettingsAfterGeneralSettingsUpdateQuery,
  UpdateCompanySettingsAfterGeneralSettingsUpdateQueryVariables
>;
export const UpdateMailTemplateMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMailTemplateMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'asDefault' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'senderEmail' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateHtml' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bccEmail' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendTemplatePreview' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMailTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'asDefault' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'asDefault' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderEmail' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'senderEmail' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subject' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateHtml' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateHtml' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bccEmail' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bccEmail' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendTemplatePreview' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendTemplatePreview' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailTemplateFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>;
export const UpdateSettingsAfterUpdateMailTemplateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateSettingsAfterUpdateMailTemplateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultEmailTemplateId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MailTemplateFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSettingsAfterUpdateMailTemplateQuery,
  UpdateSettingsAfterUpdateMailTemplateQueryVariables
>;
export const UpdateNotificationsSettingsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationsSettingsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'carrierAdjustmentsNotificationEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentReceiptsNotificationEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentFailuresNotificationEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newLoginNotificationEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'carrierAdjustmentDisputesEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newFeaturesAnnouncementsNotificationEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationsSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'carrierAdjustmentsNotificationEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'carrierAdjustmentsNotificationEnabled' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentReceiptsNotificationEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentReceiptsNotificationEnabled' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentFailuresNotificationEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentFailuresNotificationEnabled' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newLoginNotificationEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newLoginNotificationEnabled' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'carrierAdjustmentDisputesEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'carrierAdjustmentDisputesEnabled' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newFeaturesAnnouncementsNotificationEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newFeaturesAnnouncementsNotificationEnabled' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrierAdjustmentsNotificationEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentReceiptsNotificationEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentFailuresNotificationEnabled' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newLoginNotificationEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrierAdjustmentDisputesEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newFeaturesAnnouncementsNotificationEnabled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationsSettingsMutation,
  UpdateNotificationsSettingsMutationVariables
>;
export const UpdateRateGroupSortOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRateGroupSortOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RateGroupSortOrder' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRateGroupSortOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortOrder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateGroupSortOrder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRateGroupSortOrderMutation,
  UpdateRateGroupSortOrderMutationVariables
>;
export const UpdateSenderEmailMetadataMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSenderEmailMetadataMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSenderEmailMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'senderName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SenderEmailMetadataFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SenderEmailMetadata' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimHost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimTextValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dkimVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainCnameValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainVerified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSenderEmailMetadataMutation,
  UpdateSenderEmailMetadataMutationVariables
>;
export const UpdateShipmentPresetMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateShipmentPresetMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'default' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPreset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPresetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShipmentPreset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'default' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'default' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentPreset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentPreset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPreset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateShipmentPresetMutation, UpdateShipmentPresetMutationVariables>;
export const UpdateShipmentPresetsAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateShipmentPresetsAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPresets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultShipmentPresetId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateShipmentPresetsAfterUpdateQuery,
  UpdateShipmentPresetsAfterUpdateQueryVariables
>;
export const UpdateWarehouseMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWarehouseMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'returnAddress' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddressInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useOriginAsReturnAddress' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'useOriginAsReturnAddress' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'returnAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'returnAddress' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>;
export const UpdateWarehousesAfterUpdateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateWarehousesAfterUpdateQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehousesAfterUpdateQuery,
  UpdateWarehousesAfterUpdateQueryVariables
>;
export const UploadShipmentFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadShipmentFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadShipmentFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestedMapping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFriendlyShipmentKey' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadShipmentFileMutation, UploadShipmentFileMutationVariables>;
export const VerifyAchPaymentSourceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyAchPaymentSourceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amountInCents1' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amountInCents2' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAchPaymentSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentSourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentSourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amountInCents1' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amountInCents1' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amountInCents2' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amountInCents2' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentSourceFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyAchPaymentSourceMutation,
  VerifyAchPaymentSourceMutationVariables
>;
export const AccountSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountSettingsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timezones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ianaName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationsSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrierAdjustmentsNotificationEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentReceiptsNotificationEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentFailuresNotificationEnabled' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newLoginNotificationEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrierAdjustmentDisputesEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newFeaturesAnnouncementsNotificationEnabled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountSettingsQuery, AccountSettingsQueryVariables>;
export const ApplicationInformationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicationInformationQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applicationHostname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationEnvironment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationInformationQuery, ApplicationInformationQueryVariables>;
export const AutoRefundedShipmentsSummaryQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoRefundedShipmentsSummaryQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoRefundedShipmentsSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AutoRefundedShipmentsSummaryQuery,
  AutoRefundedShipmentsSummaryQueryVariables
>;
export const AverageCostChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AverageCostChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageCostChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AverageCostChartQuery, AverageCostChartQueryVariables>;
export const BatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Batch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepText' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RunningProcessFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDatePossibleNow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shy' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AddressFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returnAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AddressFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'numShipments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelFileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spreadsheetMapping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minWeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxInsured' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minInsured' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionsSummary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageTypeSummary' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryConfirmationSummary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packagePreset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PackageFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customsFormSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numCustomsForms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemSummary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exporterTaxIdSummary' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentStatusSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberStamps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFriendlyShipmentKey' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailNotificationPossible' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'foreignOrderId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderLink' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shipmentLink' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscriptionLink' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'platform' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isReturnLabel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AddressFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rateGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumShipments' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfCheapestOrBestServices' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultShipDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupKey' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'string' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'traits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'layer' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'intercomLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'affectedByUpsRateLimit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rateSummaries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RateSummaryFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'availableShipDates' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'upsellRateSummary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carrier' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'carrierKey' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstMailClass' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'mailClassKey' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mailClassTitle' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateGroupSortOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelFileFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packingListTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentSpreadsheetFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressCorrectionEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultTrackingEmailsDelay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultTrackingEmailsEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultChargeAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdMerchantAccounts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasUspsMerchantAccountError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uspsMerchantAccountStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upsMerchantAccountStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activePaymentMethods' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentApiMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentApiMethodFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RunningProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Batch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runningProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsInProgressCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsTotalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progressPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progressTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondsLeft' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PackageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentStatusSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundPendingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inTransitCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToShipCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToPrintCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RateSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RateSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstMailClass' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packageType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailClassTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageBasePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageTotalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'best' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cheapest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossedTotalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fastest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodEndDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'surcharges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'surchargeKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'helpLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crossedPrice' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWeightOz' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentApiMethodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentApiMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchQuery, BatchQueryVariables>;
export const LatestBatchesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestBatchesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestBatches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numShipments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'statusText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'runningProcess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'progressPercentage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'printed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateScanform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrierLogoClasses' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentStatusSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNr' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentStatusSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundPendingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inTransitCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToShipCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToPrintCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LatestBatchesQuery, LatestBatchesQueryVariables>;
export const BatchDebugQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BatchDebugQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batchDebug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchDebugQuery, BatchDebugQueryVariables>;
export const BatchIdFromShipmentQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BatchIdFromShipmentQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchIdFromShipmentQuery, BatchIdFromShipmentQueryVariables>;
export const BatchProcessStatusQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BatchProcessStatusQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RunningProcessFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundBatch' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RunningProcessFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Batch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runningProcess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsInProgressCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsTotalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progressPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progressTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondsLeft' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchProcessStatusQuery, BatchProcessStatusQueryVariables>;
export const BatchShipmentIdsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BatchShipmentIdsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchShipmentIdsQuery, BatchShipmentIdsQueryVariables>;
export const BatchStepQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BatchStepQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchStepQuery, BatchStepQueryVariables>;
export const BatchUploadFormQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BatchUploadFormQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customsFormRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsCodeRequired' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upload' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPresets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchUploadFormQuery, BatchUploadFormQueryVariables>;
export const BillingAddressQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillingAddressQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingAddressQuery, BillingAddressQueryVariables>;
export const BillingPageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BillingPageQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'chargeAmountOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultDestinationCountryCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultChargeAmount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'activePaymentMethods' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentApiMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentApiMethodFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentApiMethodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentApiMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingPageQuery, BillingPageQueryVariables>;
export const BoughtBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BoughtBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'statusText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelFileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canRefund' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundBatch' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateScanform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shy' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AddressFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returnAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AddressFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'numShipments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataSource' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spreadsheetMapping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxWeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minWeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxInsured' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minInsured' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionsSummary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageTypeSummary' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryConfirmationSummary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packagePreset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PackageFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customsFormSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'numCustomsForms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemSummary' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exporterTaxIdSummary' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentStatusSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberStamps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFriendlyShipmentKey' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cleanupComplete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cleanupOriginal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifyRecipientsDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailNotificationTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailNotificationPossible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailNotificationSent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'averagePrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrierKeys' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surcharge' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pirateShipService' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'samePriceForAll' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usedReturnPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isReturnLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'returnsUsed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRefunded' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'foreignOrderId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'foreignOrderId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderLink' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shipmentLink' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscriptionLink' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'platform' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'additionalTrackingEmailCopy' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'duplicateShipmentLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrierTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionXOriginal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionYOriginal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionZOriginal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'passthroughFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightOriginal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disputableAdjustmentsCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adjustments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dimensionXOriginal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dimensionYOriginal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dimensionZOriginal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disputedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disputeStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disputeReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eligibilityIssueMessage' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'processedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weightOriginal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stageDotsStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stageDots' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryEstimation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'arrivalLocalDateTime' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'pickupLocalDateTime' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseClientPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseClientPriceOriginal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalClientPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalClientPriceOriginal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canDisplayPrices' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageTypeOriginal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'packageType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mailClassOriginal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mailClass' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zoneOriginal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryIdOriginal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'surcharges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isIgnored' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'clientPriceOriginal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isOriginalSurcharge' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'helpLink' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalRefundNotice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuredValueRequested' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceClaimUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShipmentRefundable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundShipment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRedacted' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingDataGrid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rowActions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PackageFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentStatusSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentStatusSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundPendingCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inTransitCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToShipCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readyToPrintCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveredCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BoughtBatchQuery, BoughtBatchQueryVariables>;
export const BraintreeClientTokenQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BraintreeClientTokenQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'braintreeClientToken' } }],
      },
    },
  ],
} as unknown as DocumentNode<BraintreeClientTokenQuery, BraintreeClientTokenQueryVariables>;
export const CarrierAdjustmentsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CarrierAdjustmentsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'carrierAdjustments' } }],
      },
    },
  ],
} as unknown as DocumentNode<CarrierAdjustmentsQuery, CarrierAdjustmentsQueryVariables>;
export const ChannelsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChannelsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ChannelInterface' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ChannelFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'reconnectUrl' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelsQuery, ChannelsQueryVariables>;
export const ChannelSyncStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChannelSyncStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelSyncStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autosyncRequired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'progress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRunning' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChannelSyncStatusQuery, ChannelSyncStatusQueryVariables>;
export const ChargeAmountOptionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChargeAmountOptionsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'chargeAmountOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultChargeAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChargeAmountOptionsQuery, ChargeAmountOptionsQueryVariables>;
export const CompanySavingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanySavingsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companySavings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanySavingsQuery, CompanySavingsQueryVariables>;
export const CountriesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountriesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentCountryId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountriesQuery, CountriesQueryVariables>;
export const BlockedCountriesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BlockedCountriesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'carrierKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CarrierKey' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blockedCountries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'carrierKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'carrierKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'twoCharIsoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blockReason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockedCountriesQuery, BlockedCountriesQueryVariables>;
export const CountryDistributionChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountryDistributionChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryDistributionChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'countryDistributions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountryDistributionChartQuery, CountryDistributionChartQueryVariables>;
export const DataGridExportStatusQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DataGridExportStatusQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingDataExportUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'exportId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expire' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataGridExportStatusQuery, DataGridExportStatusQueryVariables>;
export const DownloadLabelByShipmentQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadLabelByShipmentQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageLayout' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadLabelByShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageLayout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageLayout' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundShipment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShipmentRefundable' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadLabelByShipmentQuery, DownloadLabelByShipmentQueryVariables>;
export const DownloadLabelsByBatchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadLabelsByBatchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageLayout' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadLabelsByBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageLayout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageLayout' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundBatch' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canRefund' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusText' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'printed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isShipmentRefundable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canInstantRefundShipment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadLabelsByBatchQuery, DownloadLabelsByBatchQueryVariables>;
export const DownloadLabelsByShareTokenQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadLabelsByShareTokenQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageLayout' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadLabelsByShareToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shareToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shareToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageLayout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageLayout' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scanform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isScanformCreated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canCreateScanform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scanformUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadLabelsByShareTokenQuery,
  DownloadLabelsByShareTokenQueryVariables
>;
export const DownloadLabelsByShipmentsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DownloadLabelsByShipmentsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageLayout' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadLabelsByShipments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageLayout' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageLayout' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labelSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageLayout' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canInstantRefundShipment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'printed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canPrint' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isShipmentRefundable' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadLabelsByShipmentsQuery,
  DownloadLabelsByShipmentsQueryVariables
>;
export const FailedShipmentsExportQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FailedShipmentsExportQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failedShipmentsExportStatus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'exportId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FailedShipmentsExportQuery, FailedShipmentsExportQueryVariables>;
export const GeneralSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeneralSettingsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelFileFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packingListTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentSpreadsheetFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressCorrectionEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeneralSettingsQuery, GeneralSettingsQueryVariables>;
export const LatestTermsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestTermsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestTerms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LatestTermsQuery, LatestTermsQueryVariables>;
export const GlobalSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GlobalSettingsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalSettingsQuery, GlobalSettingsQueryVariables>;
export const DefaultEmailResponseTimeOptionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DefaultEmailResponseTimeOptionsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultEmailResponseTimeOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DefaultEmailResponseTimeOptionsQuery,
  DefaultEmailResponseTimeOptionsQueryVariables
>;
export const ImportedOrdersBaseQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImportedOrdersBaseQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gridMode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GridMode' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importedOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gridMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'gridMode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metaData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportedOrdersBaseQuery, ImportedOrdersBaseQueryVariables>;
export const IntercomArticleQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IntercomArticleQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'intercomArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'url' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IntercomArticleQuery, IntercomArticleQueryVariables>;
export const IsEuQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsEuQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'isEu' } }],
      },
    },
  ],
} as unknown as DocumentNode<IsEuQuery, IsEuQueryVariables>;
export const MailTemplateQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MailTemplateQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailTemplates' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MailTemplateFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MailTemplateQuery, MailTemplateQueryVariables>;
export const MailTemplatesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MailTemplatesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultTrackingEmailsDelay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultTrackingEmailsEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultEmailTemplateId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MailTemplateFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailTemplateFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'senderName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bccEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateHtml' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MailTemplatesQuery, MailTemplatesQueryVariables>;
export const DefaultTrackingEmailDelayQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DefaultTrackingEmailDelayQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultTrackingEmailsDelay' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DefaultTrackingEmailDelayQuery,
  DefaultTrackingEmailDelayQueryVariables
>;
export const MapQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MapQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'width' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'height' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'map' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'width' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'width' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'height' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'height' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MapQuery, MapQueryVariables>;
export const NumbersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NumbersQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierAdjustmentsAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrierAdjustmentsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'payments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNumbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returns' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refunds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentsTotalAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'netLabelCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netLabelTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netLabelAverage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netLabelAverageDom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'netLabelAverageInt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NumbersQuery, NumbersQueryVariables>;
export const PackageTypesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PackageTypesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionsRequired' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PackageTypesQuery, PackageTypesQueryVariables>;
export const PaymentChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaymentChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentChartQuery, PaymentChartQueryVariables>;
export const PaymentModalQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaymentModalQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'chargeAmountOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activePaymentMethods' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentApiMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentApiMethodFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentApiMethodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentApiMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentModalQuery, PaymentModalQueryVariables>;
export const PaymentReceiptsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaymentReceiptsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'paymentReceipts' } }],
      },
    },
  ],
} as unknown as DocumentNode<PaymentReceiptsQuery, PaymentReceiptsQueryVariables>;
export const PickupQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PickupQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdMerchantAccounts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultChargeAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activePaymentMethods' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pickups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PickupFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentApiMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentApiMethodFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PickupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseReadyTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseCloseTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialInstructions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentApiMethodFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentApiMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PickupQuery, PickupQueryVariables>;
export const PickupCreationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PickupCreationQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activePaymentMethods' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdMerchantAccounts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasUspsMerchantAccountError' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uspsMerchantAccountStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PaymentSourceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultChargeAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentSourceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailClasses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'topLevelOnly' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serviceLevel' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowedPickupDates' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentApiMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apiTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSourceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentSource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expMonth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resultMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundableAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasConsented' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PickupCreationQuery, PickupCreationQueryVariables>;
export const PickupsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PickupsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pickups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PickupFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PickupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PickupRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oneTimePickupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseReadyTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseCloseTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageWeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'confirmationNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPackageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageLocation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialInstructions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PickupsQuery, PickupsQueryVariables>;
export const BigcartelQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BigcartelQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BigcartelFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcartelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcartelChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcartelChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BigcartelQuery, BigcartelQueryVariables>;
export const BigcommerceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BigcommerceQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BigcommerceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BigcommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BigcommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'BigcommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BigcommerceQuery, BigcommerceQueryVariables>;
export const ChargifyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChargifyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargifyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChargifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingDateSyncOption' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChargifyQuery, ChargifyQueryVariables>;
export const CratejoyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CratejoyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CratejoyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CratejoyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CratejoyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CratejoyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'skipFutureSubscriptions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CratejoyQuery, CratejoyQueryVariables>;
export const DiscogsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DiscogsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscogsFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscogsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscogsChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DiscogsChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscogsQuery, DiscogsQueryVariables>;
export const EbayQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EbayQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EbayFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EbayFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EbayChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EbayChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EbayQuery, EbayQueryVariables>;
export const EcwidQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EcwidQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EcwidFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EcwidFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EcwidChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EcwidChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatusesToImport' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customPaymentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFulfillmentStatuses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EcwidQuery, EcwidQueryVariables>;
export const EtsyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EtsyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EtsyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EtsyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EtsyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EtsyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EtsyQuery, EtsyQueryVariables>;
export const FakeshopQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FakeshopQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FakeshopFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FakeshopFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FakeshopChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FakeshopChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FakeshopQuery, FakeshopQueryVariables>;
export const MoonclerkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MoonclerkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MoonclerkFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MoonclerkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MoonclerkChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MoonclerkChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preventDuplicateShippingOption' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoonclerkQuery, MoonclerkQueryVariables>;
export const PaypalQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaypalQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaypalFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaypalFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaypalChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PaypalChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'genericLineItemEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaypalQuery, PaypalQueryVariables>;
export const ShopifyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopifyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopifyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopifyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopifyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'platform' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BasicPlatformFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappableFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rubberstampConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'override' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ShopifyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'importLineItemsNotRequireShippingEnabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'importOrdersNewerThan' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'locationsToImport' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentLocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopifyQuery, ShopifyQueryVariables>;
export const SquarespaceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SquarespaceQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespaceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SquarespaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SquarespaceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SquarespaceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SquarespaceQuery, SquarespaceQueryVariables>;
export const SubblyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubblyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubblyFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubblyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubblyChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SubblyChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubblyQuery, SubblyQueryVariables>;
export const WoocommerceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WoocommerceQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WoocommerceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChannelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platform' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoSyncEnabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MappingFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappableFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rubberstampConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rubberstamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configuredField' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WoocommerceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WoocommerceChannel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChannelInterface' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChannelFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MappingFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'WoocommerceChannel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statusesToImport' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailsEnabled' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WoocommerceQuery, WoocommerceQueryVariables>;
export const PlatformsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlatformsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platforms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'installUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reconnectSupported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingEmailSupported' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlatformsQuery, PlatformsQueryVariables>;
export const PrintPreviewQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PrintPreviewQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreateScanform' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scanformUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numShipments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentStatusSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'errorCount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrintPreviewQuery, PrintPreviewQueryVariables>;
export const PrintSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PrintSettingsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentLabelFileFormat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packingListTemplate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrintSettingsQuery, PrintSettingsQueryVariables>;
export const GetRateGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRateGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rateGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupKey' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'string' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'traits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'layer' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'intercomLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumShipments' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfCheapestOrBestServices' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rateSummaries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RateSummaryFields' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'upsellRateSummary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carrier' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'carrierKey' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstMailClass' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'mailClassKey' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'serviceTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateGroupSortOrder' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RateSummaryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RateSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firstMailClass' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packageType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailClassTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageBasePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageTotalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'best' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cheapest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossedTotalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fastest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodEndDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'savings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'surcharges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'surchargeKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'helpLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crossedPrice' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstZone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWeightOz' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRateGroupsQuery, GetRateGroupsQueryVariables>;
export const GetRatesConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRatesConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionsRequired' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeCarriers' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultDestinationCountryCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentBoundaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maxWeight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxCombinedLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxLengthPlusGirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minLongSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxLongSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMiddleSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMiddleSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minShortSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxShortSide' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentCountryId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRatesConfigQuery, GetRatesConfigQueryVariables>;
export const GetRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originZip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originCity' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'originRegionCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationZip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isResidential' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationCountryCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'weight' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionX' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionY' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionZ' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailClassKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'packageTypeKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pricingTypes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showUpsRatesWhen2x7Selected' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originZip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originZip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originCity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originCity' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originRegionCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'originRegionCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationZip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'destinationZip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isResidential' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isResidential' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationCountryCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destinationCountryCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'weight' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'weight' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionX' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionX' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionY' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionY' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionZ' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensionZ' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailClassKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailClassKeys' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'packageTypeKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'packageTypeKeys' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pricingTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pricingTypes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showUpsRatesWhen2x7Selected' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showUpsRatesWhen2x7Selected' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serviceDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cubicTier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailClass' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accuracy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'international' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'surcharges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'carrierKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceBaseTypeKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basePrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crossedTotalPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingSubType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ratePeriodId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'learnMoreUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cheapest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fastest' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRatesQuery, GetRatesQueryVariables>;
export const ReceiptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Receipt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receipt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'chargeError' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refundTotal' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'batchId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reconciliationRunId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'transactionType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reversals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newPaymentStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coveringAccountingTransaction' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentSuccessful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCharged' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReceiptQuery, ReceiptQueryVariables>;
export const RefundsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefundsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'refunds' } }],
      },
    },
  ],
} as unknown as DocumentNode<RefundsQuery, RefundsQueryVariables>;
export const RegionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegionsQuery, RegionsQueryVariables>;
export const ReportsPageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsPageQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountBalance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsPageQuery, ReportsPageQueryVariables>;
export const ReturnsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReturnsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'returns' } }],
      },
    },
  ],
} as unknown as DocumentNode<ReturnsQuery, ReturnsQueryVariables>;
export const ScanformsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ScanformsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'withScanFormsAvailable' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScanformsQuery, ScanformsQueryVariables>;
export const SenderEmailMetadataQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SenderEmailMetadataQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'senderEmailMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'verificationStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dkimHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dkimTextValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dkimVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainCnameValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnPathDomainVerified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SenderEmailMetadataQuery, SenderEmailMetadataQueryVariables>;
export const ShareDownloadUrlByBatchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShareDownloadUrlByBatchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareDownloadUrlByBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'batchId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShareDownloadUrlByBatchQuery, ShareDownloadUrlByBatchQueryVariables>;
export const ShareDownloadUrlByShipmentQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShareDownloadUrlByShipmentQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareDownloadUrlByShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShareDownloadUrlByShipmentQuery,
  ShareDownloadUrlByShipmentQueryVariables
>;
export const ShipmentBoundariesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentBoundariesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentBoundaries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'maxWeight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxCombinedLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxLengthPlusGirth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minLongSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxLongSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMiddleSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMiddleSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minShortSide' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxShortSide' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentBoundariesQuery, ShipmentBoundariesQueryVariables>;
export const ShipmentChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentChartQuery, ShipmentChartQueryVariables>;
export const ShipmentDebugQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentDebugQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentDebug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shipmentId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentDebugQuery, ShipmentDebugQueryVariables>;
export const ShipmentPieChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentPieChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentPieChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentPieChartQuery, ShipmentPieChartQueryVariables>;
export const ShipmentPresetQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentPresetQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPresets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultShipmentPresetId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentPresetQuery, ShipmentPresetQueryVariables>;
export const ShipmentPresetsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentPresetsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'packageTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weightRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dimensionsRequired' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultShipmentPresetId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentPresets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentPresetFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentPresetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShipmentPreset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packageTypeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dimensionZ' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryConfirmationFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'irregularPackage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insuredValueFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnLabelFlag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsFormEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsSigner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customsContentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internationalTaxId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customsItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hsTariffNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCodeOfOrigin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'qualifiesAsMediaMail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hazardousMaterialsEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentPresetsQuery, ShipmentPresetsQueryVariables>;
export const ShipmentTrackingNumbersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShipmentTrackingNumbersQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentTrackingNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentsTransit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentsDelivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentsNew' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentsDeliveryIssue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentTrackingNumbersQuery, ShipmentTrackingNumbersQueryVariables>;
export const StateDistributionChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StateDistributionChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stateDistributionChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stateDistributions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StateDistributionChartQuery, StateDistributionChartQueryVariables>;
export const SuggestedMappingQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'suggestedMappingQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestedMapping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userFriendlyShipmentKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valueOrMappingKey' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SuggestedMappingQuery, SuggestedMappingQueryVariables>;
export const SupportSearchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SupportSearchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'term' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'term' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'term' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupportSearchQuery, SupportSearchQueryVariables>;
export const TransactionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransactionsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'transactions' } }],
      },
    },
  ],
} as unknown as DocumentNode<TransactionsQuery, TransactionsQueryVariables>;
export const UpsPickupPrefillQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpsPickupPrefillQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pickupSummary' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'carrierKey' },
                      value: { kind: 'EnumValue', value: 'ups' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mailClassCounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mailClass' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'mailClassKey' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipmentCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overweightShipmentIncluded' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsPickupPrefillQuery, UpsPickupPrefillQueryVariables>;
export const UserQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrentUser' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailVerified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'challenges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasicPlatformFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Platform' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrentUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'debuggingEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activePlatforms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BasicPlatformFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UspsPickupPrefillQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UspsPickupPrefillQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pickupSummary' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'carrierKey' },
                      value: { kind: 'EnumValue', value: 'usps' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overweightShipmentIncluded' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWeightOz' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalWeightLbs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UspsPickupPrefillQuery, UspsPickupPrefillQueryVariables>;
export const WarehouseQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WarehouseQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarehouseQuery, WarehouseQueryVariables>;
export const WarehousesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WarehousesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'defaultWarehouseId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WarehouseFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AddressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BasicAddressInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Warehouse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useOriginAsReturnAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'returnAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AddressFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarehousesQuery, WarehousesQueryVariables>;
export const ZoneChartQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZoneChartQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DatetimeRangeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoneChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datetimeRange' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'datetimeRange' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'charts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZoneChartQuery, ZoneChartQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddressInterface: ['Address', 'Shipment'],
    BasicAddressInterface: ['Address', 'BasicAddress', 'RecipientAddress', 'Shipment'],
    ChannelInterface: [
      'BigcartelChannel',
      'BigcommerceChannel',
      'Channel',
      'ChargifyChannel',
      'CratejoyChannel',
      'DiscogsChannel',
      'EbayChannel',
      'EcwidChannel',
      'EtsyChannel',
      'FakeshopChannel',
      'MoonclerkChannel',
      'PaypalChannel',
      'ShopifyChannel',
      'SquarespaceChannel',
      'SubblyChannel',
      'WoocommerceChannel',
    ],
    ChannelUnion: [
      'BigcartelChannel',
      'BigcommerceChannel',
      'ChargifyChannel',
      'CratejoyChannel',
      'DiscogsChannel',
      'EbayChannel',
      'EcwidChannel',
      'EtsyChannel',
      'FakeshopChannel',
      'MoonclerkChannel',
      'PaypalChannel',
      'ShopifyChannel',
      'SquarespaceChannel',
      'SubblyChannel',
      'WoocommerceChannel',
    ],
    EcwidOrderStatus: ['EcwidOrderStatusImpl'],
  },
};
export default result;

export type AccountingTransactionKeySpecifier = (
  | 'amount'
  | 'balance'
  | 'batchId'
  | 'carrierId'
  | 'companyId'
  | 'createdAt'
  | 'identifier'
  | 'isMoneyTransferred'
  | 'isProfit'
  | 'isTopLevel'
  | 'moreLink'
  | 'note'
  | 'paymentId'
  | 'pickupRequestId'
  | 'reconciliationAdjustmentId'
  | 'reconciliationRunId'
  | 'referenceId'
  | 'shipmentId'
  | 'timestamp'
  | 'title'
  | 'transactionCount'
  | 'transactionType'
  | 'updatedAt'
  | 'userId'
  | AccountingTransactionKeySpecifier
)[];
export type AccountingTransactionFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierId?: FieldPolicy<any> | FieldReadFunction<any>;
  companyId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  identifier?: FieldPolicy<any> | FieldReadFunction<any>;
  isMoneyTransferred?: FieldPolicy<any> | FieldReadFunction<any>;
  isProfit?: FieldPolicy<any> | FieldReadFunction<any>;
  isTopLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  moreLink?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  pickupRequestId?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliationAdjustmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliationRunId?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionCount?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionType?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'addressType'
  | 'city'
  | 'company'
  | 'countryCode'
  | 'fullName'
  | 'id'
  | 'location'
  | 'origin'
  | 'originalId'
  | 'phone'
  | 'postcode'
  | 'regionCode'
  | 'residential'
  | 'timeZone'
  | 'valid'
  | 'validationError'
  | 'warehouse'
  | 'zip4'
  | AddressKeySpecifier
)[];
export type AddressFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  addressType?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  origin?: FieldPolicy<any> | FieldReadFunction<any>;
  originalId?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  residential?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  validationError?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  zip4?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressAutocompleteSettingsKeySpecifier = (
  | 'addressProvider'
  | 'companyEnabled'
  | 'emailEnabled'
  | 'fullNameEnabled'
  | 'googleApiKey'
  | 'googlePlacesSessionRequestsEnabled'
  | 'streetEnabled'
  | 'zipcodeEnabled'
  | AddressAutocompleteSettingsKeySpecifier
)[];
export type AddressAutocompleteSettingsFieldPolicy = {
  addressProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  companyEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  emailEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  fullNameEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  googleApiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  googlePlacesSessionRequestsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  streetEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  zipcodeEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressInterfaceKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'addressType'
  | 'city'
  | 'company'
  | 'countryCode'
  | 'fullName'
  | 'id'
  | 'location'
  | 'origin'
  | 'originalId'
  | 'phone'
  | 'postcode'
  | 'regionCode'
  | 'residential'
  | 'timeZone'
  | 'valid'
  | 'validationError'
  | 'warehouse'
  | 'zip4'
  | AddressInterfaceKeySpecifier
)[];
export type AddressInterfaceFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  addressType?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  origin?: FieldPolicy<any> | FieldReadFunction<any>;
  originalId?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  residential?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  validationError?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  zip4?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssociatedCompanyKeySpecifier = ('address' | 'title' | AssociatedCompanyKeySpecifier)[];
export type AssociatedCompanyFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicAddressKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'addressType'
  | 'city'
  | 'company'
  | 'countryCode'
  | 'fullName'
  | 'phone'
  | 'postcode'
  | 'regionCode'
  | 'residential'
  | 'timeZone'
  | 'valid'
  | 'zip4'
  | BasicAddressKeySpecifier
)[];
export type BasicAddressFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  addressType?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  residential?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  zip4?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BasicAddressInterfaceKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'addressType'
  | 'city'
  | 'company'
  | 'countryCode'
  | 'fullName'
  | 'phone'
  | 'postcode'
  | 'regionCode'
  | 'residential'
  | 'timeZone'
  | 'valid'
  | 'zip4'
  | BasicAddressInterfaceKeySpecifier
)[];
export type BasicAddressInterfaceFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  addressType?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  residential?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  zip4?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchKeySpecifier = (
  | 'canCreateScanform'
  | 'canInstantRefundBatch'
  | 'canPrint'
  | 'canRefund'
  | 'carrierLogoClasses'
  | 'cleanupComplete'
  | 'cleanupOriginal'
  | 'createdAt'
  | 'customsFormRequired'
  | 'customsFormSummary'
  | 'dataSource'
  | 'defaultShipDate'
  | 'deleted'
  | 'emailNotificationPossible'
  | 'emailNotificationSent'
  | 'emailNotificationStatus'
  | 'emailNotificationTemplate'
  | 'failedShipmentsExportStatus'
  | 'hsCodeRequired'
  | 'id'
  | 'labelFileFormat'
  | 'labelSize'
  | 'notifyRecipientsDate'
  | 'numShipments'
  | 'originAddress'
  | 'packageSummary'
  | 'percentageDelivered'
  | 'priceSummary'
  | 'printed'
  | 'rateGroups'
  | 'refundSuccessMessages'
  | 'returnAddress'
  | 'rubberStamps'
  | 'runningProcess'
  | 'scanformCreated'
  | 'scanformId'
  | 'scanformUrl'
  | 'shipDate'
  | 'shipDatePossibleNow'
  | 'shipmentStatusSummary'
  | 'shipments'
  | 'spreadsheetMapping'
  | 'status'
  | 'statusText'
  | 'step'
  | 'stepText'
  | 'title'
  | 'trackingDataGrid'
  | 'upload'
  | 'warehouse'
  | BatchKeySpecifier
)[];
export type BatchFieldPolicy = {
  canCreateScanform?: FieldPolicy<any> | FieldReadFunction<any>;
  canInstantRefundBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  canPrint?: FieldPolicy<any> | FieldReadFunction<any>;
  canRefund?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierLogoClasses?: FieldPolicy<any> | FieldReadFunction<any>;
  cleanupComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  cleanupOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customsFormRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  customsFormSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  dataSource?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultShipDate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleted?: FieldPolicy<any> | FieldReadFunction<any>;
  emailNotificationPossible?: FieldPolicy<any> | FieldReadFunction<any>;
  emailNotificationSent?: FieldPolicy<any> | FieldReadFunction<any>;
  emailNotificationStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  emailNotificationTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  failedShipmentsExportStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  hsCodeRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  labelFileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  labelSize?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyRecipientsDate?: FieldPolicy<any> | FieldReadFunction<any>;
  numShipments?: FieldPolicy<any> | FieldReadFunction<any>;
  originAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  packageSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  percentageDelivered?: FieldPolicy<any> | FieldReadFunction<any>;
  priceSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  printed?: FieldPolicy<any> | FieldReadFunction<any>;
  rateGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  refundSuccessMessages?: FieldPolicy<any> | FieldReadFunction<any>;
  returnAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberStamps?: FieldPolicy<any> | FieldReadFunction<any>;
  runningProcess?: FieldPolicy<any> | FieldReadFunction<any>;
  scanformCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  scanformId?: FieldPolicy<any> | FieldReadFunction<any>;
  scanformUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  shipDate?: FieldPolicy<any> | FieldReadFunction<any>;
  shipDatePossibleNow?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentStatusSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  shipments?: FieldPolicy<any> | FieldReadFunction<any>;
  spreadsheetMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusText?: FieldPolicy<any> | FieldReadFunction<any>;
  step?: FieldPolicy<any> | FieldReadFunction<any>;
  stepText?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingDataGrid?: FieldPolicy<any> | FieldReadFunction<any>;
  upload?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouse?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchCustomsFormSummaryKeySpecifier = (
  | 'contentType'
  | 'exporterTaxIdSummary'
  | 'itemSummary'
  | 'numCustomsForms'
  | 'signer'
  | 'title'
  | BatchCustomsFormSummaryKeySpecifier
)[];
export type BatchCustomsFormSummaryFieldPolicy = {
  contentType?: FieldPolicy<any> | FieldReadFunction<any>;
  exporterTaxIdSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  itemSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  numCustomsForms?: FieldPolicy<any> | FieldReadFunction<any>;
  signer?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchPackageSummaryKeySpecifier = (
  | 'deliveryConfirmationSummary'
  | 'dimensionsSummary'
  | 'maxInsured'
  | 'maxWeight'
  | 'minInsured'
  | 'minWeight'
  | 'packagePreset'
  | 'packageTypeSummary'
  | BatchPackageSummaryKeySpecifier
)[];
export type BatchPackageSummaryFieldPolicy = {
  deliveryConfirmationSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionsSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  maxInsured?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWeight?: FieldPolicy<any> | FieldReadFunction<any>;
  minInsured?: FieldPolicy<any> | FieldReadFunction<any>;
  minWeight?: FieldPolicy<any> | FieldReadFunction<any>;
  packagePreset?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeSummary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchPriceSummaryKeySpecifier = (
  | 'averagePrice'
  | 'carrierKeys'
  | 'isRefunded'
  | 'isReturnLabel'
  | 'pirateShipService'
  | 'returnsUsed'
  | 'samePriceForAll'
  | 'shipmentCount'
  | 'surcharge'
  | 'title'
  | 'totalPrice'
  | 'usedReturnPrice'
  | BatchPriceSummaryKeySpecifier
)[];
export type BatchPriceSummaryFieldPolicy = {
  averagePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  isRefunded?: FieldPolicy<any> | FieldReadFunction<any>;
  isReturnLabel?: FieldPolicy<any> | FieldReadFunction<any>;
  pirateShipService?: FieldPolicy<any> | FieldReadFunction<any>;
  returnsUsed?: FieldPolicy<any> | FieldReadFunction<any>;
  samePriceForAll?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentCount?: FieldPolicy<any> | FieldReadFunction<any>;
  surcharge?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  usedReturnPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BatchProcessKeySpecifier = (
  | 'itemsInProgressCount'
  | 'itemsTotalCount'
  | 'key'
  | 'processKey'
  | 'progressPercentage'
  | 'progressTitle'
  | 'secondsLeft'
  | 'status'
  | BatchProcessKeySpecifier
)[];
export type BatchProcessFieldPolicy = {
  itemsInProgressCount?: FieldPolicy<any> | FieldReadFunction<any>;
  itemsTotalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  processKey?: FieldPolicy<any> | FieldReadFunction<any>;
  progressPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  progressTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  secondsLeft?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BigcartelChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'plan'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | BigcartelChannelKeySpecifier
)[];
export type BigcartelChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  plan?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BigcommerceChannelKeySpecifier = (
  | 'active'
  | 'apiUrl'
  | 'autoSyncEnabled'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | BigcommerceChannelKeySpecifier
)[];
export type BigcommerceChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockedCountryKeySpecifier = (
  | 'blockReason'
  | 'twoCharIsoCode'
  | BlockedCountryKeySpecifier
)[];
export type BlockedCountryFieldPolicy = {
  blockReason?: FieldPolicy<any> | FieldReadFunction<any>;
  twoCharIsoCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CarrierKeySpecifier = (
  | 'allowedPickupDateCutOff'
  | 'allowedPickupDates'
  | 'carrierKey'
  | 'createdAt'
  | 'id'
  | 'mailClasses'
  | 'packageTypes'
  | 'title'
  | 'updatedAt'
  | CarrierKeySpecifier
)[];
export type CarrierFieldPolicy = {
  allowedPickupDateCutOff?: FieldPolicy<any> | FieldReadFunction<any>;
  allowedPickupDates?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierKey?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClasses?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CarrierMailClassKeySpecifier = (
  | 'accuracy'
  | 'averageDeliveryDays'
  | 'carrierTitle'
  | 'createdAt'
  | 'groupKey'
  | 'id'
  | 'international'
  | 'mailClassKey'
  | 'serviceLevel'
  | 'title'
  | 'updatedAt'
  | CarrierMailClassKeySpecifier
)[];
export type CarrierMailClassFieldPolicy = {
  accuracy?: FieldPolicy<any> | FieldReadFunction<any>;
  averageDeliveryDays?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  groupKey?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  international?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClassKey?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CarrierPackageTypeKeySpecifier = (
  | 'carrierId'
  | 'description'
  | 'dimensionsRequired'
  | 'heightRequired'
  | 'id'
  | 'packageTypeKey'
  | 'title'
  | 'weightRequired'
  | CarrierPackageTypeKeySpecifier
)[];
export type CarrierPackageTypeFieldPolicy = {
  carrierId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionsRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  heightRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  weightRequired?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChallengeKeySpecifier = (
  | 'action'
  | 'createdAt'
  | 'id'
  | 'key'
  | ChallengeKeySpecifier
)[];
export type ChallengeFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'title'
  | 'updatedAt'
  | 'valid'
  | ChannelKeySpecifier
)[];
export type ChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChannelInterfaceKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'title'
  | 'updatedAt'
  | 'valid'
  | ChannelInterfaceKeySpecifier
)[];
export type ChannelInterfaceFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChannelRubberstampConfigKeySpecifier = (
  | 'configuredField'
  | 'rubberstamp'
  | ChannelRubberstampConfigKeySpecifier
)[];
export type ChannelRubberstampConfigFieldPolicy = {
  configuredField?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChannelSyncKeySpecifier = (
  | 'date'
  | 'info'
  | 'message'
  | 'status'
  | ChannelSyncKeySpecifier
)[];
export type ChannelSyncFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChargifyChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'billingDateSyncEnabled'
  | 'billingDateSyncOption'
  | 'clientId'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'preventDuplicateShippingEnabled'
  | 'preventDuplicateShippingOption'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'skipFutureSubscriptions'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | ChargifyChannelKeySpecifier
)[];
export type ChargifyChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  billingDateSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  billingDateSyncOption?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  preventDuplicateShippingEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  preventDuplicateShippingOption?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  skipFutureSubscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChartKeySpecifier = ('charts' | 'colors' | 'labels' | ChartKeySpecifier)[];
export type ChartFieldPolicy = {
  charts?: FieldPolicy<any> | FieldReadFunction<any>;
  colors?: FieldPolicy<any> | FieldReadFunction<any>;
  labels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyKeySpecifier = (
  | 'accountBalance'
  | 'activeCarriers'
  | 'activePaymentMethods'
  | 'address'
  | 'channels'
  | 'createdAt'
  | 'createdMerchantAccounts'
  | 'debuggingEnabled'
  | 'email'
  | 'features'
  | 'fraudScore'
  | 'fraudStatus'
  | 'hasUspsMerchantAccountError'
  | 'id'
  | 'mailTemplates'
  | 'paymentSources'
  | 'phone'
  | 'pickupSummary'
  | 'pickups'
  | 'settings'
  | 'shipmentPresets'
  | 'shipmentSummary'
  | 'title'
  | 'updatedAt'
  | 'upsMerchantAccountStatus'
  | 'uspsMerchantAccountStatus'
  | 'warehouses'
  | 'zoneReportType'
  | CompanyKeySpecifier
)[];
export type CompanyFieldPolicy = {
  accountBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  activeCarriers?: FieldPolicy<any> | FieldReadFunction<any>;
  activePaymentMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  channels?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdMerchantAccounts?: FieldPolicy<any> | FieldReadFunction<any>;
  debuggingEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  features?: FieldPolicy<any> | FieldReadFunction<any>;
  fraudScore?: FieldPolicy<any> | FieldReadFunction<any>;
  fraudStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  hasUspsMerchantAccountError?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mailTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSources?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  pickupSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  pickups?: FieldPolicy<any> | FieldReadFunction<any>;
  settings?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentPresets?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  upsMerchantAccountStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  uspsMerchantAccountStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouses?: FieldPolicy<any> | FieldReadFunction<any>;
  zoneReportType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanySettingsKeySpecifier = (
  | 'addressCorrectionEnabled'
  | 'defaultChargeAmount'
  | 'defaultDestinationCountryCode'
  | 'defaultEmailTemplateId'
  | 'defaultPaymentSourceId'
  | 'defaultShipmentPresetId'
  | 'defaultTrackingEmailsDelay'
  | 'defaultTrackingEmailsEnabled'
  | 'defaultWarehouseId'
  | 'packingListTemplate'
  | 'shipmentLabelFileFormat'
  | 'shipmentLabelFormat'
  | 'shipmentLabelSize'
  | 'shipmentSpreadsheetFormat'
  | CompanySettingsKeySpecifier
)[];
export type CompanySettingsFieldPolicy = {
  addressCorrectionEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultChargeAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultDestinationCountryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultEmailTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultPaymentSourceId?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultShipmentPresetId?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultTrackingEmailsDelay?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultTrackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultWarehouseId?: FieldPolicy<any> | FieldReadFunction<any>;
  packingListTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentLabelFileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentLabelFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentLabelSize?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentSpreadsheetFormat?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountryKeySpecifier = (
  | 'countryCode'
  | 'id'
  | 'isoAlpha2Code'
  | 'isoAlpha3Code'
  | 'isoNumericCode'
  | 'name'
  | 'parentCountryId'
  | 'postcodeRequired'
  | CountryKeySpecifier
)[];
export type CountryFieldPolicy = {
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isoAlpha2Code?: FieldPolicy<any> | FieldReadFunction<any>;
  isoAlpha3Code?: FieldPolicy<any> | FieldReadFunction<any>;
  isoNumericCode?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  parentCountryId?: FieldPolicy<any> | FieldReadFunction<any>;
  postcodeRequired?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountryDistributionChartKeySpecifier = (
  | 'countryDistributions'
  | 'totalCount'
  | CountryDistributionChartKeySpecifier
)[];
export type CountryDistributionChartFieldPolicy = {
  countryDistributions?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CratejoyChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'clientId'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'skipFutureSubscriptions'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | CratejoyChannelKeySpecifier
)[];
export type CratejoyChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  clientId?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  skipFutureSubscriptions?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreatePaymentSourceResponseKeySpecifier = (
  | 'isDefault'
  | 'paymentSource'
  | CreatePaymentSourceResponseKeySpecifier
)[];
export type CreatePaymentSourceResponseFieldPolicy = {
  isDefault?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreatePaymentSourceResultKeySpecifier = (
  | 'default'
  | 'paymentSource'
  | CreatePaymentSourceResultKeySpecifier
)[];
export type CreatePaymentSourceResultFieldPolicy = {
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateShipmentPresetResultKeySpecifier = (
  | 'default'
  | 'shipmentPreset'
  | CreateShipmentPresetResultKeySpecifier
)[];
export type CreateShipmentPresetResultFieldPolicy = {
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentPreset?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateUpsMerchantAccountResultKeySpecifier = (
  | 'error'
  | 'errorCategory'
  | 'status'
  | CreateUpsMerchantAccountResultKeySpecifier
)[];
export type CreateUpsMerchantAccountResultFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  errorCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateUspsMerchantAccountResultKeySpecifier = (
  | 'error'
  | 'errorCategory'
  | 'status'
  | CreateUspsMerchantAccountResultKeySpecifier
)[];
export type CreateUspsMerchantAccountResultFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  errorCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CustomOptionKeySpecifier = (
  | 'channelId'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'override'
  | 'sourceKey'
  | 'status'
  | 'updatedAt'
  | 'valueType'
  | CustomOptionKeySpecifier
)[];
export type CustomOptionFieldPolicy = {
  channelId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  override?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceKey?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valueType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CustomsItemKeySpecifier = (
  | 'countryCodeOfOrigin'
  | 'createdAt'
  | 'hsTariffNumber'
  | 'id'
  | 'itemValue'
  | 'quantity'
  | 'title'
  | 'updatedAt'
  | 'weight'
  | CustomsItemKeySpecifier
)[];
export type CustomsItemFieldPolicy = {
  countryCodeOfOrigin?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  hsTariffNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  itemValue?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  weight?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridColumnKeySpecifier = (
  | 'alternativeSortingField'
  | 'exactMatch'
  | 'field'
  | 'hidden'
  | 'label'
  | 'multiCheck'
  | 'sortable'
  | 'type'
  | 'width'
  | DataGridColumnKeySpecifier
)[];
export type DataGridColumnFieldPolicy = {
  alternativeSortingField?: FieldPolicy<any> | FieldReadFunction<any>;
  exactMatch?: FieldPolicy<any> | FieldReadFunction<any>;
  field?: FieldPolicy<any> | FieldReadFunction<any>;
  hidden?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  multiCheck?: FieldPolicy<any> | FieldReadFunction<any>;
  sortable?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  width?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridColumnOverrideKeySpecifier = (
  | 'field'
  | 'hidden'
  | 'width'
  | DataGridColumnOverrideKeySpecifier
)[];
export type DataGridColumnOverrideFieldPolicy = {
  field?: FieldPolicy<any> | FieldReadFunction<any>;
  hidden?: FieldPolicy<any> | FieldReadFunction<any>;
  width?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridExportStatusKeySpecifier = (
  | 'downloadable'
  | 'expire'
  | 'exportId'
  | 'filename'
  | 'url'
  | DataGridExportStatusKeySpecifier
)[];
export type DataGridExportStatusFieldPolicy = {
  downloadable?: FieldPolicy<any> | FieldReadFunction<any>;
  expire?: FieldPolicy<any> | FieldReadFunction<any>;
  exportId?: FieldPolicy<any> | FieldReadFunction<any>;
  filename?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridFilterKeySpecifier = (
  | 'field'
  | 'operator'
  | 'values'
  | DataGridFilterKeySpecifier
)[];
export type DataGridFilterFieldPolicy = {
  field?: FieldPolicy<any> | FieldReadFunction<any>;
  operator?: FieldPolicy<any> | FieldReadFunction<any>;
  values?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridFilterOptionKeySpecifier = (
  | 'count'
  | 'disabled'
  | 'label'
  | 'value'
  | DataGridFilterOptionKeySpecifier
)[];
export type DataGridFilterOptionFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  disabled?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridMetaDataKeySpecifier = ('key' | 'value' | DataGridMetaDataKeySpecifier)[];
export type DataGridMetaDataFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridResultKeySpecifier = (
  | 'columns'
  | 'exportAllowed'
  | 'exportUrl'
  | 'gridActions'
  | 'id'
  | 'localFiltersEnabled'
  | 'maxPageSize'
  | 'metaData'
  | 'multiCheckOptions'
  | 'rowActions'
  | 'rows'
  | 'staticFilters'
  | 'userSettings'
  | DataGridResultKeySpecifier
)[];
export type DataGridResultFieldPolicy = {
  columns?: FieldPolicy<any> | FieldReadFunction<any>;
  exportAllowed?: FieldPolicy<any> | FieldReadFunction<any>;
  exportUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  gridActions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localFiltersEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPageSize?: FieldPolicy<any> | FieldReadFunction<any>;
  metaData?: FieldPolicy<any> | FieldReadFunction<any>;
  multiCheckOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  rowActions?: FieldPolicy<any> | FieldReadFunction<any>;
  rows?: FieldPolicy<any> | FieldReadFunction<any>;
  staticFilters?: FieldPolicy<any> | FieldReadFunction<any>;
  userSettings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridResultRowsKeySpecifier = (
  | 'checkableIds'
  | 'nodes'
  | 'totalCount'
  | DataGridResultRowsKeySpecifier
)[];
export type DataGridResultRowsFieldPolicy = {
  checkableIds?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridRowActionKeySpecifier = (
  | 'fullWidth'
  | 'id'
  | 'label'
  | 'variant'
  | DataGridRowActionKeySpecifier
)[];
export type DataGridRowActionFieldPolicy = {
  fullWidth?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  variant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridSettingsKeySpecifier = (
  | 'columnOrder'
  | 'columns'
  | 'pageSize'
  | 'sorting'
  | DataGridSettingsKeySpecifier
)[];
export type DataGridSettingsFieldPolicy = {
  columnOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  columns?: FieldPolicy<any> | FieldReadFunction<any>;
  pageSize?: FieldPolicy<any> | FieldReadFunction<any>;
  sorting?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataGridSortingKeySpecifier = ('direction' | 'field' | DataGridSortingKeySpecifier)[];
export type DataGridSortingFieldPolicy = {
  direction?: FieldPolicy<any> | FieldReadFunction<any>;
  field?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DateTimeRangeKeySpecifier = (
  | 'endDatetime'
  | 'startDatetime'
  | DateTimeRangeKeySpecifier
)[];
export type DateTimeRangeFieldPolicy = {
  endDatetime?: FieldPolicy<any> | FieldReadFunction<any>;
  startDatetime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeliveryEstimationKeySpecifier = (
  | 'arrivalLocalDateTime'
  | 'pickupLocalDateTime'
  | DeliveryEstimationKeySpecifier
)[];
export type DeliveryEstimationFieldPolicy = {
  arrivalLocalDateTime?: FieldPolicy<any> | FieldReadFunction<any>;
  pickupLocalDateTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DiscogsChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | DiscogsChannelKeySpecifier
)[];
export type DiscogsChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DistributionChartItemKeySpecifier = (
  | 'count'
  | 'key'
  | 'name'
  | 'percent'
  | 'price'
  | DistributionChartItemKeySpecifier
)[];
export type DistributionChartItemFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  percent?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DownloadLabelsByBatchResultKeySpecifier = (
  | 'batch'
  | 'fileFormat'
  | 'id'
  | 'labelSize'
  | 'pageLayout'
  | 'status'
  | 'url'
  | DownloadLabelsByBatchResultKeySpecifier
)[];
export type DownloadLabelsByBatchResultFieldPolicy = {
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  fileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  labelSize?: FieldPolicy<any> | FieldReadFunction<any>;
  pageLayout?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DownloadLabelsByShareTokenResultKeySpecifier = (
  | 'fileFormat'
  | 'labelSize'
  | 'pageLayout'
  | 'scanform'
  | 'status'
  | 'url'
  | DownloadLabelsByShareTokenResultKeySpecifier
)[];
export type DownloadLabelsByShareTokenResultFieldPolicy = {
  fileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  labelSize?: FieldPolicy<any> | FieldReadFunction<any>;
  pageLayout?: FieldPolicy<any> | FieldReadFunction<any>;
  scanform?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DownloadLabelsByShipmentsResultKeySpecifier = (
  | 'fileFormat'
  | 'id'
  | 'labelSize'
  | 'pageLayout'
  | 'shipments'
  | 'status'
  | 'url'
  | DownloadLabelsByShipmentsResultKeySpecifier
)[];
export type DownloadLabelsByShipmentsResultFieldPolicy = {
  fileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  labelSize?: FieldPolicy<any> | FieldReadFunction<any>;
  pageLayout?: FieldPolicy<any> | FieldReadFunction<any>;
  shipments?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DownloadLabelsResultKeySpecifier = (
  | 'batch'
  | 'fileFormat'
  | 'id'
  | 'labelSize'
  | 'message'
  | 'pageLayout'
  | 'scanformUrl'
  | 'shipments'
  | 'status'
  | 'url'
  | DownloadLabelsResultKeySpecifier
)[];
export type DownloadLabelsResultFieldPolicy = {
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  fileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  labelSize?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  pageLayout?: FieldPolicy<any> | FieldReadFunction<any>;
  scanformUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  shipments?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EbayChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'updatedAt'
  | 'valid'
  | EbayChannelKeySpecifier
)[];
export type EbayChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EcwidChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customFulfillmentStatuses'
  | 'customFulfillmentStatusesToImport'
  | 'customOptions'
  | 'customPaymentStatuses'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'username'
  | 'valid'
  | EcwidChannelKeySpecifier
)[];
export type EcwidChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customFulfillmentStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  customFulfillmentStatusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  customPaymentStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EcwidOrderStatusKeySpecifier = ('id' | 'name' | EcwidOrderStatusKeySpecifier)[];
export type EcwidOrderStatusFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EcwidOrderStatusImplKeySpecifier = ('id' | 'name' | EcwidOrderStatusImplKeySpecifier)[];
export type EcwidOrderStatusImplFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailTemplateKeySpecifier = (
  | 'html'
  | 'plainText'
  | 'subject'
  | EmailTemplateKeySpecifier
)[];
export type EmailTemplateFieldPolicy = {
  html?: FieldPolicy<any> | FieldReadFunction<any>;
  plainText?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EtsyChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | EtsyChannelKeySpecifier
)[];
export type EtsyChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FakeshopChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'orderGroups'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'title'
  | 'updatedAt'
  | 'valid'
  | FakeshopChannelKeySpecifier
)[];
export type FakeshopChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  orderGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeatureKeySpecifier = ('key' | 'value' | FeatureKeySpecifier)[];
export type FeatureFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GlobalSettingsKeySpecifier = (
  | 'defaultEmailResponseTimeOptions'
  | 'emailTemplate'
  | GlobalSettingsKeySpecifier
)[];
export type GlobalSettingsFieldPolicy = {
  defaultEmailResponseTimeOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  emailTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IgnoreOrderResultKeySpecifier = (
  | 'count'
  | 'ids'
  | 'success'
  | IgnoreOrderResultKeySpecifier
)[];
export type IgnoreOrderResultFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  ids?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LinkKeySpecifier = ('text' | 'url' | LinkKeySpecifier)[];
export type LinkFieldPolicy = {
  text?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LocationKeySpecifier = ('latitude' | 'longitude' | LocationKeySpecifier)[];
export type LocationFieldPolicy = {
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginResultKeySpecifier = ('authToken' | 'user' | LoginResultKeySpecifier)[];
export type LoginResultFieldPolicy = {
  authToken?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MailClassCountKeySpecifier = (
  | 'mailClass'
  | 'shipmentCount'
  | MailClassCountKeySpecifier
)[];
export type MailClassCountFieldPolicy = {
  mailClass?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MailTemplateKeySpecifier = (
  | 'asDefault'
  | 'bccEmail'
  | 'companyId'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'replyToEmail'
  | 'senderEmail'
  | 'senderName'
  | 'subject'
  | 'templateHtml'
  | 'templateText'
  | 'updatedAt'
  | 'userId'
  | MailTemplateKeySpecifier
)[];
export type MailTemplateFieldPolicy = {
  asDefault?: FieldPolicy<any> | FieldReadFunction<any>;
  bccEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  companyId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  replyToEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  senderEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  senderName?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  templateHtml?: FieldPolicy<any> | FieldReadFunction<any>;
  templateText?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MappableShipmentFieldKeySpecifier = (
  | 'key'
  | 'maxMappings'
  | 'required'
  | 'title'
  | MappableShipmentFieldKeySpecifier
)[];
export type MappableShipmentFieldFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  maxMappings?: FieldPolicy<any> | FieldReadFunction<any>;
  required?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MoonclerkChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'preventDuplicateShippingEnabled'
  | 'preventDuplicateShippingOption'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'updatedAt'
  | 'valid'
  | MoonclerkChannelKeySpecifier
)[];
export type MoonclerkChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  preventDuplicateShippingEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  preventDuplicateShippingOption?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'acceptTerms'
  | 'addCreditToPaymentSource'
  | 'buyBatch'
  | 'cancelEmailNotification'
  | 'cancelPickup'
  | 'channelAutoSync'
  | 'completeBatchFromUpload'
  | 'connectWoocommerceChannel'
  | 'createAchPaymentSource'
  | 'createBatchFromUpload'
  | 'createBigcommerceChannel'
  | 'createCcPaymentSource'
  | 'createChallenge'
  | 'createChargifyChannel'
  | 'createCratejoyChannel'
  | 'createDiscogsChannel'
  | 'createFakeshopChannel'
  | 'createMailTemplate'
  | 'createMappings'
  | 'createMoonclerkChannel'
  | 'createOfflinePaymentSource'
  | 'createOrUpdateUpsMerchantAccount'
  | 'createPayPalPaymentSource'
  | 'createPlaidAccessToken'
  | 'createPlaidActivation'
  | 'createPlaidConsent'
  | 'createPlaidValidationActivation'
  | 'createScanformByBatch'
  | 'createScanformByShareToken'
  | 'createScopedShipmentPreset'
  | 'createSenderEmailMetadata'
  | 'createShipmentPreset'
  | 'createSquarespaceChannel'
  | 'createSubblyChannel'
  | 'createTestBatch'
  | 'createTestShipment'
  | 'createTestShipmentFromFixture'
  | 'createUspsMerchantAccount'
  | 'createVenmoPaymentSource'
  | 'createWarehouse'
  | 'deleteBatch'
  | 'deleteChannel'
  | 'deleteMailTemplate'
  | 'deletePaymentSource'
  | 'deleteShipmentPreset'
  | 'deleteTestBatch'
  | 'deleteWarehouse'
  | 'disableChannel'
  | 'disputeAdjustments'
  | 'enableChannel'
  | 'fetchSenderEmailMetadata'
  | 'forgetLogin'
  | 'ignoreOrders'
  | 'invalidateOtherSessions'
  | 'loginWithPassword'
  | 'loginWithRememberToken'
  | 'logout'
  | 'ratePickup'
  | 'refundBatch'
  | 'refundCreditFromPaymentSource'
  | 'refundShipment'
  | 'rememberLogin'
  | 'requestPickup'
  | 'rerateBatch'
  | 'resetPassword'
  | 'sendConfirmationMail'
  | 'sendEmail'
  | 'sendResetPasswordLink'
  | 'sendUserEmailVerificationLink'
  | 'setBatchToNew'
  | 'setDefaultPaymentSource'
  | 'setDefaultWarehouse'
  | 'setShipmentUploadMapping'
  | 'signup'
  | 'triggerChannelSync'
  | 'unignoreOrders'
  | 'updateAccountDetails'
  | 'updateBatchTitle'
  | 'updateBigcartelChannel'
  | 'updateBigcommerceChannel'
  | 'updateBillingAddress'
  | 'updateBlockedCountries'
  | 'updateChallengeAction'
  | 'updateChargeAmount'
  | 'updateChargifyChannel'
  | 'updateCratejoyChannel'
  | 'updateDataGridUserSettings'
  | 'updateDefaultTrackingEmailsDelay'
  | 'updateDefaultTrackingEmailsEnabled'
  | 'updateDiscogsChannel'
  | 'updateEbayChannel'
  | 'updateEcwidChannel'
  | 'updateEmailNotification'
  | 'updateEtsyChannel'
  | 'updateFakeshopChannel'
  | 'updateGeneralSettings'
  | 'updateMailTemplate'
  | 'updateMoonclerkChannel'
  | 'updateNotificationsSettings'
  | 'updatePaypalChannel'
  | 'updateRateGroupSortOrder'
  | 'updateSenderEmailMetadata'
  | 'updateShipmentPreset'
  | 'updateShopifyChannel'
  | 'updateSquarespaceChannel'
  | 'updateSubblyChannel'
  | 'updateWarehouse'
  | 'updateWoocommerceChannel'
  | 'uploadFile'
  | 'uploadShipmentFile'
  | 'verifyAchPaymentSource'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  acceptTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  addCreditToPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  buyBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelEmailNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelPickup?: FieldPolicy<any> | FieldReadFunction<any>;
  channelAutoSync?: FieldPolicy<any> | FieldReadFunction<any>;
  completeBatchFromUpload?: FieldPolicy<any> | FieldReadFunction<any>;
  connectWoocommerceChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createAchPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createBatchFromUpload?: FieldPolicy<any> | FieldReadFunction<any>;
  createBigcommerceChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createCcPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createChallenge?: FieldPolicy<any> | FieldReadFunction<any>;
  createChargifyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createCratejoyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createDiscogsChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createFakeshopChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createMailTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  createMappings?: FieldPolicy<any> | FieldReadFunction<any>;
  createMoonclerkChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createOfflinePaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrUpdateUpsMerchantAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  createPayPalPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createPlaidAccessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  createPlaidActivation?: FieldPolicy<any> | FieldReadFunction<any>;
  createPlaidConsent?: FieldPolicy<any> | FieldReadFunction<any>;
  createPlaidValidationActivation?: FieldPolicy<any> | FieldReadFunction<any>;
  createScanformByBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  createScanformByShareToken?: FieldPolicy<any> | FieldReadFunction<any>;
  createScopedShipmentPreset?: FieldPolicy<any> | FieldReadFunction<any>;
  createSenderEmailMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  createShipmentPreset?: FieldPolicy<any> | FieldReadFunction<any>;
  createSquarespaceChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createSubblyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  createTestBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  createTestShipment?: FieldPolicy<any> | FieldReadFunction<any>;
  createTestShipmentFromFixture?: FieldPolicy<any> | FieldReadFunction<any>;
  createUspsMerchantAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  createVenmoPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createWarehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMailTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteShipmentPreset?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTestBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteWarehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  disableChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  disputeAdjustments?: FieldPolicy<any> | FieldReadFunction<any>;
  enableChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  fetchSenderEmailMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  forgetLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  ignoreOrders?: FieldPolicy<any> | FieldReadFunction<any>;
  invalidateOtherSessions?: FieldPolicy<any> | FieldReadFunction<any>;
  loginWithPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  loginWithRememberToken?: FieldPolicy<any> | FieldReadFunction<any>;
  logout?: FieldPolicy<any> | FieldReadFunction<any>;
  ratePickup?: FieldPolicy<any> | FieldReadFunction<any>;
  refundBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  refundCreditFromPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  refundShipment?: FieldPolicy<any> | FieldReadFunction<any>;
  rememberLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  requestPickup?: FieldPolicy<any> | FieldReadFunction<any>;
  rerateBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  resetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  sendConfirmationMail?: FieldPolicy<any> | FieldReadFunction<any>;
  sendEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  sendResetPasswordLink?: FieldPolicy<any> | FieldReadFunction<any>;
  sendUserEmailVerificationLink?: FieldPolicy<any> | FieldReadFunction<any>;
  setBatchToNew?: FieldPolicy<any> | FieldReadFunction<any>;
  setDefaultPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  setDefaultWarehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  setShipmentUploadMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  signup?: FieldPolicy<any> | FieldReadFunction<any>;
  triggerChannelSync?: FieldPolicy<any> | FieldReadFunction<any>;
  unignoreOrders?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAccountDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBatchTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBigcartelChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBigcommerceChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBillingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockedCountries?: FieldPolicy<any> | FieldReadFunction<any>;
  updateChallengeAction?: FieldPolicy<any> | FieldReadFunction<any>;
  updateChargeAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  updateChargifyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCratejoyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDataGridUserSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDefaultTrackingEmailsDelay?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDefaultTrackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDiscogsChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEbayChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEcwidChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEmailNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEtsyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFakeshopChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateGeneralSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMailTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMoonclerkChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateNotificationsSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaypalChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRateGroupSortOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSenderEmailMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  updateShipmentPreset?: FieldPolicy<any> | FieldReadFunction<any>;
  updateShopifyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSquarespaceChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSubblyChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateWarehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  updateWoocommerceChannel?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadFile?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadShipmentFile?: FieldPolicy<any> | FieldReadFunction<any>;
  verifyAchPaymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotificationsSettingsKeySpecifier = (
  | 'carrierAdjustmentDisputesEnabled'
  | 'carrierAdjustmentsNotificationEnabled'
  | 'newFeaturesAnnouncementsNotificationEnabled'
  | 'newLoginNotificationEnabled'
  | 'paymentFailuresNotificationEnabled'
  | 'paymentReceiptsNotificationEnabled'
  | NotificationsSettingsKeySpecifier
)[];
export type NotificationsSettingsFieldPolicy = {
  carrierAdjustmentDisputesEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierAdjustmentsNotificationEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  newFeaturesAnnouncementsNotificationEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  newLoginNotificationEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentFailuresNotificationEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentReceiptsNotificationEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NumbersKeySpecifier = (
  | 'carrierAdjustmentsAmount'
  | 'carrierAdjustmentsCount'
  | 'netLabelAverage'
  | 'netLabelAverageDom'
  | 'netLabelAverageInt'
  | 'netLabelCount'
  | 'netLabelTotal'
  | 'payments'
  | 'pendingNumbers'
  | NumbersKeySpecifier
)[];
export type NumbersFieldPolicy = {
  carrierAdjustmentsAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierAdjustmentsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  netLabelAverage?: FieldPolicy<any> | FieldReadFunction<any>;
  netLabelAverageDom?: FieldPolicy<any> | FieldReadFunction<any>;
  netLabelAverageInt?: FieldPolicy<any> | FieldReadFunction<any>;
  netLabelCount?: FieldPolicy<any> | FieldReadFunction<any>;
  netLabelTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  payments?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingNumbers?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OptionKeySpecifier = ('title' | 'value' | OptionKeySpecifier)[];
export type OptionFieldPolicy = {
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderKeySpecifier = (
  | 'foreignOrderId'
  | 'id'
  | 'orderItems'
  | 'orderLink'
  | 'platform'
  | 'platformKey'
  | 'shipmentLink'
  | 'subscriptionLink'
  | OrderKeySpecifier
)[];
export type OrderFieldPolicy = {
  foreignOrderId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  orderItems?: FieldPolicy<any> | FieldReadFunction<any>;
  orderLink?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  platformKey?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentLink?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionLink?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderItemKeySpecifier = ('id' | 'quantity' | 'sku' | 'title' | OrderItemKeySpecifier)[];
export type OrderItemFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  sku?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageInfoKeySpecifier = (
  | 'endCursor'
  | 'hasNextPage'
  | 'hasPreviousPage'
  | 'startCursor'
  | PageInfoKeySpecifier
)[];
export type PageInfoFieldPolicy = {
  endCursor?: FieldPolicy<any> | FieldReadFunction<any>;
  hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>;
  startCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentKeySpecifier = (
  | 'apiMethodId'
  | 'authorizedAmount'
  | 'batchId'
  | 'batchRatedAmount'
  | 'chargeError'
  | 'chargebackFeeClient'
  | 'chargebackFeePirate'
  | 'chargedAmount'
  | 'companyId'
  | 'createdAt'
  | 'debtDue'
  | 'fee'
  | 'feeCompany'
  | 'feeDate'
  | 'gatewayId'
  | 'groupPaymentId'
  | 'id'
  | 'isCompleted'
  | 'moreLink'
  | 'note'
  | 'paymentSourceId'
  | 'receipt'
  | 'reconciliationRunId'
  | 'refundTotal'
  | 'status'
  | 'strategy'
  | 'title'
  | 'total'
  | 'updatedAt'
  | 'userId'
  | PaymentKeySpecifier
)[];
export type PaymentFieldPolicy = {
  apiMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  authorizedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  batchRatedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  chargeError?: FieldPolicy<any> | FieldReadFunction<any>;
  chargebackFeeClient?: FieldPolicy<any> | FieldReadFunction<any>;
  chargebackFeePirate?: FieldPolicy<any> | FieldReadFunction<any>;
  chargedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  companyId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  debtDue?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  feeCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  feeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupPaymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
  moreLink?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSourceId?: FieldPolicy<any> | FieldReadFunction<any>;
  receipt?: FieldPolicy<any> | FieldReadFunction<any>;
  reconciliationRunId?: FieldPolicy<any> | FieldReadFunction<any>;
  refundTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  strategy?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentActionKeySpecifier = (
  | 'actionType'
  | 'applicationErrorCode'
  | 'createdAt'
  | 'errorMessage'
  | 'gatewayToken'
  | 'httpErrorCode'
  | 'identifier'
  | 'newPaymentStatus'
  | 'paymentId'
  | 'remoteReferenceId'
  | 'remoteTransactionId'
  | 'success'
  | 'userId'
  | PaymentActionKeySpecifier
)[];
export type PaymentActionFieldPolicy = {
  actionType?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationErrorCode?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  gatewayToken?: FieldPolicy<any> | FieldReadFunction<any>;
  httpErrorCode?: FieldPolicy<any> | FieldReadFunction<any>;
  identifier?: FieldPolicy<any> | FieldReadFunction<any>;
  newPaymentStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  remoteReferenceId?: FieldPolicy<any> | FieldReadFunction<any>;
  remoteTransactionId?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentApiMethodKeySpecifier = (
  | 'apiKey'
  | 'apiTitle'
  | 'code'
  | 'id'
  | PaymentApiMethodKeySpecifier
)[];
export type PaymentApiMethodFieldPolicy = {
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentSourceKeySpecifier = (
  | 'bankAccountType'
  | 'billingZipcode'
  | 'billingZipcodeVerification'
  | 'brand'
  | 'countryCode'
  | 'createdAt'
  | 'email'
  | 'expMonth'
  | 'expYear'
  | 'first6'
  | 'funding'
  | 'hasConsented'
  | 'id'
  | 'last4'
  | 'paymentMethodType'
  | 'refundableAmount'
  | 'resultMessage'
  | 'shortTitle'
  | 'title'
  | 'updatedAt'
  | 'validationStatus'
  | PaymentSourceKeySpecifier
)[];
export type PaymentSourceFieldPolicy = {
  bankAccountType?: FieldPolicy<any> | FieldReadFunction<any>;
  billingZipcode?: FieldPolicy<any> | FieldReadFunction<any>;
  billingZipcodeVerification?: FieldPolicy<any> | FieldReadFunction<any>;
  brand?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  expMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  expYear?: FieldPolicy<any> | FieldReadFunction<any>;
  first6?: FieldPolicy<any> | FieldReadFunction<any>;
  funding?: FieldPolicy<any> | FieldReadFunction<any>;
  hasConsented?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  last4?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodType?: FieldPolicy<any> | FieldReadFunction<any>;
  refundableAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  resultMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  shortTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  validationStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaypalChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'genericLineItemEnabled'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | PaypalChannelKeySpecifier
)[];
export type PaypalChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  genericLineItemEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingNumbersKeySpecifier = (
  | 'paymentsTotalAmount'
  | 'refunds'
  | 'returns'
  | PendingNumbersKeySpecifier
)[];
export type PendingNumbersFieldPolicy = {
  paymentsTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  refunds?: FieldPolicy<any> | FieldReadFunction<any>;
  returns?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PickupRequestKeySpecifier = (
  | 'canCancel'
  | 'carrier'
  | 'confirmationNumber'
  | 'createdAt'
  | 'errorMessage'
  | 'foreignId'
  | 'frequency'
  | 'hasUpsOverweightPackages'
  | 'highestServiceLevel'
  | 'id'
  | 'oneTimePickupDate'
  | 'packageLocation'
  | 'previouslyRatedPrice'
  | 'rate'
  | 'recurringPickupDays'
  | 'specialInstructions'
  | 'status'
  | 'totalPackageCount'
  | 'totalPackageWeight'
  | 'updatedAt'
  | 'warehouse'
  | 'warehouseCloseTime'
  | 'warehouseReadyTime'
  | PickupRequestKeySpecifier
)[];
export type PickupRequestFieldPolicy = {
  canCancel?: FieldPolicy<any> | FieldReadFunction<any>;
  carrier?: FieldPolicy<any> | FieldReadFunction<any>;
  confirmationNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  foreignId?: FieldPolicy<any> | FieldReadFunction<any>;
  frequency?: FieldPolicy<any> | FieldReadFunction<any>;
  hasUpsOverweightPackages?: FieldPolicy<any> | FieldReadFunction<any>;
  highestServiceLevel?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  oneTimePickupDate?: FieldPolicy<any> | FieldReadFunction<any>;
  packageLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  previouslyRatedPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringPickupDays?: FieldPolicy<any> | FieldReadFunction<any>;
  specialInstructions?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPackageCount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPackageWeight?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouseCloseTime?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouseReadyTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PickupRequestRateKeySpecifier = (
  | 'basePrice'
  | 'surcharges'
  | 'totalPrice'
  | PickupRequestRateKeySpecifier
)[];
export type PickupRequestRateFieldPolicy = {
  basePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  surcharges?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PickupSurchargeKeySpecifier = (
  | 'helpLink'
  | 'id'
  | 'name'
  | 'price'
  | PickupSurchargeKeySpecifier
)[];
export type PickupSurchargeFieldPolicy = {
  helpLink?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlaidActivationKeySpecifier = (
  | 'handle'
  | 'linkToken'
  | 'mask'
  | 'name'
  | 'paymentSource'
  | 'publicToken'
  | PlaidActivationKeySpecifier
)[];
export type PlaidActivationFieldPolicy = {
  handle?: FieldPolicy<any> | FieldReadFunction<any>;
  linkToken?: FieldPolicy<any> | FieldReadFunction<any>;
  mask?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSource?: FieldPolicy<any> | FieldReadFunction<any>;
  publicToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlatformKeySpecifier = (
  | 'additionalTrackingEmailCopy'
  | 'id'
  | 'installUrl'
  | 'module'
  | 'platformKey'
  | 'reconnectSupported'
  | 'title'
  | 'trackingEmailSupported'
  | PlatformKeySpecifier
)[];
export type PlatformFieldPolicy = {
  additionalTrackingEmailCopy?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  installUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  module?: FieldPolicy<any> | FieldReadFunction<any>;
  platformKey?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectSupported?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailSupported?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlatformMappableFieldKeySpecifier = (
  | 'field'
  | 'label'
  | PlatformMappableFieldKeySpecifier
)[];
export type PlatformMappableFieldFieldPolicy = {
  field?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlatformOverrideFieldKeySpecifier = (
  | 'field'
  | 'label'
  | PlatformOverrideFieldKeySpecifier
)[];
export type PlatformOverrideFieldFieldPolicy = {
  field?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'addressAutocompleteSettings'
  | 'adminShipmentsReport'
  | 'applicationEnvironment'
  | 'applicationHostname'
  | 'autoRefundedShipmentsSummary'
  | 'averageCostChart'
  | 'batch'
  | 'batchDebug'
  | 'blockedCountries'
  | 'boughtBatchShipmentsGrid'
  | 'braintreeClientToken'
  | 'carrier'
  | 'carrierAdjustmentReport'
  | 'carrierAdjustments'
  | 'carriers'
  | 'challenges'
  | 'channel'
  | 'channelSyncStatus'
  | 'chargeAmountOptions'
  | 'clientsGrid'
  | 'company'
  | 'companySavings'
  | 'countries'
  | 'countryDistributionChart'
  | 'downloadLabelByShipment'
  | 'downloadLabelsByBatch'
  | 'downloadLabelsByShareToken'
  | 'downloadLabelsByShipments'
  | 'globalSettings'
  | 'importedOrders'
  | 'intercomArticle'
  | 'isEu'
  | 'latestBatches'
  | 'latestTerms'
  | 'mappableShipmentFields'
  | 'notificationsSettings'
  | 'numbers'
  | 'paymentApiMethods'
  | 'paymentChart'
  | 'paymentReceipts'
  | 'platforms'
  | 'rates'
  | 'receipt'
  | 'receiptsReport'
  | 'refundReport'
  | 'refunds'
  | 'regions'
  | 'returnLabelsReport'
  | 'returns'
  | 'senderEmailMetadata'
  | 'shareDownloadUrlByBatch'
  | 'shareDownloadUrlByShipment'
  | 'shipment'
  | 'shipmentBoundaries'
  | 'shipmentChart'
  | 'shipmentDebug'
  | 'shipmentPieChart'
  | 'shipmentReport'
  | 'shipmentTrackingNumbers'
  | 'shipmentUpload'
  | 'stateDistributionChart'
  | 'supportSearch'
  | 'timezones'
  | 'transactionHistory'
  | 'transactions'
  | 'user'
  | 'zoneChart'
  | 'zoneForRoute'
  | 'zoneMapForOrigin'
  | 'zoneTable'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  addressAutocompleteSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  adminShipmentsReport?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationEnvironment?: FieldPolicy<any> | FieldReadFunction<any>;
  applicationHostname?: FieldPolicy<any> | FieldReadFunction<any>;
  autoRefundedShipmentsSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  averageCostChart?: FieldPolicy<any> | FieldReadFunction<any>;
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  batchDebug?: FieldPolicy<any> | FieldReadFunction<any>;
  blockedCountries?: FieldPolicy<any> | FieldReadFunction<any>;
  boughtBatchShipmentsGrid?: FieldPolicy<any> | FieldReadFunction<any>;
  braintreeClientToken?: FieldPolicy<any> | FieldReadFunction<any>;
  carrier?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierAdjustmentReport?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierAdjustments?: FieldPolicy<any> | FieldReadFunction<any>;
  carriers?: FieldPolicy<any> | FieldReadFunction<any>;
  challenges?: FieldPolicy<any> | FieldReadFunction<any>;
  channel?: FieldPolicy<any> | FieldReadFunction<any>;
  channelSyncStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  chargeAmountOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  clientsGrid?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  companySavings?: FieldPolicy<any> | FieldReadFunction<any>;
  countries?: FieldPolicy<any> | FieldReadFunction<any>;
  countryDistributionChart?: FieldPolicy<any> | FieldReadFunction<any>;
  downloadLabelByShipment?: FieldPolicy<any> | FieldReadFunction<any>;
  downloadLabelsByBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  downloadLabelsByShareToken?: FieldPolicy<any> | FieldReadFunction<any>;
  downloadLabelsByShipments?: FieldPolicy<any> | FieldReadFunction<any>;
  globalSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  importedOrders?: FieldPolicy<any> | FieldReadFunction<any>;
  intercomArticle?: FieldPolicy<any> | FieldReadFunction<any>;
  isEu?: FieldPolicy<any> | FieldReadFunction<any>;
  latestBatches?: FieldPolicy<any> | FieldReadFunction<any>;
  latestTerms?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableShipmentFields?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationsSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  numbers?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentApiMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentChart?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentReceipts?: FieldPolicy<any> | FieldReadFunction<any>;
  platforms?: FieldPolicy<any> | FieldReadFunction<any>;
  rates?: FieldPolicy<any> | FieldReadFunction<any>;
  receipt?: FieldPolicy<any> | FieldReadFunction<any>;
  receiptsReport?: FieldPolicy<any> | FieldReadFunction<any>;
  refundReport?: FieldPolicy<any> | FieldReadFunction<any>;
  refunds?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  returnLabelsReport?: FieldPolicy<any> | FieldReadFunction<any>;
  returns?: FieldPolicy<any> | FieldReadFunction<any>;
  senderEmailMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  shareDownloadUrlByBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  shareDownloadUrlByShipment?: FieldPolicy<any> | FieldReadFunction<any>;
  shipment?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentBoundaries?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentChart?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentDebug?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentPieChart?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentReport?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentTrackingNumbers?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentUpload?: FieldPolicy<any> | FieldReadFunction<any>;
  stateDistributionChart?: FieldPolicy<any> | FieldReadFunction<any>;
  supportSearch?: FieldPolicy<any> | FieldReadFunction<any>;
  timezones?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  zoneChart?: FieldPolicy<any> | FieldReadFunction<any>;
  zoneForRoute?: FieldPolicy<any> | FieldReadFunction<any>;
  zoneMapForOrigin?: FieldPolicy<any> | FieldReadFunction<any>;
  zoneTable?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateGroupKeySpecifier = (
  | 'affectedByUpsRateLimit'
  | 'defaultShipDate'
  | 'groupId'
  | 'groupKey'
  | 'intercomLink'
  | 'label'
  | 'maximumShipments'
  | 'numberOfCheapestOrBestServices'
  | 'rateSummaries'
  | 'title'
  | RateGroupKeySpecifier
)[];
export type RateGroupFieldPolicy = {
  affectedByUpsRateLimit?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultShipDate?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupKey?: FieldPolicy<any> | FieldReadFunction<any>;
  intercomLink?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  maximumShipments?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfCheapestOrBestServices?: FieldPolicy<any> | FieldReadFunction<any>;
  rateSummaries?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateGroupKeyKeySpecifier = ('string' | 'traits' | RateGroupKeyKeySpecifier)[];
export type RateGroupKeyFieldPolicy = {
  string?: FieldPolicy<any> | FieldReadFunction<any>;
  traits?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateGroupTraitKeySpecifier = ('layer' | 'value' | RateGroupTraitKeySpecifier)[];
export type RateGroupTraitFieldPolicy = {
  layer?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateResultKeySpecifier = (
  | 'arrivalLocalTime'
  | 'basePrice'
  | 'carrier'
  | 'cheapest'
  | 'crossedTotalPrice'
  | 'cubicTier'
  | 'deliveryDescription'
  | 'fastest'
  | 'isGuaranteedDelivery'
  | 'isSaturdayDelivery'
  | 'learnMoreUrl'
  | 'mailClass'
  | 'mailClassKey'
  | 'packageTypeKey'
  | 'pickupLocalTime'
  | 'priceBaseTypeKey'
  | 'pricingDescription'
  | 'pricingSubType'
  | 'pricingType'
  | 'ratePeriodId'
  | 'serviceDescription'
  | 'surcharges'
  | 'title'
  | 'totalPrice'
  | 'trackingDescription'
  | 'zone'
  | RateResultKeySpecifier
)[];
export type RateResultFieldPolicy = {
  arrivalLocalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  basePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  carrier?: FieldPolicy<any> | FieldReadFunction<any>;
  cheapest?: FieldPolicy<any> | FieldReadFunction<any>;
  crossedTotalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  cubicTier?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  fastest?: FieldPolicy<any> | FieldReadFunction<any>;
  isGuaranteedDelivery?: FieldPolicy<any> | FieldReadFunction<any>;
  isSaturdayDelivery?: FieldPolicy<any> | FieldReadFunction<any>;
  learnMoreUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClass?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClassKey?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  pickupLocalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  priceBaseTypeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  pricingDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  pricingSubType?: FieldPolicy<any> | FieldReadFunction<any>;
  pricingType?: FieldPolicy<any> | FieldReadFunction<any>;
  ratePeriodId?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  surcharges?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  zone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateSelectionKeySpecifier = (
  | 'groupKey'
  | 'mailClassId'
  | 'packageTypeId'
  | 'saturdayDelivery'
  | RateSelectionKeySpecifier
)[];
export type RateSelectionFieldPolicy = {
  groupKey?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClassId?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  saturdayDelivery?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateSummaryKeySpecifier = (
  | 'availableShipDates'
  | 'averageBasePrice'
  | 'averageTotalPrice'
  | 'basePrice'
  | 'best'
  | 'carrier'
  | 'cheapest'
  | 'crossedTotalPrice'
  | 'deliveryDays'
  | 'errorMessage'
  | 'fastest'
  | 'firstMailClass'
  | 'firstZone'
  | 'flatPrice'
  | 'mailClassTitle'
  | 'maxWeightOz'
  | 'packageType'
  | 'ratePeriodEndDate'
  | 'ratePeriodStartDate'
  | 'savings'
  | 'serviceTitle'
  | 'shipmentCount'
  | 'surcharges'
  | 'surchargesPrice'
  | 'totalPrice'
  | 'uniqueId'
  | 'upsellRateSummary'
  | RateSummaryKeySpecifier
)[];
export type RateSummaryFieldPolicy = {
  availableShipDates?: FieldPolicy<any> | FieldReadFunction<any>;
  averageBasePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  averageTotalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  basePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  best?: FieldPolicy<any> | FieldReadFunction<any>;
  carrier?: FieldPolicy<any> | FieldReadFunction<any>;
  cheapest?: FieldPolicy<any> | FieldReadFunction<any>;
  crossedTotalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryDays?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  fastest?: FieldPolicy<any> | FieldReadFunction<any>;
  firstMailClass?: FieldPolicy<any> | FieldReadFunction<any>;
  firstZone?: FieldPolicy<any> | FieldReadFunction<any>;
  flatPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClassTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWeightOz?: FieldPolicy<any> | FieldReadFunction<any>;
  packageType?: FieldPolicy<any> | FieldReadFunction<any>;
  ratePeriodEndDate?: FieldPolicy<any> | FieldReadFunction<any>;
  ratePeriodStartDate?: FieldPolicy<any> | FieldReadFunction<any>;
  savings?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentCount?: FieldPolicy<any> | FieldReadFunction<any>;
  surcharges?: FieldPolicy<any> | FieldReadFunction<any>;
  surchargesPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueId?: FieldPolicy<any> | FieldReadFunction<any>;
  upsellRateSummary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateSurchargeKeySpecifier = (
  | 'parentSurchargeId'
  | 'price'
  | 'surchargeKey'
  | 'title'
  | RateSurchargeKeySpecifier
)[];
export type RateSurchargeFieldPolicy = {
  parentSurchargeId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  surchargeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RateSurchargeSummaryKeySpecifier = (
  | 'averagePrice'
  | 'crossedPrice'
  | 'helpLink'
  | 'price'
  | 'shipmentCount'
  | 'surchargeId'
  | 'surchargeKey'
  | 'title'
  | RateSurchargeSummaryKeySpecifier
)[];
export type RateSurchargeSummaryFieldPolicy = {
  averagePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  crossedPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  helpLink?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentCount?: FieldPolicy<any> | FieldReadFunction<any>;
  surchargeId?: FieldPolicy<any> | FieldReadFunction<any>;
  surchargeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReceiptKeySpecifier = (
  | 'billingAddress'
  | 'chargeSuccessful'
  | 'company'
  | 'endBalance'
  | 'isCharged'
  | 'payment'
  | 'paymentSuccessful'
  | 'reversals'
  | 'startBalance'
  | 'transactions'
  | ReceiptKeySpecifier
)[];
export type ReceiptFieldPolicy = {
  billingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  chargeSuccessful?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  endBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  isCharged?: FieldPolicy<any> | FieldReadFunction<any>;
  payment?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSuccessful?: FieldPolicy<any> | FieldReadFunction<any>;
  reversals?: FieldPolicy<any> | FieldReadFunction<any>;
  startBalance?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecipientAddressKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'address3'
  | 'addressType'
  | 'city'
  | 'company'
  | 'countryCode'
  | 'email'
  | 'fullName'
  | 'location'
  | 'phone'
  | 'postcode'
  | 'regionCode'
  | 'residential'
  | 'timeZone'
  | 'valid'
  | 'zip4'
  | RecipientAddressKeySpecifier
)[];
export type RecipientAddressFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  address3?: FieldPolicy<any> | FieldReadFunction<any>;
  addressType?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  residential?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  zip4?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReconciliationAdjustmentKeySpecifier = (
  | 'createdAt'
  | 'dimensionX'
  | 'dimensionXOriginal'
  | 'dimensionY'
  | 'dimensionYOriginal'
  | 'dimensionZ'
  | 'dimensionZOriginal'
  | 'disputeReason'
  | 'disputeStatus'
  | 'disputedAt'
  | 'eligibilityIssueMessage'
  | 'id'
  | 'notes'
  | 'processedAt'
  | 'weight'
  | 'weightOriginal'
  | ReconciliationAdjustmentKeySpecifier
)[];
export type ReconciliationAdjustmentFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionX?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionXOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionY?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionYOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionZ?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionZOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  disputeReason?: FieldPolicy<any> | FieldReadFunction<any>;
  disputeStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  disputedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  eligibilityIssueMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  processedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  weight?: FieldPolicy<any> | FieldReadFunction<any>;
  weightOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionKeySpecifier = (
  | 'countryCode'
  | 'id'
  | 'name'
  | 'regionCode'
  | RegionKeySpecifier
)[];
export type RegionFieldPolicy = {
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReversalTypeKeySpecifier = (
  | 'action'
  | 'amount'
  | 'coveringAccountingTransaction'
  | ReversalTypeKeySpecifier
)[];
export type ReversalTypeFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>;
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  coveringAccountingTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanformByBatchResultKeySpecifier = (
  | 'batchId'
  | 'canCreateScanform'
  | 'scanformUrl'
  | ScanformByBatchResultKeySpecifier
)[];
export type ScanformByBatchResultFieldPolicy = {
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  canCreateScanform?: FieldPolicy<any> | FieldReadFunction<any>;
  scanformUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanformByShareTokenResultKeySpecifier = (
  | 'canCreateScanform'
  | 'id'
  | 'isScanformCreated'
  | 'scanformUrl'
  | ScanformByShareTokenResultKeySpecifier
)[];
export type ScanformByShareTokenResultFieldPolicy = {
  canCreateScanform?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isScanformCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  scanformUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScanformFileKeySpecifier = (
  | 'downloadUrl'
  | 'fileType'
  | 'id'
  | ScanformFileKeySpecifier
)[];
export type ScanformFileFieldPolicy = {
  downloadUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  fileType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SelectOptionKeySpecifier = ('label' | 'value' | SelectOptionKeySpecifier)[];
export type SelectOptionFieldPolicy = {
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SenderEmailMetadataKeySpecifier = (
  | 'dkimHost'
  | 'dkimTextValue'
  | 'dkimVerified'
  | 'domain'
  | 'errorMessage'
  | 'id'
  | 'returnPathDomain'
  | 'returnPathDomainCnameValue'
  | 'returnPathDomainVerified'
  | 'senderName'
  | 'verificationStatus'
  | SenderEmailMetadataKeySpecifier
)[];
export type SenderEmailMetadataFieldPolicy = {
  dkimHost?: FieldPolicy<any> | FieldReadFunction<any>;
  dkimTextValue?: FieldPolicy<any> | FieldReadFunction<any>;
  dkimVerified?: FieldPolicy<any> | FieldReadFunction<any>;
  domain?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  returnPathDomain?: FieldPolicy<any> | FieldReadFunction<any>;
  returnPathDomainCnameValue?: FieldPolicy<any> | FieldReadFunction<any>;
  returnPathDomainVerified?: FieldPolicy<any> | FieldReadFunction<any>;
  senderName?: FieldPolicy<any> | FieldReadFunction<any>;
  verificationStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentKeySpecifier = (
  | 'additionalRefundNotice'
  | 'address1'
  | 'address2'
  | 'addressType'
  | 'adjustments'
  | 'baseClientPrice'
  | 'baseClientPriceOriginal'
  | 'batch'
  | 'canDisplayPrices'
  | 'canInstantRefundShipment'
  | 'canPrint'
  | 'carrierKey'
  | 'carrierTitle'
  | 'city'
  | 'company'
  | 'companyId'
  | 'countryCode'
  | 'countryId'
  | 'countryIdOriginal'
  | 'deliveryEstimation'
  | 'dimensionX'
  | 'dimensionXOriginal'
  | 'dimensionY'
  | 'dimensionYOriginal'
  | 'dimensionZ'
  | 'dimensionZOriginal'
  | 'disputableAdjustmentsCount'
  | 'duplicateShipmentLink'
  | 'errorMessage'
  | 'foreignOrderId'
  | 'fullName'
  | 'id'
  | 'insuranceClaimUrl'
  | 'insuredValueRequested'
  | 'isRedacted'
  | 'isReturnLabel'
  | 'isShipmentRefundable'
  | 'location'
  | 'mailClass'
  | 'mailClassKey'
  | 'mailClassOriginal'
  | 'map'
  | 'notificationEmailSent'
  | 'order'
  | 'origin'
  | 'originalId'
  | 'packageType'
  | 'packageTypeOriginal'
  | 'passthroughFields'
  | 'phone'
  | 'postcode'
  | 'printed'
  | 'recipientAddress'
  | 'refundTimeNoticeShipmentDescription'
  | 'refundTimeNoticeShipmentTitle'
  | 'regionCode'
  | 'residential'
  | 'stageDots'
  | 'stageDotsStatus'
  | 'status'
  | 'surcharges'
  | 'timeZone'
  | 'totalClientPrice'
  | 'totalClientPriceOriginal'
  | 'trackingDataExportUrl'
  | 'trackingLink'
  | 'trackingNr'
  | 'valid'
  | 'validationError'
  | 'warehouse'
  | 'weight'
  | 'weightOriginal'
  | 'zip4'
  | 'zone'
  | 'zoneOriginal'
  | ShipmentKeySpecifier
)[];
export type ShipmentFieldPolicy = {
  additionalRefundNotice?: FieldPolicy<any> | FieldReadFunction<any>;
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  addressType?: FieldPolicy<any> | FieldReadFunction<any>;
  adjustments?: FieldPolicy<any> | FieldReadFunction<any>;
  baseClientPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  baseClientPriceOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  canDisplayPrices?: FieldPolicy<any> | FieldReadFunction<any>;
  canInstantRefundShipment?: FieldPolicy<any> | FieldReadFunction<any>;
  canPrint?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierKey?: FieldPolicy<any> | FieldReadFunction<any>;
  carrierTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  companyId?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  countryIdOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryEstimation?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionX?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionXOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionY?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionYOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionZ?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionZOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  disputableAdjustmentsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  duplicateShipmentLink?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  foreignOrderId?: FieldPolicy<any> | FieldReadFunction<any>;
  fullName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  insuranceClaimUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  insuredValueRequested?: FieldPolicy<any> | FieldReadFunction<any>;
  isRedacted?: FieldPolicy<any> | FieldReadFunction<any>;
  isReturnLabel?: FieldPolicy<any> | FieldReadFunction<any>;
  isShipmentRefundable?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClass?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClassKey?: FieldPolicy<any> | FieldReadFunction<any>;
  mailClassOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  map?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationEmailSent?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  origin?: FieldPolicy<any> | FieldReadFunction<any>;
  originalId?: FieldPolicy<any> | FieldReadFunction<any>;
  packageType?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  passthroughFields?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  printed?: FieldPolicy<any> | FieldReadFunction<any>;
  recipientAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  refundTimeNoticeShipmentDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  refundTimeNoticeShipmentTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  regionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  residential?: FieldPolicy<any> | FieldReadFunction<any>;
  stageDots?: FieldPolicy<any> | FieldReadFunction<any>;
  stageDotsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  surcharges?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  totalClientPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  totalClientPriceOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingDataExportUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingLink?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingNr?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
  validationError?: FieldPolicy<any> | FieldReadFunction<any>;
  warehouse?: FieldPolicy<any> | FieldReadFunction<any>;
  weight?: FieldPolicy<any> | FieldReadFunction<any>;
  weightOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  zip4?: FieldPolicy<any> | FieldReadFunction<any>;
  zone?: FieldPolicy<any> | FieldReadFunction<any>;
  zoneOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentAutocompleteConfigKeySpecifier = (
  | 'addressBaseUrl'
  | 'addressProvider'
  | 'googleApiKey'
  | 'googlePlacesSessionRequestsEnabled'
  | ShipmentAutocompleteConfigKeySpecifier
)[];
export type ShipmentAutocompleteConfigFieldPolicy = {
  addressBaseUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  addressProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  googleApiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  googlePlacesSessionRequestsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentBoundariesKeySpecifier = (
  | 'maxCombinedLength'
  | 'maxLengthPlusGirth'
  | 'maxLongSide'
  | 'maxMiddleSide'
  | 'maxShortSide'
  | 'maxWeight'
  | 'minLongSide'
  | 'minMiddleSide'
  | 'minShortSide'
  | ShipmentBoundariesKeySpecifier
)[];
export type ShipmentBoundariesFieldPolicy = {
  maxCombinedLength?: FieldPolicy<any> | FieldReadFunction<any>;
  maxLengthPlusGirth?: FieldPolicy<any> | FieldReadFunction<any>;
  maxLongSide?: FieldPolicy<any> | FieldReadFunction<any>;
  maxMiddleSide?: FieldPolicy<any> | FieldReadFunction<any>;
  maxShortSide?: FieldPolicy<any> | FieldReadFunction<any>;
  maxWeight?: FieldPolicy<any> | FieldReadFunction<any>;
  minLongSide?: FieldPolicy<any> | FieldReadFunction<any>;
  minMiddleSide?: FieldPolicy<any> | FieldReadFunction<any>;
  minShortSide?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentMappingKeySpecifier = (
  | 'firstValue'
  | 'id'
  | 'ordering'
  | 'shipmentKey'
  | 'userFriendlyShipmentKey'
  | 'valueOrMappingKey'
  | ShipmentMappingKeySpecifier
)[];
export type ShipmentMappingFieldPolicy = {
  firstValue?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ordering?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentKey?: FieldPolicy<any> | FieldReadFunction<any>;
  userFriendlyShipmentKey?: FieldPolicy<any> | FieldReadFunction<any>;
  valueOrMappingKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentPassthroughFieldTypeKeySpecifier = (
  | 'fieldName'
  | 'value'
  | ShipmentPassthroughFieldTypeKeySpecifier
)[];
export type ShipmentPassthroughFieldTypeFieldPolicy = {
  fieldName?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentPresetKeySpecifier = (
  | 'availableReturnLabelOptions'
  | 'createdAt'
  | 'customsContentType'
  | 'customsFormEnabled'
  | 'customsItems'
  | 'customsSigner'
  | 'deliveryConfirmation'
  | 'deliveryConfirmationFlag'
  | 'deliveryConfirmationTitle'
  | 'description'
  | 'dimensionX'
  | 'dimensionY'
  | 'dimensionZ'
  | 'hazardousMaterialsEnabled'
  | 'id'
  | 'insuredValue'
  | 'insuredValueFlag'
  | 'internationalTaxId'
  | 'irregularPackage'
  | 'originalId'
  | 'packageTypeId'
  | 'packageTypeKey'
  | 'qualifiesAsMediaMail'
  | 'returnLabel'
  | 'returnLabelFlag'
  | 'shy'
  | 'title'
  | 'updatedAt'
  | 'weight'
  | ShipmentPresetKeySpecifier
)[];
export type ShipmentPresetFieldPolicy = {
  availableReturnLabelOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customsContentType?: FieldPolicy<any> | FieldReadFunction<any>;
  customsFormEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  customsItems?: FieldPolicy<any> | FieldReadFunction<any>;
  customsSigner?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryConfirmation?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryConfirmationFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryConfirmationTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionX?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionY?: FieldPolicy<any> | FieldReadFunction<any>;
  dimensionZ?: FieldPolicy<any> | FieldReadFunction<any>;
  hazardousMaterialsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  insuredValue?: FieldPolicy<any> | FieldReadFunction<any>;
  insuredValueFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  internationalTaxId?: FieldPolicy<any> | FieldReadFunction<any>;
  irregularPackage?: FieldPolicy<any> | FieldReadFunction<any>;
  originalId?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  packageTypeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  qualifiesAsMediaMail?: FieldPolicy<any> | FieldReadFunction<any>;
  returnLabel?: FieldPolicy<any> | FieldReadFunction<any>;
  returnLabelFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  shy?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  weight?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentPriceKeySpecifier = (
  | 'apiKey'
  | 'clientPrice'
  | 'clientPriceOriginal'
  | 'helpLink'
  | 'isIgnored'
  | 'isOriginalSurcharge'
  | 'title'
  | ShipmentPriceKeySpecifier
)[];
export type ShipmentPriceFieldPolicy = {
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  clientPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  clientPriceOriginal?: FieldPolicy<any> | FieldReadFunction<any>;
  helpLink?: FieldPolicy<any> | FieldReadFunction<any>;
  isIgnored?: FieldPolicy<any> | FieldReadFunction<any>;
  isOriginalSurcharge?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentStatusSummaryKeySpecifier = (
  | 'deliveredCount'
  | 'errorCount'
  | 'inTransitCount'
  | 'readyToPrintCount'
  | 'readyToShipCount'
  | 'refundPendingCount'
  | 'refundableCount'
  | 'refundedCount'
  | ShipmentStatusSummaryKeySpecifier
)[];
export type ShipmentStatusSummaryFieldPolicy = {
  deliveredCount?: FieldPolicy<any> | FieldReadFunction<any>;
  errorCount?: FieldPolicy<any> | FieldReadFunction<any>;
  inTransitCount?: FieldPolicy<any> | FieldReadFunction<any>;
  readyToPrintCount?: FieldPolicy<any> | FieldReadFunction<any>;
  readyToShipCount?: FieldPolicy<any> | FieldReadFunction<any>;
  refundPendingCount?: FieldPolicy<any> | FieldReadFunction<any>;
  refundableCount?: FieldPolicy<any> | FieldReadFunction<any>;
  refundedCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentSummaryKeySpecifier = (
  | 'mailClassCounts'
  | 'overweightShipmentIncluded'
  | 'totalCount'
  | 'totalWeightLbs'
  | 'totalWeightOz'
  | ShipmentSummaryKeySpecifier
)[];
export type ShipmentSummaryFieldPolicy = {
  mailClassCounts?: FieldPolicy<any> | FieldReadFunction<any>;
  overweightShipmentIncluded?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWeightLbs?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWeightOz?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentTrackingNumbersKeySpecifier = (
  | 'shipmentsDelivered'
  | 'shipmentsDeliveryIssue'
  | 'shipmentsNew'
  | 'shipmentsTransit'
  | ShipmentTrackingNumbersKeySpecifier
)[];
export type ShipmentTrackingNumbersFieldPolicy = {
  shipmentsDelivered?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentsDeliveryIssue?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentsNew?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentsTransit?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShipmentUploadKeySpecifier = (
  | 'batch'
  | 'errorMessage'
  | 'fileFormat'
  | 'filename'
  | 'id'
  | 'mapping'
  | 'suggestedMapping'
  | 'uploadStatus'
  | ShipmentUploadKeySpecifier
)[];
export type ShipmentUploadFieldPolicy = {
  batch?: FieldPolicy<any> | FieldReadFunction<any>;
  errorMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  fileFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  filename?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mapping?: FieldPolicy<any> | FieldReadFunction<any>;
  suggestedMapping?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShopifyChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'fulfillmentLocations'
  | 'id'
  | 'importLineItemsNotRequireShippingEnabled'
  | 'importOrdersNewerThan'
  | 'lastSync'
  | 'locationsToImport'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | ShopifyChannelKeySpecifier
)[];
export type ShopifyChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  fulfillmentLocations?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  importLineItemsNotRequireShippingEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  importOrdersNewerThan?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  locationsToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShopifyLocationKeySpecifier = ('id' | 'name' | ShopifyLocationKeySpecifier)[];
export type ShopifyLocationFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SquarespaceChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | SquarespaceChannelKeySpecifier
)[];
export type SquarespaceChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StageDotKeySpecifier = ('message' | 'status' | 'title' | StageDotKeySpecifier)[];
export type StageDotFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StateDistributionChartKeySpecifier = (
  | 'stateDistributions'
  | 'totalCount'
  | StateDistributionChartKeySpecifier
)[];
export type StateDistributionChartFieldPolicy = {
  stateDistributions?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubblyChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'clientSecret'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | SubblyChannelKeySpecifier
)[];
export type SubblyChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  clientSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SummaryKeySpecifier = ('amount' | 'count' | SummaryKeySpecifier)[];
export type SummaryFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SupportSearchResultKeySpecifier = (
  | 'id'
  | 'label'
  | 'type'
  | 'value'
  | SupportSearchResultKeySpecifier
)[];
export type SupportSearchResultFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SyncStatusResultKeySpecifier = (
  | 'autosyncRequired'
  | 'hasError'
  | 'info'
  | 'isRunning'
  | 'messages'
  | 'progress'
  | 'timestamp'
  | SyncStatusResultKeySpecifier
)[];
export type SyncStatusResultFieldPolicy = {
  autosyncRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  hasError?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  isRunning?: FieldPolicy<any> | FieldReadFunction<any>;
  messages?: FieldPolicy<any> | FieldReadFunction<any>;
  progress?: FieldPolicy<any> | FieldReadFunction<any>;
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimezoneKeySpecifier = ('description' | 'ianaName' | TimezoneKeySpecifier)[];
export type TimezoneFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  ianaName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TriggerSyncResultKeySpecifier = (
  | 'counter'
  | 'status'
  | TriggerSyncResultKeySpecifier
)[];
export type TriggerSyncResultFieldPolicy = {
  counter?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateShipmentPresetResultKeySpecifier = (
  | 'default'
  | 'shipmentPreset'
  | UpdateShipmentPresetResultKeySpecifier
)[];
export type UpdateShipmentPresetResultFieldPolicy = {
  default?: FieldPolicy<any> | FieldReadFunction<any>;
  shipmentPreset?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | 'activePlatforms'
  | 'admin'
  | 'challenges'
  | 'company'
  | 'companyId'
  | 'createdAt'
  | 'email'
  | 'emailVerified'
  | 'emulated'
  | 'emulatedInDangerMode'
  | 'firstName'
  | 'groupId'
  | 'id'
  | 'lastName'
  | 'rateGroupSortOrder'
  | 'roles'
  | 'timezone'
  | 'updatedAt'
  | 'userHash'
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  activePlatforms?: FieldPolicy<any> | FieldReadFunction<any>;
  admin?: FieldPolicy<any> | FieldReadFunction<any>;
  challenges?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  companyId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emailVerified?: FieldPolicy<any> | FieldReadFunction<any>;
  emulated?: FieldPolicy<any> | FieldReadFunction<any>;
  emulatedInDangerMode?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  rateGroupSortOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userHash?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserTermsKeySpecifier = ('id' | 'terms' | UserTermsKeySpecifier)[];
export type UserTermsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  terms?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WarehouseKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'originAddress'
  | 'originalId'
  | 'returnAddress'
  | 'shy'
  | 'title'
  | 'updatedAt'
  | 'useOriginAsReturnAddress'
  | WarehouseKeySpecifier
)[];
export type WarehouseFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  originAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  originalId?: FieldPolicy<any> | FieldReadFunction<any>;
  returnAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  shy?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  useOriginAsReturnAddress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WoocommerceChannelKeySpecifier = (
  | 'active'
  | 'autoSyncEnabled'
  | 'createdAt'
  | 'customOptions'
  | 'id'
  | 'lastSync'
  | 'mappableFields'
  | 'platform'
  | 'reconnectUrl'
  | 'rubberstampConfig'
  | 'shopUrl'
  | 'statusesToImport'
  | 'title'
  | 'trackingEmailsEnabled'
  | 'updatedAt'
  | 'valid'
  | WoocommerceChannelKeySpecifier
)[];
export type WoocommerceChannelFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  autoSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  customOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  mappableFields?: FieldPolicy<any> | FieldReadFunction<any>;
  platform?: FieldPolicy<any> | FieldReadFunction<any>;
  reconnectUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  rubberstampConfig?: FieldPolicy<any> | FieldReadFunction<any>;
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesToImport?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  trackingEmailsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  valid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ZoneChartKeySpecifier = ('charts' | 'colors' | 'labels' | ZoneChartKeySpecifier)[];
export type ZoneChartFieldPolicy = {
  charts?: FieldPolicy<any> | FieldReadFunction<any>;
  colors?: FieldPolicy<any> | FieldReadFunction<any>;
  labels?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  AccountingTransaction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AccountingTransactionKeySpecifier
      | (() => undefined | AccountingTransactionKeySpecifier);
    fields?: AccountingTransactionFieldPolicy;
  };
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AddressKeySpecifier | (() => undefined | AddressKeySpecifier);
    fields?: AddressFieldPolicy;
  };
  AddressAutocompleteSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddressAutocompleteSettingsKeySpecifier
      | (() => undefined | AddressAutocompleteSettingsKeySpecifier);
    fields?: AddressAutocompleteSettingsFieldPolicy;
  };
  AddressInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddressInterfaceKeySpecifier
      | (() => undefined | AddressInterfaceKeySpecifier);
    fields?: AddressInterfaceFieldPolicy;
  };
  AssociatedCompany?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssociatedCompanyKeySpecifier
      | (() => undefined | AssociatedCompanyKeySpecifier);
    fields?: AssociatedCompanyFieldPolicy;
  };
  BasicAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BasicAddressKeySpecifier | (() => undefined | BasicAddressKeySpecifier);
    fields?: BasicAddressFieldPolicy;
  };
  BasicAddressInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BasicAddressInterfaceKeySpecifier
      | (() => undefined | BasicAddressInterfaceKeySpecifier);
    fields?: BasicAddressInterfaceFieldPolicy;
  };
  Batch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BatchKeySpecifier | (() => undefined | BatchKeySpecifier);
    fields?: BatchFieldPolicy;
  };
  BatchCustomsFormSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BatchCustomsFormSummaryKeySpecifier
      | (() => undefined | BatchCustomsFormSummaryKeySpecifier);
    fields?: BatchCustomsFormSummaryFieldPolicy;
  };
  BatchPackageSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BatchPackageSummaryKeySpecifier
      | (() => undefined | BatchPackageSummaryKeySpecifier);
    fields?: BatchPackageSummaryFieldPolicy;
  };
  BatchPriceSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BatchPriceSummaryKeySpecifier
      | (() => undefined | BatchPriceSummaryKeySpecifier);
    fields?: BatchPriceSummaryFieldPolicy;
  };
  BatchProcess?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BatchProcessKeySpecifier | (() => undefined | BatchProcessKeySpecifier);
    fields?: BatchProcessFieldPolicy;
  };
  BigcartelChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BigcartelChannelKeySpecifier
      | (() => undefined | BigcartelChannelKeySpecifier);
    fields?: BigcartelChannelFieldPolicy;
  };
  BigcommerceChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BigcommerceChannelKeySpecifier
      | (() => undefined | BigcommerceChannelKeySpecifier);
    fields?: BigcommerceChannelFieldPolicy;
  };
  BlockedCountry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BlockedCountryKeySpecifier | (() => undefined | BlockedCountryKeySpecifier);
    fields?: BlockedCountryFieldPolicy;
  };
  Carrier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CarrierKeySpecifier | (() => undefined | CarrierKeySpecifier);
    fields?: CarrierFieldPolicy;
  };
  CarrierMailClass?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CarrierMailClassKeySpecifier
      | (() => undefined | CarrierMailClassKeySpecifier);
    fields?: CarrierMailClassFieldPolicy;
  };
  CarrierPackageType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CarrierPackageTypeKeySpecifier
      | (() => undefined | CarrierPackageTypeKeySpecifier);
    fields?: CarrierPackageTypeFieldPolicy;
  };
  Challenge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChallengeKeySpecifier | (() => undefined | ChallengeKeySpecifier);
    fields?: ChallengeFieldPolicy;
  };
  Channel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChannelKeySpecifier | (() => undefined | ChannelKeySpecifier);
    fields?: ChannelFieldPolicy;
  };
  ChannelInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChannelInterfaceKeySpecifier
      | (() => undefined | ChannelInterfaceKeySpecifier);
    fields?: ChannelInterfaceFieldPolicy;
  };
  ChannelRubberstampConfig?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChannelRubberstampConfigKeySpecifier
      | (() => undefined | ChannelRubberstampConfigKeySpecifier);
    fields?: ChannelRubberstampConfigFieldPolicy;
  };
  ChannelSync?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChannelSyncKeySpecifier | (() => undefined | ChannelSyncKeySpecifier);
    fields?: ChannelSyncFieldPolicy;
  };
  ChargifyChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChargifyChannelKeySpecifier
      | (() => undefined | ChargifyChannelKeySpecifier);
    fields?: ChargifyChannelFieldPolicy;
  };
  Chart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChartKeySpecifier | (() => undefined | ChartKeySpecifier);
    fields?: ChartFieldPolicy;
  };
  Company?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CompanyKeySpecifier | (() => undefined | CompanyKeySpecifier);
    fields?: CompanyFieldPolicy;
  };
  CompanySettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CompanySettingsKeySpecifier
      | (() => undefined | CompanySettingsKeySpecifier);
    fields?: CompanySettingsFieldPolicy;
  };
  Country?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CountryKeySpecifier | (() => undefined | CountryKeySpecifier);
    fields?: CountryFieldPolicy;
  };
  CountryDistributionChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountryDistributionChartKeySpecifier
      | (() => undefined | CountryDistributionChartKeySpecifier);
    fields?: CountryDistributionChartFieldPolicy;
  };
  CratejoyChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CratejoyChannelKeySpecifier
      | (() => undefined | CratejoyChannelKeySpecifier);
    fields?: CratejoyChannelFieldPolicy;
  };
  CreatePaymentSourceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreatePaymentSourceResponseKeySpecifier
      | (() => undefined | CreatePaymentSourceResponseKeySpecifier);
    fields?: CreatePaymentSourceResponseFieldPolicy;
  };
  CreatePaymentSourceResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreatePaymentSourceResultKeySpecifier
      | (() => undefined | CreatePaymentSourceResultKeySpecifier);
    fields?: CreatePaymentSourceResultFieldPolicy;
  };
  CreateShipmentPresetResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateShipmentPresetResultKeySpecifier
      | (() => undefined | CreateShipmentPresetResultKeySpecifier);
    fields?: CreateShipmentPresetResultFieldPolicy;
  };
  CreateUpsMerchantAccountResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateUpsMerchantAccountResultKeySpecifier
      | (() => undefined | CreateUpsMerchantAccountResultKeySpecifier);
    fields?: CreateUpsMerchantAccountResultFieldPolicy;
  };
  CreateUspsMerchantAccountResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateUspsMerchantAccountResultKeySpecifier
      | (() => undefined | CreateUspsMerchantAccountResultKeySpecifier);
    fields?: CreateUspsMerchantAccountResultFieldPolicy;
  };
  CustomOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CustomOptionKeySpecifier | (() => undefined | CustomOptionKeySpecifier);
    fields?: CustomOptionFieldPolicy;
  };
  CustomsItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CustomsItemKeySpecifier | (() => undefined | CustomsItemKeySpecifier);
    fields?: CustomsItemFieldPolicy;
  };
  DataGridColumn?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DataGridColumnKeySpecifier | (() => undefined | DataGridColumnKeySpecifier);
    fields?: DataGridColumnFieldPolicy;
  };
  DataGridColumnOverride?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridColumnOverrideKeySpecifier
      | (() => undefined | DataGridColumnOverrideKeySpecifier);
    fields?: DataGridColumnOverrideFieldPolicy;
  };
  DataGridExportStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridExportStatusKeySpecifier
      | (() => undefined | DataGridExportStatusKeySpecifier);
    fields?: DataGridExportStatusFieldPolicy;
  };
  DataGridFilter?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DataGridFilterKeySpecifier | (() => undefined | DataGridFilterKeySpecifier);
    fields?: DataGridFilterFieldPolicy;
  };
  DataGridFilterOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridFilterOptionKeySpecifier
      | (() => undefined | DataGridFilterOptionKeySpecifier);
    fields?: DataGridFilterOptionFieldPolicy;
  };
  DataGridMetaData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridMetaDataKeySpecifier
      | (() => undefined | DataGridMetaDataKeySpecifier);
    fields?: DataGridMetaDataFieldPolicy;
  };
  DataGridResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DataGridResultKeySpecifier | (() => undefined | DataGridResultKeySpecifier);
    fields?: DataGridResultFieldPolicy;
  };
  DataGridResultRows?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridResultRowsKeySpecifier
      | (() => undefined | DataGridResultRowsKeySpecifier);
    fields?: DataGridResultRowsFieldPolicy;
  };
  DataGridRowAction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridRowActionKeySpecifier
      | (() => undefined | DataGridRowActionKeySpecifier);
    fields?: DataGridRowActionFieldPolicy;
  };
  DataGridSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridSettingsKeySpecifier
      | (() => undefined | DataGridSettingsKeySpecifier);
    fields?: DataGridSettingsFieldPolicy;
  };
  DataGridSorting?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DataGridSortingKeySpecifier
      | (() => undefined | DataGridSortingKeySpecifier);
    fields?: DataGridSortingFieldPolicy;
  };
  DateTimeRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DateTimeRangeKeySpecifier | (() => undefined | DateTimeRangeKeySpecifier);
    fields?: DateTimeRangeFieldPolicy;
  };
  DeliveryEstimation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeliveryEstimationKeySpecifier
      | (() => undefined | DeliveryEstimationKeySpecifier);
    fields?: DeliveryEstimationFieldPolicy;
  };
  DiscogsChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DiscogsChannelKeySpecifier | (() => undefined | DiscogsChannelKeySpecifier);
    fields?: DiscogsChannelFieldPolicy;
  };
  DistributionChartItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DistributionChartItemKeySpecifier
      | (() => undefined | DistributionChartItemKeySpecifier);
    fields?: DistributionChartItemFieldPolicy;
  };
  DownloadLabelsByBatchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DownloadLabelsByBatchResultKeySpecifier
      | (() => undefined | DownloadLabelsByBatchResultKeySpecifier);
    fields?: DownloadLabelsByBatchResultFieldPolicy;
  };
  DownloadLabelsByShareTokenResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DownloadLabelsByShareTokenResultKeySpecifier
      | (() => undefined | DownloadLabelsByShareTokenResultKeySpecifier);
    fields?: DownloadLabelsByShareTokenResultFieldPolicy;
  };
  DownloadLabelsByShipmentsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DownloadLabelsByShipmentsResultKeySpecifier
      | (() => undefined | DownloadLabelsByShipmentsResultKeySpecifier);
    fields?: DownloadLabelsByShipmentsResultFieldPolicy;
  };
  DownloadLabelsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DownloadLabelsResultKeySpecifier
      | (() => undefined | DownloadLabelsResultKeySpecifier);
    fields?: DownloadLabelsResultFieldPolicy;
  };
  EbayChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EbayChannelKeySpecifier | (() => undefined | EbayChannelKeySpecifier);
    fields?: EbayChannelFieldPolicy;
  };
  EcwidChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EcwidChannelKeySpecifier | (() => undefined | EcwidChannelKeySpecifier);
    fields?: EcwidChannelFieldPolicy;
  };
  EcwidOrderStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EcwidOrderStatusKeySpecifier
      | (() => undefined | EcwidOrderStatusKeySpecifier);
    fields?: EcwidOrderStatusFieldPolicy;
  };
  EcwidOrderStatusImpl?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EcwidOrderStatusImplKeySpecifier
      | (() => undefined | EcwidOrderStatusImplKeySpecifier);
    fields?: EcwidOrderStatusImplFieldPolicy;
  };
  EmailTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EmailTemplateKeySpecifier | (() => undefined | EmailTemplateKeySpecifier);
    fields?: EmailTemplateFieldPolicy;
  };
  EtsyChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EtsyChannelKeySpecifier | (() => undefined | EtsyChannelKeySpecifier);
    fields?: EtsyChannelFieldPolicy;
  };
  FakeshopChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FakeshopChannelKeySpecifier
      | (() => undefined | FakeshopChannelKeySpecifier);
    fields?: FakeshopChannelFieldPolicy;
  };
  Feature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FeatureKeySpecifier | (() => undefined | FeatureKeySpecifier);
    fields?: FeatureFieldPolicy;
  };
  GlobalSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GlobalSettingsKeySpecifier | (() => undefined | GlobalSettingsKeySpecifier);
    fields?: GlobalSettingsFieldPolicy;
  };
  IgnoreOrderResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IgnoreOrderResultKeySpecifier
      | (() => undefined | IgnoreOrderResultKeySpecifier);
    fields?: IgnoreOrderResultFieldPolicy;
  };
  Link?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LinkKeySpecifier | (() => undefined | LinkKeySpecifier);
    fields?: LinkFieldPolicy;
  };
  Location?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier);
    fields?: LocationFieldPolicy;
  };
  LoginResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LoginResultKeySpecifier | (() => undefined | LoginResultKeySpecifier);
    fields?: LoginResultFieldPolicy;
  };
  MailClassCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MailClassCountKeySpecifier | (() => undefined | MailClassCountKeySpecifier);
    fields?: MailClassCountFieldPolicy;
  };
  MailTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MailTemplateKeySpecifier | (() => undefined | MailTemplateKeySpecifier);
    fields?: MailTemplateFieldPolicy;
  };
  MappableShipmentField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MappableShipmentFieldKeySpecifier
      | (() => undefined | MappableShipmentFieldKeySpecifier);
    fields?: MappableShipmentFieldFieldPolicy;
  };
  MoonclerkChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MoonclerkChannelKeySpecifier
      | (() => undefined | MoonclerkChannelKeySpecifier);
    fields?: MoonclerkChannelFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  NotificationsSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationsSettingsKeySpecifier
      | (() => undefined | NotificationsSettingsKeySpecifier);
    fields?: NotificationsSettingsFieldPolicy;
  };
  Numbers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NumbersKeySpecifier | (() => undefined | NumbersKeySpecifier);
    fields?: NumbersFieldPolicy;
  };
  Option?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OptionKeySpecifier | (() => undefined | OptionKeySpecifier);
    fields?: OptionFieldPolicy;
  };
  Order?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrderKeySpecifier | (() => undefined | OrderKeySpecifier);
    fields?: OrderFieldPolicy;
  };
  OrderItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrderItemKeySpecifier | (() => undefined | OrderItemKeySpecifier);
    fields?: OrderItemFieldPolicy;
  };
  PageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier);
    fields?: PageInfoFieldPolicy;
  };
  Payment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentKeySpecifier | (() => undefined | PaymentKeySpecifier);
    fields?: PaymentFieldPolicy;
  };
  PaymentAction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentActionKeySpecifier | (() => undefined | PaymentActionKeySpecifier);
    fields?: PaymentActionFieldPolicy;
  };
  PaymentApiMethod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentApiMethodKeySpecifier
      | (() => undefined | PaymentApiMethodKeySpecifier);
    fields?: PaymentApiMethodFieldPolicy;
  };
  PaymentSource?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentSourceKeySpecifier | (() => undefined | PaymentSourceKeySpecifier);
    fields?: PaymentSourceFieldPolicy;
  };
  PaypalChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaypalChannelKeySpecifier | (() => undefined | PaypalChannelKeySpecifier);
    fields?: PaypalChannelFieldPolicy;
  };
  PendingNumbers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PendingNumbersKeySpecifier | (() => undefined | PendingNumbersKeySpecifier);
    fields?: PendingNumbersFieldPolicy;
  };
  PickupRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PickupRequestKeySpecifier | (() => undefined | PickupRequestKeySpecifier);
    fields?: PickupRequestFieldPolicy;
  };
  PickupRequestRate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PickupRequestRateKeySpecifier
      | (() => undefined | PickupRequestRateKeySpecifier);
    fields?: PickupRequestRateFieldPolicy;
  };
  PickupSurcharge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PickupSurchargeKeySpecifier
      | (() => undefined | PickupSurchargeKeySpecifier);
    fields?: PickupSurchargeFieldPolicy;
  };
  PlaidActivation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlaidActivationKeySpecifier
      | (() => undefined | PlaidActivationKeySpecifier);
    fields?: PlaidActivationFieldPolicy;
  };
  Platform?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PlatformKeySpecifier | (() => undefined | PlatformKeySpecifier);
    fields?: PlatformFieldPolicy;
  };
  PlatformMappableField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlatformMappableFieldKeySpecifier
      | (() => undefined | PlatformMappableFieldKeySpecifier);
    fields?: PlatformMappableFieldFieldPolicy;
  };
  PlatformOverrideField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlatformOverrideFieldKeySpecifier
      | (() => undefined | PlatformOverrideFieldKeySpecifier);
    fields?: PlatformOverrideFieldFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  RateGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateGroupKeySpecifier | (() => undefined | RateGroupKeySpecifier);
    fields?: RateGroupFieldPolicy;
  };
  RateGroupKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateGroupKeyKeySpecifier | (() => undefined | RateGroupKeyKeySpecifier);
    fields?: RateGroupKeyFieldPolicy;
  };
  RateGroupTrait?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateGroupTraitKeySpecifier | (() => undefined | RateGroupTraitKeySpecifier);
    fields?: RateGroupTraitFieldPolicy;
  };
  RateResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateResultKeySpecifier | (() => undefined | RateResultKeySpecifier);
    fields?: RateResultFieldPolicy;
  };
  RateSelection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateSelectionKeySpecifier | (() => undefined | RateSelectionKeySpecifier);
    fields?: RateSelectionFieldPolicy;
  };
  RateSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateSummaryKeySpecifier | (() => undefined | RateSummaryKeySpecifier);
    fields?: RateSummaryFieldPolicy;
  };
  RateSurcharge?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateSurchargeKeySpecifier | (() => undefined | RateSurchargeKeySpecifier);
    fields?: RateSurchargeFieldPolicy;
  };
  RateSurchargeSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RateSurchargeSummaryKeySpecifier
      | (() => undefined | RateSurchargeSummaryKeySpecifier);
    fields?: RateSurchargeSummaryFieldPolicy;
  };
  Receipt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReceiptKeySpecifier | (() => undefined | ReceiptKeySpecifier);
    fields?: ReceiptFieldPolicy;
  };
  RecipientAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecipientAddressKeySpecifier
      | (() => undefined | RecipientAddressKeySpecifier);
    fields?: RecipientAddressFieldPolicy;
  };
  ReconciliationAdjustment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReconciliationAdjustmentKeySpecifier
      | (() => undefined | ReconciliationAdjustmentKeySpecifier);
    fields?: ReconciliationAdjustmentFieldPolicy;
  };
  Region?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RegionKeySpecifier | (() => undefined | RegionKeySpecifier);
    fields?: RegionFieldPolicy;
  };
  ReversalType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReversalTypeKeySpecifier | (() => undefined | ReversalTypeKeySpecifier);
    fields?: ReversalTypeFieldPolicy;
  };
  ScanformByBatchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ScanformByBatchResultKeySpecifier
      | (() => undefined | ScanformByBatchResultKeySpecifier);
    fields?: ScanformByBatchResultFieldPolicy;
  };
  ScanformByShareTokenResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ScanformByShareTokenResultKeySpecifier
      | (() => undefined | ScanformByShareTokenResultKeySpecifier);
    fields?: ScanformByShareTokenResultFieldPolicy;
  };
  ScanformFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ScanformFileKeySpecifier | (() => undefined | ScanformFileKeySpecifier);
    fields?: ScanformFileFieldPolicy;
  };
  SelectOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SelectOptionKeySpecifier | (() => undefined | SelectOptionKeySpecifier);
    fields?: SelectOptionFieldPolicy;
  };
  SenderEmailMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SenderEmailMetadataKeySpecifier
      | (() => undefined | SenderEmailMetadataKeySpecifier);
    fields?: SenderEmailMetadataFieldPolicy;
  };
  Shipment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ShipmentKeySpecifier | (() => undefined | ShipmentKeySpecifier);
    fields?: ShipmentFieldPolicy;
  };
  ShipmentAutocompleteConfig?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentAutocompleteConfigKeySpecifier
      | (() => undefined | ShipmentAutocompleteConfigKeySpecifier);
    fields?: ShipmentAutocompleteConfigFieldPolicy;
  };
  ShipmentBoundaries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentBoundariesKeySpecifier
      | (() => undefined | ShipmentBoundariesKeySpecifier);
    fields?: ShipmentBoundariesFieldPolicy;
  };
  ShipmentMapping?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentMappingKeySpecifier
      | (() => undefined | ShipmentMappingKeySpecifier);
    fields?: ShipmentMappingFieldPolicy;
  };
  ShipmentPassthroughFieldType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentPassthroughFieldTypeKeySpecifier
      | (() => undefined | ShipmentPassthroughFieldTypeKeySpecifier);
    fields?: ShipmentPassthroughFieldTypeFieldPolicy;
  };
  ShipmentPreset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ShipmentPresetKeySpecifier | (() => undefined | ShipmentPresetKeySpecifier);
    fields?: ShipmentPresetFieldPolicy;
  };
  ShipmentPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ShipmentPriceKeySpecifier | (() => undefined | ShipmentPriceKeySpecifier);
    fields?: ShipmentPriceFieldPolicy;
  };
  ShipmentStatusSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentStatusSummaryKeySpecifier
      | (() => undefined | ShipmentStatusSummaryKeySpecifier);
    fields?: ShipmentStatusSummaryFieldPolicy;
  };
  ShipmentSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentSummaryKeySpecifier
      | (() => undefined | ShipmentSummaryKeySpecifier);
    fields?: ShipmentSummaryFieldPolicy;
  };
  ShipmentTrackingNumbers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShipmentTrackingNumbersKeySpecifier
      | (() => undefined | ShipmentTrackingNumbersKeySpecifier);
    fields?: ShipmentTrackingNumbersFieldPolicy;
  };
  ShipmentUpload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ShipmentUploadKeySpecifier | (() => undefined | ShipmentUploadKeySpecifier);
    fields?: ShipmentUploadFieldPolicy;
  };
  ShopifyChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ShopifyChannelKeySpecifier | (() => undefined | ShopifyChannelKeySpecifier);
    fields?: ShopifyChannelFieldPolicy;
  };
  ShopifyLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShopifyLocationKeySpecifier
      | (() => undefined | ShopifyLocationKeySpecifier);
    fields?: ShopifyLocationFieldPolicy;
  };
  SquarespaceChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SquarespaceChannelKeySpecifier
      | (() => undefined | SquarespaceChannelKeySpecifier);
    fields?: SquarespaceChannelFieldPolicy;
  };
  StageDot?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StageDotKeySpecifier | (() => undefined | StageDotKeySpecifier);
    fields?: StageDotFieldPolicy;
  };
  StateDistributionChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StateDistributionChartKeySpecifier
      | (() => undefined | StateDistributionChartKeySpecifier);
    fields?: StateDistributionChartFieldPolicy;
  };
  SubblyChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SubblyChannelKeySpecifier | (() => undefined | SubblyChannelKeySpecifier);
    fields?: SubblyChannelFieldPolicy;
  };
  Summary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SummaryKeySpecifier | (() => undefined | SummaryKeySpecifier);
    fields?: SummaryFieldPolicy;
  };
  SupportSearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SupportSearchResultKeySpecifier
      | (() => undefined | SupportSearchResultKeySpecifier);
    fields?: SupportSearchResultFieldPolicy;
  };
  SyncStatusResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SyncStatusResultKeySpecifier
      | (() => undefined | SyncStatusResultKeySpecifier);
    fields?: SyncStatusResultFieldPolicy;
  };
  Timezone?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimezoneKeySpecifier | (() => undefined | TimezoneKeySpecifier);
    fields?: TimezoneFieldPolicy;
  };
  TriggerSyncResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TriggerSyncResultKeySpecifier
      | (() => undefined | TriggerSyncResultKeySpecifier);
    fields?: TriggerSyncResultFieldPolicy;
  };
  UpdateShipmentPresetResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateShipmentPresetResultKeySpecifier
      | (() => undefined | UpdateShipmentPresetResultKeySpecifier);
    fields?: UpdateShipmentPresetResultFieldPolicy;
  };
  User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
    fields?: UserFieldPolicy;
  };
  UserTerms?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserTermsKeySpecifier | (() => undefined | UserTermsKeySpecifier);
    fields?: UserTermsFieldPolicy;
  };
  Warehouse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WarehouseKeySpecifier | (() => undefined | WarehouseKeySpecifier);
    fields?: WarehouseFieldPolicy;
  };
  WoocommerceChannel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WoocommerceChannelKeySpecifier
      | (() => undefined | WoocommerceChannelKeySpecifier);
    fields?: WoocommerceChannelFieldPolicy;
  };
  ZoneChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ZoneChartKeySpecifier | (() => undefined | ZoneChartKeySpecifier);
    fields?: ZoneChartFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
