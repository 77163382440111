const Z_INDEX = {
  sidebarDrawer: 980,
  sidebar: 990,
  dropdown: 1000,
  adminBar: 1005,
  calendar: 1010,
  sticky: 1020,
  fixed: 1030,
  modalBackdrop: 1040,
  modal: 1050,
  popover: 1060,
  tooltip: 1070,
} as const;

export default Z_INDEX;
