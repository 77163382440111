export const COLOR = {
  green: '#2ca200', // 9x
  darkGreen: '#008e00', // 4x
  lightGreen: '#dbf1d5', // 1x

  red: '#d9534f', // 13x
  darkRed: '#b92c28', // 0x
  lightRed: '#f2dede', // 0x

  blue: '#00a9ff', // 34x primary focus info link
  darkBlue: '#2a6496', // 2x
  lightBlue: '#d9f2ff', // 2x

  yellow: '#efc100', // 3x

  chartPink: '#FF6384',
  chartBlue: '#36A2EB',
  chartYellow: '#FFCE56',
  chartTeal: '#4BC0C0',

  blueButtonAccent: '#0098e6',
  redButtonAccent: '#d43b37',
  greenButtonAccent: '#289200',
  yellowButtonAccent: '#d7ae00',
  greyButtonAccent: '#bcbcbc',
} as const;

export const GREYSCALE = {
  white: '#ffffff', // 30x
  grey00: '#fbfbfb', // 7x whiteSmokeVeryLight
  grey10: '#f5f5f5', // 8x whiteSmokeLight
  grey20: '#ebebeb', // 16x whiteSmokeTight' & 'whiteSmokeDark' was invented by me.
  grey30: '#cccccc', // 55x border -> veryLightGrey
  grey40: '#aaaaaa', // 1x shimmer
  grey50: '#999999', // 28x textMuted,label -> nobel
  grey60: '#666666', // 6x dimGray
  grey80: '#333333', // 15x text -> nightRider
  grey90: '#1a1a1a',
  black: '#000000', // heading
} as const;

export const ALERT_COLOR = {
  border: {
    danger: '#dca7a7',
    success: '#b2dba1',
    info: '#9acfea',
    warning: '#f5e79e',
  },
  background: {
    danger: '#f2dede',
    success: '#dff0d8',
    info: '#d8ecf6',
    warning: '#fcf8e3',
  },
  borderTop: {
    danger: '#e4b9c0',
    success: '#c2deb7',
    info: '#aedde6',
    warning: '#f7e1b5',
  },
  gradient: {
    danger: '#e4b9c0',
    success: '#c8e5bc',
    info: '#b8def0',
    warning: '#f8efc0',
  },
  color: {
    danger: '#a94442',
    success: '#3c763d',
    info: '#31708f',
    warning: '#8a6d3b',
  },
} as const;

export const DEFAULT_CHART_COLORS = [
  COLOR.chartPink,
  COLOR.chartBlue,
  COLOR.chartYellow,
  COLOR.chartTeal,
  // COLOR.blue,
  // COLOR.green,
  // COLOR.yellow,
  // COLOR.red,
  // GREYSCALE.grey30,
] as const;

export type PirateShipColor =
  | (typeof COLOR)[keyof typeof COLOR]
  | (typeof GREYSCALE)[keyof typeof GREYSCALE];
