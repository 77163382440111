import { useQuery, useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { gql } from '../../gql';
import { GetRatesQuery, GetRatesQueryVariables } from '../../gql/graphql';

export const getRatesConfigQuery = gql(/* GraphQL */ `
  query GetRatesConfig {
    carriers {
      id
      carrierKey
      mailClasses {
        mailClassKey
      }
      packageTypes {
        id
        packageTypeKey
        title
        description
        heightRequired
        weightRequired
        dimensionsRequired
      }
    }
    company {
      id
      activeCarriers
      warehouses {
        ...WarehouseFields
      }
      settings {
        defaultDestinationCountryCode
        defaultWarehouseId
      }
    }
    shipmentBoundaries {
      maxWeight
      maxCombinedLength
      maxLengthPlusGirth
      minLongSide
      maxLongSide
      minMiddleSide
      maxMiddleSide
      minShortSide
      maxShortSide
    }
    countries {
      id
      name
      countryCode
      parentCountryId
    }
  }
`);

export const useGetRatesConfigQuery = () => useQuery(getRatesConfigQuery);

export const getRatesQuery = gql(/* GraphQL */ `
  query GetRates(
    $originZip: String!
    $originCity: String
    $originRegionCode: String
    $destinationZip: String
    $isResidential: Boolean
    $destinationCountryCode: String
    $weight: Float
    $dimensionX: Float
    $dimensionY: Float
    $dimensionZ: Float
    $mailClassKeys: [String!]!
    $packageTypeKeys: [String!]!
    $pricingTypes: [String!]
    $showUpsRatesWhen2x7Selected: Boolean
  ) {
    rates(
      originZip: $originZip
      originCity: $originCity
      originRegionCode: $originRegionCode
      destinationZip: $destinationZip
      isResidential: $isResidential
      destinationCountryCode: $destinationCountryCode
      weight: $weight
      dimensionX: $dimensionX
      dimensionY: $dimensionY
      dimensionZ: $dimensionZ
      mailClassKeys: $mailClassKeys
      packageTypeKeys: $packageTypeKeys
      pricingTypes: $pricingTypes
      showUpsRatesWhen2x7Selected: $showUpsRatesWhen2x7Selected
    ) {
      title
      deliveryDescription
      trackingDescription
      serviceDescription
      pricingDescription
      cubicTier
      mailClassKey
      mailClass {
        accuracy
        international
      }
      packageTypeKey
      zone
      surcharges {
        title
        price
      }
      carrier {
        carrierKey
        title
      }
      totalPrice
      priceBaseTypeKey
      basePrice
      crossedTotalPrice
      pricingType
      pricingSubType
      ratePeriodId
      learnMoreUrl
      cheapest
      fastest
    }
  }
`);

export const useGetRatesLazyQuery = (
  options?: LazyQueryHookOptions<GetRatesQuery, GetRatesQueryVariables>,
) => useLazyQuery(getRatesQuery, options);
