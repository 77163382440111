/**
 * Use spacing tokens for padding, margins and position coordinates in pixel.
 */

export const BASE_SPACE = 48;

export const SPACING = {
  none: '0px',
  auto: 'auto',
  xxxl: `${BASE_SPACE * 2}px`, // 96
  xxl: `${BASE_SPACE}px`, // 48
  xl: `${BASE_SPACE / 2}px`, // 24
  lg: `${BASE_SPACE / 3}px`, // 16
  md: `${BASE_SPACE / 4}px`, // 12
  sm: `${BASE_SPACE / 8}px`, // 6
  xs: `${BASE_SPACE / 16}px`, // 3
  xxs: `${BASE_SPACE / 48}px`, // 1
} as const;
