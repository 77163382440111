import { useLocation, useNavigate } from 'react-router-dom';
import environment from '../utils/environment';

export default function useIntercomArticle() {
  const navigate = useNavigate();
  const location = useLocation();
  if (environment.isBridge()) {
    return (href: string) => {
      window.location.href = `${location.search}#help=${href}`;
    };
  }
  return (href: string) => navigate(`${location.search}#help=${href}`);
}
