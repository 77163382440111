import { forwardRef, Ref, useEffect } from 'react';
import RateCard, { UpsCreateAccountRateCard } from './RateCard';
import RateResults from './RateResults';
import type { RateResults as RateResultsType } from './PublicRatesCalculatorForm';
import useForwardedRef from '../../hooks/useForwardedRef';

export type PublicRatesCalculatorResultsProps = {
  rateResults: RateResultsType;
  scrollOnResultsChange?: boolean;
  onRegisterClick: () => void;
};

function PublicRatesCalculatorRateResultsComponent(
  { rateResults, scrollOnResultsChange, onRegisterClick }: PublicRatesCalculatorResultsProps,
  ref: Ref<HTMLDivElement>,
) {
  const forwardedRef = useForwardedRef(ref);

  // Scroll to results when they change
  useEffect(() => {
    if (scrollOnResultsChange && forwardedRef.current) {
      forwardedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [scrollOnResultsChange, rateResults, forwardedRef]);

  // Check if UPS rating is rate limited. In that case we will get a rating
  // error with code 3001.
  const isUpsRateLimited =
    rateResults.error?.graphQLErrors?.some((error) => error.extensions?.code === 3001) || false;

  return (
    <RateResults
      ref={forwardedRef}
      // Identity mapping to get rid of 'readonly' type modifier
      errors={rateResults.error?.graphQLErrors}
    >
      {rateResults.data?.rates?.map((rate) => (
        <RateCard
          key={`${rate.mailClassKey}-${rate.packageTypeKey}`}
          rate={rate}
          onButtonClick={onRegisterClick}
        />
      ))}

      {isUpsRateLimited && (
        <UpsCreateAccountRateCard
          onRegisterLinkClick={onRegisterClick}
          onButtonClick={onRegisterClick}
        />
      )}
    </RateResults>
  );
}

const PublicRatesCalculatorRateResults = forwardRef(PublicRatesCalculatorRateResultsComponent);
export default PublicRatesCalculatorRateResults;
