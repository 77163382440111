const fontSize = {
  xxs: '8px',
  xs: '13px',
  sm: '14px',
  md: '17px',
  lg: '19px',
  xl: '21px',
  xxl: '30px',
  xxxl: '38px',
  xxxxl: '51px', // 3 * md (3 * 17px)
} as const;

// see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping //
const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
} as const;

const LINE_HEIGHT = {
  standard: 1.42857143,
  forLargerFont: 1.1,
} as const;

const font = {
  primary: {
    fontFamily: 'ARRrrrial, ARRrrrmoji, Helvetica, sans-serif',
    lineHeight: LINE_HEIGHT.standard,
    fontSize: fontSize.md,
    fontWeight: [fontWeight.light, fontWeight.regular, fontWeight.medium, fontWeight.bold],
  },
  secondary: {
    fontFamily: 'Harimau Dua, sans-serif', // the loading screen fish joke font
    lineHeight: 1.4,
    fontSize: fontSize.md,
    fontWeight: [fontWeight.regular],
  },
} as const;

// eslint-disable-next-line import/prefer-default-export
export const TYPOGRAPHY = {
  font,
  body: {
    fontFamily: font.primary.fontFamily,
    lineHeight: font.primary.lineHeight,
    fontSize: font.primary.fontSize,
    fontWeight: fontWeight.regular,
  },
  fontWeight,
  heading: {
    h1: {
      lineHeight: LINE_HEIGHT.forLargerFont,
      fontSize: fontSize.xxl,
      fontWeight: fontWeight.bold,
    },
    h2: {
      lineHeight: LINE_HEIGHT.forLargerFont,
      fontSize: fontSize.xl,
      fontWeight: fontWeight.medium,
    },
    h3: {
      lineHeight: LINE_HEIGHT.forLargerFont,
      fontSize: '22.5px', // TODO: are we using this? check. makes no sense that h3 is bigger than h2
      fontWeight: fontWeight.medium,
    },
    h4: {
      lineHeight: LINE_HEIGHT.standard,
      fontSize: fontSize.md,
      fontWeight: fontWeight.medium,
    },
    h5: {
      lineHeight: LINE_HEIGHT.standard,
      fontSize: fontSize.sm,
      fontWeight: fontWeight.medium,
    },
  },
  fontSize,
} as const;
