import { useEffect } from 'react';

type GlobalKeyPressedOptions = {
  target: EventTarget;
  eventName: 'keydown' | 'keyup' | 'keypress';
  capture: boolean;
};

const defaultOptions: GlobalKeyPressedOptions = {
  target: document,
  eventName: 'keydown',
  capture: false,
};

export default function useKeyPressed(
  key: KeyboardEvent['key'],
  callback: (event: KeyboardEvent) => void,
  shouldApply: boolean = true, // hook used global, but can also be used in an element scope
  options: Partial<GlobalKeyPressedOptions> = {},
): void {
  useEffect(() => {
    const { target, eventName, capture } = { ...defaultOptions, ...options };
    const onKeyEvent = (event: Event) => {
      if (event instanceof KeyboardEvent && event.key === key && shouldApply) {
        callback(event);
      }
    };

    target.addEventListener(eventName, onKeyEvent, capture);

    return () => {
      target.removeEventListener(eventName, onKeyEvent, capture);
    };
  }, [key, callback, options, shouldApply]);
}
