import { ApolloLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import QueueLink from 'apollo-link-queue';
import tokenService from '../services/token';
import { Logger } from '../services/logging';
import environment from '../utils/environment';

type AuthToken = string;
type RememberToken = string;

export type AuthLinkOptions = {
  loginWithRememberToken: (rememberToken: RememberToken) => Promise<AuthToken>;
  rememberLogin: () => Promise<RememberToken>;
  onInvalidAuth?: () => void;
  logger?: Logger;
};

// Handle errors, especially auth errors
const createErrorLink = (
  queueLink: QueueLink,
  { loginWithRememberToken, rememberLogin, onInvalidAuth, logger }: AuthLinkOptions,
) => {
  let authRefreshPromise = Promise.resolve();

  return onError(({ graphQLErrors, operation, response, forward }) => {
    // If there are no errors, we are not interested
    if (!graphQLErrors) {
      return undefined;
    }

    // Look for Authentication errors
    const hasAuthError = graphQLErrors.some(
      (graphQLError) =>
        graphQLError.extensions?.category === 'security' &&
        graphQLError.message === 'You need to be logged to access this field',
    );

    // Stop error handling if no auth errors were found
    if (!hasAuthError) {
      return undefined;
    }

    // Without a remember token, we cannot renew auth, so stop handling
    if (!tokenService.getRememberToken()) {
      return undefined;
    }

    // At this point, we have an auth error and a remember token, so let's handle auth renew
    return new Observable((observer) => {
      logger?.debug('Failed operation due to unset/expired auth token.');

      // Await completion of previous auth refresh
      authRefreshPromise = authRefreshPromise
        .then(async () => {
          const previousAuthToken = operation.getContext().authToken as string | null;
          const currentAuthToken = tokenService.getAuthToken();
          const rememberToken = tokenService.getRememberToken();

          // We have to check again, because the remember token might be gone already
          if (!rememberToken) {
            throw new Error('Missing remember token');
          }

          // Only renew auth if the auth token has not changed in the meantime. If it did, we already
          // re-authenticated for a previously failed request and can skip ahead to the request retry.
          if (previousAuthToken === currentAuthToken) {
            // Hold new requests while we re-authenticate
            logger?.debug('Queueing up requests');
            queueLink.close();

            // Remove auth token that we know failed before
            tokenService.removeAuthToken();

            // Login with remember token
            logger?.debug('Renewing auth with remember token');
            const newAuthToken = await loginWithRememberToken(rememberToken);
            tokenService.setAuthToken(newAuthToken);
            tokenService.removeRememberToken();

            // Request a new remember token
            logger?.debug('Requesting new remember token');
            const newRememberToken = await rememberLogin();
            tokenService.setRememberToken(newRememberToken);

            // Open the queue again
            logger?.debug('Releasing requests from queue');
            queueLink.open();
          }

          // Retry failed request. Binds original observer as response handler.
          logger?.debug('Retrying failed request...');
          forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch((error) => {
          logger?.debug(`Failed to renew auth after expired auth token: ${error.message}`);

          tokenService.removeAuthToken();
          tokenService.removeRememberToken();

          // Pass down original error response
          observer.next(response!);

          // Open the queue again in case it was closed
          logger?.debug('Releasing requests from queue after error');
          queueLink.open();

          if (onInvalidAuth) {
            onInvalidAuth();
          }
        });
    });
  });
};

export const createAuthLink = (options: AuthLinkOptions) => {
  // Queues requests when gate is closed (e.g. during token renewal)
  const queueLink = new QueueLink();

  // Handles auth errors and re-authenticates
  const errorLink = createErrorLink(queueLink, options);

  // Injects auth information into context, e.g. to set headers
  const contextLink = setContext((_, prevContext) => {
    // Get tokens from storage
    const authToken = tokenService.getAuthToken();
    const deviceToken = tokenService.getDeviceToken();

    // Return the headers to the context so httpLink can read them
    return {
      ...prevContext,
      authToken,
      deviceToken,

      headers: {
        ...prevContext.headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
        'Device-Id': deviceToken,
      },
    };
  });

  const links = [queueLink, errorLink];

  // Don't send auth and device header in bridged mode
  if (!environment.isBridge()) {
    links.push(contextLink);
  }

  return ApolloLink.from(links);
};
