import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import blockForwardingProps from '../../utils/blockForwardingProps';
import { TextFieldWrapper } from '../form/TextField';
import { Col } from '../layout/Grid';

type DimensionColProps = {
  dimension: 'x' | 'y' | 'z';
  zRequired?: boolean;
};

const DimensionCol = styled(Col, blockForwardingProps('dimension', 'zRequired'))<DimensionColProps>`
  ${TextFieldWrapper} {
    position: relative;

    ${({ dimension, zRequired }) => {
      switch (dimension) {
        case 'x':
          return css`
            border-radius: ${BORDER_RADIUS.sm} ${BORDER_RADIUS.none} ${BORDER_RADIUS.none}
              ${BORDER_RADIUS.sm};
          `;
        case 'y':
          return css`
            margin-left: -${BORDER_WIDTH.sm};
            margin-right: ${zRequired ? `-${BORDER_WIDTH.sm}` : BORDER_WIDTH.none};
            border-radius: ${zRequired
              ? BORDER_RADIUS.none
              : `${BORDER_RADIUS.none} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.none}`};
          `;
        case 'z':
          return css`
            border-radius: ${BORDER_RADIUS.none} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm}
              ${BORDER_RADIUS.none};
          `;
        default:
          // If the exhaustiveCheck line shows an error, the switch is missing a case
          // eslint-disable-next-line no-case-declarations
          const exhaustiveCheck: never = dimension;

          // This should never happen
          throw new Error(`Unhandled dimension case ${exhaustiveCheck}`);
      }
    }}

    &:focus-within {
      z-index: 1;
    }
  }
`;

export default DimensionCol;
