import styled from '@emotion/styled';

type CarrierLogoProps = {
  carrierKey?: string;
};

const CarrierLogo = styled.img<CarrierLogoProps>`
  height: ${({ carrierKey }) => {
    switch (carrierKey) {
      case 'UPS':
        return '32px';

      default:
        return '22px';
    }
  }};
  margin: ${({ carrierKey }) => {
    switch (carrierKey) {
      case 'UPS':
        return '-3px 4px 0px 0px';

      default:
        return '2px 4px 0 0';
    }
  }};
`;

export default CarrierLogo;
