import {
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  forwardRef,
  ReactNode,
  ButtonHTMLAttributes,
  Ref,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import useImagePreloader from '../../hooks/useImagePreloader';
import { COLOR, GREYSCALE, PirateShipColor } from '../../styles/colors';
import useHighlightColor from '../../hooks/useHighlightColor';
import Icon from '../Icon';
import { BORDER_WIDTH, BORDER_RADIUS } from '../../styles/borders';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

export type DropdownSelectOption<V extends string | number, D = unknown> = {
  value: V;
  title?: ReactNode;
  description?: ReactNode;
  iconUrl?: string;
  disabled?: boolean;
  data?: D; // if it makes sense to bind extra data to the option, do it here
};

export type HighlightProps = {
  disabled?: boolean;
  open: boolean;
  highlightColor?: PirateShipColor;
};

export type DropdownOptionProps = {
  open: boolean;
  disabled: boolean;
  hasCaret: boolean;
  isSingleOption?: boolean;
};

export type DropdownSelectProps<
  V extends string | number,
  O extends DropdownSelectOption<V>, // this can be defined with a tighter type depending on use-case
> = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'role' | 'aria-selected' | 'onChange' | 'onClick' | 'value' | 'defaultValue'
> & {
  options: O[];
  datadogAction?: string;
  prefill?: boolean;
  error?: boolean;
  value?: NoInfer<V>;
  defaultValue?: NoInfer<V>;
  disabled?: boolean;
  onChange?: (value: V, event: SyntheticEvent<any, any>) => void;
  onRenderOption?: (option: O) => ReactNode;
  onRenderOpener?: () => ReactNode;
  imageSize?: number;
};

export const StyledDropdownSelectTitle = styled.div`
  font-size: ${TYPOGRAPHY.fontSize.md};
  color: ${GREYSCALE.black};
  font-weight: ${TYPOGRAPHY.fontWeight.regular};
  line-height: ${TYPOGRAPHY.fontSize.lg};
  margin: ${SPACING.none};
`;

export const StyledDropdownDescription = styled.small`
  font-size: ${TYPOGRAPHY.fontSize.sm};
  line-height: ${TYPOGRAPHY.fontSize.md};
  color: ${GREYSCALE.grey50};
`;

export const StyledDropdownIconWrapper = styled.div`
  box-sizing: border-box;
  width: 30px;
  padding-left: ${SPACING.md};
  padding-right: ${SPACING.md};
  align-self: center;
  justify-content: center;
`;
export const StyledDropdownOption = styled.button<DropdownOptionProps>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  appearance: none;
  background: none;
  border: 0;
  text-align: inherit;
  padding: ${SPACING.md};
  padding-right: ${({ hasCaret }) => (hasCaret ? '' : '45px')};
  color: ${GREYSCALE.grey80};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  :focus-within {
    outline-color: ${COLOR.blue};
    background-color: ${COLOR.lightBlue};
  }
  cursor: ${({ isSingleOption, disabled }) => {
    if (disabled) {
      return 'not-allowed';
    }
    if (isSingleOption) {
      return 'auto';
    }
    return 'pointer';
  }};
  border-bottom: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
  border-spacing: ${SPACING.none};
  ${({ isSingleOption, open }) =>
    !isSingleOption &&
    open &&
    css`
      border-bottom-color: ${GREYSCALE.grey30};
      &:hover {
        background: ${COLOR.lightBlue};
        border-color: ${COLOR.blue};
        border-top: ${BORDER_WIDTH.sm} solid ${COLOR.blue};
        margin-top: -2px;
      }
    `};

  &:last-child {
    border-bottom: ${BORDER_WIDTH.none};
  }

  &:first-of-type {
    border-radius: ${BORDER_WIDTH.lg} ${BORDER_WIDTH.lg} ${BORDER_WIDTH.none} ${BORDER_WIDTH.none};
  }

  ${({ open }) =>
    open &&
    css`
      background-color: ${GREYSCALE.white};
    `}
`;
export const StyledOptionsWrapper = styled.div`
  list-style: none;
  padding: ${SPACING.none};
  margin: ${SPACING.none};
  font-size: ${TYPOGRAPHY.fontSize.md};
  line-height: ${TYPOGRAPHY.fontSize.md};
  min-height: ${TYPOGRAPHY.fontSize.md};
  position: relative;
`;
export const StyledCheckboxWrapper = styled.div`
  padding-right: ${SPACING.sm};
`;
export const StyledLeftCaretIcon = styled(Icon)`
  min-height: 70px;
`;
export const StyledDropdownSelectWrapper = styled.div<
  HighlightProps & Omit<DropdownSelectProps<any, any>, 'options'>
>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  list-style: none;
  border-style: solid;
  border-width: ${BORDER_WIDTH.sm};
  border-color: ${({ highlightColor, prefill }) =>
    prefill ? COLOR.blue : highlightColor || GREYSCALE.grey30};
  border-radius: ${BORDER_RADIUS.sm};
  background: ${({ highlightColor, prefill, open }) => {
    if (highlightColor === COLOR.blue || open) {
      return GREYSCALE.white;
    }
    if (prefill) {
      return `linear-gradient(to bottom, ${GREYSCALE.white} 24%, ${COLOR.lightBlue} 100%)`;
    }
    return `linear-gradient(to bottom, ${GREYSCALE.white} 24%, ${GREYSCALE.grey20} 100%)`;
  }};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  position: relative;
`;
export const StyledDropdownImage = styled.img<{ imageSize?: number }>`
  max-width: 130px;
  max-height: 130px;
  margin-right: 20px;
  ${({ imageSize }) =>
    imageSize &&
    css`
      width: ${imageSize}px;
      height: ${imageSize}px;
    `}
`;
export const StyledTitleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: center;

  &:hover {
    .Icon {
      color: ${GREYSCALE.grey30};
    }
  }
`;

function DropdownSelectComponent<V extends string | number, O extends DropdownSelectOption<V>>(
  props: DropdownSelectProps<V, O>,
  ref: Ref<HTMLSelectElement>,
) {
  const {
    name,
    className,
    options,
    value,
    defaultValue,
    disabled: selectDisabled,
    onChange,
    onRenderOption,
    imageSize,
    error,
    prefill,
    id,
    datadogAction,
    ...others
  } = props;

  const preloadImage = useImagePreloader();

  const [selectedValue, setSelectedValue] = useState(value ?? defaultValue ?? options[0].value);

  // map the current value to its option
  const selectedOption = options.find((option) => option.value === selectedValue) ?? options[0];
  const [open, setOpen] = useState(false);
  const isSingleOption = options.length === 1;

  // Preload all icons when options change (usually just after mount)
  useEffect(() => {
    options.flatMap((option) => (option.iconUrl ? [option.iconUrl] : [])).forEach(preloadImage);
  }, [options, preloadImage]);

  // Close dropdown menu on outside click
  const openDropdownMenuRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect([openDropdownMenuRef], () => setOpen(false));

  // React to value changes
  useEffect(() => {
    setSelectedValue(value ?? defaultValue ?? options[0].value);
  }, [value, defaultValue, options]);

  const renderStyledDropdownOpener = () => {
    const {
      value: optionValue,
      title = '',
      description,
      disabled: optionDisabled = false,
      iconUrl,
    } = selectedOption;

    return (
      <StyledDropdownOption
        type="button"
        role="option"
        data-dd-action-name={datadogAction}
        aria-selected
        isSingleOption={isSingleOption}
        hasCaret
        open={open}
        disabled={selectDisabled || optionDisabled}
        key={optionValue}
        data-option-value={optionValue} // required in acceptance tests for the findability of the button representing a select option
        id={id || `${name}-${optionValue}`} // required for Formik
        onClick={(event) => {
          if (isSingleOption) {
            event.stopPropagation();
            return;
          }
          setOpen(!selectDisabled && !open);
        }}
        {...others}
      >
        {onRenderOption ? (
          onRenderOption(selectedOption)
        ) : (
          <>
            {iconUrl && <StyledDropdownImage src={iconUrl} imageSize={imageSize} />}
            <StyledTitleWrapper>
              <StyledDropdownSelectTitle>{title}</StyledDropdownSelectTitle>
              <StyledDropdownDescription>{description}</StyledDropdownDescription>
            </StyledTitleWrapper>
          </>
        )}
        {!isSingleOption && (
          <StyledDropdownIconWrapper>
            <Icon icon={open ? 'caret-up' : 'caret-down'} size="lg" />
          </StyledDropdownIconWrapper>
        )}
      </StyledDropdownOption>
    );
  };

  const renderStyledDropdownOption = (option: O) => {
    if (option === selectedOption) {
      return null; // if the option is the top option, don't render it again in the list.
    }

    const {
      value: optionValue,
      title = '',
      description,
      disabled: optionDisabled = false,
      iconUrl,
    } = option;

    return (
      <StyledDropdownOption
        type="button"
        role="option"
        data-dd-action-name="Dropdown Option"
        aria-selected={false}
        isSingleOption={isSingleOption}
        hasCaret={false}
        open={open}
        disabled={selectDisabled || optionDisabled}
        key={optionValue}
        data-option-value={optionValue} // required in acceptance tests for the findability of the button representing a select option
        id={`${name}-${optionValue}`} // required for Formik
        onClick={(event) => {
          if (optionDisabled) {
            return;
          }
          // uncontrolled DropdownSelect
          if (value === undefined) {
            setSelectedValue(optionValue);
          }

          onChange?.(optionValue, event);
        }}
        {...others}
      >
        {onRenderOption ? (
          onRenderOption(option)
        ) : (
          <>
            {iconUrl && <StyledDropdownImage src={iconUrl} imageSize={imageSize} />}
            <StyledTitleWrapper>
              <StyledDropdownSelectTitle>{title}</StyledDropdownSelectTitle>
              <StyledDropdownDescription>{description}</StyledDropdownDescription>
            </StyledTitleWrapper>
          </>
        )}
      </StyledDropdownOption>
    );
  };

  return (
    <>
      <select
        hidden
        aria-hidden
        ref={ref}
        name={name}
        value={selectedValue}
        disabled={selectDisabled}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={(event) => {
          onChange?.(event.target.value as V, event);
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.title || option.value}
          </option>
        ))}
      </select>
      <StyledDropdownSelectWrapper
        data-name={name}
        role="listbox"
        ref={open ? openDropdownMenuRef : null}
        highlightColor={useHighlightColor(Boolean(error), open)}
        prefill={prefill}
        disabled={selectDisabled}
        className={className}
        open={open}
        onClick={() => {
          setOpen(!selectDisabled && !open);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            setOpen(false);
          }
        }}
      >
        {renderStyledDropdownOpener()}
        {open && options.length > 1 && (
          <StyledOptionsWrapper>{options.map(renderStyledDropdownOption)}</StyledOptionsWrapper>
        )}
      </StyledDropdownSelectWrapper>
    </>
  );
}

const DropdownSelect = forwardRef(DropdownSelectComponent);
export default DropdownSelect;
