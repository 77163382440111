import { ApolloClient, ApolloLink, Operation, NormalizedCacheObject } from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { OperationDefinitionNode } from 'graphql';
import { Body } from '@apollo/client/link/http/selectHttpOptionsAndBody';
import { createAuthLink } from './authLink';
import { appVersionVar, cache } from './cache';
import loggingService from '../services/logging';
import { loginWithRememberTokenMutation } from '../operations/mutations/loginWithRememberToken';
import { rememberLoginMutation } from '../operations/mutations/rememberLogin';
import {
  LoginWithRememberTokenMutation,
  LoginWithRememberTokenMutationVariables,
  RememberLoginMutation,
} from '../gql/graphql';
import sleep from '../utils/sleep';

export type ApolloClientInitOptions = {
  onInvalidAuth?: () => void;
  endpoint?: string;
  onNetworkError?: (
    error: NonNullable<ErrorResponse['networkError']>,
    operation: Operation,
  ) => void;
  onGraphQLError?: (
    graphQLErrors: NonNullable<ErrorResponse['graphQLErrors']>,
    operation: Operation,
  ) => void;
};

export default function initApolloClient({
  onInvalidAuth,
  endpoint,
  onNetworkError,
  onGraphQLError,
}: ApolloClientInitOptions = {}) {
  const logger = loggingService.getLogger();
  let client: ApolloClient<NormalizedCacheObject>;

  // Transparently handles re-authentication and setting auth headers
  const authLink = createAuthLink({
    onInvalidAuth,
    logger,
    loginWithRememberToken: async (rememberToken) => {
      const result = await client.mutate<
        LoginWithRememberTokenMutation,
        LoginWithRememberTokenMutationVariables
      >({
        mutation: loginWithRememberTokenMutation,
        variables: { rememberToken },
        context: { skipQueue: true },
      });

      if (!result.data) {
        throw new Error('Could not login with remember token');
      }

      return result.data.loginWithRememberToken.authToken;
    },
    rememberLogin: async () => {
      const result = await client.mutate<RememberLoginMutation>({
        mutation: rememberLoginMutation,
        context: { skipQueue: true },
      });

      if (!result.data) {
        throw new Error('Could not remember login');
      }

      return result.data.rememberLogin;
    },
  });

  // Handle errors by delegating them to passed-in handlers
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    // Pass on network errors
    if (networkError && onNetworkError) {
      onNetworkError(networkError, operation);
    }

    // Pass on GraphQL errors
    if (graphQLErrors && onGraphQLError) {
      onGraphQLError(graphQLErrors, operation);
    }
  });

  // Retry on network or server errors (this does not cover application errors)
  const retryLink = new RetryLink({
    delay: {
      initial: 1_000,
      max: 10_000,
      jitter: true, // randomize delays between attempts to avoid thundering herd problem
    },
    attempts: {
      max: 3,
      retryIf: (_error, operation) =>
        new Promise((resolve) => {
          const context = { operationName: operation.operationName };

          // Do not retry mutations. We should always see an OperationDefinition.
          const operationDef = operation.query.definitions.find(
            (def) => def.kind === 'OperationDefinition',
          ) as OperationDefinitionNode | undefined;
          if (!operationDef || operationDef.operation !== 'query') {
            logger.debug('Skipping retry for non-query', { context });

            resolve(false);
            return;
          }

          // Hold before next attempt in case we have no network connection at all
          if (!navigator.onLine) {
            let timeout: number | null = null;

            const onlineHandler = () => {
              if (onlineHandler !== null) {
                window.removeEventListener('online', onlineHandler);
              }

              if (timeout !== null) {
                window.clearTimeout(timeout);
              }

              logger.debug('Connection established, retrying request', { context });

              resolve(true);
            };

            // Note that 'online' does not guarantee internet connectivity, just any network connection
            window.addEventListener('online', onlineHandler);

            // Abort after 10 minutes which is the time we will cache responses on the backend
            const ABORT_RETRIES_AFTER_TIME = 600_000;
            timeout = window.setTimeout(() => {
              if (onlineHandler !== null) {
                window.removeEventListener('online', onlineHandler);
              }

              logger.debug('Aborting retry after 10 minutes', { context });

              resolve(false);
            }, ABORT_RETRIES_AFTER_TIME);

            logger.debug('Connection offline, waiting for connectivity to retry request', {
              context,
            });

            return;
          }

          // Retry in all other cases
          logger.debug('Request failed, retrying', { context });
          resolve(true);
        }),
    },
  });

  // Handle uploads (terminating link)
  const uploadLink = createUploadLink({
    uri: endpoint,
    credentials: 'same-origin',
    fetch: async (input: RequestInfo | URL, init?: CMRequestInit) => {
      let opName = '';

      if (init?.body instanceof FormData) {
        const operations = init.body.get('operations');
        if (typeof operations === 'string') {
          opName = (JSON.parse(operations) as Body).operationName ?? '';
        }
      } else if (init?.body) {
        opName = (JSON.parse(init.body) as Body).operationName ?? '';
      }

      const response = await fetch(
        `${endpoint}${endpoint?.includes('?') ? '&' : '?'}opname=${opName}`,
        init,
      );

      // Check for Cloudflare challenges
      if (response.headers.get('cf-mitigated') === 'challenge') {
        // If we get a challenge, we reload the page to show it.
        window.location.reload();

        // Delay execution so processing does not continue before the reload happens.
        await sleep(1000);

        return response;
      }

      // Store the app version on the backend in a reactive variable
      const appVersion = response.headers.get('App-Version');
      appVersionVar(appVersion);

      return response;
    },
  });

  client = new ApolloClient({
    connectToDevTools: import.meta.env.DEV,
    link: ApolloLink.from([errorLink, retryLink, authLink, uploadLink]),
    cache,
    defaultOptions: {
      // watchQuery is internally used by useQuery and useLazyQuery
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'none',
      },
    },
  });

  return client;
}
