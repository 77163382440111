import { defaultDataIdFromObject } from '@apollo/client';
import { DataGridResult } from '../../gql/graphql';

export function calculateDataGridCacheId(dataGridResult: Partial<DataGridResult>) {
  // customize ImportGrid cache id - append grid mode
  if (dataGridResult.id === 'index_import_grid-2018-12-17') {
    const gridModeMetaData = dataGridResult.metaData?.find(
      (metaData) => metaData.key === 'gridMode',
    );
    if (gridModeMetaData) {
      return `DataGridResult:${dataGridResult.id}:${gridModeMetaData.value}`;
    }
  }

  return defaultDataIdFromObject(dataGridResult);
}
