import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREYSCALE } from '../../styles/colors';
import { BORDER_RADIUS } from '../../styles/borders';

const BaseShimmer = styled.div<BaseProps>`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  background: ${GREYSCALE.grey00}
    linear-gradient(
      to right,
      ${GREYSCALE.grey00} 0%,
      ${GREYSCALE.grey20} 20%,
      ${GREYSCALE.grey00} 40%,
      ${GREYSCALE.grey00} 100%
    )
    no-repeat;
  background-size: 100vw 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  ${({ darker }) =>
    darker &&
    css`
      background: ${GREYSCALE.grey30}
        linear-gradient(
          to right,
          ${GREYSCALE.grey30} 0%,
          ${GREYSCALE.grey40} 20%,
          ${GREYSCALE.grey30} 40%,
          ${GREYSCALE.grey30} 100%
        )
        no-repeat;
    `}

  @keyframes placeholderShimmer {
    0% {
      background-position: -50vw 0;
    }
    100% {
      background-position: 100vw 0;
    }
  }
`;

type RowProps = {
  horizontalGap?: number;
  verticalGap?: number;
};

type BaseProps = {
  style?: React.CSSProperties;
  darker?: boolean;
};

type RectangleProps = BaseProps & {
  width?: number;
  height?: number;
};

type RoundedRectangleProps = RectangleProps & {
  rounding?: number;
};

type LinesProps = RectangleProps & {
  count: number;
};

type CircleProps = BaseProps & {
  radius?: number;
};

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.verticalGap || 15}px;

  > * {
    margin-right: ${(props) => props.horizontalGap || 15}px;
  }

  > :last-child {
    margin-right: 0;
  }
`;

const Rectangle = styled(BaseShimmer)<RectangleProps>`
  max-width: 100%;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => props.height || 50}px;
`;

const RoundedRectangle = styled(Rectangle)<RoundedRectangleProps>`
  border-radius: ${(props) => (props.rounding ? `${props.rounding}px` : BORDER_RADIUS.sm)};
`;

const Circle = styled(BaseShimmer)<CircleProps>`
  border-radius: 100%;
  width: ${(props) => props.radius || 50}px;
  height: ${(props) => props.radius || 50}px;
`;

const LinesContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  width: 100%;

  > :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

function Line({ height = 14, style, ...others }: RectangleProps) {
  return <Rectangle height={height} style={{ verticalAlign: 'baseline', ...style }} {...others} />;
}

function Lines({ count, ...lineProps }: LinesProps) {
  return (
    <LinesContainer>
      {[...Array(count)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Line key={index} {...lineProps} />
      ))}
    </LinesContainer>
  );
}

const Shimmer = {
  Row,
  Rectangle,
  RoundedRectangle,
  Circle,
  Line,
  Lines,
};

export default Shimmer;
