import '../polyfills';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createRoot, Root } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import initLocale from '../locale';
import initValidation from '../validation';
import initIcons from '../styles/icons';
import initGrid from '../styles/grid';
import initApolloClient from '../apollo/client';
import GlobalIntercomArticle from '../components/GlobalIntercomArticle';
import FlashMessages from '../components/FlashMessages';
import PublicRatesCalculatorForm, {
  RateResults,
} from '../components/rates/PublicRatesCalculatorForm';
import CubicCalculator from '../components/rates/CubicCalculator';
import PublicRatesCalculatorRateResults from '../components/rates/PublicRatesCalculatorRateResults';

initLocale('en-US');
initValidation();
initIcons();
initGrid();

const reactRoots = new WeakMap<Element, Root>();
let graphqlEndpoint: string;
let apolloClient: ApolloClient<NormalizedCacheObject>;

function getApolloClient() {
  if (!graphqlEndpoint) {
    throw new Error('GraphQL endpoint is not configured yet');
  }

  if (!apolloClient) {
    apolloClient = initApolloClient({ endpoint: graphqlEndpoint });
  }

  return apolloClient;
}

// Bind to window
(window as any).PublicRateCalculator = {
  setGraphqlEndpoint(endpoint: string) {
    graphqlEndpoint = endpoint;
  },
  renderForm(rootEl: Element, onResults: (results: RateResults) => void): () => void {
    const root = reactRoots.get(rootEl) ?? createRoot(rootEl);
    reactRoots.set(rootEl, root);

    root.render(
      <StrictMode>
        <ApolloProvider client={getApolloClient()}>
          <FlashMessages />
          <PublicRatesCalculatorForm onRateResults={onResults} />
        </ApolloProvider>
      </StrictMode>,
    );

    return root.unmount;
  },
  renderResults(rootEl: Element, results: RateResults): () => void {
    // const root = reactRoots.get(rootEl) ?? createRoot(rootEl);
    // reactRoots.set(rootEl, root);

    /**
     * PP-10600 Uses React 17-style rendering to disable a weird "maximum update depth"
     * error when triggering a modal.
     */

    // root.render(
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
      <StrictMode>
        <BrowserRouter future={{ v7_startTransition: false }}>
          <ApolloProvider client={getApolloClient()}>
            <Suspense fallback={null}>
              <GlobalIntercomArticle />
            </Suspense>
            <PublicRatesCalculatorRateResults
              rateResults={results}
              scrollOnResultsChange
              onRegisterClick={() => {
                window.location.href = 'https://ship.pirateship.com';
              }}
            />
          </ApolloProvider>
        </BrowserRouter>
      </StrictMode>,
      rootEl,
    );

    // return root.unmount;
    // eslint-disable-next-line react/no-deprecated
    return () => ReactDOM.unmountComponentAtNode(rootEl);
  },
};

// Bind to window
(window as any).PriorityMailCubicCalculator = {
  render(rootEl: Element): () => void {
    const root = reactRoots.get(rootEl) ?? createRoot(rootEl);
    reactRoots.set(rootEl, root);

    root.render(
      <StrictMode>
        <CubicCalculator mailClass="priorityMailCubic" />
      </StrictMode>,
    );

    return root.unmount;
  },
};
(window as any).ParcelSelectGroundCubicCalculator = {
  // Still used on historical service page
  render(rootEl: Element): () => void {
    const root = reactRoots.get(rootEl) ?? createRoot(rootEl);
    reactRoots.set(rootEl, root);

    root.render(
      <StrictMode>
        <CubicCalculator mailClass="parcelSelectGroundCubic" />
      </StrictMode>,
    );

    return root.unmount;
  },
};
(window as any).GroundAdvantageCubicCalculator = {
  render(rootEl: Element): () => void {
    const root = reactRoots.get(rootEl) ?? createRoot(rootEl);
    reactRoots.set(rootEl, root);

    root.render(
      <StrictMode>
        <CubicCalculator mailClass="groundAdvantageCubic" />
      </StrictMode>,
    );

    return root.unmount;
  },
};

// Dispatch ready event
document.dispatchEvent(new Event('calculatorsReady'));
