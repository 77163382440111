import { Children, forwardRef, Ref, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Col as ReactGridSystemCol } from 'react-grid-system';
import Alert from '../Alert';
import { Row } from '../layout/Grid';
import convertBbcode from '../../utils/convertBbcode';

const Styled = {
  ScrollToWrapper: styled.div`
    padding-top: 40px;
  `,
  RatesTileWrapper: styled(ReactGridSystemCol)`
    margin-bottom: 25px;
  `,
};

type RateResultsProps = {
  errors?: ReadonlyArray<Error>;
  children?: ReactNode[];
};

function RateResultsComponent({ errors, children }: RateResultsProps, ref: Ref<HTMLDivElement>) {
  const results = Children.toArray(children);

  return (
    <Styled.ScrollToWrapper ref={ref}>
      {/* Only show errors when we don't have any results */}
      {(results === undefined || results.length === 0) && errors && errors.length > 0 && (
        <Alert variant="danger">{convertBbcode(errors[0].message)}</Alert>
      )}

      {results && (
        <Row>
          {results.map((result, index) => (
            <Styled.RatesTileWrapper
              // Using index here is fine because the results won't shuffle around
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              md={12}
              lg={6}
            >
              {result}
            </Styled.RatesTileWrapper>
          ))}
        </Row>
      )}
    </Styled.ScrollToWrapper>
  );
}
const RateResults = forwardRef(RateResultsComponent);
export default RateResults;
