import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const loginWithRememberTokenMutation = gql(/* GraphQL */ `
  mutation LoginWithRememberTokenMutation($rememberToken: AuthToken!) {
    loginWithRememberToken(rememberToken: $rememberToken) {
      authToken
      user {
        ...CurrentUser
      }
    }
  }
`);

export const useLoginWithRememberTokenMutation = () =>
  useMutation(loginWithRememberTokenMutation, { errorPolicy: 'ignore' });
