import { setConfiguration as setGridConfiguration } from 'react-grid-system';
import { BREAKPOINT } from './breakpoints';
import { BASE_SPACE } from './spacing';

export const gutterSpacing = {
  gutter: BASE_SPACE,
  gutterHalf: BASE_SPACE / 2,
};

export const MAX_SCREEN_CLASS = 'lg';
export const BREAKPOINTS = [BREAKPOINT.sm, BREAKPOINT.md, BREAKPOINT.lg, BREAKPOINT.xl];
export const CONTAINER_WIDTHS = [
  BREAKPOINT.sm - 18,
  BREAKPOINT.md - 22,
  BREAKPOINT.lg - 30,
  BREAKPOINT.xl - 40,
];
export const GUTTER_WIDTH = gutterSpacing.gutterHalf;
export const GRID_COLUMNS = 12;

export default function initGrid() {
  setGridConfiguration({
    maxScreenClass: MAX_SCREEN_CLASS,
    breakpoints: BREAKPOINTS,
    containerWidths: CONTAINER_WIDTHS,
    gutterWidth: GUTTER_WIDTH,
    gridColumns: GRID_COLUMNS,
  });
}
