import { RefObject, useEffect } from 'react';

export default function useOutsideClickEffect(
  refs: RefObject<HTMLElement> | (RefObject<HTMLElement> | undefined)[],
  handler: (event: Event) => void,
) {
  useEffect(
    () => {
      const refsArray = Array.isArray(refs) ? refs : [refs];
      const listener = (event: MouseEvent | TouchEvent) => {
        // Ignore scrollbar clicks
        if (event.target && (event.target as Element).tagName === 'HTML') {
          return;
        }

        // Only call the handler if the click was not the referenced
        // element itself or any of its descendants.
        for (let i = 0, n = refsArray.length; i < n; i++) {
          const ref = refsArray[i];
          if (ref && ref.current && ref.current.contains(event.target as Element | null)) {
            return;
          }
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Passed handler usually changes with each render, which will
    // make this effect clean up and rebind each time, which is not
    // a big deal. It can be optimized though by using useCallback
    // on the handler before passing it to this hook.
    [refs, handler],
  );
}
