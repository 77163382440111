import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLOR } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

const errorMessageStyle = css`
  color: ${COLOR.red};
  font-weight: ${TYPOGRAPHY.fontWeight.medium};
  font-size: ${TYPOGRAPHY.fontSize.xs};
  display: block;
  text-align: right;
`;

const ErrorMessage = styled.div`
  ${errorMessageStyle}
  margin-top: ${SPACING.xs};
`;

export const NoHeightErrorMessage = styled.div`
  ${errorMessageStyle}
  position: absolute;
  right: ${SPACING.md};
`;

export const MultiFieldErrorMessage = styled.div`
  ${errorMessageStyle}
  margin-top: ${css(`calc(${SPACING.xs} - ${SPACING.xl})`)};
`;

export default ErrorMessage;
