import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { gql } from '../../gql';
import { IntercomArticleQuery, IntercomArticleQueryVariables } from '../../gql/graphql';

export const intercomArticleQuery = gql(/* GraphQL */ `
  query IntercomArticleQuery($url: String!) {
    intercomArticle(url: $url)
  }
`);

export const useLazyIntercomArticleQuery = (
  options: LazyQueryHookOptions<IntercomArticleQuery, IntercomArticleQueryVariables>,
) => useLazyQuery(intercomArticleQuery, options);
