import formatFloat from './formatFloat';

type WeightObject = {
  pounds: number;
  ounces: number;
};

/**
 * convert a partial weight object to a number of ounces
 */
export function toOunces(weightObject: Partial<WeightObject>): number {
  const p = weightObject.pounds || 0;
  const o = weightObject.ounces || 0;
  return p * 16 + o;
}

/**
 * convert a weight object (with potentially decimal pound values or ounce values over 16)
 * to a weight object with a whole numbered pound value and ounce value under 16
 */
function standardizeWeight({ pounds, ounces }: WeightObject): WeightObject {
  const weightInOunces = toOunces({ pounds, ounces });
  const standardizedPounds = Math.floor(weightInOunces / 16);
  const standardizedOunces = weightInOunces - standardizedPounds * 16;
  return { pounds: standardizedPounds, ounces: standardizedOunces };
}
/**
 * Convert ounces and pounds to a string representation
 * e.g.
 * 1 lbs 6.1 oz
 * 1 lbs
 * 6 oz
 */
export default function formatWeight(
  { pounds, ounces }: WeightObject,
  precision: number = 3,
): string {
  const { pounds: standardizedPounds, ounces: standardizedOunces } = standardizeWeight({
    pounds,
    ounces,
  });
  const asPounds =
    standardizedPounds > 0 ? `${standardizedPounds} lb${standardizedPounds === 1 ? '' : 's'}` : '';

  const asOunces = standardizedOunces > 0 ? `${formatFloat(standardizedOunces, precision)} oz` : '';
  return `${asPounds} ${asOunces}`.trim();
}

export function toWeightObject(weightInOunces: number, precision: number = 3): WeightObject {
  const normalizedPounds = Math.floor(weightInOunces / 16);
  const normalizedOunces = formatFloat((weightInOunces / 16 - normalizedPounds) * 16, precision);
  return { pounds: normalizedPounds, ounces: +normalizedOunces };
}
