import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import useIntercomArticle from '../hooks/useIntercomArticle';
import { COLOR } from '../styles/colors';
import { SPACING } from '../styles/spacing';
import { TYPOGRAPHY } from '../styles/typography';
import { ExternalLink } from './Link';
import Icon from './Icon';

const Styled = {
  HelpLinkIcon: styled(Icon)`
    width: ${SPACING.md};
    height: ${SPACING.md};
    margin-left: ${SPACING.xs};
    font-size: ${TYPOGRAPHY.fontSize.xs};
    color: ${COLOR.blue};
    cursor: pointer;
  `,
};
type IntercomArticleLinkProps = PropsWithChildren<{
  href: string;
  onClick?: () => void; // optional extra functionality
}>;

export default function IntercomArticleLink({
  href,
  onClick,
  children,
  ...props
}: IntercomArticleLinkProps) {
  const openIntercomArticle = useIntercomArticle();

  return (
    <ExternalLink
      href={href}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (onClick) onClick();
        openIntercomArticle(href);
      }}
      {...props}
    >
      {children}
    </ExternalLink>
  );
}

type IntercomArticleIconLinkProps = {
  url: string;
  title: string;
};

export function IntercomArticleIconLink({ url, title, ...props }: IntercomArticleIconLinkProps) {
  return (
    <IntercomArticleLink href={url} {...props}>
      <Styled.HelpLinkIcon title={title} icon="circle-question" />
    </IntercomArticleLink>
  );
}
