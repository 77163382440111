/**
 * Responsive Breakpoints - Minimum widths in Pixels
 */
export const BREAKPOINT = {
  xs: 320, // Smartphone
  sm: 768, // Tablet
  md: 992, // Desktop
  lg: 1200, // Large Desktop
  xl: 1600, // Extra Large Desktop
} as const;

/**
 * Minimum heights in Pixels
 */
export const HEIGHTBREAKPOINT = {
  xxs: 480,
  xs: 640,
  sm: 720,
  md: 900,
  lg: 1080,
  xl: 1200,
} as const;

export const MEDIA_QUERY = {
  xsMin: `${BREAKPOINT.xs}px`, // Smartphone Min.
  xsMax: `${BREAKPOINT.sm - 1}px`, // Smartphone Max.
  smMin: `${BREAKPOINT.sm}px`, // Tablet Min.
  smMax: `${BREAKPOINT.md - 1}px`, // Tablet Max.
  mdMin: `${BREAKPOINT.md}px`, // Desktop Min.
  mdMax: `${BREAKPOINT.lg - 1}px`, // Desktop Max.
  lgMin: `${BREAKPOINT.lg}px`, // Large Desktop Min.
  lgMax: `${BREAKPOINT.xl - 1}px`, // Large Desktop Max.
  xlMin: `${BREAKPOINT.xl}px`, // Extra Large Desktop Min.

  hMdMin: `${HEIGHTBREAKPOINT.md}px`, // Desktop Height Min.
} as const;
