import environment from '../utils/environment';

export const LOG_SETTING_STORAGE_KEY = 'LOG';
export const logLevels = ['debug', 'info', 'warn', 'error'] as const;
export type LogLevel = (typeof logLevels)[number];
export type Context = object;
export type Logger = {
  log(logLevel: LogLevel, message: string, context?: object, error?: Error): void;
} & {
  [K in LogLevel]: (message: string, context?: object, error?: Error) => void;
};

export type LoggingOptions = {
  context?: object;
  batchId?: number;
  shipmentId?: number;
  referenceCode?: string;
};

const loggingService = {
  getLogger(enableDebugging?: boolean): Logger {
    const log = (logLevel: LogLevel, message: string, context?: object, error?: Error) => {
      // eslint-disable-next-line no-console
      const consoleLogFunc = console[logLevel] ?? console.log;
      const shouldSkip = logLevel === 'debug' && !enableDebugging;

      let consoleMessage = `%c[${logLevel.toUpperCase()}]%c ${message}`;
      if (shouldSkip) {
        consoleMessage += ` %c(This message will not show up in the backend logs)`;
      }

      const consoleLogArgs = [];
      consoleLogArgs.push(consoleMessage);
      consoleLogArgs.push('font-weight:bold;');
      consoleLogArgs.push('font-weight:normal;');
      if (shouldSkip) {
        consoleLogArgs.push('font-style:italic;color:gray;');
      }
      if (context) {
        consoleLogArgs.push(context);
      }

      // Log to console
      if (environment.isDevelopment()) {
        consoleLogFunc.apply(console, consoleLogArgs);
      }

      // Send off to Datadog unless severity is debug and debug mode is disabled
      if (!shouldSkip) {
        // We group context data with the 'context' key and also set the message as a separate field
        // that we can use for segmenting
        const logContext = { message, context };

        // package sets DD_LOGS automatically on window. Therefore, this method can be used inside bridge & web-client
        window.DD_LOGS?.onReady(() => {
          window.DD_LOGS?.logger.log(message, logContext, logLevel, error);
        });
      }
    };

    const methods: Partial<Logger> = { log };

    // Bind convenience functions for each possible log level, e.g. logger.warn('foo')
    logLevels.forEach((logLevel) => {
      methods[logLevel] = log.bind(methods, logLevel);
    });
    return methods as Logger;
  },
};

export default loggingService;
