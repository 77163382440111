export const BORDER_WIDTH = {
  none: '0px',
  xs: '1px',
  sm: '2px',
  lg: '3px',
  xl: '5px',
} as const;

export const BORDER_RADIUS = {
  none: '0px',
  xxs: '2px',
  xs: '4px',
  sm: '5px',
  lg: '10px',
} as const;
