/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

const initValidation = () =>
  yup.setLocale({
    mixed: {
      default: 'This field is invalid',
      required: 'This field is required',
      notType: ({ type, originalValue }) => {
        // currently, only the number case is relevent for us.
        if (type === 'number') {
          const invalidString = originalValue.replace(/[0-9]/g, '');
          const invalidCharacters = Array.from(new Set(invalidString)).join(', ');
          return invalidCharacters.length > 1
            ? `Values must be numeric. "${invalidCharacters}" are not allowed`
            : `Values must be numeric. ${invalidCharacters} is not allowed`;
        }
        return `Entered values are not allowed`;
      },
    },
    string: {
      length: ({ length }) =>
        `Value must be exactly ${length} character${length === 1 ? '' : 's'} long`,
      min: ({ min }) => `Value must be at least ${min} character${min === 1 ? '' : 's'} long`,
      max: ({ max }) => `Value must be at most ${max} character${max === 1 ? '' : 's'} long`,
      matches: 'Value must match the following: "${regex}"',
      email: 'Enter a valid email address',
      url: 'Value must be a valid URL',
      uuid: 'Value must be a valid UUID',
      trim: 'Value must not start or end with whitespace',
      lowercase: 'Value must be all lowercase',
      uppercase: 'Value must be all uppercase',
    },
    number: {
      min: 'Value must be greater than or equal to ${min}',
      max: 'Value must be less than or equal to ${max}',
      lessThan: 'Value must be less than ${less}',
      moreThan: 'Value must be greater than ${more}',
      notEqual: 'Value must not be equal to ${notEqual}',
      positive: 'Value must be a positive number',
      negative: 'Value must be a negative number',
      integer: 'Value must be an integer',
    },
    date: {
      min: 'Date must be later than ${min}',
      max: 'Date must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field has unspecified keys: ${unknown}',
    },
    array: {
      min: ({ min }) => `Field must have at least ${min} item${min === 1 ? '' : 's'}`,
      max: ({ max }) =>
        `Field field must have less than or equal to ${max} item${max === 1 ? '' : 's'}`,
    },
  });

export default initValidation;
