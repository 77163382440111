import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const rememberLoginMutation = gql(/* GraphQL */ `
  mutation RememberLoginMutation {
    rememberLogin
  }
`);

export const useRememberLoginMutation = () =>
  useMutation(rememberLoginMutation, { errorPolicy: 'all' });
