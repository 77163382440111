import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import withOpacity from '../../utils/withOpacity';
import Button, { ButtonProps, ButtonSize } from './Button';

export type ProgressButtonProps = ButtonProps & {
  progress?: boolean;
  datadogAction?: string;
};

const stripeSizeMap = new Map<ButtonSize, string>([
  ['small', '28px'],
  ['medium', '80px'],
  ['large', '100px'],
  ['xLarge', '100px'],
]);

const progressAnimation = (px: string = '80px') => keyframes`
  from {
    background-position: ${SPACING.none} ${SPACING.none}
  }
  to {
    background-position: ${px} ${SPACING.none}
  }
`;

const ProgressButton = styled(Button)<ProgressButtonProps>`
  ${({ progress, size = 'medium' }) => {
    if (progress) {
      return css`
        background-size: ${stripeSizeMap.get(size)} ${stripeSizeMap.get(size)};
        background-position: 0 0;
        background-repeat: repeat;
        background-image: linear-gradient(
          45deg,
          ${withOpacity(GREYSCALE.white, 0.15)} 25%,
          transparent 25%,
          transparent 50%,
          ${withOpacity(GREYSCALE.white, 0.15)} 50%,
          ${withOpacity(GREYSCALE.white, 0.15)} 75%,
          transparent 75%,
          transparent
        );
        animation: ${progressAnimation(stripeSizeMap.get(size))} 2s linear infinite;
      `;
    }

    return undefined;
  }}
`;

export default ProgressButton;
