import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const shipmentBoundariesQuery = gql(/* GraphQL */ `
  query ShipmentBoundariesQuery {
    shipmentBoundaries {
      maxWeight
      maxCombinedLength
      maxLengthPlusGirth
      minLongSide
      maxLongSide
      minMiddleSide
      maxMiddleSide
      minShortSide
      maxShortSide
    }
  }
`);

export const useShipmentBoundariesQuery = () => useQuery(shipmentBoundariesQuery);
