import { useEffect, useState } from 'react';
import { COLOR, GREYSCALE, PirateShipColor } from '../styles/colors';

const useHighlightColor = (error: boolean, focused: boolean): PirateShipColor => {
  const [highlightColor, setHighlightColor] = useState<PirateShipColor>(GREYSCALE.grey30);
  useEffect(() => {
    if (error) {
      setHighlightColor(COLOR.red);
    } else if (focused) {
      setHighlightColor(COLOR.blue);
    } else {
      setHighlightColor(GREYSCALE.grey30);
    }
  }, [error, focused]);
  return highlightColor;
};

export default useHighlightColor;
