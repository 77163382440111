import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { GREYSCALE, COLOR } from '../../styles/colors';
import Button from '../form/Button';
import { MaybeExternalLink, FakeLink } from '../Link';
import formatCurrency from '../../utils/formatCurrency';
import carrierKeyToIconUrl from '../../utils/carrierKeyToIconUrl';
import Badge from '../Badge';
import { TYPOGRAPHY } from '../../styles/typography';
import { BORDER_WIDTH, BORDER_RADIUS } from '../../styles/borders';
import convertBbcode from '../../utils/convertBbcode';
import CarrierLogo from './CarrierLogo';

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    border-color: ${GREYSCALE.grey30};
    border-style: solid;
    border-width: ${BORDER_WIDTH.sm};
    border-radius: ${BORDER_RADIUS.sm};
    padding: 0;
    height: 100%;
    margin: 0 6px;
    background-color: ${GREYSCALE.grey00};
  `,
  MainWrapper: styled.div`
    flex: 1;
    padding: 20px;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
  `,
  MainHeadlineWrapper: styled.div`
    display: flex;
    align-items: baseline;
  `,
  MainHeadline: styled.h2`
    margin: 0;
    padding-left: 6px;
    font-size: 25px;
    line-height: 1.2;
    flex-grow: 2;
    margin-bottom: 12px;
  `,
  MainCarrier: styled.div`
    display: flex;
    align-self: flex-start;
  `,
  MainDescription: styled.div<{ withSubdescription: boolean }>`
    margin: 7px 0;
    margin-top: 7px;
    margin-bottom: ${({ withSubdescription }) => (withSubdescription ? '0px' : '7px')};
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    color: ${GREYSCALE.grey80};
    font-size: 19px;
    display: block;
  `,
  MainSubdescription: styled.span`
    font-size: 13px;
    color: ${GREYSCALE.grey50};
    margin-bottom: 7px;
  `,
  MainSmall: styled.span`
    font-size: 13px;
    color: ${GREYSCALE.grey50};
    margin-bottom: 0px;
  `,
  Accuracy: styled.span`
    font-size: ${TYPOGRAPHY.fontSize.xs};
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    color: ${GREYSCALE.grey50};
  `,
  PriceInfoWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    border-top: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    border-bottom: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
    background-color: ${COLOR.lightGreen};
    padding: 16px 20px;
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    font-size: 20px;
    line-height: 20px;
  `,
  PriceInfoSavingsWrapper: styled.div`
    color: ${COLOR.darkGreen};
    display: flex;
    flex-direction: column;
  `,
  PriceInfoClientPrice: styled.span`
    padding-top: 4px;
    text-align: right;
    font-size: 40px;
    line-height: 36px;
  `,
  PriceInfoSavingsText: styled.span`
    color: ${COLOR.darkGreen};
  `,
  PriceInfoSavingsCrossedPrice: styled.span`
    color: ${GREYSCALE.grey50};
    text-decoration: line-through;
  `,
  UpsSavingsText: styled.span`
    color: ${COLOR.darkGreen};
    font-size: 19px;
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
  `,
  BottomWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 10px;
  `,
  BottomLink: styled(MaybeExternalLink)`
    display: block;
    padding: 6px 0;
    font-size: ${TYPOGRAPHY.fontSize.sm};
    color: ${COLOR.blue};

    :hover {
      text-decoration: underline;
    }
  `,
};

type RateCardSkeletonProps = {
  iconUrl?: string;
  iconAltText?: string;
  title: ReactNode;
  cheapest?: boolean;
  fastest?: boolean;
  descriptionLine1?: ReactNode;
  descriptionLine2?: ReactNode;
  descriptionLine2Extra?: ReactNode; // PP-9388: perhaps we can take this out again if we do not need it after a well thought out solution
  descriptionLine3?: ReactNode;
  accuracy?: ReactNode;
  priceInfo: ReactNode;
  learnMoreUrl?: string;
  learnMoreText?: string;
  buttonText?: string;
  onButtonClick: () => void;
};

export function RateCardSkeleton({
  iconUrl,
  iconAltText = '',
  title,
  cheapest,
  fastest,
  descriptionLine1,
  descriptionLine2,
  descriptionLine2Extra,
  descriptionLine3,
  accuracy,
  priceInfo,
  learnMoreUrl,
  learnMoreText = 'Learn more',
  buttonText = 'Ship now',
  onButtonClick,
}: RateCardSkeletonProps) {
  return (
    <Styled.Container className="RateCard">
      <Styled.MainWrapper>
        <Styled.MainHeadlineWrapper>
          {iconUrl && (
            <Styled.MainCarrier>
              <CarrierLogo src={iconUrl} alt={iconAltText} carrierKey={iconAltText} />
            </Styled.MainCarrier>
          )}
          <Styled.MainHeadline>{title}</Styled.MainHeadline>
        </Styled.MainHeadlineWrapper>
        {(cheapest || fastest || descriptionLine1) && (
          <Styled.MainSmall>
            {cheapest && <Badge variant="cheapest">Cheapest</Badge>}
            {fastest && <Badge variant="fastest">Fastest</Badge>}
            {descriptionLine1}
          </Styled.MainSmall>
        )}
        {descriptionLine2 && (
          <Styled.MainDescription withSubdescription={!!descriptionLine2Extra}>
            {descriptionLine2}{' '}
            {accuracy != null && <Styled.Accuracy>{`(${accuracy}% accuracy)`}</Styled.Accuracy>}
          </Styled.MainDescription>
        )}
        {descriptionLine2Extra && (
          <Styled.MainSubdescription>{descriptionLine2Extra}</Styled.MainSubdescription>
        )}
        {descriptionLine3 && <Styled.MainSmall>{descriptionLine3}</Styled.MainSmall>}
      </Styled.MainWrapper>
      <Styled.PriceInfoWrapper>{priceInfo}</Styled.PriceInfoWrapper>
      <Styled.BottomWrapper>
        <Styled.BottomLink href={learnMoreUrl}>{learnMoreText}</Styled.BottomLink>
        <Button variant="info" onClick={() => onButtonClick()}>
          {buttonText}
        </Button>
      </Styled.BottomWrapper>
    </Styled.Container>
  );
}

type UpsCreateAccountRateCardProps = {
  onRegisterLinkClick: () => void;
  onButtonClick: () => void;
};

export function UpsCreateAccountRateCard({
  onRegisterLinkClick,
  onButtonClick,
}: UpsCreateAccountRateCardProps) {
  return (
    <RateCardSkeleton
      iconUrl={carrierKeyToIconUrl('ups')}
      iconAltText="UPS"
      title="Multiple UPS options"
      descriptionLine1="Instant Access, Deep Discounts"
      descriptionLine2={
        <>
          To see more UPS rates,
          <br />
          <FakeLink onClick={onRegisterLinkClick}>create a Pirate Ship account</FakeLink>
        </>
      }
      priceInfo={
        <Styled.UpsSavingsText>Save up to 76% on UPS with Pirate Ship</Styled.UpsSavingsText>
      }
      learnMoreUrl="#"
      onButtonClick={onButtonClick}
    />
  );
}

type UpsAgreeTermsRateCardProps = {
  onAgreeTermsClick: () => void;
  onButtonClick: () => void;
};

export function UpsAgreeTermsRateCard({
  onAgreeTermsClick,
  onButtonClick,
}: UpsAgreeTermsRateCardProps) {
  return (
    <RateCardSkeleton
      iconUrl={carrierKeyToIconUrl('ups')}
      iconAltText="UPS"
      title="Multiple UPS options"
      descriptionLine1="Instant Access, Deep Discounts"
      descriptionLine2={
        <>
          To see more UPS rates,
          <br />
          <FakeLink onClick={onAgreeTermsClick}>click here to agree to the UPS terms</FakeLink>
        </>
      }
      priceInfo={
        <Styled.UpsSavingsText>Save up to 76% on UPS with Pirate Ship</Styled.UpsSavingsText>
      }
      learnMoreUrl="#"
      onButtonClick={onButtonClick}
    />
  );
}

export type Rate = {
  title: string;
  deliveryDescription: string;
  trackingDescription: string;
  serviceDescription: string;
  pricingDescription: string;
  cubicTier: string | null;
  mailClassKey: string;
  packageTypeKey: string;
  zone: string;
  mailClass: {
    international: boolean;
    accuracy: number | null;
  };
  carrier: {
    carrierKey: string;
    title: string;
  };
  totalPrice: number;
  crossedTotalPrice: number;
  learnMoreUrl: string;
  cheapest: boolean;
  fastest: boolean;
};

export type RateCardProps = {
  rate: Rate;
  onButtonClick: (rate: Rate) => void;
};

export default function RateCard({ rate, onButtonClick }: RateCardProps) {
  const {
    title,
    deliveryDescription,
    trackingDescription,
    serviceDescription,
    pricingDescription,
    cubicTier,
    mailClassKey,
    zone,
    mailClass,
    carrier: { carrierKey, title: carrierTitle },
    totalPrice,
    crossedTotalPrice,
    learnMoreUrl,
    cheapest,
    fastest,
  } = rate;

  const descriptionLine1 = (() => {
    const parts: string[] = [];

    if (cubicTier) {
      parts.push(`${Number(cubicTier) / 10} cubic ${cubicTier === '10' ? 'foot' : 'feet'}`);
    }

    const zoneWording = mailClass.international ? 'Price Group' : 'Zone';

    if (zone !== null && zone > '0') {
      parts.push(`${zoneWording} ${zone}`);
    }

    if (pricingDescription !== '') {
      parts.push(pricingDescription);
    }

    return parts.join(', ');
  })();

  // PP-9388
  // if we have extra information in the delivery description (demarcated with ';', then we extract it here and put it in an extra line)
  const [descriptionLine2, descriptionLine2Extra] = deliveryDescription.split(';');

  const descriptionLine3 = (() => {
    const parts: string[] = [];

    if (trackingDescription !== '') {
      parts.push(trackingDescription);
    }

    if (serviceDescription !== '') {
      parts.push(serviceDescription);
    }

    return parts.join(' • ');
  })();

  const icon = {
    url: carrierKeyToIconUrl(carrierKey, mailClassKey),
    title:
      // Future-proof this by also checking for 'SimpleExportRate'
      mailClassKey === 'FirstGlobalRate' || mailClassKey === 'SimpleExportRate'
        ? 'Pirate Ship'
        : carrierTitle,
  };

  return (
    <RateCardSkeleton
      iconUrl={icon.url}
      iconAltText={icon.title}
      title={title}
      cheapest={cheapest}
      fastest={fastest}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      descriptionLine2Extra={descriptionLine2Extra}
      descriptionLine3={convertBbcode(descriptionLine3)}
      accuracy={mailClass.accuracy}
      priceInfo={
        <>
          <Styled.PriceInfoSavingsWrapper>
            {crossedTotalPrice > totalPrice && (
              <>
                <Styled.PriceInfoSavingsText>
                  Save {(100 - (100 / crossedTotalPrice) * totalPrice).toFixed(0)}%
                </Styled.PriceInfoSavingsText>
                <Styled.PriceInfoSavingsCrossedPrice>
                  {formatCurrency(crossedTotalPrice)} retail
                </Styled.PriceInfoSavingsCrossedPrice>
              </>
            )}
          </Styled.PriceInfoSavingsWrapper>
          <Styled.PriceInfoClientPrice>{formatCurrency(totalPrice)}</Styled.PriceInfoClientPrice>
        </>
      }
      learnMoreUrl={learnMoreUrl}
      learnMoreText="Learn more &amp; view rates"
      onButtonClick={() => onButtonClick(rate)}
    />
  );
}
