import styled from '@emotion/styled';
import { useState } from 'react';
import packageTypeIcons from '../../assets/packageTypeIcons';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import roundFloat from '../../utils/roundFloat';
import sortDims from '../../utils/sortDims';
import Label from '../form/Label';
import TextField, { TextFieldWrapper } from '../form/TextField';
import { Col, Container, Row } from '../layout/Grid';
import DimensionCol from './DimensionCol';
import CarrierLogo from './CarrierLogo';

// @deprecated but still used on marketing page https://www.pirateship.com/usps/parcel-select-ground-cubic
const parcelSelectGroundCubicTable: TierTable = [
  { maxSize: 16, cubicFeet: 0.1, tier: 1 },
  { maxSize: 21, cubicFeet: 0.2, tier: 2 },
  { maxSize: 24, cubicFeet: 0.3, tier: 3 },
  { maxSize: 26, cubicFeet: 0.4, tier: 4 },
  { maxSize: 28, cubicFeet: 0.5, tier: 5 },
  { maxSize: 30, cubicFeet: 0.6, tier: 6 },
  { maxSize: 32, cubicFeet: 0.7, tier: 7 },
  { maxSize: 34, cubicFeet: 0.8, tier: 8 },
  { maxSize: 35, cubicFeet: 0.9, tier: 9 },
  { maxSize: 36, cubicFeet: 1.0, tier: 10 },
];

const groundAdvantageCubicTable: TierTable = [
  { maxSize: 16, cubicFeet: 0.1, tier: 1 },
  { maxSize: 21, cubicFeet: 0.2, tier: 2 },
  { maxSize: 24, cubicFeet: 0.3, tier: 3 },
  { maxSize: 26, cubicFeet: 0.4, tier: 4 },
  { maxSize: 28, cubicFeet: 0.5, tier: 5 },
  { maxSize: 30, cubicFeet: 0.6, tier: 6 },
  { maxSize: 32, cubicFeet: 0.7, tier: 7 },
  { maxSize: 34, cubicFeet: 0.8, tier: 8 },
  { maxSize: 35, cubicFeet: 0.9, tier: 9 },
  { maxSize: 36, cubicFeet: 1.0, tier: 10 },
];

const priorityMailCubicTable: TierTable = [
  { maxSize: 21, cubicFeet: 0.1, tier: 1 },
  { maxSize: 27, cubicFeet: 0.2, tier: 2 },
  { maxSize: 31, cubicFeet: 0.3, tier: 3 },
  { maxSize: 34, cubicFeet: 0.4, tier: 4 },
  { maxSize: 36, cubicFeet: 0.5, tier: 5 },
];

const Styled = {
  Container: styled.div`
    max-width: 465px;
    margin: 0 auto;

    ${TextFieldWrapper} {
      border-bottom-left-radius: ${BORDER_RADIUS.none} !important;
      border-bottom-right-radius: ${BORDER_RADIUS.none} !important;
    }
  `,
  PackageButton: styled.button<{ selected: boolean }>`
    padding: ${SPACING.lg};
    border: ${BORDER_WIDTH.xs} solid
      ${({ selected }) => (selected ? COLOR.darkBlue : GREYSCALE.grey50)};
    border-radius: ${BORDER_RADIUS.sm};
    color: ${({ selected }) => (selected ? GREYSCALE.white : 'auto')} !important;
    background-color: ${({ selected }) => (selected ? COLOR.blue : 'auto')};
    cursor: pointer;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${CarrierLogo} {
      height: 100px;
      width: auto;
    }
    @media (max-width: ${MEDIA_QUERY.smMin}) {
      width: clamp(100px, 40vw, 200px);
      height: clamp(100px, 40vw, 200px);

      ${CarrierLogo} {
        height: clamp(60px, 16vw, 100px);
        width: auto;
      }
    }
  `,
  PackageType: styled.div`
    padding: ${SPACING.md} 0;
    @media (max-width: ${MEDIA_QUERY.xsMin}) {
      display: none;
    }
  `,
  Solution: styled.div<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: ${SPACING.lg};
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    text-align: center;
    align-items: center;
    justify-content: center;
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm} ${BORDER_WIDTH.sm};
    border-color: ${GREYSCALE.grey30};
    border-style: solid;
    border-bottom-left-radius: ${BORDER_RADIUS.sm};
    border-bottom-right-radius: ${BORDER_RADIUS.sm};
  `,
  Hint: styled.div`
    border: ${BORDER_WIDTH.xs} solid ${COLOR.darkGreen};
    color: ${COLOR.darkGreen};
    border-radius: ${BORDER_RADIUS.sm};
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${SPACING.md};
  `,
  MailClassTitle: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.lg};
    padding-bottom: ${SPACING.md};
  `,
};

type MailClass = 'parcelSelectGroundCubic' | 'groundAdvantageCubic' | 'priorityMailCubic';
type PackageType = 'boxOrRigidPackage' | 'envelopePolyBagBoxBag';

type TierTableRow = {
  maxSize: number;
  cubicFeet: number;
  tier: number;
};

export type TierTable = TierTableRow[];

const prompt = new Map<PackageType, string>([
  ['boxOrRigidPackage', `Enter your package's dimensions (Inches - Max 18")`],
  ['envelopePolyBagBoxBag', `Enter the empty envelope's dimensions (Inches - Max 18")`],
]);

type CubicCalculatorProps = {
  mailClass: MailClass;
};

function getTierTable(mailClass: MailClass) {
  switch (mailClass) {
    case 'parcelSelectGroundCubic':
      return parcelSelectGroundCubicTable;
    case 'groundAdvantageCubic':
      return groundAdvantageCubicTable;
    case 'priorityMailCubic':
      return priorityMailCubicTable;
    default:
      throw new Error('Invalid mail class');
  }
}

function getMailClassTitle(mailClass: MailClass) {
  switch (mailClass) {
    case 'parcelSelectGroundCubic':
      return 'Parcel Select Ground Cubic';
    case 'groundAdvantageCubic':
      return 'Ground Advantage Cubic';
    case 'priorityMailCubic':
      return 'Priority Mail Cubic';
    default:
      throw new Error('Invalid mail class');
  }
}

export default function CubicCalculator({ mailClass }: CubicCalculatorProps) {
  const [selectedPackageType, setSelectedPackageType] = useState<PackageType>('boxOrRigidPackage');

  const tierTable = getTierTable(mailClass);
  const mailClassTitle = getMailClassTitle(mailClass);

  // all three measurements are in inches
  const [length, setLength] = useState<string>('');
  const [width, setWidth] = useState<string>('');
  const [height, setHeight] = useState<string>('');

  const toCubicFeet = (xInch: number, yInch: number, zInch: number) =>
    (xInch * yInch * zInch) / 1728; // 1728 is how many cubic inches are in a cubic foot
  const toNumberWord = (n: 1 | 2 | 3) => {
    if (n === 1) return 'One';
    if (n === 2) return 'Two';
    if (n === 3) return 'Three';
    return '';
  };
  const isTooBig = (table: TierTable): boolean => {
    if (selectedPackageType === 'boxOrRigidPackage') {
      // 3D
      const cubicFeet = toCubicFeet(+length, +width, +height);
      return cubicFeet > table[table.length - 1].cubicFeet;
    }
    // 2D
    const maxSize = +length + +width;
    return maxSize > table[table.length - 1].maxSize;
  };

  // delivers the tier table row appropriate to the measurements given (rate), and next smaller one
  const getCubicTierRows = ():
    | { rate: TierTableRow; nextSmallerRate: TierTableRow }
    | undefined => {
    const roundDownToNearestQuarter = (a: number) => Math.floor(a * 4) / 4;
    const [dimX, dimY, dimZ] = [+length, +width, +height].map(roundDownToNearestQuarter);
    const getTableIndex2d = () => {
      const maxSize = dimX + dimY;
      return tierTable.findIndex((t) => maxSize <= t.maxSize);
    };

    const getTableIndex3d = () => {
      const cubicFeet = toCubicFeet(dimX, dimY, dimZ);
      return tierTable.findIndex((t) => cubicFeet <= t.cubicFeet);
    };
    const index =
      selectedPackageType === 'boxOrRigidPackage' ? getTableIndex3d() : getTableIndex2d();

    if (index < 0) return undefined;
    if (index === 0) {
      // if the rate is the lowest in the table, create a fake next smaller rate for calculations
      return { rate: tierTable[0], nextSmallerRate: { tier: 0, maxSize: -20, cubicFeet: -0.1 } };
    }
    return { rate: tierTable[index], nextSmallerRate: tierTable[Math.max(index - 1, 0)] };
  };

  const getIncompleteFeedback = (): string | undefined => {
    if (
      selectedPackageType === 'boxOrRigidPackage' &&
      (length === '' || width === '' || height === '')
    ) {
      return "Enter your package's outer dimensions";
    }

    if (selectedPackageType === 'envelopePolyBagBoxBag' && (length === '' || width === '')) {
      return "Enter your envelope's empty outer dimensions";
    }

    return undefined;
  };

  const getFeedback = (): string | undefined => {
    // check if any inputs are still empty
    const incompleteFeedback = getIncompleteFeedback();
    if (incompleteFeedback) return incompleteFeedback;

    const dims =
      selectedPackageType === 'boxOrRigidPackage' ? [+length, +width, +height] : [+length, +width];

    const minDims = selectedPackageType === 'boxOrRigidPackage' ? [6, 3, 0.25] : [0.1, 0.1];

    // check if any dimensions are too long (both mailClasses have the same 18" limit)
    const isTooLong = (n: number) => n > 18;
    const countTrues = (p: number, c: boolean) => (c ? p + 1 : p); // reduces an array to how many true values are in it
    const tooLongCount = dims.map(isTooLong).reduce(countTrues, 0);
    if (tooLongCount) {
      return `${toNumberWord(tooLongCount as 1 | 2 | 3)} dimension${
        tooLongCount > 1 ? 's are' : ' is'
      } too long for ${mailClassTitle}, maximum 18"`;
    }

    // check if any dimensions are too short
    const countOversized = (p: number, c: number, i: number) => (c < minDims[i] ? p + 1 : p); // compare each ordered dimension and count sinners
    const tooShortCount: number = sortDims(dims).reduce(countOversized, 0);
    if (tooShortCount) {
      return `${toNumberWord(tooShortCount as 1 | 2 | 3)} dimension${
        tooShortCount > 1 ? 's are' : ' is'
      } too short for ${mailClassTitle}, minimum dimensions are ${minDims.join('x')}"`;
    }

    // check if the package volume is too big
    // (only need to do this for Box or Rigid Package, as there is a tier for maxSize=18+18=36 in 2d for both rates)
    if (selectedPackageType === 'boxOrRigidPackage' && isTooBig(tierTable)) {
      return `Keep your package under ${
        tierTable[tierTable.length - 1].cubicFeet
      } cubic feet to qualify for ${mailClassTitle}.`;
    }

    return undefined;
  };

  const getSolution = (): string =>
    getFeedback() || `Qualifying Cubic Tier: ${getCubicTierRows()?.rate.tier}`;

  const getHint = (): string | undefined => {
    const tiers = getCubicTierRows();
    // gives a normalised interpolation of how close t is to right (from left)
    const lerp = (left: number, right: number, t: number): number => (t - left) / (right - left);
    if (tiers) {
      const t =
        selectedPackageType === 'boxOrRigidPackage'
          ? lerp(
              tiers.nextSmallerRate.cubicFeet,
              tiers.rate.cubicFeet,
              toCubicFeet(+length, +width, +height),
            )
          : lerp(tiers.nextSmallerRate.maxSize, tiers.rate.maxSize, +length + +width);
      if (t >= 0 && t <= 0.5) {
        return "If your package was a little smaller, you'd be in a cheaper tier.";
      }
      if (t > 0.5 && t < 0.9) {
        return 'Your package could be larger and stay in the same pricing tier.';
      }
      return "You're maxing out the volume for this pricing tier. Nice work!";
    }
    return undefined;
  };

  return (
    <Styled.Container>
      <Container>
        <Row justify="center" align="center" wrap="nowrap" spaceBelow>
          <Col xs="content">
            <Styled.PackageButton
              selected={selectedPackageType === 'boxOrRigidPackage'}
              onClick={() => setSelectedPackageType('boxOrRigidPackage')}
            >
              <CarrierLogo src={packageTypeIcons.Parcel} />
              <Styled.PackageType>Box or Rigid Package</Styled.PackageType>
            </Styled.PackageButton>
          </Col>
          <div>or</div>
          <Col xs="content">
            <Styled.PackageButton
              selected={selectedPackageType === 'envelopePolyBagBoxBag'}
              onClick={() => setSelectedPackageType('envelopePolyBagBoxBag')}
            >
              <CarrierLogo src={packageTypeIcons.SoftEnvelope} />
              <Styled.PackageType>Envelope, Poly Bag or Box in a Bag</Styled.PackageType>
            </Styled.PackageButton>
          </Col>
        </Row>
        <Row justify="center" spaceBelow>
          <Col xs="content">
            <div>{prompt.get(selectedPackageType)}</div>
          </Col>
        </Row>
        <Row nogutter>
          <DimensionCol dimension="x" xs={selectedPackageType === 'boxOrRigidPackage' ? 4 : 6}>
            <TextField
              center
              type="number"
              label="Length"
              value={length}
              onChange={(e) => setLength(e.target.value)}
            />
          </DimensionCol>
          <DimensionCol
            dimension="y"
            xs={selectedPackageType === 'boxOrRigidPackage' ? 4 : 6}
            zRequired={selectedPackageType === 'boxOrRigidPackage'}
          >
            <TextField
              center
              type="number"
              label="Width"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            />
          </DimensionCol>
          {selectedPackageType === 'boxOrRigidPackage' && (
            <DimensionCol dimension="z" xs={4}>
              <TextField
                center
                type="number"
                label="Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </DimensionCol>
          )}
        </Row>
        <Row justify="center" spaceBelow>
          <Col md={12}>
            <Styled.Solution
              backgroundColor={(() => {
                if (getIncompleteFeedback()) {
                  return GREYSCALE.grey10;
                }
                if (getFeedback()) {
                  return COLOR.lightRed;
                }
                return COLOR.lightGreen;
              })()}
            >
              {getSolution()}
            </Styled.Solution>
          </Col>
        </Row>

        {selectedPackageType === 'boxOrRigidPackage' && (
          <Row justify="center" spaceBelow>
            <Col xs="content">
              <Label>
                Exact Cubic Volume:{' '}
                {roundFloat(Math.max(0, toCubicFeet(+height, +width, +length)), 2)}
              </Label>
            </Col>
          </Row>
        )}
        <Row justify="center" spaceBelow>
          <Col md={12}>{!getFeedback() && <Styled.Hint>{getHint()}</Styled.Hint>}</Col>
        </Row>
      </Container>
    </Styled.Container>
  );
}
