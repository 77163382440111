import compareAsc from 'date-fns/compareAsc';
import { CustomsFormSubformValues } from '../components/subforms/CustomsFormSubform';
import { CustomsLineItemSubformValues } from '../components/subforms/CustomsLineItemSubform';
import formatWeight, { toOunces, toWeightObject } from '../utils/formatWeight';
import sortDims from '../utils/sortDims';
import { ValidatorConfig } from './addCustomMethods';
import newDate from '../utils/newDate';

// VALIDATORS //
// a collection of pure functions that check a value, and:
// return an error message string if the check is failed
// return undefined if the value passes the check.
// Optionally, a second config object parameter may be added to these functions.
// Optionally, a third otherValues object gives all other values for comparison.

export type Validator<T, C = void> = (
  value: T | null | undefined,
  config?: ValidatorConfig<C>,
  otherValues?: Object,
) => string | undefined;
/**
 * Validate that a string only consists of letters.
 */
export const letters: Validator<string> = (value) => {
  if (value == null || !/^[a-z]+$/i.test(value)) {
    return 'Letters only';
  }

  return undefined;
};

/**
 * Validate that a string matches a valid (basic) email pattern
 * Our policy:
 * only allow alphanumeric, dot, hyphen, underscore and plus in local part. no consecutive, starting or ending dots. 64 char max.
 * only allow alphanumeric and dash in domain. no starting or ending dash. 63 char max.
 * only allow alphabet in top-level domain. 2-63 character range.
 */
export const emailAddress: Validator<string> = (value) => {
  // What each group means:
  // LOCAL PART
  // (?!\.) : do not start with a dot in the local part
  // (?!.*\.{2,}.*@) : do not allow two or more dots in a row before the atsign
  // (?!.*\.@) : do not end with a dot in the local part, i.e. don't allow ".@"
  // [a-z0-9.+_-]{1,64}@ : allow alphanumeric, dot, plus, underscore and hypen characters (at least 1, no more than 64) before the atsign

  // DOMAIN:
  // (?![-.]) : no starting with a dash or dot
  // (?!.*\.-) : no dash after the first dot in the domain
  // (?!.*-\.) : no dash before the first dot in the domain
  // (?!.*[^a-z0-9.-]+.*\.) : don't allow anything OTHER than alphanumeric, dot and dash in the domain
  // (?!.*\.{2,}) : don't allow 2 or more consecutive dots
  // (?!.*[a-z0-9-]{64,}\.) : don't allow more than 63 alphanumeric characters
  // (?=.*\.[a-z]{2,63}$) : after the final dot, allow only between 2-63 letters
  const emailRegex =
    /^(?!\.)(?!.*\.{2,}.*@)(?!.*\.@)[a-z0-9.+_-]{1,64}@(?![-.])(?!.*\.-)(?!.*-\.)(?!.*[^a-z0-9.-]+.*\.)(?!.*\.{2,})(?!.*[a-z0-9-]{64,}\.)(?=.*\.[a-z]{2,63}$)/i;

  if (value == null || !emailRegex.test(value)) {
    return 'Enter a valid email';
  }

  return undefined;
};

/**
 * Validate numbers and fractions that represent a length.
 *
 * Valid formats:
 * '12'
 * '12"'
 * '12.25'
 * '.25'
 * '12/5'
 * '12 1/4'
 * '12 /4'
 * '12-2/5'
 * '12 - 2/5'
 * '12.25 1/12'
 * '12.25 /12'
 * '12.25" /12'
 */
export const packageLength: Validator<string> = (value) => {
  if (
    value == null ||
    !/^(\.+)?([0-9]+[.]?)+(["″]+)?([/]+[0-9]+)?([\s-]+)?([0-9]*?[/]+[0-9]+)?$/gm.test(value)
  ) {
    return 'Enter a valid number e.g. 2, 2.25 or 2 1/4';
  }

  return undefined;
};

export type WeightValue = {
  weightPounds: number;
  weightOunces: number;
};
/**
 * Validate that weight is not too heavy.
 */
export type WeightConfig = {
  maxWeight: number;
};
export const packageWeight: Validator<WeightValue, WeightConfig> = (value, config) => {
  if (value == null || Number.isNaN(value)) {
    return 'Enter a weight';
  }
  const { weightOunces, weightPounds } = value;
  if (Number.isNaN(weightOunces) || Number.isNaN(weightPounds)) {
    return 'One or more weights is not a number';
  }
  const weightInOunces = toOunces({ pounds: weightPounds, ounces: weightOunces });
  if (weightInOunces === 0) {
    return 'Weight cannot be 0 lb';
  }

  if (!config) {
    return undefined;
  }

  const { pounds, ounces } = toWeightObject(weightInOunces); // "redistribute" weight in case user typed something over 16 ounces
  if (weightInOunces > config.maxWeight) {
    return `Maximum weight is ${config.maxWeight / 16} lb, you entered ${pounds} lb ${
      ounces > 0 ? `${ounces} oz` : ''
    }`;
  }
  return undefined;
};

export type MaxAverageWeightConfig = {
  parcelCount: number;
  max: number;
};

export const maxAverageWeight: Validator<number, MaxAverageWeightConfig> = (value, config) => {
  if (!value || !config) return undefined;
  const allowedTotalWeight = config.parcelCount * config.max;
  if (value >= allowedTotalWeight) {
    return `Average parcel weight should not exceed ${config.max} lb`;
  }
  return undefined;
};

// as we are using values from _another_ subform to verify this validation rule, we pass it in as a config
// note: normally if we validate based on other values in the same form, we use yup's in-built .when() method
export const customsWeightsPackageWeight: Validator<WeightValue, CustomsFormSubformValues> = (
  value,
  customsFormSubformValues,
) => {
  if (!value || !customsFormSubformValues) return undefined;
  const { weightOunces, weightPounds } = value;
  const { customsItems, customsFormEnabled } = customsFormSubformValues;

  const weightInOunces = toOunces({ pounds: weightPounds, ounces: weightOunces });

  const totalCustomsItemsWeightsInOunces = customsFormEnabled
    ? customsItems.reduce(
        (prev: number, currItem: CustomsLineItemSubformValues) =>
          prev +
          toOunces({
            pounds: Number(currItem.weightPounds),
            ounces: Number(currItem.weightOunces),
          }),
        0,
      )
    : 0;

  if (customsFormEnabled && totalCustomsItemsWeightsInOunces > weightInOunces) {
    return `The weight of the items in your customs form (${formatWeight({
      pounds: 0,
      ounces: totalCustomsItemsWeightsInOunces,
    })}) is higher than the total weight (${formatWeight({ pounds: 0, ounces: weightInOunces })})`;
  }
  return undefined;
};

/**
 * Validate 2d/3d package dimensions
 */
export type Dimensions3Value = {
  length: number;
  width: number;
  height: number;
};
export type Dimensions2Value = {
  length: number;
  width: number;
};
export type DimensionsConfig = {
  maxCombinedLength: number;
  maxLengthPlusGirth: number;
  maxLongSide: number;
  maxMiddleSide: number;
  maxShortSide: number;
  minShortSide: number;
  minLongSide: number;
  minMiddleSide: number;
};

/**
 * Check the dimensions of a 2d package are within bounds given by the config (and MAX_ENVELOPE length)
 */
export const packageDimensions2d: Validator<Dimensions2Value, DimensionsConfig> = (
  value,
  config,
) => {
  if (!config) return undefined;

  const guardedValue = value || { length: 0, width: 0 };
  const { length, width } = guardedValue;

  const MAX_ENVELOPE = 18;

  const { minLongSide = 0, minMiddleSide = 0 } = config;

  // sort values
  const [longSide, shortSide] = sortDims([length, width]);

  // Envelope/Softpack
  if (length === 0 && width === 0) {
    return undefined;
  }

  if (length === 0 || width === 0) {
    return 'Enter both dimensions';
  }

  // Max Dimensions
  if (shortSide > MAX_ENVELOPE || longSide > MAX_ENVELOPE) {
    return `For envelopes larger than ${MAX_ENVELOPE}″ in either direction, you must use the box package type and enter all 3 dimensions of your final package`;
  }

  // Min Dimensions
  if (shortSide < minMiddleSide || longSide < minLongSide) {
    return `Your envelope is too small! The minimum dimensions are ${minLongSide}x${minMiddleSide}″`;
  }

  return undefined;
};

/**
 * Check the dimensions of a 3d package are within bounds given by the config
 */
export const packageDimensions3d: Validator<Dimensions3Value, DimensionsConfig> = (
  value,
  config,
) => {
  if (!config) return undefined;
  const guardedValue = value || { length: 0, width: 0, height: 0 };
  const { length, width, height } = guardedValue;

  const {
    maxCombinedLength,
    maxLengthPlusGirth,
    maxLongSide,
    maxMiddleSide,
    maxShortSide,
    minShortSide = 0,
    minLongSide = 0,
    minMiddleSide = 0,
  } = config;

  let combinedLengthAndGirth = 0;
  let combinedLength = 0;

  // Combined Length + Girth: L + (W + H) * 2
  // Height will be 2" for all Envelopes/Softpacks
  const calcCombinedLengthAndGirth = (shortSide: number, middleSide: number, longSide: number) =>
    longSide + (middleSide + shortSide) * 2;

  // Combined Length: L + W + H
  // Height will be 2" for all Envelopes/Softpacks
  const calcCombinedLength = (shortSide: number, middleSide: number, longSide: number) =>
    shortSide + middleSide + longSide;

  // sort values
  const [longSide, middleSide, shortSide] = sortDims([length, width, height]);

  combinedLengthAndGirth = calcCombinedLengthAndGirth(shortSide, middleSide, longSide);
  combinedLength = calcCombinedLength(shortSide, middleSide, longSide);
  if (length === 0 || width === 0 || height === 0) {
    return 'Enter all 3 dimensions of your package';
  }

  // Min Dimensions
  if (shortSide < minShortSide || middleSide < minMiddleSide || longSide < minLongSide) {
    return `Your package is too small! The minimum dimensions are ${minLongSide}x${minMiddleSide}x${minShortSide}″`;
  }

  // Max Combined Length + Girth: L + (W + H) * 2
  if (combinedLengthAndGirth > maxLengthPlusGirth) {
    return `Your package is too big! The maximum Length plus Girth (Width x 2 + Height x 2) is ${maxLengthPlusGirth}″, but your package is ${combinedLengthAndGirth}″`;
  }

  // Max Combined Length: L + W + H
  if (combinedLength > maxCombinedLength) {
    return `Your package is too big! The maximum combined Length (Length + Width + Height) is ${maxCombinedLength}″, but your box is ${combinedLength}″`;
  }

  // Max Dimensions
  if (shortSide > maxShortSide || middleSide > maxMiddleSide || longSide > maxLongSide) {
    return `Your package is too big! The maximum dimensions are ${length}x${width}x${height}″`;
  }

  return undefined;
};

/**
 * Check the dimensions of a 2d package are within bounds given by the config (and MAX_ENVELOPE length)
 */
export const packageDimensions2dPublicRates: Validator<Dimensions2Value, DimensionsConfig> = (
  value,
  config,
) => {
  if (!config) return undefined;

  const guardedValue = value || { length: 0, width: 0 };
  const { length, width } = guardedValue;
  // Pass if all fields are left empty
  if (length === 0 && width === 0) {
    return undefined;
  }
  return packageDimensions2d({ length, width }, config);
};

/**
 * Check the dimensions of a 3d package are within bounds given by the config
 */
export const packageDimensions3dPublicRates: Validator<Dimensions3Value, DimensionsConfig> = (
  value,
  config,
) => {
  if (!config) return undefined;
  const guardedValue = value || { length: 0, width: 0, height: 0 };
  const { length, width, height } = guardedValue;
  // Pass if all fields are left empty
  if (length === 0 && width === 0 && height === 0) {
    return undefined;
  }

  return packageDimensions3d({ length, width, height }, config);
};

/**
 * Check the endDate is equal to or greater than the start date
 */

export type DateRangeValue = {
  startDate: Date;
  endDate: Date;
};
export type DateRangeConfig = {
  isTimeComparison: boolean;
};
export const endDateAfterStartDate: Validator<DateRangeValue, DateRangeConfig> = (
  value,
  config,
) => {
  if (!config) return undefined;
  const timeOrDate = config.isTimeComparison ? 'time' : 'date';
  if (value == null) {
    return 'Enter valid dates';
  }

  if (compareAsc(value.startDate, value.endDate) >= 0) {
    if (timeOrDate === 'time') {
      return 'Change earliest time and latest time';
    }
    return `The end date must be after the start date`;
  }
  return undefined;
};

/**
 * US Zipcode
 */
export const usZip: Validator<string> = (value) => {
  if (value == null || !/^\d{5}(-\d{4})?$/.test(value)) {
    return 'Enter a valid zipcode';
  }

  return undefined;
};

/**
 * PO Box
 */
export const noPoBox: Validator<string> = (value) => {
  if (
    value == null ||
    /^P. Box|^P.0 Box|^P. box|^P. Box|^P. BOX|^P. 0|^Box |^P. I. Box|^P. O|^p. o box|^P. O.|^PO |^P.O |^P.O./.test(
      value,
    )
  ) {
    return 'Physical address required, no PO boxes allowed';
  }

  return undefined;
};

/**
 * Canada Zipcode
 */
export const canadaZip: Validator<string> = (value) => {
  if (value == null || !/^[ABCEGHJKLMNPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d$/i.test(value)) {
    return 'Enter a valid Canadian zipcode';
  }

  return undefined;
};

/**
 * US and Canada Zipcode
 */
export const uscanZip: Validator<string> = (value) => {
  if (usZip(value) === undefined || canadaZip(value) === undefined) {
    return undefined;
  }

  return 'Enter a valid US or Canada zipcode';
};

/**
 * Validate that value is boolean true
 */
export const isTrue: Validator<boolean> = (value) => {
  if (value !== true) {
    return 'This field is required';
  }

  return undefined;
};

/**
 * Validate that string at least two words seperated by at least one space
 */

export const fullName: Validator<string> = (value) => {
  const trimmed = value?.trim() ?? '';
  if (!/[ ]/.test(trimmed)) {
    return 'Carriers require your full name';
  }

  return undefined;
};

/**
 * Validate that string is only letters / spaces
 */

export const lettersSpaces: Validator<string> = (value) => {
  if (value == null || !/^[a-z ]+$/i.test(value)) {
    return 'Please use letters only';
  }

  return undefined;
};

/**
 * Validate that a credit card's mm/yy expiry date is after the current date
 * As this method is chained after the mmyy method, it is assumed that value has the format "mm/yy"
 */
export const futureDate: Validator<string> = (value) => {
  if (!value) return 'Enter a valid date';

  const [month, year] = value.split('/');
  const expiryDateStartOfMonth = newDate(`20${year}-${month}-01`); // turns mm/yy to yyyy/mm/01
  const now = newDate('now');
  const startOfThisMonth = newDate(`${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-01`);

  if (startOfThisMonth.getTime() > expiryDateStartOfMonth.getTime()) {
    return 'Enter an unexpired date';
  }

  return undefined;
};

/**
 * Validate that a string has the format MM/YY
 */
export const mmyy: Validator<string> = (value) => {
  if (!value) return undefined;
  const [month, year] = value.split('/');
  const errors = [];
  if (!/^(0[1-9]{1}|1[0-2]{1})$/.test(month)) {
    errors.push('month');
  }
  if (!/^([0-9]{2})$/.test(year)) {
    errors.push('year');
  }
  return errors.length ? `Enter a valid ${errors.join(' and ')}` : undefined;
};

export type ExceptionsConfig = {
  exceptions: string[];
  errorMessageField: Record<string, string>;
  keyNames?: Map<string, string>;
};

/**
 * Validate that a string is not in a list of other strings (expect for the values listed in the exceptionsConfig)
 */
export const unique: Validator<string, ExceptionsConfig> = (
  value,
  exceptionsConfig,
  otherValues,
) => {
  if (!value || !exceptionsConfig || !otherValues) return undefined;
  if (exceptionsConfig.exceptions.includes(value)) return undefined;
  const entries = Object.entries(otherValues).filter((v) => v[1] === value);
  const keys = entries
    .map((e) => {
      if (exceptionsConfig.keyNames?.has(e[0])) {
        return exceptionsConfig.keyNames.get(e[0]);
      }
      return e[0];
    })
    .join(', ');
  const readableErrorField = exceptionsConfig.errorMessageField[value];
  return entries.length > 1
    ? `You chose more than one field for ${readableErrorField}: ${keys}`
    : undefined;
};

export type MaxConfig = { max: number };

export const maxPassthroughFields: Validator<string, MaxConfig> = (
  value,
  maxConfig,
  otherValues,
) => {
  if (!value || !maxConfig?.max || !otherValues) return undefined;
  const maxAmountReached =
    Object.values(otherValues).filter((v) => v === 'PASSTHROUGH').length > maxConfig.max;

  return maxAmountReached && value === 'PASSTHROUGH'
    ? `You cannot have more than ${maxConfig.max} passthrough field${maxConfig.max > 1 ? 's' : ''}`
    : undefined;
};

/**
 * Validate specifically that only one name type is mapped.
 * First, middle and last are all allowed, but any combination of full name and specific name are not
 */
export const oneNameType: Validator<string> = (value, _, otherValues) => {
  if (!value || !otherValues) return undefined;
  const names = ['FIRST_NAME', 'MIDDLE_NAME', 'LAST_NAME', 'FULL_NAME'];
  if (!names.includes(value)) return undefined; // no name fields mapped, this check is irrelevant
  if (!Object.values(otherValues).includes('FULL_NAME')) return undefined; // fullName is not mapped
  return Object.values(otherValues).filter((v) => names.includes(v)).length > 1
    ? "Either map just the First and Last Name or just the Full Name, but don't map all of them!"
    : undefined;
};

export type RequiredFieldsConfig = {
  fields: Record<string, string>;
};

/**
 * Validate that an object containing all values in a form contains each required field defined in the config.
 */
export const requiredFields: Validator<Record<string, string>, RequiredFieldsConfig> = (
  valueObject,
  config,
) => {
  if (!valueObject || !config) return undefined;

  const unmappedRequiredFields = Object.keys(config.fields)
    .filter((key) => !Object.values(valueObject).includes(key))
    .map((key) => config.fields[key]);

  return unmappedRequiredFields.length > 0
    ? `You need to map required ${
        unmappedRequiredFields.length > 1 ? 'fields' : 'field'
      }: ${unmappedRequiredFields.join(', ')}`
    : undefined;
};

/**
 * Validate that an object containing all values in a form contains at least BOTH first and last names, OR fullname, OR company.
 */
export const namesOrCompany: Validator<Record<string, string>> = (valueObject) => {
  if (!valueObject) return undefined;
  const values = Object.values(valueObject);
  return values.includes('FULL_NAME') ||
    values.includes('COMPANY') ||
    (values.includes('FIRST_NAME') && values.includes('LAST_NAME'))
    ? undefined
    : `You must map either the First and Last Name or just the Full Name, or at least the Company.`;
};
export type CharactersConfig = {
  characters: RegExp;
};

/**
 * Allow only a certain set of characters in input
 */
export const validCharacters: Validator<string, CharactersConfig> = (value, charactersConfig) => {
  if (!value || !charactersConfig) {
    return undefined;
  }
  let invalidCharacters = value.replace(charactersConfig.characters, '');

  // remove duplicates
  invalidCharacters = Array.from(new Set(invalidCharacters)).join(', ');
  if (!invalidCharacters) {
    return undefined;
  }
  const message =
    invalidCharacters.length > 1
      ? 'The following characters are not allowed:'
      : 'The following character is not allowed:';
  return `${message} ${invalidCharacters}`;
};

/**
 * Ban a certain set of characters in input
 */
export const invalidCharacters: Validator<string, CharactersConfig> = (value, charactersConfig) => {
  if (!value || !charactersConfig) {
    return undefined;
  }
  const matches = value.match(charactersConfig.characters);
  if (!matches?.length) {
    return undefined;
  }

  // remove duplicates
  const uniqueMatches = Array.from(new Set(matches));
  const message =
    uniqueMatches.length > 1
      ? 'The following characters are not allowed:'
      : 'The following character is not allowed:';
  return `${message} ${uniqueMatches.join(', ')}`;
};

export const internationalTaxId: Validator<string> = (value) => {
  if (!value) {
    return undefined;
  }
  if (
    value.match(/^IM([0-9]){10}$/) ||
    value.match(/^GB([0-9]){9}$/) ||
    value.match(/^VOEC([0-9]){7}$/) ||
    value.match(
      /^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK|UK)([0-9]){7,}$/,
    )
  ) {
    return undefined;
  }
  if (value.match(/IM\s?#?([0-9])+/i)) {
    return 'A valid IOSS number needs to be specified (i.e. IM1234567890)';
  }
  if (value.match(/GB\s?#?([0-9])+/i)) {
    return 'A valid HRMC number needs to be specified (i.e. GB123456789)';
  }
  if (value.match(/VOEC\s?#?([0-9])+/i)) {
    return 'A valid VOEC number needs to be specified (i.e. VOEC1234567)';
  }
  if (
    value.match(
      /(EORI|EORI:)?\s?#?(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK|UK)([0-9])+/i,
    )
  ) {
    return 'A valid EORI number needs to be specified (i.e. DE123456789012345)';
  }
  return undefined;
};

export const hsCode: Validator<string> = (value) => {
  if (!value) return undefined;
  // strip dots and dashes from harmonization code
  const strippedValue = value.replace(/\.|-/g, '');
  if (!value.match(/^(\d|\.|-)+$/)) {
    return 'Only numbers, periods and dashes are allowed in HS codes';
  }
  if (strippedValue.length < 6) {
    return 'A minimum of 6 digits is required for valid HS codes';
  }
  if (strippedValue.length > 14) {
    return 'A maximum of 14 digits is allowed for valid HS codes';
  }
  return undefined;
};

/**
 * Check a US phone number
 */
